import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { InputSlider, InputSliderType } from "../InputSlider/InputSlider";

interface ISliderInputFormFieldProps {
  name: string;
  label: string;
  sliderType: InputSliderType;
  min: number;
  max: number;
  sliderStep: number;
  debounceAction?: () => void;
  suffix?: string;
  infoPopover?: IInfoPopoverProps;
  disabled?: boolean;
  triggerValidation?: boolean; // Useful/needed when validation depends on other input values
}

export const SliderInputFormField = ({
  label,
  name,
  sliderType,
  min,
  max,
  sliderStep,
  debounceAction,
  infoPopover,
  disabled,
  triggerValidation,
}: ISliderInputFormFieldProps) => {
  const {
    control,
    trigger,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <InputSlider
            {...field}
            min={min}
            max={max}
            step={sliderStep}
            setValue={(value: number) => {
              field.onChange(value);
              if (triggerValidation) trigger();
            }}
            label={label}
            debounceAction={debounceAction}
            sliderType={sliderType}
            infoPopover={infoPopover}
            error={error}
            disabled={disabled || isSubmitting}
          />
        );
      }}
    />
  );
};
