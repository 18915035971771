/* eslint-disable import/no-extraneous-dependencies */
import { useStores } from "netbank-shared/src/hooks";
import { AccountType, InvoiceDistributionFormatType, InvoiceUpdateStatus } from "../models/CustomerProducts";

export interface IChangeInvoiceParams {
  loading: boolean;
  status: InvoiceUpdateStatus;
  prevInvoiceMethod: InvoiceDistributionFormatType | undefined;
  newInvoiceMethod: InvoiceDistributionFormatType | undefined;
  changeInvoice: () => void;
  setUpdatedInvoiceMethod: (invoiceMethod: InvoiceDistributionFormatType) => void;
  cleanup: () => void;
}

export const useDetermineChangeInvoiceParamsByAccountType = (
  accountType: AccountType | undefined,
): IChangeInvoiceParams => {
  const { loanStore, leasingStore } = useStores();

  const emptyResponse: IChangeInvoiceParams = {
    loading: false,
    status: InvoiceUpdateStatus.Initial,
    prevInvoiceMethod: undefined,
    newInvoiceMethod: undefined,
    changeInvoice: () => {},
    setUpdatedInvoiceMethod: () => {},
    cleanup: () => {},
  };
  if (!accountType) return emptyResponse;

  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        loading: loanStore.updatingInvoiceMethod,
        status: loanStore.invoiceUpdateStatus,
        prevInvoiceMethod: loanStore.currentAccount?.invoiceDistributionFormat,
        newInvoiceMethod: loanStore.newInvoiceMethod,
        changeInvoice: () => loanStore.changeInvoiceMethod(),
        setUpdatedInvoiceMethod: (invoiceMethod: InvoiceDistributionFormatType) =>
          loanStore.setUpdatedInvoiceMethod(invoiceMethod),
        cleanup: () => loanStore.resetChangeInvoiceMethod(),
      };
    case AccountType.PrivateLeasing:
    case AccountType.Leasing:
      return {
        loading: leasingStore.updatingInvoiceMethod,
        status: leasingStore.invoiceUpdateStatus,
        prevInvoiceMethod: leasingStore.currentAccount?.invoiceDistributionFormat,
        newInvoiceMethod: leasingStore.newInvoiceMethod,
        changeInvoice: () => leasingStore.changeInvoiceMethod(),
        setUpdatedInvoiceMethod: (invoiceMethod: InvoiceDistributionFormatType) =>
          leasingStore.setUpdatedInvoiceMethod(invoiceMethod),
        cleanup: () => leasingStore.resetChangeInvoiceMethod(),
      };
    default:
      return emptyResponse;
  }
};
