import React from "react";
import { observer } from "mobx-react";
import FocusTrap from "focus-trap-react";
import styles from "./Popup.scss";
import { IPopup } from "netbank-shared/src/libs/models/Content/Popup";
import { useStores } from "netbank-shared/src/hooks";

type IPopupProps = IPopup;

export const Popup = observer(({ children, open, element }: IPopupProps) => {
  const { uiStore } = useStores();
  const classes = [styles.wrapper];

  if (open) {
    classes.push(styles.open);
  }

  const desktopWidth = 360;
  const elementRect = element?.current?.getBoundingClientRect();
  const left = elementRect?.left || 0;
  let top = 0;

  if (element?.current && elementRect) {
    const paddingTop = 14;
    top = window.pageYOffset + elementRect.top + element.current.offsetHeight + paddingTop;
  }

  const position = { top, left: 16 };

  if (!uiStore.isMobile && element?.current) {
    position.left = left + element.current.offsetWidth - desktopWidth;
  }
  return (
    <div className={classes.join(" ")} style={position}>
      <FocusTrap>
        <div className={styles.inner}>{children}</div>
      </FocusTrap>
    </div>
  );
});
