/* eslint-disable no-undef */
import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { Locale, TerminateInsuranceReason } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { ITerminateInsurancePage } from "libs/models/Content/Page";
import { ITerminateInsuranceReason } from "libs/models/Content/TerminateInsuranceReason";
import { ListItem } from "libs/models/Content/ListItem";

export interface ITerminateInsuranceForm {
  terminationReason: string;
  phone: string;
  termsAccepted: boolean;
  message?: string;
}

interface ITerminateInsuranceProps {
  locale: Locale;
  page: ITerminateInsurancePage;
  requiredError?: string;
  invalidError?: string;
}

export const getTerminateInsuranceReasons = (terminateInsuranceReasons: ITerminateInsuranceReason[]): ListItem[] =>
  Object.keys(TerminateInsuranceReason).map((key, index) => ({
    index,
    enumValue: (TerminateInsuranceReason as KeyObject<string>)[key],
    value:
      terminateInsuranceReasons?.find(
        (terminateInsuranceReason) => terminateInsuranceReason.reason === (key as TerminateInsuranceReason),
      )?.label || tx(`terminateInsurance.reasons.${key}`),
    label:
      terminateInsuranceReasons?.find(
        (terminateInsuranceReason) => terminateInsuranceReason.reason === (key as TerminateInsuranceReason),
      )?.label || tx(`terminateInsurance.reasons.${key}`),
  }));

export const generateTerminateInsuranceSchema = (
  props: ITerminateInsuranceProps,
): yup.ObjectSchema<ITerminateInsuranceForm> => {
  const { terminateInsuranceReason, terminateInsuranceReasons, phone, termsCheckbox } = props.page;
  const invalidPhoneError = phone?.error || tx("validators.phone");
  const termsAcceptanceError = termsCheckbox?.error || tx("validators.required");
  const invalidTerminateInsuranceReasonError = terminateInsuranceReason?.error || tx("validators.required");

  const terminateInsuranceReasonOptions = getTerminateInsuranceReasons(terminateInsuranceReasons);
  const validReasons = terminateInsuranceReasonOptions.map((item) => item.value);

  const schema = yup.object({
    terminationReason: yup
      .string()
      .oneOf(validReasons, invalidTerminateInsuranceReasonError)
      .required(invalidTerminateInsuranceReasonError),
    message: yup.string().optional(),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
    termsAccepted: yup.boolean().required(termsAcceptanceError).oneOf([true], termsAcceptanceError),
  });

  return schema;
};
