import React from "react";
import { IConversation } from "netbank-shared/src/libs/models/Message/Conversations";
import { tx } from "netbank-shared/src/libs/i18n";
import { useStores } from "netbank-shared/src/hooks";
import { IMessageInboxPage } from "netbank-shared/src/libs/models/Content/Page";
import { InboxTabs } from "netbank-shared/src/libs/models/Message/Inbox";
import { MessageSender } from "netbank-shared/src/libs/models/Content/Enums";
import { Button } from "~views/shared";
import styles from "./MessageInbox.scss";
import rootStyles from "~views/pages/Root.scss";
import archiveIcon from "~assets/archive.svg";
import mailIcon from "~assets/mail.svg";
import mailOpenIcon from "~assets/mail-open.svg";
import { DynamicLink } from "~views/shared/DynamicLink/DynamicLink";
import { formatDate, processMessagePreviewHtml } from "netbank-shared/src/libs/utils";

interface IMessageRowProps {
  data: IConversation;
  displayLastSent?: boolean;
}

export const ConversationRow = ({ data, displayLastSent }: IMessageRowProps) => {
  const [open, setOpen] = React.useState(false);
  const [archiving, setArchiving] = React.useState(false);
  const { contentStore, messageStore } = useStores();
  const page = contentStore.currentPage as IMessageInboxPage;
  const wrapperClasses = [styles.conversationWrapper];
  const conversationTitleClasses = [styles.title];
  const dateClasses = [styles.date];
  const actionsClasses = [styles.conversationActions];
  const sentTabIsActive = messageStore.activeTab === InboxTabs.Sent;

  if (open) {
    wrapperClasses.push(styles.open);
    dateClasses.push(styles.hidden);
  }

  if (data.hasUnreadMessages && !sentTabIsActive) {
    conversationTitleClasses.push(styles.unread);
    dateClasses.push(styles.unread);
  }

  if (open && !data.isArchived) {
    actionsClasses.push(styles.open);
  }

  let createdDate = "";
  let previewMessage;

  if (displayLastSent) {
    const lastSentIndex = data.messages.findIndex((m) => m.createdBy !== MessageSender.Santander);
    createdDate = formatDate(data.messages[lastSentIndex]?.createdDate) || "";
    previewMessage = data.messages[lastSentIndex];
  } else {
    createdDate = formatDate(data.messages[0]?.createdDate) || "";
    previewMessage = data.messages.find((m) => !!m.body);
  }

  return (
    <div className={wrapperClasses.join(" ")}>
      <div className={styles.conversationRow}>
        <DynamicLink
          to={`${page.newMessagePage}?conversationId=${data.conversationId}`}
          className={styles.conversationLink}
        >
          {!open && <div className={dateClasses.join(" ")}>{createdDate}</div>}
          <div className={styles.conversationContent}>
            <div className={styles.tag}>{tx("misc.message")}</div>
            <div className={conversationTitleClasses.join(" ")}>{data.title}</div>
            {previewMessage && (
              <div className={styles.messageBodyPreview}>
                {processMessagePreviewHtml(previewMessage.body, previewMessage.direction)}
              </div>
            )}
          </div>
        </DynamicLink>
        <div className={styles.conversationOptions}>
          <div className={styles.outsideOptions}>
            {!data.isArchived && (
              <Button onClick={() => setOpen(!open)} className={styles.expandButton}>
                <div className={rootStyles.ellipsis} />
              </Button>
            )}
            {!sentTabIsActive && (
              <img
                src={data.hasUnreadMessages ? mailIcon : mailOpenIcon}
                className={styles.mailIcon}
                alt={data.hasUnreadMessages ? "mail-icon-closed" : "mail-icon-open"}
              />
            )}
          </div>

          <div className={actionsClasses.join(" ")}>
            <div className={styles.archive}>
              {archiving && (
                <div className={styles.spinnerWrapper}>
                  <div className={[rootStyles.loading, rootStyles.spinner].join(" ")} />
                </div>
              )}
              {!archiving && (
                <Button
                  className={styles.archiveButton}
                  onClick={async () => {
                    setArchiving(true);
                    await messageStore.archive(data.conversationId);
                    await messageStore.getConversations(false);
                  }}
                >
                  <div className={styles.iconLabelWrapper}>
                    <img className={styles.archiveIcon} src={archiveIcon} alt="archive-icon" />
                    <span>{tx("message.toArchive")}</span>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
