import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import { SwiperProps, SwiperSlideProps } from "swiper/react";

interface CustomSwiperProps extends SwiperProps {
  customPaginationWrapper?: string;
}

// Wrapper component for Swiper. Using recommended "Swiper Element" registration setup
export const CarouselComponent = (props: CustomSwiperProps) => {
  const swiperRef = useRef<any>(null);
  const { children, customPaginationWrapper, ...rest } = props;

  useEffect(() => {
    if (swiperRef?.current) {
      // Register Swiper web component
      register();

      if (customPaginationWrapper && rest.pagination) {
        // Add custom target pagination element
        const paginationElement = `.${customPaginationWrapper} .custom-swiper-pagination`;
        rest.pagination =
          typeof rest.pagination === "boolean"
            ? { enabled: true, el: paginationElement }
            : { ...rest.pagination, el: paginationElement };
      }

      // Assign props to swiper element
      Object.assign(swiperRef.current, rest);

      // initialize swiper
      swiperRef.current.initialize();
    }
  }, [swiperRef]);

  return (
    <div className={customPaginationWrapper}>
      <swiper-container init={false} ref={swiperRef}>
        {children}
      </swiper-container>
      {customPaginationWrapper && <div className="custom-swiper-pagination" />}
    </div>
  );
};
export const CarouselSlide = (props: SwiperSlideProps) => {
  const { children, ...rest } = props;

  return <swiper-slide {...rest}>{children}</swiper-slide>;
};
