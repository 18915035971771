import { IGdprData } from "libs/models/Gdpr/Gdpr";

export const mockGdprData: IGdprData = {
  nodes: [
    {
      key: "29092ca7-3c0e-4487-be83-c7d707fcd240",
      text: "Sopimus : 718130",
      selectable: true,
      isError: false,
      nodes: [
        {
          key: "33d45518-df91-4130-89c2-a3b18d4a9f3c",
          text: "Vuokra : 519,42",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "56c8f1d3-3cc8-45e7-a726-8844f908bf94",
          text: "SopimusKohteet",
          selectable: true,
          isError: false,
          nodes: [
            {
              key: "bddd29c8-c29b-4d02-9bdf-1b42fa7192a3",
              text: "Sopimuskohde",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "c3cce9e4-e19c-437c-b62f-937197ba0718",
                  text: "Kohde",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "94259a27-843c-411f-8c2c-fe6e8aba9a34",
                      text: "Merkki : Nissan",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                    {
                      key: "7ae517af-5c64-40b3-8a2d-12d57317c796",
                      text: "Malli : TDZALDYJ12TGA-AEEFNNE",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          key: "0f5617f4-b0f7-4f76-8e98-a9fdfe074547",
          text: "SopimuksenLaskut",
          selectable: true,
          isError: false,
          nodes: [
            {
              key: "ea273671-06c4-4a09-bdcd-d5ab45d1c721",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "bdf6c00d-2ddd-495a-88ad-a72e5f18aa54",
                  text: "Laskunumero : 265652989",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "773e93a0-bd4e-4fb2-a431-8faf52536dce",
                  text: "Paivays : 2022-03-16",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3337cde4-1d53-43a5-9094-ab320c5ac806",
                  text: "Erapaiva : 2022-03-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4805a54c-038f-4d29-93f7-3049749433ce",
                  text: "ViimeisimmanMaksunPaivays : 2022-03-21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4fb95a8b-9906-4678-a950-d1c8bb3b56ad",
                  text: "Summa : 581.81",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "bc53a772-1135-4c5d-a8de-3803efebf9d0",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b59cc8b7-619f-456b-aa38-cbcb5d36d9f5",
                  text: "MaksettuSumma : 581.81",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2283ab56-5852-431f-ac55-e0e76e661efc",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "c758a360-b576-4f1e-b87e-8d601a0cafe2",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "ffc4b14b-b88d-46b3-8d7e-f306aed2457b",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "c5046a05-af2b-49e3-9829-24a2263dd3c3",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "8ee33eca-c88c-4f3e-bc29-b70334bb2bfc",
                          text: "AvoinSumma : 581.81",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "3a87e45b-425e-48ee-a82e-733b31127b5d",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "a4796306-2074-44e0-bbbe-419cd06b60b5",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "01289406-ce7b-4e0e-bdd4-94ceca834ad9",
                              text: "Summa : 581.81",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "fa67c04b-c10a-4bcc-b70f-165a16b85b10",
                              text: "ArvoPaiva : 2022-03-21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c67c06d2-3440-4fd4-aaa8-cf801176ca33",
                              text: "Arkistointikoodi : 2203220581103457",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "35ee7f57-12fb-4699-a7e1-de5fa46f752d",
                              text: "Maksaja : LAAKSO TAINA",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "ac5c3fc7-96ae-46ee-9437-f1dc7c90df9c",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "6de1a534-1bce-4fde-acb9-56bde7ba7ddd",
                  text: "Laskunumero : 265652990",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9444d465-d5bc-434d-a07b-94a2dbabb26f",
                  text: "Paivays : 2022-03-16",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "f8349da1-e2f7-4761-8a4c-cc2adb3efd12",
                  text: "Erapaiva : 2022-04-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d2ad43fa-8d7e-4ace-bde7-4dd877955923",
                  text: "ViimeisimmanMaksunPaivays : 2022-04-04",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "14327a26-4ac8-41ce-a7e6-f11a11cd075e",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d7c4f6d8-5dc7-4e1b-bb2b-658a2f93ceb9",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "87f14321-f11d-416e-b56a-7df72a36b302",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4fdaafe0-a5c4-4df7-b3fb-fab579de018b",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "6288e9f4-ac83-4d33-84e3-11c10e10fc33",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "4a369656-fec8-43db-86df-e8a3db483d7f",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b282c036-8adc-428a-8301-779259f65dd7",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "bf97e323-5d4b-4518-9a4f-e3c9a02db9c3",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f624b553-a80a-4fbe-92ff-f10aff8aed91",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "3157fe48-68ab-43b0-92ce-1dd9aadccbbf",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "108c4cea-8b7b-45d5-ba4d-b80a04d7e9c2",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c8d6a774-5143-4a49-b2ec-2403c5b195fc",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "76191ca3-f0ff-4a86-bba3-487938db6695",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "b1fc8e5b-c172-455a-87cd-b9c842c9a338",
                              text: "ArvoPaiva : 2022-04-04",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "3789b70a-d70c-4095-a8a7-df6f8660a1f5",
                              text: "Arkistointikoodi : 2204047066411060",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "d7548b31-6f6d-4678-ac32-e0e74764ae34",
                              text: "Maksaja : LAAKSO TAINA",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "8596c529-fdc0-4145-a693-8d3f2a018608",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "d9291b57-aac9-4f52-8b3a-27212b019e48",
                  text: "Laskunumero : 265859110",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "fa0d09a3-ae44-49ac-9b24-401d9416a400",
                  text: "Paivays : 2022-04-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "966e58c0-2947-4685-b067-ec1220fc8aa0",
                  text: "Erapaiva : 2022-05-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "10cff6b9-8558-4578-bd52-512791a2319c",
                  text: "ViimeisimmanMaksunPaivays : 2022-05-06",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e98ceb9f-6666-4dd7-92af-8a7376e6ed9a",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0617173b-82ce-48fb-a3c5-380c64d87b7b",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6cf80958-7f12-47f3-9d68-65621c568278",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "45a7ac46-ea49-4515-a552-749e5af50872",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "db254839-a977-4c26-8de9-90e37037e3bd",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "32feec2d-07ce-42f6-9ab6-38272fd61fac",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "496f1b2d-9e4e-4741-a54a-e5025c0efa2c",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "89c72875-f9b9-402c-bf3d-b249c3f4d761",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f2ea9750-49a2-4c9c-a7a9-bf7684791186",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "e7724e25-4994-4702-9a6f-6a571704bd5c",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "31fbf4da-f5ee-4daf-afe9-0e529a2ef4eb",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "ef8efbd1-725a-4c4f-ade9-46893833ecb3",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "7db9562c-11a6-410b-9622-9af6a299a9e0",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c8ccc672-90b8-4264-b470-119b42e268b4",
                              text: "ArvoPaiva : 2022-05-06",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "1d0631da-7c2f-49ac-9163-aad9b33264e4",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "17f4ebc3-ed2d-4847-a0ba-8830dff1f064",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "c9fe59c4-5e2e-4427-8ef3-3617d8bcf7ab",
                  text: "Laskunumero : 266148748",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e62fac31-2e6d-45fb-81da-782ef04ffd2f",
                  text: "Paivays : 2022-05-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d57dd803-0f3d-47e1-ab1f-ab4be8c3d369",
                  text: "Erapaiva : 2022-06-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "38445811-d2ff-48f5-a73c-e8939c8b3bb4",
                  text: "ViimeisimmanMaksunPaivays : 2022-06-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d287044c-c0f6-4cc5-8cc4-2d081897162d",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2170b1cb-b436-4382-b3d4-679d5f0fb09d",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "8015330f-4529-44c9-967a-99a79972a3fe",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6b5bdfbb-8faf-42b4-9ffa-49ee93c8696c",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "76897f88-9bb9-4e39-83bf-64fec1a821f6",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "dff7e192-3e69-4576-9bf2-6a3ba1e4b248",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "6f5ecc08-e352-4b6f-9d89-ad37407fc20b",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "f1e5445b-d49f-4d7a-b8b9-3d8fcdd4b368",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9b118438-d117-4ba8-b867-c293aa32769a",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "0860261d-88ae-469f-bbb4-56a5f8934d9f",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "064189f6-a6e2-428e-8718-27ebef6181dc",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "52f20545-cab5-4613-8c7e-455c57f5b086",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "d92fc2ea-70f9-4b07-a586-c4c355aaaa94",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "91a20e7b-b418-4d1b-9e40-bf49781ddcc0",
                              text: "ArvoPaiva : 2022-06-07",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "203cd21d-bc25-4b5f-a427-bdda5cdfe975",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "e59e2d71-a3f4-40ed-b3ff-ac956262bdb2",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "c209d3ca-c1bf-4b95-b3f9-f62d53cb8d03",
                  text: "Laskunumero : 266440467",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "be7c73d6-ccb9-4212-8691-7edbbb80100f",
                  text: "Paivays : 2022-06-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2965450d-252c-4529-a47d-f2abca7ddb79",
                  text: "Erapaiva : 2022-07-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "f011a9e7-d7e3-4d47-a1cb-f6edd9622d03",
                  text: "ViimeisimmanMaksunPaivays : 2022-06-26",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "838802e2-748f-44fe-b2bf-a939d1e1308d",
                  text: "Summa : 562.67",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1ff77dcd-d7f7-4b83-8c2b-d209b11a1468",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b49c52ba-49c7-4bc8-a987-ce62cb3895d5",
                  text: "MaksettuSumma : 562.67",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "8364e13a-bfcc-49b7-968f-bd4206ae97be",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "fbc483b3-b3f3-44de-a688-6d83ecd41e46",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "fc33928d-5115-4d96-a525-5817b5655e03",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "f0c005e2-7974-4c12-baee-5640e0b02979",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "634a9b90-13ee-43a2-8a84-cdef5425360d",
                          text: "AvoinSumma : 562.67",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "a41fd5e4-6d07-4208-8f43-3da94267b965",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f02b77e4-78d5-425d-8b27-7856fcef5a40",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "31145f59-2b7d-47d2-8e72-64380d81c10e",
                          text: "MaksettuSumma : 562.67",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "692cfcd1-1e21-4453-a6a8-58353637dd4f",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "e52b9946-17ed-4b71-a0d0-0caba1ac638b",
                              text: "Summa : 562.67",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "19c30fc0-87a0-4bec-8f46-056be31a7810",
                              text: "ArvoPaiva : 2022-06-26",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "69a0fa6b-f0ba-42a2-8aa9-9491a82b5e0f",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "bd176ba5-da66-4e55-888c-d30aa7b0c5dd",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "610885a3-3fa6-4b55-b088-89f63f06d50d",
                  text: "Laskunumero : 266729090",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "371ce174-ff83-4b57-8100-b3f88ae6a67e",
                  text: "Paivays : 2022-07-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "8aecd6a3-e118-4243-bb92-33fef78e580c",
                  text: "Erapaiva : 2022-08-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "87fd875d-9b57-4a5c-ab36-117ade936088",
                  text: "ViimeisimmanMaksunPaivays : 2022-08-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "23328d75-a4fb-4c2f-872c-fb05205a1cbd",
                  text: "Summa : 562.91",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3ec00ba9-a9c7-4127-8047-5eee773e31bb",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "a6424832-7827-4ac4-8e52-0b5d6730f5e3",
                  text: "MaksettuSumma : 562.91",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b25b4897-497d-4eea-95b9-657f1af9e16b",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "c31d1b40-841d-40b5-b581-ac1438887b1e",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "e3a9dc09-9f93-4cd1-85e9-fed1644da16c",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "d9d2f684-7927-4598-88a8-a7d32d75105a",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "c29138da-65d7-4986-b061-63c14d9fbf07",
                          text: "AvoinSumma : 562.91",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "55399013-f14c-4f3a-9acb-11b5dd26ce3c",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9bf85dd6-71ab-4842-9ecf-4a2e96d00975",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "efcbf749-e2f0-43e0-9255-41f5b04039d4",
                          text: "MaksettuSumma : 562.91",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "e02506a1-450c-4eb9-b533-ccb3e66342b3",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "d421c34f-279b-4ed3-a498-b31c3c92a18b",
                              text: "Summa : 562.91",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "865d0044-207b-434f-8e14-4d1288e32482",
                              text: "ArvoPaiva : 2022-08-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7869850b-bb7f-49b4-ae05-8586362f31e0",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "9ddf8957-0923-4210-b5be-892d07817ac9",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "d94e560a-f0ae-4d7c-87e5-e412479e31f1",
                  text: "Laskunumero : 267013093",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "12ec7280-3895-4b41-94cf-4c8fffbc7e0e",
                  text: "Paivays : 2022-08-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "870b0b3b-84d1-4032-8c72-9d452f475201",
                  text: "Erapaiva : 2022-09-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "78a9505b-beee-4e25-b969-08d077ee3adb",
                  text: "ViimeisimmanMaksunPaivays : 2022-09-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "37fdd87d-09fe-42f8-88f9-a5de73044286",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "45ff0734-501a-4f06-870c-69ce66ac72f3",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1a29ff6e-fa76-4a98-b9ae-6dca1c94fa15",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "27dc3844-1a84-49f9-a6a6-2faf03314796",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "e13c93a6-08e9-4de6-a440-4ed1735f503f",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "82831d02-44a0-432b-9c80-74d25252aa65",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "a4385273-a8fd-459c-a13c-8d72fa4efb67",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "dfbd298a-eb67-4475-ada5-1a2e4080c8d7",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "0df41e74-46c4-40bc-a6ef-e88dfe4672ab",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9093594f-1ff4-4a56-9333-7bac62535376",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "ed825322-6943-4100-8aea-432383807d50",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "40719fe2-b040-4319-9fc4-410162f837ee",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "df2a1cc9-3a27-41c4-8975-05d5d8c05c06",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "e0f5acbc-5fa6-4206-867c-b3b89415f466",
                              text: "ArvoPaiva : 2022-09-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "6699555f-4c03-41ff-9f04-f7149d0d32cc",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "ae64b45d-874b-49a8-b521-0437070394d8",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "39144d48-4eb2-49d4-8f9d-9c29a162259c",
                  text: "Laskunumero : 267299532",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0381540c-1db6-43cb-a612-2348aeb3a69b",
                  text: "Paivays : 2022-09-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0fe03376-5043-4ccc-b4c9-c2d9e93b05c6",
                  text: "Erapaiva : 2022-10-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "5ea9398c-45aa-4922-959f-b16a4cbd0422",
                  text: "ViimeisimmanMaksunPaivays : 2022-10-05",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "adbc174b-2543-41d3-bbd4-d1d2bfd288b1",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "43eadd8c-97b3-46be-b5e5-aa5fb74f7efe",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7d378aa8-c88b-41f2-94fd-b797a425a646",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "95094fc1-8b88-4512-9738-8d02b8fc8ef2",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "eda71a25-f3e5-485c-b367-ed919deb414a",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "1176b74f-592e-4b5a-b0cd-5af4720b5243",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "99d4200a-fd30-4d5f-a905-ae057262edff",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "0dcac197-7694-48e0-b325-0e59d56fdb75",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "72e13031-f127-4bb3-95b5-fd68f0c89277",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "25116be1-0a85-4300-adcb-c5418aca7f42",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "a3d4e18f-d32c-46a0-94db-9656b66a2074",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "ed976857-84e9-4c81-ba0b-e64ad6da5803",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "f6af4be5-857e-4c04-a34c-e7c3b9ef491a",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "9a91441b-70b1-487e-8328-965227a2fdfd",
                              text: "ArvoPaiva : 2022-10-05",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "a498e435-5c66-468c-89fa-55e3b5bcd4e9",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "9fc8bf28-647d-421a-b21a-53c09709358b",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "c4ea0304-3b3c-4805-ac1a-1368ea448df6",
                  text: "Laskunumero : 267586610",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "792b6f71-5c92-4b73-ae28-5cf401c76509",
                  text: "Paivays : 2022-10-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4f375cfc-4e0b-405b-b5d8-c88893e3b6ff",
                  text: "Erapaiva : 2022-11-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3e901c91-0029-4d36-a92f-822dfa1ef14c",
                  text: "ViimeisimmanMaksunPaivays : 2022-11-03",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "fc9f6b95-0b41-4154-8703-84ac669eec4e",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "45e9be5f-b263-4697-93df-3884ce6929d6",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6349f908-0727-4231-9102-e52f5607330d",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0b57fd1e-f302-4119-a77f-0bc79b599474",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "e4358cf6-9c31-4575-aab4-19587a3a01a9",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "a53ee374-a270-4ce8-96cd-f1975fc009b0",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "a2296336-84c6-44d2-a104-e35d01ee41fb",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "c66cd10e-1cce-47a4-8670-2c653072193e",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f51428d0-2617-4901-93dc-4e3e01def407",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "ff4340bd-d859-438f-aa6c-3483c89dc87a",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "7d88e31d-527e-4aeb-925f-ec9a44728c32",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "dfb0cbf7-b0ec-44ea-9b63-bf9bd67a5633",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "e5afff1a-34ef-4f07-a89c-975147c4fc8c",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "9e6904b3-5112-4a27-9023-aac1f0479f62",
                              text: "ArvoPaiva : 2022-11-03",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "a767c63d-ac6c-409b-a6f8-5cbaec6925bc",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "fa2694a4-6c64-4b62-ba76-6cd0ff4e6c2e",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "7a3d738d-5477-4d15-92bb-f4fd9732ddcb",
                  text: "Laskunumero : 267867705",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2dd77ff7-c7c6-4431-a6be-dc9686242be3",
                  text: "Paivays : 2022-11-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "efda8b29-11b0-463c-901e-3255fd307ca2",
                  text: "Erapaiva : 2022-12-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "857d98a6-290a-4892-843e-cdb995f2edfd",
                  text: "ViimeisimmanMaksunPaivays : 2022-12-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4e8f25ec-de99-4326-bf14-8f45593f74bd",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0e6ab21f-0d00-4de1-bdf4-af4fa8865180",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "aef19149-6a05-405d-8a72-39c425869ae2",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d52dc5eb-26c0-46d3-ab05-e763b5921070",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "232903ac-a450-4e10-920d-9dc2241a7c99",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "6b5bdfc2-d5e1-49a4-93f3-781ad51533a6",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "1f14f5d5-0fed-47d7-a01d-f4ad2211325f",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "82c27313-1fa8-477b-9a34-1277f36fab45",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "3f313f7e-76b1-4991-ab90-63a0f19ceb77",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "d3676bee-88e2-4244-8de4-2bb8fcd247c0",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "776e60a5-ffc7-4d44-bc8f-b36ca24a10aa",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "02cf0138-32e7-49b5-bfe1-3d4615f73da1",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "8a56e0ce-cdbd-4ecb-9558-1d73118bd5b3",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "cc5240f3-c7c4-4870-b2d7-baf57995b306",
                              text: "ArvoPaiva : 2022-12-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7eddb0fc-f6f4-4ab5-b374-9b2017f93be6",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "4ee379f0-9ffa-4461-afaa-b4e123933b50",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "59d2e300-08c0-4eec-a98e-825cc5bf75bf",
                  text: "Laskunumero : 268153881",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d1b77f0d-439c-4acf-bdda-85045a5d04f8",
                  text: "Paivays : 2022-12-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "901dc25d-a159-42e4-9796-a810a7ff828a",
                  text: "Erapaiva : 2023-01-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1be519f8-b943-4347-a451-fee8a0e4ec6c",
                  text: "ViimeisimmanMaksunPaivays : 2023-01-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c0510c86-167b-4ea0-ad81-4b31d89979f5",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "295040d8-1a6b-406f-b2d0-0c360006d912",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "bc158349-66f5-4156-b5ba-6ae7c4762dcb",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "52528fb7-ef0b-4a3d-9d5d-3094c4334e0b",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "0df5a2b6-fb8b-4d8d-8345-8c8bc56cea4b",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "98823335-db4f-4497-836f-1ba3f4b3eb51",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "9ea22e2d-361d-45c8-b14e-f66dbc02e0fb",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "619e4455-e18d-43e8-9c1b-3809eaf42220",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "7a806acf-9378-46a8-8b03-98da2843ea5f",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c81bb56b-f929-4e58-8225-68c266c20385",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "d323e224-8dde-47cd-a1d9-ccc0d699c530",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "69ece8a3-4c8d-4591-b590-4223bd7ab42b",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "a8d5acee-24d8-4aa1-bde0-8d93a26a9337",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "926aebee-b902-4d48-a070-3eaac4bd74ec",
                              text: "ArvoPaiva : 2023-01-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7af7fb28-89e8-410a-92ac-80d7a065c53c",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "318d28f0-b2ff-4403-8eb4-dd7dde654d62",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "373a2216-8cbf-4670-a5fe-25727ac8834b",
                  text: "Laskunumero : 268434113",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "ef13695d-85e0-4c36-9c03-d75e3cd378ac",
                  text: "Paivays : 2023-01-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "31cd9204-779f-40fb-8ab7-f000b799ec8b",
                  text: "Erapaiva : 2023-02-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3d474047-9925-4d98-a831-810283be556d",
                  text: "ViimeisimmanMaksunPaivays : 2023-02-04",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "abab3211-a880-45d0-9ebe-e97cc7086a14",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7a1d2513-119b-4090-a340-f37ae100da32",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "334ebb49-9be9-4484-9001-da0474cd1d6a",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1f0fc2d0-8988-4d32-bb34-ee9cd88533fd",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "4e9cd090-f9af-40c8-858f-533b2cc2939d",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "107891b6-f85b-4218-9900-0c25738d74d9",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "66b67c72-9428-444b-af54-a58b7b5ee0d9",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "90b5dabb-2912-4563-9287-8d84617382ee",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9b38b911-658b-4b54-b280-a185040d9597",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "726afb8b-c34e-4f3b-93da-58def2b8f7eb",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9727005c-799f-4d3d-97f2-f49b042694c5",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "b85a61b5-27e5-47a1-acbd-272266fc5358",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "6c7f605f-243b-463d-b1b1-9fffad8cc038",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "5e58fa20-04fd-4ab2-b07c-535ebd9163f0",
                              text: "ArvoPaiva : 2023-02-04",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "6e0dd4bb-aa53-4074-ae71-72126a1d4fc0",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "05c29ae0-9a5a-4421-80d6-e2857ae06c1e",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "b11864db-21da-495e-977f-937494645ead",
                  text: "Laskunumero : 268709103",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1edbe915-5d6a-49ce-a261-df3327931c47",
                  text: "Paivays : 2023-02-05",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9dda4b02-bd06-4150-83a8-60360aa12b15",
                  text: "Erapaiva : 2023-03-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7267eb8f-e625-42f5-98fe-022252bebafe",
                  text: "ViimeisimmanMaksunPaivays : 2023-03-04",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1b7a2ad8-9883-4530-b8e5-2c68e1894454",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c37ce0cf-3f8a-418f-b2de-b60c7eb4bd7b",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9984187a-37ff-42a5-8fc3-9f2f90ce1f9d",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "521a7889-bf03-4de2-a33e-15a6b3817186",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "eaca2379-40b1-4e8b-a8c4-5576ffd27efe",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "11afaf03-6516-4d97-bbfd-0bc2e387cb34",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "18e63fb2-2309-4d91-a1a3-a181aec5d35b",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "d229b5a9-58e9-4706-aa84-cdd773838727",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "801db09d-3503-462d-af71-f5285b0abcea",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "42fd67b3-6a41-40b3-83a6-66cc08bf21e9",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "77ca248a-7ec6-4fc2-9f46-b34d89692e7e",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "2c776780-39fb-47f2-95ce-79f3de0ea478",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "0c4aa273-e163-4b7b-9680-526cbc98b41c",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "eab97ed9-d41c-4d4f-81c6-472d75bd7dc2",
                              text: "ArvoPaiva : 2023-03-04",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "5f3b56c9-5abb-4750-9a8f-e91c20e3fdb7",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "dc0946a3-da8a-4a10-a81b-f0b339ddd2cb",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "27f76c90-b5ee-4c31-8aa0-b04847077194",
                  text: "Laskunumero : 268988840",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "8aea3ed4-7cb1-4ff7-be78-5e44935565ad",
                  text: "Paivays : 2023-03-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "14237684-8c29-40f1-81c3-9310529466f6",
                  text: "Erapaiva : 2023-04-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "77e98cdf-4825-42da-89aa-bd6634cf5fe5",
                  text: "ViimeisimmanMaksunPaivays : 2023-04-05",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "f11ad346-755d-4969-a6af-3c821d859f5f",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2efc1d21-4408-4863-abec-1da5c31e5d3f",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6fa8e467-5c3b-4774-abdb-e5ce866b32a1",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "46ab40b4-8438-45c3-9f2d-ee2924194dea",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "2a071d19-60c3-491f-85be-2ae318b61b84",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "9249666d-e0a5-408b-8a6c-28f877cf6d49",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "018d55ff-a844-4e1c-a43c-4790b50fd844",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "8a6ae048-ecd7-43d1-b7ac-090e4897b516",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "6bdac7ad-42c1-4fe0-9d3c-8ed622d776a6",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "a41c1adb-0b4f-4273-9dd4-aa9285ac636f",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "b4317efd-65fe-4236-96b8-6829406faf06",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "af8cde9a-d6fe-48ed-8e1a-a45eb5733f02",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "e44b6016-55e5-46cb-a6f2-adcec14964de",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "8515539c-59cb-4aeb-9669-ce28ca9f7ca5",
                              text: "ArvoPaiva : 2023-04-05",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c0cb5ea4-d36f-47fc-9a8a-679115f3ad55",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "5328dab2-5167-4344-b2a3-a97a40b4dc10",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "e5399c74-1961-48e4-9a5f-972e11ad6f41",
                  text: "Laskunumero : 269259922",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c6566590-6f92-484c-9479-89589e82606d",
                  text: "Paivays : 2023-04-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "319cb9fb-a5f6-455d-a3e7-9b9181cd79cd",
                  text: "Erapaiva : 2023-05-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "34a0c373-1d1c-40ee-8a3e-afe65b822f66",
                  text: "ViimeisimmanMaksunPaivays : 2023-04-22",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "cfc87537-2f9d-4be1-8ad3-c37c497bbb25",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "ad765e1f-6720-4b27-8c71-c04fd4829192",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "50f22545-6f28-4591-9284-0cd186357a4a",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "a334a3aa-9144-478e-a549-54285293056e",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "044510bb-1821-4a6a-ab46-f2cab714e128",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "e2adcbac-9679-4bd7-bd52-b36d1896e725",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "a1da261c-d33d-499b-b077-8c0607f111a0",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "868d6ad7-aa1d-4f5a-a4e3-4dbeb7c1d833",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "7a3e1b8f-45b6-4ec8-b267-c70eda677eaf",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "eb791b8e-5f2c-4f0b-ac3c-b07411bf81fe",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "2ae52c97-9c56-430f-886d-bf36b82274b6",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "20fcb0ff-0e9f-4705-b746-cf3e443f26eb",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "bbe3ea75-e510-4112-a857-560ad1936764",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "d178aee4-2bd2-4c94-a300-5e862586430b",
                              text: "ArvoPaiva : 2023-04-22",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "8be15284-c3e0-4558-a402-bf1adbf73967",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "7903cefa-939d-480a-9547-7e0b4f4ba070",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "912dc17d-d92d-4768-b728-18529e5b314c",
                  text: "Laskunumero : 269527859",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6aa83d21-4c74-45e3-934a-2e245eadb79c",
                  text: "Paivays : 2023-05-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "06922247-e41d-4f5b-94c5-3244fc38c767",
                  text: "Erapaiva : 2023-06-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1c6a26d7-1e93-4e60-94a9-f59c0fb5e580",
                  text: "ViimeisimmanMaksunPaivays : 2023-06-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6901db50-0fed-441b-a7ec-4d5ed91803e9",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "56f14aef-3afc-4e78-894f-44773e1b7273",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "6aeac47e-f8fd-47b7-b295-1a463210673e",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4c7df14a-cbf5-46bc-9862-2afc6b057860",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b752a121-d7c1-4e18-bd80-99925f696922",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "db61675e-cadf-4ffa-9d47-4470081f695b",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "2c1edb76-f797-46cc-bd11-535c4cc27847",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "420a932d-610d-4de4-a39b-8b8ee1a01aff",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "7aec3ce0-0149-4868-a617-4d436d465200",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "d4beae5d-1b8e-4b67-80e2-0df98ac3dff7",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "ec7a3f33-58f9-4955-87bd-3fe582c30f7f",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "818c7c11-d080-490a-b29a-f752f25f933b",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "464ef394-0428-4e60-bf90-72d683cf17e3",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "df815b82-c744-437a-9f66-66bba0dfb7c5",
                              text: "ArvoPaiva : 2023-06-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "1dadce3e-fa10-400c-b4c7-c956989a0169",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "a5277d24-485a-4c42-835b-7dfacb1d97f9",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "3df50ee0-9c2c-49a5-aed5-b86d3a7c4572",
                  text: "Laskunumero : 269795606",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "af1ce722-1ce8-439d-a46a-191312b3b93e",
                  text: "Paivays : 2023-06-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "fbcd7797-287b-447c-bbb7-0b4e4f7eb6a4",
                  text: "Erapaiva : 2023-07-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b548fd88-ed72-45f9-ab35-bd904c40b8ee",
                  text: "ViimeisimmanMaksunPaivays : 2023-07-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "fbbd03a4-5cc6-4ae5-bc97-cb4188269995",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "5fb71ca8-332b-40c8-bbbe-7f2aa9fd3e48",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "14f2c17f-004e-4c83-a611-99bfc9a9f940",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b339a4c8-7c45-4231-a2c0-87e6ced26b43",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "28c34d3e-3487-470e-9aa5-637345a2a53f",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "409a8f6d-c1fb-43de-8745-9b2bd573ccce",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "1c05fd3a-f7c9-46ae-98aa-3399b14ecda1",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "233eb669-293a-479d-bc6b-0f101fa67cde",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "dcde38cb-a4cc-412d-8d3e-1cf1b7f4ff7c",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "990b6f30-aa3a-452b-939d-bcc406d76793",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "2d37536c-f7ab-482c-ad9d-1b1ee66b0578",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9bcd8b77-b94e-494a-84f0-5092ccfde563",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "2342429f-32cb-4594-8ea6-6b4d9bd6535c",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "bd9ef3b0-d223-485f-92df-4d14582051cd",
                              text: "ArvoPaiva : 2023-07-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c08b0a29-f1e5-480c-b49e-a5cebfaa93f2",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "b69815aa-994b-4afb-a4b9-298290eecf4a",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "803c489d-455c-40bf-9990-8d420d0ea7d3",
                  text: "Laskunumero : 270059724",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "25698f71-aa3d-4474-86b5-74d7abeea013",
                  text: "Paivays : 2023-07-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "94a13075-ad06-4aae-a9eb-e36f28590910",
                  text: "Erapaiva : 2023-08-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b83e326b-fb93-4fca-8da2-0aec5f91aa3f",
                  text: "ViimeisimmanMaksunPaivays : 2023-08-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "5ef51c66-0ef2-4bee-a404-e8e74e373d35",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "516b4f32-cc3b-417d-af1d-740092df1b40",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c4a82caa-1643-4d81-bb64-58afaeb44cce",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2093462c-29ff-4394-94f8-c3c8c87cf098",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "9366da77-8591-4738-b2c5-1766b2c33a96",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "74e2a450-f3d0-4c4a-8819-84c78bca3a82",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "ecdb63e1-7e31-417e-ac41-cdf0c1243e32",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "7bed7357-202e-4a69-a272-b04bfd2fa32d",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "b0445e1b-3ea7-42e7-909d-cb6870577e94",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "39ae0401-7b70-4d43-b627-0d5e85354936",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "99d9a8b5-78ab-4ce7-8738-8d24ee4b0fa5",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "88f4338c-964a-4bf8-b999-d73d5a0abaae",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "4dbc07cd-7a63-4635-bc7f-7ac2ee64c6f8",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "45a04cf1-ea1b-4bcf-96a3-f155e8eca37f",
                              text: "ArvoPaiva : 2023-08-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "bf3820df-3e93-47f8-b072-0053f563ff36",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "00e707a1-2d88-47cd-ba11-2d8ca73a9a68",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "0cba9e81-9d04-4493-9b16-46c8dcb8d0f8",
                  text: "Laskunumero : 270316511",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e45dd435-9539-4b6b-a95d-5edc19aaf3a4",
                  text: "Paivays : 2023-08-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0021c27c-d7bb-4714-b482-3ee61eb7032d",
                  text: "Erapaiva : 2023-09-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e5322948-271f-44e7-96eb-5d200faf03e2",
                  text: "ViimeisimmanMaksunPaivays : 2023-09-04",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "008fb7f7-cb2f-4f44-ac78-37fda041d721",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3f5a6791-2811-4de0-8e71-7f59e3f1d0c9",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "35f4381a-03a0-4b5a-a420-4a419fcb8771",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2e04973f-ac77-4c1a-80dc-2cdae6d537cd",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "f02dd16e-99df-496b-9cbf-19c442bb5250",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "2ca8a23e-5384-4895-b886-836843fa0c97",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "607f7636-f04e-4a48-951e-d1abbc80f099",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "2974bfc3-82d1-485b-bef2-e35dbeb66944",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "81ce601e-136c-47a7-b7fb-552d06fb2845",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "fb8bb539-f812-427b-946f-8cd43798c702",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "3d221fd3-9788-419f-b9f0-15f3a0dbaaa8",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "19ac9d74-6b70-4b5b-abb1-d45bf6b31c37",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "cc84bf25-0543-4e7b-ae9b-17f414408129",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "f2adbf24-eb94-434d-b31a-f7ca4b34d4f1",
                              text: "ArvoPaiva : 2023-09-04",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "373da2d9-c9d1-4042-88cb-0f7c5bd901ae",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "f82ea65e-ae31-4c2b-83e8-4bee19613aed",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "ec03f739-0c59-42ae-b4b7-e56661ee918c",
                  text: "Laskunumero : 270569999",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1c6a2496-4796-4081-a0a7-2dde5b5420b9",
                  text: "Paivays : 2023-09-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "29f15eef-45f3-4749-9ef1-a17bb44abbe7",
                  text: "Erapaiva : 2023-10-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "411fb107-4eb1-4da2-9c72-7a9dd47be493",
                  text: "ViimeisimmanMaksunPaivays : 2023-09-30",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "8eec154f-a761-402d-a2ae-918aa776ca57",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "0a35ebd9-e7c4-4dbd-915d-9f923ca5efea",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e893f2a0-17b9-442c-9f00-e34f8d859c41",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "36bf1379-f3c0-443d-900d-de836bf69465",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "4a644ebb-93cf-4fd4-8234-2ffc2a9f9fb7",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "2247eca2-6795-45a8-ac14-560c08893c30",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "cd66f319-2bdb-482a-b3d2-71fba4d5eb2a",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "0a3b6d12-b121-494e-8010-84f4451c4101",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "e725bc43-1656-4731-a334-60e40286856d",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "18cae055-1a5c-4872-b1ee-bcee9f1d6620",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "04d0d521-315d-41df-ab8c-86fc03f8273d",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f355fca1-f873-4e0b-87d2-bdffe6064b8f",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "6f3424af-bff6-4b13-a3f0-980e08ec71d2",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "b74507c3-d609-4d05-9152-43537bbfd90c",
                              text: "ArvoPaiva : 2023-09-30",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c050ef67-c75f-47a0-9f35-973c62b7e75e",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "6fd475e5-4085-4a5c-b51d-495d1ee5f267",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "027b241c-ecd3-46fe-8775-03e4ecbf6cf1",
                  text: "Laskunumero : 270822645",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "07674aa8-9887-4b1b-adaa-374d6f043eb9",
                  text: "Paivays : 2023-10-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "557b5388-eaf3-4650-9d4c-3ded156b3bcf",
                  text: "Erapaiva : 2023-11-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3dfffec3-3479-475c-a6fa-6ff7aef3add1",
                  text: "ViimeisimmanMaksunPaivays : 2023-11-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c95a2247-ddf3-4f8c-8b55-ac55042baa9e",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9b77cb0f-dab0-4561-b842-9c622a75bd33",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "af2de474-491b-4907-8cb8-fe90bf668cb5",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3a84f83a-cce6-4c30-be76-25c16229d774",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "6a2a23d5-2244-4aa8-8988-302ae9c246a9",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "d243c97a-dc6f-4ba8-9fa9-73c6a1522cec",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b060d1dc-9a81-4ea7-83a6-daa92189d8b3",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "cc0ad8bb-4fc3-4c92-a620-8d9a72272bc9",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9d2f4700-2e24-46eb-b47e-cb83af96874a",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "6c1b935f-1ac7-4460-a926-1fefcb744113",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "828aa950-07ae-4b9d-a708-1a356ca6ab92",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "d09e236a-0c75-4b19-a138-61b7c7bb2fa3",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "cadcf17e-dc4f-45bb-8cba-729dab50794e",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "a2b59858-7cec-4c3c-affe-dbf2f20822fd",
                              text: "ArvoPaiva : 2023-11-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "f4bab278-f879-4b3b-a7d4-0bedc93c446d",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "bd1dc941-36a5-4d58-aadb-d24acf42b254",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "f305d6cb-1f72-4333-b0df-698df7e781e5",
                  text: "Laskunumero : 271072590",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "f31b6e25-a8e8-4be2-b3a4-1d10e7019a35",
                  text: "Paivays : 2023-11-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9f07c4f9-1422-4c70-b405-56ae7b97ed6c",
                  text: "Erapaiva : 2023-12-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "dcf329e7-5717-470a-8ed0-1c817544cc44",
                  text: "ViimeisimmanMaksunPaivays : 2023-12-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "986ffe2b-e377-43d5-91e8-505df0edb7ad",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b7b49ea9-54bb-4c6d-a7e3-612e91116c4e",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "191edc7f-41c9-44d5-b86f-0edfa2e147f0",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "df3c571d-5c5c-4b4f-a928-c8f98b4175db",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "74829644-e000-4fc1-ad92-6ea354e9a3e7",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "eb5efa77-97b3-4d75-a079-c8a2db868993",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b7c0e656-9350-4845-912e-ce9c659f7834",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "27fd1dfb-c62e-4310-9c15-99ae21f92bf9",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "01883b5f-f3f5-47fe-ba45-1aba1922bd00",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "14ab097b-9743-4de1-b480-816d6bb3b855",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "24094d9e-f07a-4fef-951f-82ca8014b83a",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "866ce2b6-88cc-49f0-bd4e-2c965f8ca51c",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "25227404-9566-4c98-b8ef-e4df31a9d81e",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "fc3ba47a-f705-4857-a007-45118334140b",
                              text: "ArvoPaiva : 2023-12-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7106f617-3c38-42a0-9773-4d9e2ca24529",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "ccc022fb-6bfc-4355-9aa6-ae86e3646194",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "42459147-540f-415d-8572-ffe7193a6d14",
                  text: "Laskunumero : 271320219",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b598e6b2-7a4e-487f-a842-d9cb3ed54fb0",
                  text: "Paivays : 2023-12-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "da3a3a83-6541-44cf-8663-ef6cda17ecba",
                  text: "Erapaiva : 2024-01-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "a01e0d1a-0e19-451b-8549-80867bfe2a13",
                  text: "ViimeisimmanMaksunPaivays : 2024-01-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d66db8ac-b7fb-4643-9e6a-be941400fb9c",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3d0f7f14-9fc1-4ec5-a7b3-3262a3d09481",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "39a9892a-ff62-4897-8a87-8565d313f53a",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "16ff6775-ea10-41b8-9671-fcb1d752ba64",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "9da7cff3-2fe9-4750-b0ea-7227a50c4b1a",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "79ad4009-ce2a-4b0d-b782-e7a846167679",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "3b3bbabe-3d44-475a-856f-103e39acf027",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "8c5fe21b-808d-4410-be0d-ba574c879b65",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9802ddee-cf87-4b31-9dd4-d8b4febe74c9",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "4e931bea-83f6-4c76-8947-34a564bfc6ef",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "cecbeb52-2692-403b-a835-86628fe4bdce",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c12f5a20-5bad-42bc-bcbe-9592cebf5a5e",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "0ec12b41-7281-431d-9af7-36cd68670593",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7943190a-d397-4a1d-b798-9c6449e59a35",
                              text: "ArvoPaiva : 2024-01-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "197ac78b-e4fc-4745-a9fd-33fd7863351e",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "b56d96e1-898c-4657-bb25-31c029dc014a",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "5def61ee-3a0b-423b-ba6f-fb85d3ecc987",
                  text: "Laskunumero : 271564246",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "abb1d4d0-e9b9-4027-a670-aa9ffa571748",
                  text: "Paivays : 2024-01-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "56b6ec29-e76a-4c28-bf1f-64479292104a",
                  text: "Erapaiva : 2024-02-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "c892f36c-fae6-4b49-9f52-e00bcd60482a",
                  text: "ViimeisimmanMaksunPaivays : 2024-02-03",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "64ef7f62-f32c-4ea0-9ba7-178e75d1311c",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4f49ea74-1292-4c9a-aa26-ac2ad9ba8382",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2ffc161c-5953-4948-b574-d4b9505a2ab2",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "22d60ff3-5782-4137-a99a-43073a670817",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "ffeaa831-3fe7-4d71-86a1-fb61d2b66b2d",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "21a6f43b-e7b8-424c-8cd8-4d40fcdb2bbd",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "1dcdbc07-a203-4ae2-be7a-49b59033da0f",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "8be07fd8-3e41-4afa-b8b1-12be82aeeb91",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "9fb46245-a33a-407e-a944-14551a9380ef",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "839862d2-0ee2-447b-8902-766d32cf1685",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "e1aba466-6e89-45ca-9ba2-1952ddce58e7",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "f9cbd892-7d1a-4b13-9b7c-8051235b72b1",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "3a298082-993e-4804-ab7b-fcde9f418d04",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "57d4179e-8fbc-4517-949e-f56acc2a4166",
                              text: "ArvoPaiva : 2024-02-03",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "69146d5d-2b40-41b5-93df-9e5022c2aa67",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "9da1479a-4d95-4e74-b1e4-4104af669341",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "a84d722b-1a83-44ac-9d95-f9f483e6b37f",
                  text: "Laskunumero : 271806064",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "86bf5cb7-59cd-4e37-88b6-55d399cfbb45",
                  text: "Paivays : 2024-02-06",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "86582f51-6e65-4fce-a1de-bdf10e22e691",
                  text: "Erapaiva : 2024-03-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "28ede507-a926-4c4b-bb14-fbc6cb49ccb7",
                  text: "ViimeisimmanMaksunPaivays : 2024-03-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "eb649925-b981-4279-a77a-df6812687527",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "93de9d09-6bbb-4bc7-b78f-f56667a2d317",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2dc23364-8338-43df-a4c7-1e56a3a972fb",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2bd07907-1872-4c5d-aded-6928e17d6be0",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "cf3f61c5-f95b-412a-8a63-fe81b9f52c0a",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "986bcc2d-c7b6-44cc-9151-c207be6347c8",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "20c0ec7a-8d44-442e-9068-7d69a9c4ef0c",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "4b13d4a1-8bb7-45d2-879f-27ae71b266c9",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "0a838b7e-89c7-462b-a654-fb0cba493f95",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c5e2bddd-0726-4be9-9399-ffca151f8f76",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c86453e6-77e2-4040-8f49-bf23173d81fe",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c06a47f7-9841-46cc-acf5-6ac51c623965",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "0a4108c1-5b82-46d8-821e-d5ceb320938f",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "eb47598f-326d-4505-8624-145ba4b623b3",
                              text: "ArvoPaiva : 2024-03-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "13994e00-8d1e-4902-b053-6c6c510c7a73",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "c7c44613-e9e3-4683-9e67-694de78d4fe9",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "55b60144-be0a-4202-bd1c-612b02763bd2",
                  text: "Laskunumero : 272050053",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "fa55a763-a627-43d2-aa5e-6d1a46ea87be",
                  text: "Paivays : 2024-03-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "3920c70b-dcfb-4964-8673-20bdda955408",
                  text: "Erapaiva : 2024-04-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "95c618f5-8c44-48f3-9ef6-12e15eb9e681",
                  text: "ViimeisimmanMaksunPaivays : 2024-04-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "4dbbc98a-6323-4c01-bfe8-acdb3518089b",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "d6499eb3-3a5d-43e4-9399-3b449b4fbff2",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1923ea94-80e9-4cb5-9c2e-4d3a094cf73f",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7bb87ce2-1e49-494b-8c9a-5b61bb4d7966",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "02b99fb8-da0a-4507-9091-ce9e1ccd9959",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "caee1178-b0cd-43ef-a0b4-7c045650185e",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "67b1dbcc-671d-4915-ad55-b2263c1441cd",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "b7e9faec-f50d-4986-94cf-b243f3189978",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "5879221d-d111-4a4e-86c2-c6194b3eff0b",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "729066ec-c9fa-42ec-b462-3f483fcca3c0",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "40a4b2fe-f787-4149-b8a6-b1be49802cb1",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "7d144f5e-e019-4d4a-a4dd-d5ee47d4760a",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "1210cfc4-2f0f-46e1-93ad-38d45a76613b",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "b15c9dce-6bae-48bb-bc2f-537415fb175b",
                              text: "ArvoPaiva : 2024-04-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c71875a6-48ab-4ffc-b065-dc498e7dd002",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "f9b89b50-a7d9-435c-bad5-f777aeed2b69",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "d58fd8f8-d597-487c-af81-4c508c558fa2",
                  text: "Laskunumero : 272289235",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "9d6b3a87-51ac-4cc8-a082-716911d061a1",
                  text: "Paivays : 2024-04-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "96fd20b1-85eb-45d3-91af-bde094c9c91b",
                  text: "Erapaiva : 2024-05-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "1d7a8d64-a494-49bc-8a2b-e93301824e94",
                  text: "ViimeisimmanMaksunPaivays : 2024-05-02",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e7a52eb7-2009-4e96-8d2a-c194424713ff",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "87b99661-848b-4ea9-85f0-312c19e4b1d3",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "5c5dffcc-5e18-4fc7-a580-029933a8c28a",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "348dfd79-d501-44f4-ae93-83f82e34abf4",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b1d5bcf3-e04d-4477-90ef-09e4c0c61bcb",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "ebd1486b-24d4-4032-b1e6-3b78e74382e6",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "15a9671a-dc96-4744-95f3-dda57b4d5f4a",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "7ae1d79b-0f7d-44df-8f59-99a9804e0606",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "0fcdebfd-8ff6-4ae4-b350-d7128457974d",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "fa966154-5900-4b32-8342-fb4471270a7b",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "279114c2-0683-459e-b700-61ff56934293",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "31741e30-e148-43e6-a51f-1247340e48d4",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "fd1d5d59-93da-4814-9a98-7e219891fdfb",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "ee31ddb2-b37f-4ef1-b4e7-097c34496113",
                              text: "ArvoPaiva : 2024-05-02",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "e196291e-e788-4200-9de2-69f3dc309347",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "7ebba42e-1385-4e6b-aa64-5f792c29a49e",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "79194268-379d-4715-883b-cd9a236e82a7",
                  text: "Laskunumero : 272532496",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2a1df794-d967-4013-b9cc-d8a86a135300",
                  text: "Paivays : 2024-05-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b6060e7c-3d49-48a1-8527-7a0096ead5cb",
                  text: "Erapaiva : 2024-06-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "910aaea4-2d29-4115-a834-04e651849966",
                  text: "ViimeisimmanMaksunPaivays : 2024-06-03",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "59cbc4b2-a895-4f7f-8521-fb27477d1653",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "20c468b8-a557-4d96-b95d-2ddad1df95f0",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "e9bd4d02-8de7-43a2-b611-7968d82c5537",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "be902e4e-2104-4a0e-adc8-f1d3c7a647f4",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "246dd65f-162b-46e3-b079-1de87f57198a",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "bcaaebf4-9b72-4983-b4aa-fcf77edfc351",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "a8b72033-71d2-4209-9df1-172c30cdf636",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "7f02f7a8-ad47-43ff-94f9-dbe28a124bc3",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "59c92ec8-8495-4daf-b673-a4d3d6fb83c6",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "3dee1fda-942b-4071-8847-38d0d0978ca7",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "57a1efa9-353a-41e2-8f07-5cb776ec6fd8",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "5105c315-2f50-4d9c-a7ba-1f0c4dd0d919",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "5a084625-a968-4c06-925d-83353d94aa50",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "0720e7cc-f123-4e95-a085-577430f24e54",
                              text: "ArvoPaiva : 2024-06-03",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "7f533c60-b553-452a-a7ff-094e36ea994b",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "b3c8eac4-118e-4404-b0ad-5015bd3899e5",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "48486083-9b8d-42cf-8802-dabfdfd794de",
                  text: "Laskunumero : 272771716",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "438c37fe-bd9d-43e9-92b4-9e01ec811cfb",
                  text: "Paivays : 2024-06-07",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "33e5b3e9-10a0-4e96-9e6a-754e84bf14e7",
                  text: "Erapaiva : 2024-07-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "14ff316b-f727-469a-b830-709de701e1e3",
                  text: "ViimeisimmanMaksunPaivays : 2024-07-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "b1e60502-4a8c-4216-a3aa-392a9c66b8c6",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "03d1e3c7-76ea-48c7-b9cd-6cfecae72723",
                  text: "AvoinSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "06a58196-f047-4dde-8ce2-11a0e9e7c88a",
                  text: "MaksettuSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "36a1e50e-b2b4-42b8-8267-56e89afdb1b6",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "91bd111b-47f5-489c-8eba-82c15af81a9e",
                      text: "Tila : Maksettu",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
                {
                  key: "e6ee4db9-efb7-49be-aad5-cda50c098c0c",
                  text: "Viitemaksut",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "d0a3fc66-5a66-46a1-8ec4-cdf822644f3a",
                      text: "Maksutieto",
                      selectable: true,
                      isError: false,
                      nodes: [
                        {
                          key: "bd7efad2-eebf-45e9-bbc6-22195c248b91",
                          text: "AvoinSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "c36bb39c-4539-451a-b7e2-353dc740e3fe",
                          text: "MaksunJalkeenAvoinSumma : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "77c0dfac-0db3-4520-bdd5-eace2042eafe",
                          text: "Ylimaksu : 0.00",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "99bba667-8e30-4fb2-9ca7-0356302e6a2c",
                          text: "MaksettuSumma : 562.21",
                          selectable: false,
                          isError: false,
                          nodes: null,
                        },
                        {
                          key: "dbc46973-f44c-4838-b638-99e977c0d457",
                          text: "Maksusuoritus",
                          selectable: true,
                          isError: false,
                          nodes: [
                            {
                              key: "1f3bab85-acd8-49cc-b9bd-fb3c8efada90",
                              text: "Summa : 562.21",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "47a677b6-1ae3-4ffb-8410-cb027e3a69f5",
                              text: "ArvoPaiva : 2024-07-01",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                            {
                              key: "c98af216-f509-4619-914b-81cc6497811f",
                              text: "Maksaja : LAAKSO TAINA HANNELE",
                              selectable: false,
                              isError: false,
                              nodes: null,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              key: "e935b04a-f8f3-425b-bbb9-2547a2b97682",
              text: "Lasku",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "6b51b70e-5313-479a-8b75-6ab61ccb9120",
                  text: "Laskunumero : 273002475",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "2ce627c4-b9b6-4148-90a3-3e8f7a9ef60a",
                  text: "Paivays : 2024-07-08",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "f62e2bd0-7b84-4080-8cef-6730faaef315",
                  text: "Erapaiva : 2024-08-01",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7802dbac-038d-4372-b895-73b9bea0ea06",
                  text: "Summa : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "7e6c2290-0ae1-4249-8827-b9259480bbfa",
                  text: "AvoinSumma : 562.21",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "df883233-4eb3-43aa-b6c0-956101604f34",
                  text: "MaksettuSumma : 0.00",
                  selectable: false,
                  isError: false,
                  nodes: null,
                },
                {
                  key: "727695ce-9a75-4376-bbed-5ad70de98670",
                  text: "Tila",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "b7b05f31-c4bc-4798-b3c4-0b97bc8e473f",
                      text: "Tila : Avoin",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: "e00d97dc-d758-4f76-b82e-a9faf672009f",
      text: "Sopimus : 718298",
      selectable: true,
      isError: false,
      nodes: [
        {
          key: "acdc6a06-7551-4595-8ea6-7063cef3ca6a",
          text: "Sopimusnumero : 718298",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "4152b04f-b203-4840-8d90-4c6be9f5a0dc",
          text: "SopimusTyyppi : Huoltoleasing yksityishlö",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "ab63c9d1-db7f-4a0f-bb33-e2edd43e0134",
          text: "Kuvaus : NISSAN Leaf N-CONNECTA MY21 40 KWH LED FI",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "c8df1123-031a-4767-a533-e5abfb16edbc",
          text: "AlkuPvm : 2022-05-01",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "96088471-5afe-4103-98e5-52e5dcb0ea9c",
          text: "LainaAikaKk : 36",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "d234363b-5354-4952-bd4f-b87ac9962aad",
          text: "PaattymisPvm : 2025-05-01",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "1f0e2108-5ebd-4618-a5e5-6fd801c1d42b",
          text: "Vuokra : 427,14",
          selectable: false,
          isError: false,
          nodes: null,
        },
        {
          key: "54005541-3403-4c86-a49b-c4f4aa0dff2f",
          text: "SopimusKohteet",
          selectable: true,
          isError: false,
          nodes: [
            {
              key: "e1d1f863-0ef3-4fb3-8c79-d49d63321454",
              text: "Sopimuskohde",
              selectable: true,
              isError: false,
              nodes: [
                {
                  key: "e437e735-5dbc-4807-8d41-78b4f7c322f8",
                  text: "Kohde",
                  selectable: true,
                  isError: false,
                  nodes: [
                    {
                      key: "164fc4f6-d299-47d1-83ed-bab1b9226654",
                      text: "Merkki : Nissan",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                    {
                      key: "4ff79db3-971d-4e07-b417-a81c67f808b8",
                      text: "Malli : FSDALE9ZE16GDBP-K-NNE",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                    {
                      key: "36560a3e-8953-43ac-8780-458081c957e5",
                      text: "Rekisteritunnus : WIL1046234",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                    {
                      key: "2a0060e7-f5a3-458c-aca5-5729afc71d35",
                      text: "Valmistenumero : AWK000000001046234",
                      selectable: false,
                      isError: false,
                      nodes: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: "aaee3222-3473-487c-880e-96e4d844243c",
      text: "Service Unavailable - Please Try Again",
      selectable: false,
      isError: true,
      nodes: null,
    },
    {
      key: "aaee3222-3473-487c-880e-96e4d844243d",
      text: "Sopimus : Asiakkuuteen liittyy sopimuksia, joiden tietoja ei voida hakea automaattisessa prosessissa. Ota yhteyttä asiakaspalveluun saadaksesi lisätietoja.",
      selectable: true,
      isError: false,
      nodes: [
        {
          key: "b16e0edd-106d-4d16-9711-0b18236ec231",
          text: "Info : Asiakkuuteen liittyy sopimuksia, joiden tietoja ei voida hakea automaattisessa prosessissa. Ota yhteyttä asiakaspalveluun saadaksesi lisätietoja.",
          selectable: false,
          isError: false,
          nodes: null,
        },
      ],
    },
  ],
};
