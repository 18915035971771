import { InvoiceDistributionFormatType } from "../models/CustomerProducts";

export const getInvoiceMethodDisplayNameByInvoiceDistributionFormatType = (
  invoiceDistributionFormatType?: InvoiceDistributionFormatType,
) => {
  switch (invoiceDistributionFormatType) {
    case InvoiceDistributionFormatType.PaperInvoice:
      return "Paperisena postitse";
    case InvoiceDistributionFormatType.SantanderInvoice:
      return "Santanderin Verkkopalveluun";
    default:
      return "";
  }
};
