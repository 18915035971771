import MockAdapter from "axios-mock-adapter";
import { mockConversation, mockConversations } from "./messages/conversations";
import { mockAttachments } from "./messages/attachments";

export const MockMessageApi = (mock: MockAdapter) => {
  const idRegexSegment = "[0-9]+";

  mock.onGet(`/conversations`).reply(200, mockConversations);

  mock.onGet(new RegExp(`/conversations/${idRegexSegment}`)).reply(200, mockConversation);

  mock.onPost(new RegExp(`/conversations/${idRegexSegment}`)).reply(201, {});

  mock.onGet(new RegExp(`/attachments/${idRegexSegment}`)).reply(200, { attachment: mockAttachments[0] });

  mock.onPost(`/attachments`).reply(201, {});
};
