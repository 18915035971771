import { ICreateTransferResponse } from "../../../models/Transfer/Transfer";

export const mockTransfer: ICreateTransferResponse = {
  toAccountId: "CV054819",
  toAccountNumber: "054819",
  toAccountName: "Opsparing Fri",
  fromAccountId: "CV7878275",
  fromAccountNumber: "7878275",
  fromAccountName: "Opsparing Fri",
  success: true,
  details: "Overforsel fra 054819 til 7878275.",
  amount: 100,
  hasLongTransfer: false,
};

export const mockUnsuccessfulTransfer: ICreateTransferResponse = {
  toAccountId: "CV054819",
  toAccountNumber: "054819",
  toAccountName: "Opsparing Fri",
  fromAccountId: "CV7878275",
  fromAccountNumber: "7878275",
  fromAccountName: "Opsparing Fri",
  success: false,
  details: "Noget gik galt. Prøv igen senere",
  amount: 100,
  hasLongTransfer: false,
};
