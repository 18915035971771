import React from "react";
import { observer } from "mobx-react";
import styles from "./InfoPopover.scss";
import informationIcon from "~assets/information-icon.svg";
import questionMarkIcon from "~assets/questionmark.svg";
import { useStores } from "netbank-shared/src/hooks";

export interface IInfoPopoverProps {
  label?: string;
  popoverTitle?: string;
  className?: string;
  content?: string;
  icon?: "info" | "question";
}

export const InfoPopover = observer(({ label, popoverTitle, content, className, icon = "info" }: IInfoPopoverProps) => {
  const { uiStore } = useStores();
  const popoverRef = React.useRef<HTMLDivElement>();

  const classes = [styles.wrapper];

  if (className) {
    classes.push(className);
  }

  return (
    <div
      ref={popoverRef}
      className={classes.join(" ")}
      onClick={() =>
        uiStore.setPopover({
          children: content,
          title: popoverTitle,
          open: true,
          element: popoverRef.current,
          xOffset: 5,
          yOffset: 20,
          cancelAction: () => {
            uiStore.removePopover();
          },
        })
      }
    >
      <img src={icon === "info" ? informationIcon : questionMarkIcon} alt="info-icon" />
      {label && <span>{label}</span>}
    </div>
  );
});
