/* eslint-disable import/no-extraneous-dependencies */
import { useStores } from "netbank-shared/src/hooks";
import { AccountType, ILeaseAccount, ILoanAccount } from "netbank-shared/src/libs/models/CustomerProducts";
import { PromiseToPayStatusRequest } from "../models/Content/Enums";
import { IPromiseToPayForm } from "libs/forms/PromiseToPay/PromiseToPay";

export interface IPromiseToPayParams {
  currentAccount: ILoanAccount | ILeaseAccount | undefined;
  submitPromiseToPayRequest: (accountId: string, data: IPromiseToPayForm) => void;
  cleanUp: () => void;
  promiseToPayStatus: PromiseToPayStatusRequest;
  creatingPromiseToPay: boolean;
}

export const useDeterminePromiseToPayParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string | null | undefined,
): IPromiseToPayParams => {
  const { loanStore, leasingStore } = useStores();
  const emptyResponse: IPromiseToPayParams = {
    submitPromiseToPayRequest: () => {},
    currentAccount: undefined,
    promiseToPayStatus: PromiseToPayStatusRequest.Initial,
    creatingPromiseToPay: false,
    cleanUp: () => {},
  };
  if (!accountId || !accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.SecuredLoan:
    case AccountType.PrivateLoan:
    case AccountType.SalesFinance:
      return {
        submitPromiseToPayRequest: (loanId: string, data: IPromiseToPayForm) =>
          loanStore.submitPromiseToPay(loanId, data),
        cleanUp: () => loanStore.resetPromiseToPayStates(),
        currentAccount: loanStore.currentAccount,
        promiseToPayStatus: loanStore.promiseToPayStatus,

        creatingPromiseToPay: loanStore.creatingPromiseToPay,
      };
    case AccountType.PrivateLeasing:
    case AccountType.Leasing:
      return {
        submitPromiseToPayRequest: (leaseId: string, data: IPromiseToPayForm) =>
          leasingStore.submitPromiseToPay(leaseId, data),
        cleanUp: () => leasingStore.resetPromiseToPayStates(),
        currentAccount: leasingStore.currentAccount,
        promiseToPayStatus: leasingStore.promiseToPayStatus,
        creatingPromiseToPay: leasingStore.creatingPromiseToPay,
      };
    default:
      return emptyResponse;
  }
};
