import React from "react";
import { observer } from "mobx-react";
import styles from "./LoginNotifications.scss";
import { useStores } from "netbank-shared/src/hooks";
import { NotificationLevel, INotification } from "netbank-shared/src/libs/models/Content/Notification";
import informationIcon from "~assets/information.svg";
import importantIcon from "~assets/important.svg";
import errorIcon from "~assets/error.svg";
import { DynamicLink } from "../DynamicLink/DynamicLink";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { HtmlContent } from "../HtmlContent/HtmlContent";

export const LoginNotifications = observer(() => {
  const { contentStore, uiStore } = useStores();

  const { isMobile } = uiStore;
  const { currentPage } = contentStore;

  const notifications = currentPage?.loginNotifications;

  const order = { error: 1, important: 2, information: 3 };

  if (!notifications || notifications.length <= 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {notifications
        .slice()
        .sort((a, b) => order[a.level] - order[b.level])
        .map((notification: IGenericContentLink<INotification>) => {
          const content = isMobile ? notification.mobileContent : notification.content;

          const classes = [styles.notification];

          let icon = "";

          switch (notification.level) {
            case NotificationLevel.information:
              classes.push(styles.information);
              icon = informationIcon;
              break;
            case NotificationLevel.important:
              classes.push(styles.important);
              icon = importantIcon;
              break;
            case NotificationLevel.error:
              classes.push(styles.error);
              icon = errorIcon;
              break;
            default:
              break;
          }

          const showLink = isMobile || (!isMobile && !notification.hideLinkOnDesktop);

          const link =
            notification.linkItem?.[0]?.link?.[0] ||
            (notification.link && notification.link?.length > 0 ? notification.link[0] : undefined);

          const trackingCategory = notification?.linkItem?.[0].trackingCategory;
          const trackingAction = notification?.linkItem?.[0].trackingAction;

          return (
            <div key={notification.contentLink?.guidValue} className={classes.join(" ")}>
              <div className={styles.wrapper}>
                {icon && <img className={styles.icon} src={icon} alt="icon" />}
                <div className={styles.innerWrapper}>
                  <div className={styles.contentWrapper}>
                    {notification.header && <p className={styles.header}>{notification.header}</p>}
                    {content && <HtmlContent html={content} />}
                    {showLink && link && (
                      <DynamicLink
                        className={styles.link}
                        to={link.href}
                        target={link.target}
                        trackingCategory={trackingCategory}
                        trackingAction={trackingAction}
                      >
                        {link.text}
                      </DynamicLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
});
