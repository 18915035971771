import { IAccountTransactions } from "../../../../models/CustomerProducts";

export const mockTransactionsIndex: IAccountTransactions = {
  transactions: [
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-10-01T00:00:00+01:00",
      valueDate: "2023-10-05T00:00:00+02:00",
      storeName: "",
      transactionAmount: 145.0,
      isReserved: true,
      transactionText: "Overførsel",
      balance: 5432,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-09-29T00:00:00+01:00",
      valueDate: "2023-10-03T00:00:00+02:00",
      storeName: "",
      transactionAmount: 145.0,
      isReserved: true,
      transactionText: "Overførsel",
      balance: 5123,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-09-20T00:00:00+01:00",
      valueDate: "2023-09-25T00:00:00+02:00",
      storeName: "TRANSACTIONSTORE:3855592039610",
      transactionAmount: 60.0,
      isReserved: false,
      transactionText: "SKRIFTLIG PÅMIN.AVG",
      balance: 5406,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-09-05T00:00:00+01:00",
      valueDate: "2023-09-10T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
      balance: 5400,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-08-20T00:00:00+01:00",
      valueDate: "2023-08-21T00:00:00+01:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
      balance: 5333,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-05-07T00:00:00+02:00",
      valueDate: "2023-05-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
      balance: 1080,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-01-07T00:00:00+02:00",
      valueDate: "2023-01-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 30.0,
      isReserved: false,
      transactionText: "AVIAVGIFT",
      balance: 5056,
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2022-07-28T00:00:00+02:00",
      valueDate: "2022-07-28T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: -200.0,
      isReserved: false,
      transactionText: "INBETALNING - PG OCR",
      balance: 5730,
    },
  ],
  nextToken: "e095cb78b6e983cf",
};

export const mockTransactionsSecond: IAccountTransactions = {
  transactions: [
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-04-16T00:00:00+02:00",
      valueDate: "2018-04-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 236.0,
      isReserved: false,
      transactionText: "MEDIAMARKT",
    },
    {
      currency: "DKK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2017-03-06T00:00:00+02:00",
      valueDate: "2018-03-01T00:00:00+02:00",
      storeName: "Test Store",
      transactionAmount: 758.0,
      isReserved: false,
      transactionText: "ELGIGANTEN",
    },
  ],
  nextToken: undefined,
};

export const mockDepositTransactions: IAccountTransactions = {
  transactions: [
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-06T00:00:00+01:00",
      valueDate: "2023-04-06T00:00:00+01:00",
      storeName: "",
      transactionAmount: -145.0,
      isReserved: true,
      transactionText: "Uttag",
      balance: undefined,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-05T00:00:00+01:00",
      valueDate: "2023-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: 146.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 2000.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-05T00:00:00+01:00",
      valueDate: "2023-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -4655.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 0.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-05T00:00:00+01:00",
      valueDate: "2023-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: 5.0,
      isReserved: false,
      transactionText: "Ränta",
      balance: 4655.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-05T00:00:00+01:00",
      valueDate: "2023-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -145.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 4650.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-03-05T00:00:00+01:00",
      valueDate: "2023-04-01T00:00:00+02:00",
      storeName: "",
      transactionAmount: -60.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 4795.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-02-05T00:00:00+01:00",
      valueDate: "2023-03-01T00:00:00+01:00",
      storeName: "",
      transactionAmount: -145.0,
      isReserved: false,
      transactionText: "Uttag",
      balance: 4855.0,
    },
    {
      currency: "SEK",
      foreignCurrencyAmount: undefined,
      exchangeRate: undefined,
      purchaseDate: "2023-02-04T00:00:00+01:00",
      valueDate: "2023-03-01T00:00:00+01:00",
      storeName: "",
      transactionAmount: 5000.0,
      isReserved: false,
      transactionText: "Insättning",
      balance: 5000.0,
    },
  ],
  nextToken: undefined,
};

export const mockCreditTransactions: IAccountTransactions = {
  transactions: [
    {
      transactionId: "W412977432",
      purchaseDate: "2023-08-31T13:21:54+00:00",
      valueDate: "2023-08-31T00:00:00+02:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-08-15T00:00:00+00:00",
      valueDate: "2023-08-15T00:00:00+02:00",
      transactionAmount: -11.59,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-08-15T00:00:00+00:00",
      valueDate: "2023-08-15T00:00:00+02:00",
      transactionAmount: -7.57,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: "W412663432",
      purchaseDate: "2023-08-16T06:47:19+00:00",
      valueDate: "2023-08-15T00:00:00+02:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: "W411859632",
      purchaseDate: "2023-08-03T12:58:58+00:00",
      valueDate: "2023-08-04T00:00:00+02:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-07-15T00:00:00+00:00",
      valueDate: "2023-07-15T00:00:00+02:00",
      transactionAmount: -7.27,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-07-15T00:00:00+00:00",
      valueDate: "2023-07-15T00:00:00+02:00",
      transactionAmount: -11.75,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: "W411504332",
      purchaseDate: "2023-07-15T23:15:57+00:00",
      valueDate: "2023-07-15T00:00:00+02:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: "W410548832",
      purchaseDate: "2023-06-29T14:10:44+00:00",
      valueDate: "2023-06-29T00:00:00+02:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-06-15T00:00:00+00:00",
      valueDate: "2023-06-15T00:00:00+02:00",
      transactionAmount: -12.71,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-06-15T00:00:00+00:00",
      valueDate: "2023-06-15T00:00:00+02:00",
      transactionAmount: -7.67,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: "W410264932",
      purchaseDate: "2023-06-15T23:16:02+00:00",
      valueDate: "2023-06-15T00:00:00+02:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: "W49281732",
      purchaseDate: "2023-05-30T14:05:15+00:00",
      valueDate: "2023-05-30T00:00:00+02:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-05-15T00:00:00+00:00",
      valueDate: "2023-05-15T00:00:00+02:00",
      transactionAmount: -12.47,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-05-15T00:00:00+00:00",
      valueDate: "2023-05-15T00:00:00+02:00",
      transactionAmount: -7.43,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: "W49023132",
      purchaseDate: "2023-05-15T23:15:53+00:00",
      valueDate: "2023-05-15T00:00:00+02:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-04-15T00:00:00+00:00",
      valueDate: "2023-04-15T00:00:00+02:00",
      transactionAmount: -13.27,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-04-15T00:00:00+00:00",
      valueDate: "2023-04-15T00:00:00+02:00",
      transactionAmount: -7.35,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: "W47794532",
      purchaseDate: "2023-04-15T23:15:56+00:00",
      valueDate: "2023-04-15T00:00:00+02:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: "W46922732",
      purchaseDate: "2023-03-30T16:18:34+00:00",
      valueDate: "2023-03-30T00:00:00+02:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-03-15T00:00:00+00:00",
      valueDate: "2023-03-15T00:00:00+01:00",
      transactionAmount: -12.98,
      transactionText: "Rente kontantudbetaling",
    },
    {
      transactionId: undefined,
      purchaseDate: "2023-03-15T00:00:00+00:00",
      valueDate: "2023-03-15T00:00:00+01:00",
      transactionAmount: -2.37,
      transactionText: "Varekøb rente",
    },
    {
      transactionId: "W46645032",
      purchaseDate: "2023-03-15T23:15:50+00:00",
      valueDate: "2023-03-15T00:00:00+01:00",
      transactionAmount: -35.0,
      transactionText: "Faktureringsgebyr",
    },
    {
      transactionId: "W46488632",
      purchaseDate: "2023-03-08T12:53:26+00:00",
      valueDate: "2023-03-08T00:00:00+01:00",
      transactionAmount: 150.0,
      transactionText: "Indbetaling",
    },
  ],
  nextToken: undefined,
};
