import { ApplicationStatus } from "../../../../models/Content/Enums";
import { IApplication, ICreateApplicationResponse } from "libs/models/CustomerProducts";
import { v4 as uuidv4 } from "uuid";

export const mockGetApplicationsResponse: IApplication[] = [
  { applicationId: uuidv4(), status: ApplicationStatus.New, loanAmount: 100000, duration: 10 },
];

export const mockCreateApplicationResponse: ICreateApplicationResponse = {
  applicationId: uuidv4(),
};
