import React, { ReactNode, useEffect } from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { useStores } from "netbank-shared/src/hooks";
import rootStyles from "../Root.scss";
import headerStyles from "~shared/Header/Header.scss";
import sideNavStyles from "~shared/SideNav/SideNav.scss";
import skeletonStyles from "./RootSkeleton.scss";
import { SkeletonWrapper } from "~views/shared";

export const HeaderSkeleton = observer(() => {
  const { uiStore } = useStores();
  return (
    <div className={headerStyles.wrapper}>
      {uiStore.isMobile ? (
        <>
          <SkeletonWrapper width={144} height={44} />
          <div className={rootStyles.flex}>
            <SkeletonWrapper width={30} height={21} style={{ marginRight: "150px" }} />
            <SkeletonWrapper width={20} height={21} />
          </div>
        </>
      ) : (
        <div className={headerStyles.contentWrapper}>
          <SkeletonWrapper width={250} height={25} className={rootStyles.flexFixCenterCol} />
          <div className={headerStyles.userIcons}>
            <SkeletonWrapper className={headerStyles.userIcon} style={{ display: "flex" }} width={135} height={44} />
            <SkeletonWrapper className={headerStyles.userIcon} style={{ display: "flex" }} width={135} height={44} />
            <SkeletonWrapper className={headerStyles.userIcon} style={{ display: "flex" }} width={135} height={44} />
          </div>
        </div>
      )}
    </div>
  );
});

export const SideNavSkeleton = observer(() => {
  return (
    <nav className={sideNavStyles.nav}>
      {new Array(6)
        .fill(undefined)
        .map(() => uuidv4())
        .map((key) => {
          return (
            <div className={skeletonStyles.navItem} key={key}>
              <SkeletonWrapper width={29} height={29} />
              <SkeletonWrapper width={100} height={22} />
            </div>
          );
        })}
    </nav>
  );
});

interface IRootSkeletonProps {
  children: ReactNode;
}

export const RootSkeleton = observer(({ children }: IRootSkeletonProps) => {
  const { uiStore } = useStores();

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  return (
    <div className={rootStyles.contentWrapper}>
      <HeaderSkeleton />
      <div className={rootStyles.main}>
        {!uiStore.isMobile && <SideNavSkeleton />}
        <div className="mainWrapper">
          <div className={rootStyles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
});
