import React from "react";
import styles from "./Conversation.scss";

interface NameIconProps {
  name: string;
}

export const InitialsIcon = ({ name }: NameIconProps) => {
  const initials = name.toUpperCase().split(" ");

  return (
    <div className={styles.initialsWrapper}>
      {initials[0][0]}
      {initials.length > 1 && initials[initials.length - 1]?.[0]}
    </div>
  );
};
