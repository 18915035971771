import { ApisauceInstance, create } from "apisauce";
import { IConfig } from "libs/models/Config";
import { ILoanCalculationResponse } from "libs/models/CustomerProducts";

export class LoanCalculationApi {
  client?: ApisauceInstance;

  init = (config: IConfig | undefined) => {
    if (config) {
      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.LOAN_CALCULATION_OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      this.client = create({
        baseURL: config.LOAN_CALCULATION_URL,
        headers,
        timeout: 10000,
      });
    }
  };

  calculate = async (loanAmount: number, duration: number) => {
    return this.client?.post<ILoanCalculationResponse>("calculate", { loanAmount, duration });
  };
}
