import * as yup from "yup";
import { tx } from "../i18n";
import { Product } from "../models/Content/Enums";

export interface IReplyMessageAccountData {
  accountId?: string;
  productName?: Product;
}

export interface IReplyMessageForm {
  body: string;
  files?: File[];
}

export const generateReplyMessageFormSchema = () => {
  const requiredError = tx("validators.required");

  const yupSchema = new yup.ObjectSchema<IReplyMessageForm>({
    body: yup.string().required(requiredError),
    files: yup.array(),
  });

  return yupSchema;
};
