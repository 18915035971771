import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { IMultiSelectOption } from "netbank-shared/src/libs/models/MultiSelect/MultiSelectOption";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";

interface IMultiSelectFieldProps {
  name: string;
  label: string;
  options?: IMultiSelectOption[];
  triggerValidation?: boolean; // Useful/needed when validation depends on other input values
  disabled?: boolean;
  placeholder?: string;
  infoPopover?: IInfoPopoverProps;
}

export const MultiSelectFormField = ({
  label,
  name,
  options,
  triggerValidation,
  disabled,
  placeholder,
  infoPopover,
}: IMultiSelectFieldProps) => {
  const {
    control,
    trigger,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef, fieldState }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <MultiSelect
            {...field}
            onChange={(value) => {
              field.onChange(value);
              if (triggerValidation) trigger();
            }}
            label={label}
            placeholder={placeholder}
            options={options}
            disabled={disabled || isSubmitting}
            error={fieldState.error?.message?.toString()}
            infoPopover={infoPopover}
          />
        );
      }}
    />
  );
};
