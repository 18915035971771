export abstract class CommonService {
  abstract handleLogout(url?: string, isInternal?: boolean): void;

  abstract getQueryParam(key: string): string;

  abstract redirect(url: string, params?: object): void;

  abstract redirectToLogin(): void;

  abstract createPdf(): BlobPart | Promise<BlobPart> | void;

  abstract downloadDocument(
    fileData: string | Blob,
    name: string,
    mimeType?: string,
    autoDownload?: boolean,
    inModal?: boolean,
  ): any;

  resetRoute?(name: string, params?: any): void;

  pop?(): boolean;

  getCurrentRouteName?(): string;

  getCurrentRouteParams?(): any;
}
