import * as React from "react";
import styles from "../SelfServiceFormPage/SelfServiceFormPage.scss";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { DayPicker, Styles as DayPickerStyles } from "react-day-picker";
import { useState } from "react";
import { InfoPopover } from "~views/shared/InfoPopover/InfoPopover";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import { Input } from "~views/shared/Input/Input";
import { Button } from "~views/shared/Button/Button";
import { PhoneValidatorMultiCountry } from "netbank-shared/src/libs/validators/PhoneValidator";
import { IUpdateDueDayFormInfo } from "netbank-shared/src/libs/models/UpdateDueDay";
import { IUpdateDueDayPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { IUpdateDueDayParams } from "netbank-shared/src/libs/determineLoadingParams";
import { useNavigate } from "react-router-dom";

interface IUpdateDueDayForm {
  pageData: IUpdateDueDayPage;
  updateParams: IUpdateDueDayParams;
  accountInfo: { name: string; displayNumber: string };
  goToAccountUrl: string;
}

export const UpdateDueDayForm = observer(({ pageData, updateParams, goToAccountUrl }: IUpdateDueDayForm) => {
  const { uiStore, customerStore } = useStores();
  const february = new Date("2015/02/01"); // A non leap year where February starts on a Sunday

  const { currentCustomer } = customerStore;

  const {
    buttonLabel,
    dayInputLabel,
    dayInputInfoTitle,
    dayInputInfoText,
    lastDayOfMonthLabel,
    phoneInputLabel,
    phoneInputInfoTitle,
    phoneInputInfoText,
  } = pageData;

  const { updateDueDay, updatingDueDay, currentDueDay } = updateParams;

  const [lastDayOfMonth, setLastDayOfMonth] = useState(false);

  const dayPickerStyles: DayPickerStyles = {
    caption: { display: "none" },
    head: { visibility: "hidden", height: 11 },
    tbody: { paddingRight: 10, paddingLeft: 10 },
    row: { marginTop: 8, marginBottom: 8 },
    cell: { fontSize: 13, width: 31, height: 31 },
    button: { padding: 17 },
  };

  const [formInfo, setFormInfo] = useState<IUpdateDueDayFormInfo>({
    selectedDay: undefined,
    phoneNumber: currentCustomer?.mobilePhone.value,
  });

  const isValidInput = () => {
    if (formInfo.selectedDay === undefined) return false;

    if (formInfo.phoneNumber === undefined) return false;

    if (!uiStore.locale || !PhoneValidatorMultiCountry(uiStore.locale, formInfo.phoneNumber)) return false;

    return true;
  };

  const navigate = useNavigate();

  const goBackToAccount = () => {
    navigate(goToAccountUrl);
  };

  return (
    <div>
      <div className={styles.input}>
        <div className={styles.label}>
          <p>{dayInputLabel || tx("date.newDueDay")}</p>
          {dayInputInfoText && (
            <InfoPopover popoverTitle={dayInputInfoTitle} content={dayInputInfoText} icon="question" />
          )}
        </div>
        <div className={`${styles.input} ${styles.day}`}>
          <div className={styles.dateInput}>
            <DayPicker
              className={`${styles.dayPicker} ${lastDayOfMonth ? styles.disabled : ""}`}
              defaultMonth={february}
              styles={dayPickerStyles}
              disabled={lastDayOfMonth || !!currentDueDay ? new Date(`2015/02/${currentDueDay}`) : undefined}
              selected={
                !!formInfo.selectedDay && formInfo.selectedDay !== 32
                  ? new Date(`2015/02/${formInfo.selectedDay}`)
                  : undefined
              }
              onDayClick={(day) => {
                setLastDayOfMonth(false);
                setFormInfo({ ...formInfo, selectedDay: day.getDate() });
              }}
            />
            <Checkbox
              label={lastDayOfMonthLabel || tx("date.lastDayOfMonth")}
              checkboxId="last-day"
              checked={lastDayOfMonth}
              onChange={() => {
                const checked = !lastDayOfMonth;
                setLastDayOfMonth(checked);
                setFormInfo({ ...formInfo, selectedDay: checked ? 32 : undefined });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.label}>
          <p>{phoneInputLabel || tx("updateContactInfo.phoneLabel")}</p>
          {phoneInputInfoText && (
            <InfoPopover popoverTitle={phoneInputInfoTitle} content={phoneInputInfoText} icon="question" />
          )}
        </div>
        <Input
          value={formInfo.phoneNumber || ""}
          onChange={(e) => setFormInfo({ ...formInfo, phoneNumber: e.target.value })}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          large
          title={tx("selfServiceForm.goBack")}
          bordered
          borderColor="black"
          color="white"
          onClick={() => goBackToAccount()}
          className={styles.button}
        />
        <Button
          className={styles.button}
          large
          title={buttonLabel || tx("misc.send")}
          color={isValidInput() ? "red" : ""}
          bordered={!isValidInput()}
          disabled={!isValidInput()}
          loading={updatingDueDay}
          onClick={() => updateDueDay(formInfo)}
        />
      </div>
    </div>
  );
});
