import { ICustomerResponse, IUpdateContactInfo } from "libs/models/Customer/Customer";

export const mockSECustomerData: ICustomerResponse = {
  customerData: {
    customerId: "24325123",
    name: "Zlatan",
    surname: "Ibrahimović",
    address: {
      street: {
        value: "Råsta Strandväg 1",
        editable: false,
      },
      city: {
        value: "Stockholm",
        editable: false,
      },
      country: {
        value: "Sweden",
        editable: false,
        validAnswers: [],
      },
      postCode: {
        value: "169 56",
        editable: false,
      },
    },
    emailAddress: {
      value: "test@santanderconsumer.se",
      editable: false,
    },
    mobilePhone: {
      value: "+46 8 502 123 45",
      editable: false,
    },
    capabilities: {
      canHandleTransferAccounts: false,
      hasNationalPayoutAccount: true,
    },
    personalPreferences: [],
  },
};

export const mockUpdateContactInfo: IUpdateContactInfo = {
  updatedCustomerInfoError: false,
};
