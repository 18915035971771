/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from "react";
import Select, { StylesConfig, MultiValue, createFilter, components, SelectInstance } from "react-select";
import { IMultiSelectOption } from "netbank-shared/src/libs/models/MultiSelect/MultiSelectOption";
import rootStyles from "~views/pages/Root.scss";
import styles from "./MultiSelect.scss";
import makeAnimated from "react-select/animated";
import { Colors } from "~globalstyles/colors";
import errorIcon from "~assets/error.svg";
import searchIcon from "~assets/search-red.svg";
import deleteIcon from "~assets/delete.svg";
import { tx } from "netbank-shared/src/libs/i18n";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";

const multiSelectStyles: StylesConfig = {
  control: (base, { menuIsOpen }) => ({
    ...base,
    minHeight: "48px",
    borderWidth: "2px",
    borderColor: Colors.mediumSky,
    borderRadius: "11px",
    borderBottomLeftRadius: menuIsOpen ? "0px" : "11px",
    borderBottomRightRadius: menuIsOpen ? "0px" : "11px",
    boxShadow: "none",
    ":hover, :focus": {
      borderColor: Colors.darkSky,
    },
  }),
  option: (base, { isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isSelected || isFocused ? Colors.blackSqueeze : undefined,
      filter: isSelected ? "brightness(98%)" : "",
      borderBottom: `1px solid ${Colors.white}`,
      color: Colors.black,
      ":active": {
        backgroundColor: Colors.blackSqueeze,
        filter: "brightness(98%)",
      },
    };
  },
  menu: (base) => {
    return {
      ...base,
      marginTop: 0,
      borderRadius: "11px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: "none",
      border: `2px solid ${Colors.darkSky}`,
      borderTop: "none",
      overflow: "hidden",
    };
  },
  menuList: (base) => {
    return {
      ...base,
      padding: 0,
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      boxShadow: "none",
    };
  },
  multiValue: (base) => {
    return {
      ...base,
      backgroundColor: Colors.blackSqueeze,
      padding: "3px 8px 3px 16px",
      borderRadius: "22px",
      height: "38px",
      ":hover": {
        backgroundColor: Colors.mediumSky,
      },
      ":hover div[role=button] > div": {
        backgroundColor: Colors.darkSky,
        cursor: "pointer",
      },
    };
  },
  multiValueLabel: (base) => ({
    ...base,
    paddingRight: "8px",
    fontSize: "18px",
    fontWeight: "400",
    color: Colors.black,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ":hover": {
      backgroundColor: "initial",
      color: "initial",
    },
  }),
  input: (base) => ({
    ...base,
    overflow: "hidden",
  }),
};

interface IMultiSelectProps {
  options?: IMultiSelectOption[];
  value?: IMultiSelectOption[];
  onChange: (value: MultiValue<IMultiSelectOption> | undefined) => void;
  label?: string;
  placeholder?: string;
  infoPopover?: IInfoPopoverProps;
  error?: string;
  disabled?: boolean;
  showMenuOnFocus?: boolean;
}

export const MultiSelect = ({
  value,
  options,
  onChange,
  label,
  placeholder,
  infoPopover,
  error,
  disabled,
  showMenuOnFocus = true,
}: IMultiSelectProps) => {
  const animatedComponents = makeAnimated();
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const sortedOptions = [...(options as IMultiSelectOption[])].sort((a, b) => {
    const p1 = a.priority === undefined ? 0 : 1;
    const p2 = b.priority === undefined ? 0 : 2;
    return p2 - p1;
  });

  const selectRef = useRef<SelectInstance>(null);
  const blurWhenRemovingSelectedOption = () => selectRef.current && selectRef.current.blur(); // Fix to make select interactive when removing all selected options

  return (
    <div>
      {label && (
        <div className={styles.labelRow}>
          <span className={styles.label}>{label}</span>
          {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
        </div>
      )}
      <Select
        ref={selectRef}
        styles={multiSelectStyles}
        components={{
          ...animatedComponents,
          DropdownIndicator: () => null,
          IndicatorsContainer: () => <img className={styles.searchIcon} src={searchIcon} alt="search" />,
          MultiValueRemove: (props) => {
            return (
              <components.MultiValueRemove {...props}>
                <div className={styles.valueRemove} onClick={() => blurWhenRemovingSelectedOption()}>
                  <img className={styles.icon} src={deleteIcon} alt="delete" />
                </div>
              </components.MultiValueRemove>
            );
          },
        }}
        noOptionsMessage={() => tx("misc.multiSelectNoOptions")}
        placeholder={placeholder}
        value={value}
        onFocus={() => {
          if (showMenuOnFocus) setMenuIsOpen(true);
        }}
        onBlur={() => {
          if (showMenuOnFocus) setMenuIsOpen(false);
        }}
        options={sortedOptions as any}
        onChange={(v) => {
          setMenuIsOpen(false);
          onChange(v);
        }}
        filterOption={createFilter({
          ignoreCase: true,
          ignoreAccents: true,
          trim: false,
          matchFrom: "start",
        })}
        hideSelectedOptions={false}
        menuIsOpen={menuIsOpen}
        isDisabled={disabled}
        blurInputOnSelect
        isMulti
      />
      {error && (
        <div className={rootStyles.error}>
          <img src={errorIcon} alt="error-icon" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
