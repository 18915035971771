import React from "react";
import styles from "./SettlementDocument.scss";
import rootStyles from "~views/pages/Root.scss";
import pdfIcon from "~assets/pdf-icon.svg";

interface ISettlementDocumentProps {
  fileName: string;
  loading: boolean;
  downloadAction?: () => Promise<void> | void;
}

export const SettlementDocument = ({ fileName, loading, downloadAction }: ISettlementDocumentProps) => {
  const type = fileName.split(".").pop();
  return (
    <button type="button" className={styles.file} onClick={downloadAction}>
      {loading ? (
        <div className={styles.documentLoader}>
          <span className={[styles.spinner, rootStyles.spinner, rootStyles.loading].join(" ")} />
        </div>
      ) : (
        <>
          <img className={rootStyles.pdfIcon} src={pdfIcon} alt="pdf-icon" />
          <span>{`${fileName} (${type})`}</span>
        </>
      )}
    </button>
  );
};
