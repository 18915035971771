import * as React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SelfServiceFormPage.scss";
import { Button, InfoText } from "~views/shared";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";

interface ISelfServiceFormResult {
  goBackToAccountUrl: string;
  content: {
    header: string;
    text?: string;
    buttonLabel: string;
  };
  contentType: NotificationLevel;
}

export const SelfServiceFormResult = ({ content, goBackToAccountUrl, contentType }: ISelfServiceFormResult) => {
  const navigate = useNavigate();

  const goBackToAccount = () => {
    navigate(goBackToAccountUrl);
  };

  const { header, text, buttonLabel } = content;

  return (
    <div>
      <InfoText header={header} text={text} level={contentType} />
      <div className={styles.buttonContainer}>
        <Button
          large
          title={buttonLabel}
          bordered
          borderColor="black"
          color="white"
          onClick={() => goBackToAccount()}
        />
      </div>
    </div>
  );
};
