import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "../Page.scss";
import rootStyles from "../Root.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { Header } from "~views/shared";

export const OutdatedBrowser = observer(() => {
  const contentWrapperClasses = [rootStyles.contentWrapper];
  const { contentStore, uiStore } = useStores();
  useEffect(() => {
    const fetchPage = async () => {
      await contentStore.getPage(`/${tx("routing.lang")}`);
    };
    fetchPage();
  }, []);

  const page = contentStore.currentPage as IPage;
  const image = uiStore.isMobile
    ? page?.outdatedBrowserImageMobile || page?.outdatedBrowserImage
    : page?.outdatedBrowserImage;

  if (!page) return null;
  return (
    <div className={contentWrapperClasses.join(" ")}>
      <Header />
      <div className={rootStyles.main}>
        <div className={styles.wrapper}>
          <section className={[styles.outdatedBrowser, rootStyles.fullWidth].join(" ")}>
            <HtmlContent className={styles.outdatedBrowserContent} html={page.outdatedBrowserContent} />
            {image?.url && (
              <img className={styles.outdatedBrowserImage} src={image?.url} alt={image.altText || image.name} />
            )}
          </section>
        </div>
      </div>
    </div>
  );
});
