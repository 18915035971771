import React, { ReactNode, RefObject, useRef, useState } from "react";
import { observer } from "mobx-react";
import styles from "./ExpandableText.scss";

interface IExpandableTextProps {
  header: string;
  rightHeader?: string;
  children: ReactNode;
  expanded?: boolean;
  borderedBottom?: boolean;
}

export const ExpandableText = observer(
  ({ header, rightHeader, children, borderedBottom, expanded = false }: IExpandableTextProps) => {
    const textRef: RefObject<HTMLDivElement> = useRef(null);

    const [open, setOpen] = useState(expanded);
    const classes = [styles.wrapper];

    if (open) {
      classes.push(styles.open);
    }

    if (borderedBottom) {
      classes.push(styles.borderedBottom);
    }

    return (
      <div className={classes.join(" ")}>
        <button type="button" className={styles.header} onClick={() => setOpen(!open)}>
          <div className={styles.buttonContent}>
            <span>{header}</span>
            {!open && rightHeader && <span>{rightHeader}</span>}
          </div>
        </button>
        <div ref={textRef} className={styles.text}>
          <span className={styles.childWrapper}>{children}</span>
        </div>
      </div>
    );
  },
);
