import React, { RefObject, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import styles from "./HamburgerNav.scss";
import { useStores } from "netbank-shared/src/hooks";
import { Menu } from "../Menu/Menu";
import FocusTrap from "focus-trap-react";

export const HamburgerNav = observer(() => {
  const { contentStore } = useStores();
  const [open, setOpen] = useState(false);
  const mainMenuRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (mainMenuRef.current) {
      if (open) {
        disableBodyScroll(mainMenuRef.current);
      } else {
        enableBodyScroll(mainMenuRef.current);
      }
    }
  }, [open]);

  const navClasses = [styles.nav];
  const iconClasses = [styles.hamburgerIcon];

  if (open) {
    navClasses.push(styles.open);
    iconClasses.push(styles.open);
  }
  return (
    <FocusTrap active={open}>
      <div className={styles.hamburgerMenu}>
        <button type="button" className={styles.hamburger} onClick={() => setOpen(!open)}>
          <div className={iconClasses.join(" ")} />
        </button>
        <nav className={navClasses.join(" ")}>
          <Menu page={contentStore.currentPage} toggleOpen={() => setOpen(!open)} mainMenuRef={mainMenuRef} />
        </nav>
      </div>
    </FocusTrap>
  );
});
