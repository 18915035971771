import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { IDepositAccount } from "netbank-shared/src/libs/models/CustomerProducts";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import {
  formatDate,
  getDaysLeftUntil,
  templateString,
  toLocaleString,
  getAccountDisplayName,
} from "netbank-shared/src/libs/utils";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, InfoText } from "~views/shared";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import styles from "../Page.scss";
import { withRoot } from "../Root";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import editIcon from "~assets/pencil-outline.svg";

export interface DepositPageProps {
  location: Location;
}

const renderFTDDate = (currentAccount: IDepositAccount, activationDateTitle?: string, maturityDateTitle?: string) => {
  const { activationDate, maturityDate, isActivationPeriodExpired } = currentAccount;
  let date;
  let title;

  if (!currentAccount || !activationDate || !maturityDate) return undefined;

  if (isActivationPeriodExpired) {
    date = formatDate(maturityDate);
    title = maturityDateTitle || tx("deposit.FTDMaturityDateTitle");
  } else {
    date = formatDate(activationDate);
    title = activationDateTitle || tx("deposit.FTDActivationDateTitle");
  }

  return (
    <p>
      <span className={styles.bold}>{title}: </span>
      {date}
    </p>
  );
};

const renderFTDInformation = (
  currentAccount: IDepositAccount,
  maturityDateText?: string,
  activationDateText?: string,
  activationDaysLeftText?: string,
  lastActivationDayText?: string,
) => {
  const { activationDate, maturityDate, isActivationPeriodExpired } = currentAccount;

  if (!currentAccount || !activationDate || !maturityDate) return undefined;

  const level = isActivationPeriodExpired ? NotificationLevel.important : NotificationLevel.information;

  const daysLeftUntilActivation = getDaysLeftUntil(activationDate);
  const daysLeftUntilActivationText = templateString(
    daysLeftUntilActivation > 1
      ? activationDaysLeftText || tx("deposit.FTDActivationDaysLeftText")
      : lastActivationDayText || tx("deposit.FTDLastActivationDayText"),
    {
      activationDate: activationDate ? formatDate(activationDate) : "",
      maturityDate: maturityDate ? formatDate(maturityDate) : "",
      activationDaysLeft: daysLeftUntilActivation,
    },
  );

  const text = templateString(
    isActivationPeriodExpired
      ? maturityDateText || tx("deposit.FTDDepositPageMaturityDateTemplateStringText")
      : activationDateText || tx("deposit.FTDDepositPageActivationDateTemplateStringText"),
    {
      maturityDate: formatDate(maturityDate),
      activationDate: formatDate(activationDate),
      activationDaysLeft: daysLeftUntilActivation,
      activationDaysLeftText: daysLeftUntilActivationText,
    },
  );

  return (
    <section>
      <InfoText text={text} level={level} />
    </section>
  );
};

const DepositPage = observer(() => {
  const location = useLocation();
  const { depositId } = useParams<IProductRouteParams>();
  const { contentStore, depositStore, uiStore } = useStores();
  const { currency, locale } = uiStore;
  const { currentAccount } = depositStore;

  const depositTemplate = contentStore.currentDepositTemplatePage;

  useEffect(() => {
    if (depositId) {
      const fetchAccount = async () => {
        if (!depositStore.loadingAccount) {
          depositStore.getDepositAccount(depositId);
        }
      };
      fetchAccount();
    }
    return () => {
      depositStore.resetCurrentAccount();
    };
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      depositStore.currentAccountId = depositId;
      if (!depositStore.loadingAccount) {
        await contentStore.getDepositTemplate();
      }
    };
    fetchTemplate();
  }, [depositStore.loadingAccount, location]);

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  useEffect(() => {
    if (depositId) {
      depositStore.setCurrentAccount(depositId);
    }
  }, [currentAccount, depositId, contentStore.depositTemplatePages]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage || depositStore.loadingAccount) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  const isClosing = !!currentAccount.closesAt && new Date(currentAccount.closesAt).getTime() > Date.now();

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        {currentAccount.isClosed && (
          <div className={styles.bannerWrapper}>
            <InfoText
              text={depositTemplate?.closedAccountBannerText || tx("deposit.closedAccountBannerText")}
              level={NotificationLevel.information}
            />
          </div>
        )}
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(depositStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(depositTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{depositTemplate?.subHeading || `${tx("misc.accountNumber")}:`}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
          {depositTemplate?.accountTypeSubHeadingLabel && (
            <p>
              <span className={styles.bold}>{depositTemplate.accountTypeSubHeadingLabel}</span>
              {` ${currentAccount.name}`}
            </p>
          )}
          {renderFTDDate(currentAccount, depositTemplate?.activationDateTitle, depositTemplate?.maturityDateTitle)}
        </div>
      </section>
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isTablet && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${depositTemplate?.image?.url})`,
              }}
            />
          )}
          <div className={styles.templateContent}>
            <div className={styles.balance}>
              <div className={styles.templateTitleContainer}>
                <span className={styles.templateTitle}>
                  {depositTemplate?.availableAmount || tx("deposit.availableBalance")}
                </span>
                <h3>{toLocaleString(isClosing ? 0 : currentAccount.availableBalance, currency, locale)}</h3>
              </div>
              <div className={styles.templateTitleContainer}>
                <div className={styles.rightText}>
                  <span className={styles.templateTitle}>
                    {depositTemplate?.bookedBalance || tx("deposit.bookedBalance")}
                  </span>
                  <h3>{toLocaleString(isClosing ? 0 : currentAccount.interestCarryingBalance, currency, locale)}</h3>
                </div>
              </div>
            </div>

            <div className={styles.flexBetween}>
              <div className={styles.templateTitleContainer}>
                <span className={styles.templateTitle}>{depositTemplate?.interest}</span>
                <h4>
                  {(!!currentAccount.accountInterest || currentAccount.accountInterest === 0) &&
                    `${toLocaleString(currentAccount.accountInterest || 0, undefined, locale, 2)} %`}
                  {!!currentAccount.minimumInterestBearingAmount && (
                    <div className={styles.interestInfoText}>
                      {templateString(
                        depositTemplate?.interestInfoText || tx("deposit.minimumInterestBearingAmountInfo"),
                        {
                          minimumInterestBearingAmount: toLocaleString(
                            currentAccount.minimumInterestBearingAmount,
                            currency,
                            locale,
                            0,
                          ),
                        },
                      )}
                    </div>
                  )}
                </h4>
              </div>
              <div className={styles.templateTitleContainer}>
                <div className={styles.rightText}>
                  <span className={styles.templateTitle}>{depositTemplate?.accruedInterest}</span>
                  <h4>{toLocaleString(currentAccount.accumulatedInterest || 0, currency, locale)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isClosing && !currentAccount.isClosed && depositTemplate?.closingAccountInfoContent && (
        <section>
          <InfoText text={depositTemplate?.closingAccountInfoContent} level={NotificationLevel.information} />
        </section>
      )}
      {!currentAccount.isClosed &&
        renderFTDInformation(currentAccount, depositTemplate?.maturityDateText, depositTemplate?.activationDateText)}
      <ContentArea page={depositTemplate} />
    </div>
  );
});

export default withRoot(DepositPage);
