import { IBaseAccount } from "libs/models/CustomerProducts";

export const getAccountDisplayName = (account: IBaseAccount) => {
  if (account?.nickname) {
    if (account.nickname.length > 20) {
      return account.nickname.slice(0, 20).concat("...");
    }
    return account.nickname;
  }
  return account?.name;
};
