import React from "react";
import { observer } from "mobx-react";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { AccordionFaqBlock } from "./Accordions/AccordionFaqBlock";
import styles from "./FaqBlock.scss";
import rootStyles from "~views/pages/Root.scss";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { IFaq } from "netbank-shared/src/libs/models/Content/Faq";
import { capitalize } from "netbank-shared/src/libs/utils";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";

interface IFaqBlockProps {
  faqBlocks?: IGenericContentLink<IFAQItem>[];
  data?: IGenericContentLink<IFaq>;
  color?: string;
  label?: string;
  noHorizontalPadding?: boolean;
  accordion?: boolean;
  noBorder?: boolean;
  accountId?: string;
}

export const FaqBlock = observer(
  ({ data, faqBlocks, label, color, noHorizontalPadding, accordion, noBorder, accountId }: IFaqBlockProps) => {
    const faqs = faqBlocks || data?.faqBlocks;
    if (!faqs || faqs?.length === 0) return null;

    const sectionClasses = [];
    const classes = [styles.wrapper];

    if (noHorizontalPadding) {
      sectionClasses.push(rootStyles.faqNoHorizontalPadding);
    }

    if (color) {
      classes.push(styles[`bg${capitalize(color)}`]);
    }

    if (accordion) {
      sectionClasses.push(rootStyles.noPadding, rootStyles.noBorder, rootStyles.noBottomPadding);
    }

    if (noBorder) {
      sectionClasses.push(rootStyles.noBorder, rootStyles.noBottomPadding);
    }

    return (
      <section className={sectionClasses.join(" ")}>
        {data?.header && <h3>{data.header}</h3>}
        {data?.text && <HtmlContent html={data.text} />}
        {label && <span className={styles.label}>{label}</span>}
        <div className={classes.join(" ")}>
          {faqs.map((faqBlock) => (
            <AccordionFaqBlock key={faqBlock.contentLink.guidValue} data={faqBlock} accountId={accountId} />
          ))}
        </div>
      </section>
    );
  },
);
