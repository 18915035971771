import { ILeaseAccount, ILeaseAccountsApiResponse, ILeaseBaseAccount } from "libs/models/CustomerProducts";
import { AccountStatus, AccountType } from "../../../models/CustomerProducts/BaseAccount";
import { InvoiceDistributionFormatType } from "../../../models/CustomerProducts/Invoice";

export * from "./payments";

export const mockBaseLeaseAccountOne: ILeaseBaseAccount = {
  name: "Leasing 1",
  nickname: "My Personal Leasing 1",
  accountId: "4257560",
  accountStatus: AccountStatus.AccountOk,
  accountType: AccountType.Leasing,
  isDisabled: false,
  displayNumber: "4257560",
  availableBalance: 0,
  carInformation: {
    license: "ABC123",
    description: "A Car",
  },
  hasOverdueInvoice: true,
  isAmlFrozen: true,
};

export const mockLeaseAccountOne: ILeaseAccount = {
  ...mockBaseLeaseAccountOne,
  contractPeriod: 36,
  openDate: "2024-01-03T00:00:00+02:00",
  elapsedPeriod: 12,
  canPromiseToPay: true,
  canPromiseToPayInvoiceInformation: {
    invoiceNumber: "123123",
    dueDate: "2024-09-01T00:00:00Z",
    invoiceAmount: 1000,
  },
  invoiceDistributionFormat: InvoiceDistributionFormatType.PaperInvoice,
  canRequestMileageChange: true,
  eInvoice: undefined,
  insurances: [
    {
      type: "Santander Lainaturva",
      canTerminate: true,
    },
  ],
};

export const mockLeaseAccounts: ILeaseAccountsApiResponse = {
  leaseAccounts: [mockLeaseAccountOne],
  errors: "",
};
