import React from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { spaceEveryFourthLetter } from "netbank-shared/src/libs/utils";
import cardStyles from "./Card.scss";
import { CreditAccountNames, CardStatus, ICard } from "netbank-shared/src/libs/models/CustomerProducts";
import chipImg from "~assets/card-chip.svg";

export interface ICardItemProps {
  card: ICard;
}

export const CardItem = observer(({ card }: ICardItemProps) => {
  const { creditStore, contentStore } = useStores();
  const { currentAccount } = creditStore;
  const { currentCardTemplatePage } = contentStore;

  if (!currentAccount || !currentCardTemplatePage) return null;

  const classes = [cardStyles.cardItem];

  switch (currentAccount.name) {
    case CreditAccountNames.SantanderRed:
      classes.push(cardStyles.red);
      break;
    case CreditAccountNames.AcceptCard:
      classes.push(cardStyles.tundora);
      break;
    default:
      classes.push(cardStyles.red);
      break;
  }

  let cardState: string;
  switch (card.status) {
    case CardStatus.Active:
      cardState = currentCardTemplatePage.activeCardLabel || tx("card.activeCardLabel");
      break;
    case CardStatus.Blocked:
      cardState = currentCardTemplatePage.blockedCardLabel || tx("card.blockedCardLabel");
      classes.push(cardStyles.disabled);
      break;
    case CardStatus.Expired:
      cardState = currentCardTemplatePage.expiredCardLabel || tx("card.expiredCardLabel");
      classes.push(cardStyles.disabled);
      break;
    case CardStatus.Inactive:
      cardState = currentCardTemplatePage.inactiveCardLabel || tx("card.inactiveCardLabel");
      classes.push(cardStyles.disabled);
      break;
    default:
      cardState = "";
      break;
  }

  return (
    <div className={classes.join(" ")}>
      <div className={cardStyles.cardContent}>
        <img src={chipImg} alt="chip" width={36} height={27} />
        <div>
          <p className={cardStyles.cardNumber}>{spaceEveryFourthLetter(card.maskedCardNumber)}</p>
          {card.cardHolderName && <p className={cardStyles.holder}>{card.cardHolderName.toUpperCase()}</p>}
          <div className={cardStyles.cardDetails}>
            {card.expiryDate && <p className={cardStyles.expiry}>{dayjs(card.expiryDate).format("MM/YY")}</p>}
            {cardState && <p className={cardStyles.cardState}>{cardState.toUpperCase()}</p>}
          </div>
        </div>
      </div>
    </div>
  );
});
