import { TransferStatus } from "../Content/Enums";

export interface ITransferAccountsResponse {
  accounts: ITransferAccount[];
}

export interface ITransfer {
  autoStartToken: string;
  transactionId: string;
}

export interface ITransferStatus {
  status: TransferStatus;
}

export interface IToAccountFields {
  accountNumber: string;
  clearingNumber?: string;
  displayName?: string;
  displayNumber?: string;
  isNationalPayoutAccount?: boolean;
  isFixedTerm?: boolean;
  availableBalance?: number;
}

export interface ITransferAccountFields {
  accountNumber: string;
  clearingNumber: string;
  displayName: string;
}

export type ITransferAccount = ITransferAccountFields & {
  bank: string;
  isAccountVerified: boolean;
};

export interface IInternalTransferAccount {
  accountId: string;
  type: InternalTransferAccountType;
}

export enum InternalTransferAccountType {
  Internal = "Internal",
  NemAccount = "NemAccount",
}

export interface ITransferCalculationResponse {
  allowed: boolean;
  valueDate: string;
  amount: number;
  fromAccountAvailableBalance: number;
  penaltyAmount: number;
  toAccountAvailableBalance: number;
  details: string;
  fromAccountId: string;
  fromAccountNumber: string;
  toAccountId: string;
  toAccountNumber: string;
}

export interface IWithdrawalCalculationResponse {
  accountId: string;
  accountNumber: string;
  accountName: string;
  availableBalance: number;
  interestCarryingBalance: number;
  amount: number;
  valueDate: string;
  penaltyAmount: number;
  allowed: boolean;
  details: string;
}

export interface ICreateTransferResponse {
  fromAccountId: string;
  fromAccountNumber: string;
  fromAccountName: string;
  toAccountId: string;
  toAccountNumber: string;
  toAccountName: string;
  amount: number;
  success: boolean;
  details: string;
  hasLongTransfer: boolean;
}

export interface ITransferStatusContent {
  statusCode: string;
  statusType: number;
  header: string;
  text: string;
  longTransferText?: string;
}

export interface ICreateWithdrawalResponse {
  accountId: string;
  accountNumber: string;
  accountName: string;
  amount: number;
  success: boolean;
  details: string;
}

export interface IGetAccountRedemptionResponse {
  accountId: string;
  accountName: string;
  accountNumber: string;
  openDate: string;
  closeDate: string;
  availableBalance: number;
  interestCarryingBalance: number;
  totalPayment: number;
  interest: number;
  accumulatedInterest: number;
  penaltyInterest: number;
  reservedAmount: number;
}

export interface ISavedAccountFormProps {
  handleSavedAccount: () => void; // Either create or update function
  account: ITransferAccountFields | undefined;
  setAccount: (account: ITransferAccountFields) => void;
  loading: boolean;
  clearingNumberLabel?: string;
  accountNumberLabel?: string;
  accountNameLabel?: string;
  clearingStartsWithEightThenFiveDigitsError?: string;
  clearingNumberInformation?: string;
  accountNumberInformation?: string;
  saveLabel?: string;
  extraClasses?: string[];
  updating?: boolean;
  error?: string;
  header?: string;
}

export interface ITransferResultContent {
  labels?: ITransferResultLabels;
  withdrawalTimes?: ITransferResultWithdrawalTimes;
  infoTexts?: ITransferResultInfoTexts;
  newTransferText?: string;
  withdrawalSuccessHeaderText?: string;
  withdrawalErrorHeaderText?: string;
  transferSuccessHeaderText?: string;
  transferErrorHeaderText?: string;
  withdrawalSuccessText?: string;
  transferErrorText?: string;
  transferSuccessText?: string;
  nationalPayoutAccountWithdrawalInfo?: string;
}

interface ITransferResultLabels {
  transferedAmount?: string;
  fromAccount?: string;
  toAccount?: string;
  transferTime?: string;
}

interface ITransferResultWithdrawalTimes {
  myDeposit?: string;
  myDepositPlus?: string;
}

interface ITransferResultInfoTexts {
  myDepositTransfer?: string;
  myDepositPlusTransfer?: string;
  myDepositPlusTransferToOpenFTD?: string;
  openFTDTransfer?: string;
  activatedFTDTransfer?: string;
}
