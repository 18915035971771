import React from "react";
import { observer } from "mobx-react";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { LoadingLottie } from "../Lottie/LoadingLottie";

interface IProcessingSpinnerProps {
  header: string;
  text: string;
}

export const ProcessingSpinner = observer(({ header, text }: IProcessingSpinnerProps) => {
  return (
    <div>
      <HtmlContent html={`<h1>${header}</h1>`} />
      {text && <HtmlContent html={text} />}
      <LoadingLottie />
    </div>
  );
});
