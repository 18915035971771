import { useTracking } from "netbank-shared/src/hooks";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { tx } from "netbank-shared/src/libs/i18n";
import React, { CSSProperties, forwardRef, ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./DynamicLink.scss";

interface IDynamicLinkProps {
  to: string;
  target?: string;
  children?: ReactNode;
  className?: string;
  trackingCategory?: TrackingCategory;
  trackingAction?: TrackingAction;
  trackingPrefix?: string;
  style?: CSSProperties;
  onClick?: () => void;
  download?: string;
  tabIndex?: 0 | -1 | undefined;
  disabled?: boolean;
}

export const DynamicLink = forwardRef<HTMLAnchorElement, IDynamicLinkProps>(
  (
    {
      to,
      children,
      onClick,
      trackingCategory,
      trackingAction,
      trackingPrefix,
      download,
      tabIndex = undefined,
      disabled,
      className,
      ...rest
    },
    ref,
  ) => {
    const dataLayer = useTracking((window as any).dataLayer);

    if (typeof to !== "string") {
      return null;
    }

    const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (trackingCategory && trackingAction) {
        dataLayer.pushInteraction(trackingCategory, trackingAction, trackingPrefix);
      }

      onClick?.();
    };

    const href = to === "" ? `/${tx("routing.lang")}` : to;

    const linkClasses = [className];
    const aClasses = [className];

    if (disabled) {
      linkClasses.push(styles.disabled);
      aClasses.push(styles.disabled);
    }

    if (href.startsWith("/")) {
      return (
        <Link
          ref={ref}
          to={href}
          {...rest}
          onClick={clickHandler}
          tabIndex={tabIndex}
          className={linkClasses.join(" ")}
        >
          {children}
        </Link>
      );
    }
    return (
      <a
        ref={ref}
        href={href}
        download={download}
        {...rest}
        onClick={clickHandler}
        tabIndex={tabIndex}
        className={aClasses.join(" ")}
      >
        {children}
      </a>
    );
  },
);
