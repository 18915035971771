import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { Lang } from "netbank-shared/src/libs/models/Content/Enums";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "./Dropdown/Dropdown";
import webIcon from "~assets/web.svg";

interface ILangPickerProps {
  uncachedHomePage?: IPage;
}

const buildLanguageDropdownItems = (languageList: Lang[]) => {
  return languageList.map((lang, index) => {
    return { index, value: Lang[lang], label: tx(`langPicker.${lang}`) };
  });
};

export const LangPicker = observer(({ uncachedHomePage }: ILangPickerProps) => {
  const navigate = useNavigate();
  const { contentStore, uiStore } = useStores();
  const { changeLanguage, i18nLang, currentPage, translateUrl } = contentStore;

  const handleChange = async (item: ListItem) => {
    const redirectUrl = currentPage && (await translateUrl(currentPage.url, item.value, i18nLang));
    await changeLanguage(item.value);
    navigate(`${redirectUrl}`);
  };

  const homePage = uncachedHomePage || contentStore.homePage;

  const availableLanguages =
    (homePage?.languagePicker?.supportedLanguages?.split(",").filter((s) => s !== "") as Lang[]) || [];

  // Current language should always be available
  if (uiStore.lang && !availableLanguages.includes(uiStore.lang)) {
    availableLanguages.push(uiStore.lang);
  }

  if (!homePage?.languagePicker || homePage.languagePicker.hidden || availableLanguages.length < 2) {
    return null;
  }

  const languageDropdownItems = buildLanguageDropdownItems(availableLanguages);

  return (
    <Dropdown
      icon={webIcon}
      value={tx(`langPicker.${i18nLang}`)}
      onChange={handleChange}
      list={languageDropdownItems}
      borderless
      smallChevron
    />
  );
});
