import React, { useState } from "react";
import { observer } from "mobx-react";
import styles from "./ExpandableInfo.scss";
import { IDataRow } from "netbank-shared/src/libs/models/IDataRow";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";

interface IExpandableInfoProps {
  rows: IDataRow[];
  leftHeader: string | number;
  rightHeader?: string | number;
  contentBelowHeader?: React.ReactNode;
  children?: React.ReactNode;
}

export const ExpandableInfo = observer(
  ({ rows, leftHeader, rightHeader, contentBelowHeader, children }: IExpandableInfoProps) => {
    const [open, setOpen] = useState(false);
    const classes = [styles.wrapper];
    const contentClasses = [styles.content];

    if (open) {
      classes.push(styles.open);
      contentClasses.push(styles.open);
    }

    const hasChildren = typeof children !== "undefined";

    return (
      <div className={classes.join(" ")}>
        <button type="button" className={styles.label} onClick={() => setOpen(!open)}>
          <div className={styles.header}>
            <span className={styles.labelText}>{leftHeader}</span>
            {rightHeader && <span className={styles.labelText}>{rightHeader}</span>}
          </div>
          {contentBelowHeader && contentBelowHeader}
          <span className={styles.icon} />
        </button>
        <div className={contentClasses.join(" ")}>
          <div className={`${styles.data} ${hasChildren ? styles.hasChildren : ""}`}>
            {rows
              .filter((row) => {
                if (row.showCondition === false) return false;
                if (row.type === "data") return !!row.data;
                if (row.type === "comp") return !!row.comp;
                return !!row.text;
              })
              .map((row, i) => {
                if (row.type === "data") {
                  return (
                    <div key={i}>
                      <div className={styles.contentRow}>
                        <span className={styles.bold}>{row.label}</span>
                        <span>{row.data}</span>
                      </div>
                      {row.description && <HtmlContent html={row.description} />}
                    </div>
                  );
                }

                if (row.type === "comp") {
                  const Comp = row.comp;
                  return (
                    <div key={i}>
                      <div className={styles.contentRow}>
                        <span className={styles.bold}>{row.label}</span>
                        {Comp}
                      </div>
                      {row.description && <HtmlContent html={row.description} />}
                    </div>
                  );
                }
                return (
                  <div className={styles.contentTextarea} key={i}>
                    <span>{row.text}</span>
                  </div>
                );
              })}
          </div>
          {children}
        </div>
      </div>
    );
  },
);
