import MockAdapter from "axios-mock-adapter";
import {
  mockComplianceDefinitions,
  mockComplianceStatuses,
  mockComplianceSignatures,
  mockUploadAttachments,
} from "./customerCompliance";

const idRegexSegment = "[a-zA-Z0-9-]*";

export const MockCustomerComplianceApi = (mock: MockAdapter) => {
  mock.onGet("statuses").reply(200, mockComplianceStatuses);
  mock.onGet("signatures").reply(200, mockComplianceSignatures); // TODO: Implement polling support for mock
  mock.onGet("questionnaire-definitions").reply(200, mockComplianceDefinitions);
  mock.onPut(new RegExp(`questionnaire-answers/${idRegexSegment}`)).reply(204);
  mock.onPost(new RegExp(`questionnaire-answers/${idRegexSegment}/attachments`)).reply(200, mockUploadAttachments);
  mock.onDelete(new RegExp(`questionnaire-answers/${idRegexSegment}/attachments/${idRegexSegment}`)).reply(204);
};
