import { AccountType } from "../models/CustomerProducts";
import { tx } from "../i18n";

export function getLangInUrl(url: string) {
  // Assumes url structure of /lang/page/*
  return url.split("/")[1];
}

export function setLangInUrl(url: string, lang: string) {
  // Assumes url structure of /lang/page/*
  const urlArray = url.split("/");
  urlArray[1] = lang;
  return urlArray.join("/");
}

export function getProductUrl(accountType: AccountType, accountId: string) {
  let productString = "";
  switch (accountType) {
    case AccountType.PrivateLoan:
      productString = tx("routing.privateLoan");
      break;
    case AccountType.SecuredLoan:
      productString = tx("routing.securedLoan");
      break;
    case AccountType.SalesFinance:
      productString = tx("routing.salesFinance");
      break;
    case AccountType.Deposit:
      productString = tx("routing.deposit");
      break;
    case AccountType.Credit:
      productString = tx("routing.card");
      break;
    case AccountType.Leasing:
    case AccountType.PrivateLeasing:
      productString = tx("routing.leasing");
      break;
    default:
  }

  return `/${tx("routing.lang")}/${productString}/${accountId}`;
}
