import React from "react";
import styles from "./OrganizationOwnerTable.scss";
import { IInfoPopoverProps, InfoPopover } from "~views/shared/InfoPopover/InfoPopover";
import { IComplianceDefinitionQuestion } from "netbank-shared/src/libs/models/CustomerCompliance/Compliance";
import { observer } from "mobx-react";

interface IOrganizationOwnerTableRowProps {
  label: string;
  value: string | undefined;
}

interface IOrganizationOwnerTableProps {
  id: string;
  list: IComplianceDefinitionQuestion[][] | undefined;
  renderField: (field: IComplianceDefinitionQuestion) => React.ReactNode;
  label?: string;
  infoPopover?: IInfoPopoverProps;
}

const Row = ({ label, value }: IOrganizationOwnerTableRowProps) => {
  if (!label) return null;
  return (
    <div className={styles.row}>
      <span>{label}</span>
      <span className={styles.ellipsis}>{value}</span>
    </div>
  );
};

export const OrganizationOwnerTable = observer(
  ({ id, list, renderField, label, infoPopover }: IOrganizationOwnerTableProps) => {
    // Return null if list is empty or all list values are empty
    if (!list || list?.every((item) => item.every((row) => !row.answers?.[0]?.text))) return null;

    return (
      <div>
        {label && (
          <div className={styles.labelRow}>
            <span className={styles.label}>{label}</span>
            {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
          </div>
        )}
        <div className={styles.wrapper}>
          {list.map((rows, i) => {
            if (rows.every((row) => !row.answers?.[0]?.text)) return null;
            return (
              <div className={styles.rowWrapper} key={`OrganizationOwnerTable-${id}-${i}`}>
                {rows
                  .sort((a, b) => Number(a.editable) - Number(b.editable))
                  .map((row, j) => {
                    if (row.editable) {
                      return renderField(row);
                    }

                    return (
                      <Row
                        key={`OrganizationOwnerTable-${id}-row-${j}-${row.id}`}
                        label={row.questionText!}
                        value={row.answers?.[0]?.text || "-"}
                      />
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
