import { AccountStatus, AccountType } from "../../../models/CustomerProducts/BaseAccount";
import { IDepositAccount, IDepositAccountsApiResponse, IDepositBaseAccount } from "libs/models/CustomerProducts";
import { InternalTransferAccountType } from "../../../models/Transfer/Transfer";
import dayjs from "dayjs";
import { DepositType } from "../../../models/Content/Enums";

const ACTIVATION_DAYS = 14;

const getFixedTermFromDepositType = (type?: DepositType) => {
  switch (type) {
    case DepositType.Opsparing6:
      return 6;
    case DepositType.Opsparing12:
      return 12;
    case DepositType.Opsparing24:
      return 24;
    default:
      return null;
  }
};

export const mockBaseDepositAccountOne: IDepositBaseAccount = {
  name: "Opsparing Plus",
  nickname: "My Favorite Plus 123",
  accountId: "1",
  displayNumber: "200102246820",
  availableBalance: 33.5,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isActive: true,
  accountType: AccountType.Deposit,
  depositType: DepositType.HoejrentePlus,
  internalTransferAccounts: [
    {
      accountId: "2",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "3",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "4",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "6",
      type: InternalTransferAccountType.Internal,
    },
  ],
  has31DaysWithdrawalTime: true,
  isFixedTerm: false,
};

export const mockBaseDepositAccountTwo: IDepositBaseAccount = {
  name: "Opsparing - Fri",
  nickname: "",
  accountId: "2",
  displayNumber: "44444444",
  availableBalance: 15000,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isActive: true,
  accountType: AccountType.Deposit,
  depositType: DepositType.HoejrenteFri,
  internalTransferAccounts: [
    {
      accountId: "1",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "3",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "4",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "6",
      type: InternalTransferAccountType.Internal,
    },
  ],
  closesAt: undefined,
  has31DaysWithdrawalTime: false,
  isFixedTerm: false,
};

export const mockBaseDepositAccountThree: IDepositBaseAccount = {
  name: "Opsparing 6",
  nickname: undefined,
  accountId: "3",
  displayNumber: "058439",
  availableBalance: 15000,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isClosed: false,
  isActive: true,
  accountType: AccountType.Deposit,
  depositType: DepositType.Opsparing6,
  internalTransferAccounts: [
    {
      accountId: "1",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "2",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "4",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "6",
      type: InternalTransferAccountType.Internal,
    },
  ],
  closesAt: undefined,
  has31DaysWithdrawalTime: false,
  isFixedTerm: true,
  ...(() => {
    const today = dayjs().startOf("day");
    const daysPassed = ACTIVATION_DAYS;
    const activationDate = today.add(ACTIVATION_DAYS - daysPassed, "days").toISOString();
    const isActivationPeriodExpired = today.isAfter(activationDate);
    const maturityDate = today
      .add(6, "months")
      .add(ACTIVATION_DAYS - daysPassed, "days")
      .toISOString();
    return {
      activationDate,
      maturityDate,
      isActivationPeriodExpired,
    };
  })(),
};

export const mockBaseDepositAccountFour: IDepositBaseAccount = {
  name: "Opsparing 12",
  nickname: undefined,
  accountId: "4",
  displayNumber: "058437",
  availableBalance: 500,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isClosed: false,
  isActive: true,
  accountType: AccountType.Deposit,
  depositType: DepositType.Opsparing12,
  internalTransferAccounts: [
    {
      accountId: "1",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "2",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "3",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "6",
      type: InternalTransferAccountType.Internal,
    },
  ],
  closesAt: undefined,
  has31DaysWithdrawalTime: false,
  isFixedTerm: true,
  ...(() => {
    const today = dayjs().startOf("day");
    const daysPassed = 0;
    const activationDate = today.add(ACTIVATION_DAYS - daysPassed, "days").toISOString();
    const isActivationPeriodExpired = today.isAfter(activationDate);

    const maturityDate = today
      .add(12, "months")
      .add(ACTIVATION_DAYS - daysPassed, "days")
      .toISOString();
    return {
      activationDate,
      maturityDate,
      isActivationPeriodExpired,
    };
  })(),
};

export const mockBaseDepositAccountFive: IDepositBaseAccount = {
  name: "Opsparing 24",
  nickname: undefined,
  accountId: "5",
  displayNumber: "058437",
  availableBalance: 500,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isClosed: false,
  isActive: true,
  accountType: AccountType.Deposit,
  depositType: DepositType.Opsparing24,
  internalTransferAccounts: [
    {
      accountId: "1",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "2",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "3",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "6",
      type: InternalTransferAccountType.Internal,
    },
  ],
  closesAt: undefined,
  has31DaysWithdrawalTime: false,
  isFixedTerm: true,
  ...(() => {
    const today = dayjs().startOf("day");
    const daysPassed = ACTIVATION_DAYS + 1;
    const activationDate = today.add(ACTIVATION_DAYS - daysPassed, "days").toISOString();
    const isActivationPeriodExpired = today.isAfter(activationDate);

    const maturityDate = today
      .add(24, "months")
      .add(ACTIVATION_DAYS - daysPassed, "days")
      .toISOString();
    return {
      activationDate,
      maturityDate,
      isActivationPeriodExpired,
    };
  })(),
};

export const mockBaseDepositAccountSix: IDepositBaseAccount = {
  name: "Opsparing Plus",
  accountId: "6",
  displayNumber: "058437",
  availableBalance: 0,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isClosed: true,
  isActive: false,
  accountType: AccountType.Deposit,
  internalTransferAccounts: [
    {
      accountId: "1",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "2",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "3",
      type: InternalTransferAccountType.Internal,
    },
    {
      accountId: "5",
      type: InternalTransferAccountType.Internal,
    },
  ],
  closesAt: undefined,
  has31DaysWithdrawalTime: false,
  isFixedTerm: false,
};

export const mockDepositAccountOne: IDepositAccount = {
  ...mockBaseDepositAccountOne,
  accumulatedInterest: 1,
  bankTransferFee: 1,
  bookedBalance: 1,
  interestCarryingBalance: 50.0,
  accountInterest: 10,
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  hasTieredInterestRate: false,
  minimumInterestBearingAmount: 100000,
};

export const mockDepositAccountTwo: IDepositAccount = {
  ...mockBaseDepositAccountTwo,
  accumulatedInterest: 1,
  bankTransferFee: 1,
  bookedBalance: 1,
  interestCarryingBalance: 15500,
  accountInterest: 2.0,
  accountInterestBuckets: [
    { minAmount: 0, maxAmount: 25000, interest: 0.05 },
    { minAmount: 25000, maxAmount: 200000, interest: 0 },
    { maxAmount: 200000, interest: -0.5 },
  ],
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  hasTieredInterestRate: true,
  minimumInterestBearingAmount: 0,
};

export const mockDepositAccountThree: IDepositAccount = {
  ...mockBaseDepositAccountThree,
  accumulatedInterest: 1,
  bankTransferFee: 1,
  bookedBalance: 1,
  interestCarryingBalance: 33.5,
  accountInterest: 10,
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  earlyDebitFeeRate: 2.5,
  fixedTermMonths: getFixedTermFromDepositType(mockBaseDepositAccountThree.depositType),
  minimumInterestBearingAmount: 25000,
  hasTieredInterestRate: false,
};

export const mockDepositAccountFour: IDepositAccount = {
  ...mockBaseDepositAccountFour,
  accumulatedInterest: 1,
  bankTransferFee: 1,
  bookedBalance: 1,
  interestCarryingBalance: 15500,
  accountInterest: 0,
  accountInterestBuckets: [
    { interest: 0, maxAmount: 100000, minAmount: 0 },
    { interest: 1.05, maxAmount: null, minAmount: 100000 },
  ],
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  earlyDebitFeeRate: 3.0,
  fixedTermMonths: getFixedTermFromDepositType(mockBaseDepositAccountFour.depositType),
  minimumInterestBearingAmount: 100000,
  hasTieredInterestRate: false,
};

export const mockDepositAccountFive: IDepositAccount = {
  ...mockBaseDepositAccountFive,
  accumulatedInterest: 1,
  bankTransferFee: 1,
  bookedBalance: 1,
  interestCarryingBalance: 15500,
  accountInterest: 0,
  accountInterestBuckets: [
    { interest: 0, maxAmount: 100000, minAmount: 0 },
    { interest: 1.05, maxAmount: null, minAmount: 100000 },
  ],
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  earlyDebitFeeRate: 3.0,
  fixedTermMonths: getFixedTermFromDepositType(mockBaseDepositAccountFive.depositType),
  minimumInterestBearingAmount: 100000,
  hasTieredInterestRate: false,
};

export const mockDepositAccountSix: IDepositAccount = {
  ...mockBaseDepositAccountSix,
  accumulatedInterest: 1,
  bankTransferFee: 0,
  bookedBalance: 0,
  interestCarryingBalance: 0,
  accountInterest: 0,
  accountInterestBuckets: [
    { interest: 0, maxAmount: 100000, minAmount: 0 },
    { interest: 1.05, maxAmount: null, minAmount: 100000 },
  ],
  paymentAccount: "",
  currency: "",
  openDate: dayjs().toString(),
  earlyDebitFeeRate: 3.0,
  minimumInterestBearingAmount: 100000,
  hasTieredInterestRate: false,
};

export const mockDepositAccounts: IDepositAccountsApiResponse = {
  depositAccounts: [
    mockDepositAccountOne,
    mockDepositAccountTwo,
    mockDepositAccountThree,
    mockDepositAccountFour,
    mockDepositAccountFive,
    mockDepositAccountSix,
  ],
  errors: "",
};
