import React from "react";
import errorIcon from "~assets/error.svg";
import rootStyles from "~views/pages/Root.scss";

interface IErrorTextProps {
  error: string;
}

export const ErrorText = ({ error }: IErrorTextProps) => {
  return (
    <div className={rootStyles.error}>
      <img src={errorIcon} alt="error-icon" />
      <span>{error}</span>
    </div>
  );
};
