import React, { RefObject, useEffect } from "react";
import { observer } from "mobx-react";
import styles from "./ConfirmationPopup.scss";
import { useStores } from "netbank-shared/src/hooks";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { Button } from "..";
import { Colors } from "netbank-shared/src/libs/models/Content/Enums";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

interface IConfirmationPopupProps {
  confirmationPopupRef: RefObject<HTMLDivElement>;
}

export const ConfirmationPopup = observer(({ confirmationPopupRef }: IConfirmationPopupProps) => {
  const { uiStore } = useStores();

  const { confirmationPopup } = uiStore;

  const confirmationPopupActive = !!confirmationPopup;

  useEffect(() => {
    if (confirmationPopupActive) {
      if (confirmationPopupRef.current) disableBodyScroll(confirmationPopupRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [confirmationPopupActive]);

  if (!confirmationPopup) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.wrapper} ref={confirmationPopupRef}>
        {confirmationPopup.header && <h2 className={styles.header}>{confirmationPopup.header}</h2>}
        {confirmationPopup.text && <HtmlContent className={styles.text} html={confirmationPopup.text} />}
        <div className={styles.actions}>
          {confirmationPopup.closeText && confirmationPopup.closeAction && (
            <Button
              title={confirmationPopup.closeText}
              onClick={() => confirmationPopup.closeAction?.()}
              className={styles.closeAction}
              color={Colors.Boulder}
              bordered
              borderColor={Colors.Boulder}
              fullWidth
              centered
            />
          )}
          {confirmationPopup.applyText && confirmationPopup.applyAction && (
            <Button
              title={confirmationPopup.applyText}
              onClick={() => confirmationPopup.applyAction()}
              className={styles.applyAction}
              color={Colors.Red}
              fullWidth
              centered
            />
          )}
        </div>
      </div>
    </div>
  );
});
