import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import styles from "../Page.scss";
import { SkeletonWrapper } from "~views/shared";

export const ProductPageSkeleton = observer(() => {
  const { contentStore, depositStore, creditStore, loanStore, leasingStore } = useStores();

  const classes = [styles.wrapper];

  if (
    !contentStore.fetchingTemplatePage &&
    !depositStore.loadingAccount &&
    !creditStore.loadingAccount &&
    !loanStore.loadingAccount &&
    !leasingStore.loadingAccount
  ) {
    classes.push(styles.hidden);
  }

  return (
    <div className={classes.join(" ")}>
      <section className={styles.headerWrapper}>
        <SkeletonWrapper width={150} />
        <SkeletonWrapper height={40} width={350} />
        <SkeletonWrapper width={200} />
      </section>
      <section>
        <div className={styles.templateHeader}>
          <SkeletonWrapper className={[styles.image, styles.skeleton].join(" ")} />
          <div className={styles.templateContent}>
            <div>
              <SkeletonWrapper width={115} />
              <SkeletonWrapper width={100} height={32} />
            </div>

            <div className={[styles.flexBetween, styles.skeleton].join(" ")}>
              <div>
                <SkeletonWrapper width={115} />
                <SkeletonWrapper width={130} height={28} />
              </div>
              <div>
                <SkeletonWrapper width={115} />
                <SkeletonWrapper width={130} height={28} />
              </div>
            </div>
            <SkeletonWrapper height={6} />
          </div>
        </div>
      </section>
      <section>
        <SkeletonWrapper height={150} />
      </section>
    </div>
  );
});
