import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { TextArea } from "../TextArea/TextArea";

interface ITextAreaFormFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  infoPopover?: IInfoPopoverProps;
  disabled?: boolean;
  textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

export const TextAreaFormField = ({
  label,
  placeholder,
  name,
  infoPopover,
  disabled,
  textareaProps,
}: ITextAreaFormFieldProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <TextArea
            {...field}
            {...textareaProps}
            label={label}
            infoPopover={infoPopover}
            placeholder={placeholder}
            disabled={disabled || isSubmitting}
            error={error}
          />
        );
      }}
    />
  );
};
