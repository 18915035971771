import MockAdapter from "axios-mock-adapter";
import { mockLeaseAccounts, mockOverduePayments, mockPaidPayments, mockPaymentPlan, mockDuePayment } from "./leasing";
import { IPayment, InvoiceStatus, PaymentType } from "../../models/CustomerProducts";

export const MockLeasingApi = (mock: MockAdapter) => {
  const idRegexSegment = "[0-9]+";
  const singleAccountIdRegexSegment = "^[0-9]+$";

  const getAccountFromUrlAccountId = (url: string | undefined, index: number = 0) => {
    if (!url) return undefined;
    const urlArray = url.split("/");
    const accountId = urlArray[index];
    return mockLeaseAccounts.leaseAccounts?.find((a) => a.accountId === accountId);
  };

  mock.onGet("").reply(200, mockLeaseAccounts);

  mock.onGet(new RegExp(`${singleAccountIdRegexSegment}`)).reply((config) => {
    if (config.url) {
      const leasingAccount = getAccountFromUrlAccountId(config.url);
      if (leasingAccount) {
        return [200, { leasingAccount, error: "" }];
      }
    }
    return [500];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/payments`)).reply((config) => {
    const paymentType = config.params.paymentType as PaymentType;
    if (config.url) {
      const leasingAccount = getAccountFromUrlAccountId(config.url, 1);
      if (leasingAccount) {
        let data: IPayment[] | IPayment | null;
        switch (paymentType) {
          case PaymentType.Due:
            data = mockDuePayment.payments;
            break;
          case PaymentType.All:
            data = mockPaymentPlan.payments;
            break;
          case PaymentType.Overdue:
            data = mockOverduePayments.payments;
            break;

          case PaymentType.Paid:
            data = mockPaidPayments.payments;
            break;
          default:
            data = null;
        }

        return [200, { payments: data, error: "" }];
      }
    }
    return [404];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/invoices`)).reply((config) => {
    const status = config.params.invoiceStatus as InvoiceStatus;
    if (config.url) {
      const leaseAccount = getAccountFromUrlAccountId(config.url, 1);
      if (leaseAccount) {
        let data: IPayment[] | IPayment | null;
        switch (status) {
          case InvoiceStatus.Paid:
            data = mockPaidPayments.payments;
            break;
          default:
            data = null;
        }
        return [200, { invoices: data, error: "" }];
      }
    }
    return [404];
  });

  mock.onPut(new RegExp(`${idRegexSegment}/invoices/${idRegexSegment}`)).reply(201);

  mock.onPut(new RegExp(`${idRegexSegment}/invoice-methods`)).reply(204);

  mock.onPost(new RegExp(`${idRegexSegment}/mileages`)).reply(201);

  mock.onPost(new RegExp(`${idRegexSegment}/insurances`)).reply(201);

  mock.onDelete(new RegExp(`${idRegexSegment}/insurances`)).reply(201);
};
