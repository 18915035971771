import { useEffect } from "react";
import { useTracking } from "netbank-shared/src/hooks";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

interface IPushInteraction {
  eventCategory?: TrackingCategory;
  eventAction?: TrackingAction;
  requirement?: boolean;
  eventPrefix?: string;
  eventValue?: number;
}
export function usePushInteraction({
  eventCategory,
  eventAction,
  requirement,
  eventPrefix,
  eventValue,
}: IPushInteraction): void {
  const dataLayer = useTracking((window as any).dataLayer);
  useEffect(() => {
    const condition = requirement === undefined ? true : requirement;
    if (condition && !!eventCategory && !!eventAction) {
      dataLayer.pushInteraction(eventCategory, eventAction, eventPrefix, eventValue);
    }
  }, []);
}
