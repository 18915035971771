import { ApisauceInstance, create } from "apisauce";
import { tx } from "../i18n";
import { IConfig } from "../models/Config";
import { Store } from "../../stores";
import { CustomerStore } from "stores/CustomerStore";
import { CustomerPersistentStore } from "stores/CustomerPersistentStore";

export class EpiserverApi {
  client?: ApisauceInstance;

  rootStore: Store;

  customerStore: CustomerStore;

  customerPersistentStore: CustomerPersistentStore;

  episerverRootTimeout: number = 30000;

  episerverTimeout: number = 30000;

  episerverRetries: number = 3;

  constructor(rootStore: Store, customerStore: CustomerStore, customerPersistentStore: CustomerPersistentStore) {
    this.rootStore = rootStore;
    this.customerStore = customerStore;
    this.customerPersistentStore = customerPersistentStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      if (config.EPISERVER_ROOT_TIMEOUT_MS && config.EPISERVER_ROOT_TIMEOUT_MS >= 2000)
        this.episerverRootTimeout = config.EPISERVER_ROOT_TIMEOUT_MS;
      if (config.EPISERVER_TIMEOUT_MS && config.EPISERVER_TIMEOUT_MS >= 2000)
        this.episerverTimeout = config.EPISERVER_TIMEOUT_MS;
      if (config.EPISERVER_RETRIES && config.EPISERVER_RETRIES >= 1) this.episerverRetries = config.EPISERVER_RETRIES;

      this.client = create({
        baseURL: config.EPISERVER_URL,
        timeout: this.episerverTimeout,
      });
    }
  };

  getHeaders = () => {
    const { platformStore } = this.rootStore;

    const currentAccount = this.rootStore.currentAccountDetails || this.rootStore.currentAccount;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": tx("routing.lang"),
      Preflight: "true",
      UserData: JSON.stringify({
        customerID: this.rootStore.globalPersistentStore.customerId || "",
        currentAccount: JSON.stringify(currentAccount),
        isCorporateAccount:
          this.customerPersistentStore.isLoggedInToCorporateAccount || this.customerStore.isOnlyCorporateCustomer(),
        browserName: this.rootStore.platformStore?.browser?.name || "",
        browserVersion: this.rootStore.platformStore?.browser?.version,
        operatingSystem: this.rootStore.platformStore?.getOS() || "",
        accounts: {
          displayNumbers: this.rootStore.allCustomerProducts?.map((e) => e.displayNumber),
          accountTypes: this.rootStore.allCustomerProducts?.map((e) => e.accountType),
          customerProducts: this.rootStore.allCustomerProducts?.map((cp) => {
            return {
              displayNumber: cp.displayNumber,
              accountType: cp.accountType,
              isClosed: cp.isClosed,
            };
          }),
        },
        parties: this.customerStore.currentCustomer?.parties,
        isMobileApp: this.rootStore.platformStore?.isApplication(),
        isMobileBrowser: this.rootStore.platformStore?.isMobile?.(),
        appVersion: this.rootStore.platformStore?.isApplication() ? this.rootStore.platformStore?.getVersion?.() : "",
      }),
    };

    const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

    if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

    return { headers };
  };

  getData = async <T>(slug?: string) => {
    if (this.client && slug?.match(`^(/|/${tx("routing.lang")}/?)$`)) {
      this.client.axiosInstance.defaults.timeout = this.episerverRootTimeout;
    } else if (this.client) {
      this.client.axiosInstance.defaults.timeout = this.episerverTimeout;
    }

    let response;
    try {
      for (let index = 0; index < this.episerverRetries; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        response = await this.client?.get<T>(`${slug}?expand=*`, {}, this.getHeaders());
        if (response?.ok || response?.status === 404) {
          break;
        } else if (response?.originalError) {
          this.rootStore.appInsights?.trackException({ error: response.originalError });
        }
      }
    } catch (e) {
      if (e instanceof Error) this.rootStore.appInsights?.trackException({ error: e });
    }
    return response;
  };
}
