import React from "react";
import { observer } from "mobx-react";
import styles from "./Popover.scss";
import { useStores } from "netbank-shared/src/hooks";
import close from "~assets/cancel-black-bold.svg";

export const Popover = observer(() => {
  const { uiStore } = useStores();
  const { popover } = uiStore;
  const classes = [styles.wrapper];
  const ref = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  const [isFlipped, setIsFlipped] = React.useState(false);

  if (popover?.open) {
    classes.push(styles.open);
  }

  const desktopWidth = 360;
  const elementRect = popover?.element?.getBoundingClientRect();

  React.useEffect(() => {
    setIsFlipped(false);
    let top = 0;
    const xOffset = popover?.xOffset || 0;
    const yOffset = popover?.yOffset || 0;

    if (popover?.element && elementRect && ref.current !== null) {
      const paddingTop = 20;
      top =
        window.scrollY -
        (ref?.current?.offsetHeight ?? 0) +
        elementRect.top -
        (popover?.element.offsetHeight ?? 0) -
        paddingTop +
        yOffset;

      if (top < 0) {
        setIsFlipped(true);
        top = window.scrollY + elementRect.top + (popover?.element.offsetHeight ?? 0) + paddingTop;
      }
    }

    const left = elementRect?.left || 0;
    const popoverPosition = { top, left: 16 };

    if (!uiStore.isMobile && popover?.element) {
      popoverPosition.left = left + (popover?.element.offsetWidth || 0) - desktopWidth + xOffset;
    }

    setPosition(popoverPosition);
  }, [ref]);

  const handleClickOutside = (e: MouseEvent) => {
    if (ref && e.target instanceof Node && !ref.current?.contains(e.target)) {
      uiStore.removePopover();
    }
  };

  React.useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  let left = desktopWidth - 36; // dekstopWidth - width of pointer - spacing from edge

  if (uiStore.isMobile && elementRect && popover?.element) {
    left = 3 + (elementRect?.left || 0) - (popover?.element.offsetWidth || 0) / 2;
  }

  const pointerPosition = { left };
  const pointerClasses = [styles.pointer];

  if (isFlipped) pointerClasses.push(styles.flipped);

  return (
    <div ref={ref} className={classes.join(" ")} style={position}>
      <div role="button" tabIndex={0} className={styles.closeButton} onClick={() => uiStore.removePopover()}>
        <img src={close} alt="close" />
      </div>
      <div className={styles.inner}>
        <h3 className={styles.title}>{popover?.title}</h3>
        <span>{popover?.children}</span>
      </div>
      <div className={pointerClasses.join(" ")} style={pointerPosition} />
    </div>
  );
});
