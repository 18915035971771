import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { getQueryParam } from "~utils/misc";
import { withStore } from "../../../mobx-web";
import { tx } from "netbank-shared/src/libs/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Curity.scss";
import { LoadingLottie } from "~views/shared/Lottie/LoadingLottie";
import { ProfilePickerPage } from "../Login/ProfilePickerPage";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

const Curity = observer(() => {
  const location = useLocation();
  const curityCode = getQueryParam(location?.search, "code");
  const curityState = getQueryParam(location?.search, "state");

  const { customerStore, rootStore, customerPersistentStore, contentStore, securePersistentStore } = useStores();

  const { currentPage } = contentStore;

  const navigate = useNavigate();
  const dataLayer = useTracking((window as any).dataLayer);

  const localState = securePersistentStore.state;

  const handleLoginSuccess = async () => {
    const customer = await customerStore.getCustomer();
    if (!customer) {
      securePersistentStore.resetStore();
      const authUrl = await customerStore.getAuthenticationUrl();
      rootStore.setNoCustomerError(() => window.open(authUrl, "_self"));
      dataLayer.pushInteraction(TrackingCategory.Login, TrackingAction.LoginKO);
      return;
    }

    if (customerStore.hasParties) return;

    dataLayer.pushInteraction(TrackingCategory.Login, TrackingAction.LoginOK);
    if (!customerPersistentStore.viewedTerms && contentStore.currentPage?.termsPage) {
      navigate(contentStore.currentPage.termsPage);
      dataLayer.pushPageView(contentStore.currentPage.termsPage);
    } else {
      navigate(`/${tx("routing.lang")}`);
      dataLayer.pushPageView(`/${tx("routing.lang")}`);
    }
  };

  useEffect(() => {
    const fetchData = async (code: string) => {
      const success = await customerStore.getCurityToken(code);
      await contentStore.getPage(`/${tx("routing.lang")}`, false);
      if (success) {
        handleLoginSuccess();
      } else {
        const authUrl = await customerStore.getAuthenticationUrl();
        rootStore.setCurityGeneralError(() => window.open(authUrl, "_self"));
        dataLayer.pushInteraction(TrackingCategory.Login, TrackingAction.LoginKO);
      }
    };

    const handleCurityStateError = async () => {
      await contentStore.getPage(`/${tx("routing.lang")}`, false);
      const authUrl = await customerStore.getAuthenticationUrl();
      rootStore.setCurityGeneralError(() => window.open(authUrl, "_self"));
    };

    if (rootStore.isMock) {
      fetchData("");
    } else if (curityCode && curityState === localState) {
      fetchData(curityCode);
    } else {
      handleCurityStateError();
    }
  }, [location]);

  if (customerStore.hasParties) {
    return (
      <ProfilePickerPage
        data={{
          header: currentPage?.profilePickerHeader || tx("login.profilePickerHeader"),
          subHeader: currentPage?.profilePickerSubHeader || tx("login.profilePickerSubHeader"),
          privateProfileLabel: currentPage?.privateProfileLabel || tx("login.privateProfileLabel"),
          organizationProfileLabel: currentPage?.organizationProfileLabel || tx("login.organizationProfileLabel"),
        }}
      />
    );
  }

  return (
    <div className={styles.center}>
      <LoadingLottie />
    </div>
  );
});

export default withStore(Curity);
