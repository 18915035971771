export interface IProperty<T> {
  propertyDataType: string;
  value: T;
}

export function isProperty<T>(object: IProperty<T> | any): object is IProperty<T> {
  const isObject = !!object && typeof object === "object";
  return (
    isObject &&
    Boolean("value" in (object as IProperty<T>)) &&
    typeof (object as IProperty<T>)?.propertyDataType === "string"
  );
}
