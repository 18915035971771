import lottie, { AnimationConfigWithData, AnimationEventName, AnimationItem } from "lottie-web";
import React, { CSSProperties, LegacyRef, useEffect, useRef } from "react";

interface ILottieOptions {
  autoplay?: boolean;
  loop?: boolean;
  animationData: any;
}

interface IEventListener {
  eventName: AnimationEventName;
  callback: (_: any) => void;
}

export interface ILottieProps {
  ariaLabel?: string;
  ariaRole?: string;
  eventListeners?: IEventListener[];
  height?: string | number;
  onClick?: <T>(_: T) => any;
  options: ILottieOptions;
  style?: CSSProperties;
  title?: string;
  width?: string | number;
}

export const Lottie = ({
  ariaLabel,
  ariaRole,
  eventListeners,
  height,
  onClick,
  options,
  style,
  title,
  width,
}: ILottieProps) => {
  const reference: LegacyRef<HTMLDivElement> = useRef(null);
  let animationOptions: AnimationConfigWithData | undefined;
  let animation: AnimationItem | undefined;

  const registerEvents = (listeners?: IEventListener[]) => {
    listeners?.forEach((listener) => {
      animation?.addEventListener(listener.eventName, listener.callback);
    });
  };

  const deRegisterAllAnimationEvents = () => {
    const animationEventNames = [
      "enterFrame",
      "loopComplete",
      "complete",
      "segmentStart",
      "destroy",
      "config_ready",
      "data_ready",
      "DOMLoaded",
      "error",
      "data_failed",
      "loaded_images",
    ] as AnimationEventName[];

    animationEventNames.forEach((eventName) => animation?.removeEventListener(eventName));
  };

  useEffect(() => {
    const { animationData, autoplay, loop } = options;
    registerEvents(eventListeners);

    if (reference.current) {
      animationOptions = {
        container: reference.current,
        renderer: "svg",
        loop: loop !== false,
        autoplay: autoplay !== false,
        animationData,
      };

      animationOptions = { ...animationOptions, ...options };

      animation = lottie.loadAnimation(animationOptions);
      animation.play();
    }

    return () => {
      deRegisterAllAnimationEvents();
      animation?.destroy();
      animationOptions = undefined;
      animation = undefined;
    };
  }, []);

  useEffect(() => {
    deRegisterAllAnimationEvents();
    registerEvents(eventListeners);
  }, [eventListeners]);

  const getSize = (initial?: string | number) => {
    return typeof initial === "number" ? `${initial}px` : initial ?? "100%";
  };

  const lottieStyles = {
    width: getSize(width),
    height: getSize(height),
    overflow: "hidden",
    margin: "0 auto",
    outline: "none",
    ...style,
  };

  return (
    <div
      ref={reference}
      style={lottieStyles}
      onClick={onClick}
      title={title}
      role={ariaRole}
      aria-label={ariaLabel}
      tabIndex={-1}
    />
  );
};
