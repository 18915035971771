import dayjs from "dayjs";
import { AccountStatus, AccountType } from "../../../models/CustomerProducts/BaseAccount";
import {
  CardStatus,
  ICreditAccount,
  ICreditAccountsApiResponse,
  ICreditBaseAccount,
} from "../../../models/CustomerProducts";

export const mockBaseCreditAccountOne: ICreditBaseAccount = {
  accountId: "93530002743298",
  displayNumber: "93530002743298",
  name: "SantanderRed",
  nickname: "My SantanderRed",
  accountType: AccountType.Credit,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  availableBalance: 20000,
  balance: -30000,
};

export const mockBaseCreditAccountTwo: ICreditBaseAccount = {
  accountId: "93530102443111",
  displayNumber: "93530102443111",
  name: "AcceptCard",
  accountType: AccountType.Credit,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  availableBalance: 1000,
  balance: -9000,
};

export const mockBaseCreditAccountThree: ICreditBaseAccount = {
  accountId: "93530102443112",
  displayNumber: "93530102443112",
  name: "AcceptCard",
  accountType: AccountType.Credit,
  accountStatus: AccountStatus.AccountOk,
  isDisabled: false,
  isClosed: true,
  availableBalance: 0,
  balance: 0,
};

export const mockCreditAccountOne: ICreditAccount = {
  ...mockBaseCreditAccountOne,
  accountInterest: 8.85,
  creditLimit: 50000,
  bookedBalance: -30000,
  currency: "DKK",
  hasInsurance: false,
  debtorName: "Niklas Bendtner",
  cards: [
    {
      cardId: "4352000289978410",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().add(1, "year").toString(),
      maskedCardNumber: "479653XXXXXX9893",
      status: CardStatus.Active,
    },
    {
      cardId: "4352000289978411",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().subtract(1, "month").toString(),
      maskedCardNumber: "479653XXXXXX9893",
      status: CardStatus.Expired,
    },
    {
      cardId: "4352000289978412",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().subtract(2, "month").toString(),
      maskedCardNumber: "479653XXXXXX9893",
      status: CardStatus.Blocked,
    },
  ],
};

export const mockCreditAccountTwo: ICreditAccount = {
  ...mockBaseCreditAccountTwo,
  accountInterest: 12.4,
  creditLimit: 10000,
  bookedBalance: -9000,
  currency: "DKK",
  hasInsurance: true,
  debtorName: "Niklas Bendtner",
  cards: [
    {
      cardId: "4168000283171289",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().add(3, "years").toString(),
      maskedCardNumber: "479653XXXXXX1234",
      status: CardStatus.Active,
    },
    {
      cardId: "4168000283171290",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().subtract(1, "month").toString(),
      maskedCardNumber: "479653XXXXXX1234",
      status: CardStatus.Expired,
    },
  ],
};

export const mockCreditAccountThree: ICreditAccount = {
  ...mockBaseCreditAccountThree,
  accountInterest: 12.4,
  creditLimit: 10000,
  bookedBalance: 0,
  currency: "DKK",
  hasInsurance: true,
  debtorName: "Niklas Bendtner",
  cards: [
    {
      cardId: "4168000283171289",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().add(3, "years").toString(),
      maskedCardNumber: "479653XXXXXX1234",
      status: CardStatus.Active,
    },
    {
      cardId: "4168000283171290",
      cardHolderName: "Niklas Bendtner",
      expiryDate: dayjs().subtract(1, "month").toString(),
      maskedCardNumber: "479653XXXXXX1234",
      status: CardStatus.Expired,
    },
  ],
};

export const mockCreditAccounts: ICreditAccountsApiResponse = {
  creditAccounts: [mockCreditAccountOne, mockCreditAccountTwo, mockCreditAccountThree],
  errors: "",
};
