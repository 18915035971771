import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useDebouncedEffect, useFetchData, useStores } from "netbank-shared/src/hooks";
import { IIncreaseLoanPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import {
  IIncreaseLoanForm,
  generateIncreaseLoanForm,
} from "netbank-shared/src/libs/forms/IncreaseLoan/IncreaseLoanForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormWrapper } from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { SliderInputFormField } from "~views/shared/Forms/SliderInputFormField";
import { InputSliderType } from "~views/shared/InputSlider/InputSlider";
import { IncreaseLoanSummaryBlock } from "./IncreaseLoanSummaryBlock";
import { SelfServicePageSkeleton } from "~views/pages/Skeletons/SelfServicePageSkeleton";
import { generateLinkWithParams, templateString } from "netbank-shared/src/libs/utils";

export const IncreaseLoanPage = observer(() => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { contentStore, loanStore, uiStore } = useStores();
  const { currentPage } = contentStore;
  const page = currentPage as IIncreaseLoanPage;

  const {
    loanAmount,
    terms,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
    calculationSummaryLabels,
    calculationSummaryFooter,
    resumeLink,
    manualResumeContent,
    manualResumeLinkText,
  } = page;
  const { currentAccount, selfServiceSusbmissionState, increaseLoanApplicationId } = loanStore;

  const methods = useForm<IIncreaseLoanForm>({
    resolver: yupResolver(
      generateIncreaseLoanForm({
        locale: uiStore.locale!,
        minLoanAmount: currentAccount?.increaseLoanDetails?.minimumEligibleAmount,
        maxLoanAmount: currentAccount?.increaseLoanDetails?.maximumEligibleAmount,
        accountType: currentAccount?.accountType,
        page,
      }),
    ),
    defaultValues: {
      loanAmount: currentAccount?.increaseLoanDetails?.minimumEligibleAmount,
      duration: 5,
    },
  });

  const { loanAmount: loanAmountValue, duration } = methods.watch();

  const onChange = () => loanStore.calculateIncreaseLoan(loanAmountValue, duration);

  const generateResumeLink = () => {
    if (!increaseLoanApplicationId) return undefined;
    return generateLinkWithParams(resumeLink?.[0]?.link?.[0], {
      applicationId: increaseLoanApplicationId,
    });
  };

  useFetchData({
    callback: async () => {
      await loanStore.calculateIncreaseLoan(loanAmountValue, duration);
      setLoading(false);
    },
    cleanup: () => {
      loanStore.resetIncreaseLoanState();
      loanStore.resetSelfServiceState();
    },
  });

  useEffect(() => {
    if (!increaseLoanApplicationId) return;
    const applicaionResumeLink = generateResumeLink();
    if (applicaionResumeLink) window.open(applicaionResumeLink.href, "_blank");
  }, [increaseLoanApplicationId]);

  useDebouncedEffect(onChange, [loanAmountValue, duration], 500);

  if (loading) return <SelfServicePageSkeleton />;

  if (!currentAccount || !currentAccount.canIncreaseLoan || !currentAccount.increaseLoanDetails) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const onSubmit: SubmitHandler<IIncreaseLoanForm> = async (data) => loanStore.increaseLoan(data);

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const generateSuccessContent = () => {
    if (!successContent?.textContent) return undefined;
    let result = successContent.textContent;
    const applicaionResumeLink = generateResumeLink();

    if (applicaionResumeLink?.href && manualResumeContent && manualResumeLinkText) {
      result += templateString(manualResumeContent, {
        increaseLoanResumeLink: `<a href="${applicaionResumeLink.href}" target="_blank">${manualResumeLinkText}</a>`,
      });
    }

    return result;
  };

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: generateSuccessContent(),
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <SliderInputFormField
              name="loanAmount"
              label={loanAmount.label || tx("increaseLoan.loanAmountLabel")}
              sliderType={InputSliderType.Moneytary}
              min={currentAccount.increaseLoanDetails!.minimumEligibleAmount}
              max={currentAccount.increaseLoanDetails!.maximumEligibleAmount}
              sliderStep={5000}
              infoPopover={{ popoverTitle: loanAmount?.infoTitle, content: loanAmount?.infoText }}
              disabled={loanStore.fetchingIncreaseLoanCalculation}
            />
            <SliderInputFormField
              name="duration"
              label={terms.label || tx("increaseLoan.termsLabel")}
              sliderType={InputSliderType.Years}
              min={2}
              max={12}
              sliderStep={1}
              infoPopover={{ popoverTitle: terms?.infoTitle, content: terms?.infoText }}
              disabled={loanStore.fetchingIncreaseLoanCalculation}
            />
            <IncreaseLoanSummaryBlock labels={calculationSummaryLabels} footer={calculationSummaryFooter} />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
