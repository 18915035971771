/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, makeObservable, observable } from "mobx";
import { DepositApi } from "../libs/api";
import { IDepositAccount, IDepositBaseAccount } from "../libs/models/CustomerProducts/DepositAccount";
import { Store } from "./Store";
import { IDocument, IGetTransactionsRequest, ITransaction } from "libs/models/CustomerProducts";

export class DepositStore {
  depositApi: DepositApi;

  rootStore: Store;

  constructor(depositApi: DepositApi, rootStore: Store) {
    this.depositApi = depositApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  depositCustomerProducts: IDepositBaseAccount[] = [];

  @observable
  closedDepositCustomerProducts: IDepositBaseAccount[] = [];

  @observable
  currentAccount: IDepositAccount | undefined;

  @observable
  loadingAccount: boolean = false;

  @observable
  transactions: ITransaction[] = [];

  @observable
  contracts?: IDocument[];

  @observable
  loadingTransactions: boolean = false;

  @observable
  loadingTransactionsError: boolean = false;

  @observable
  loadingContracts: boolean = false;

  @computed
  get currentAccountId(): string {
    return this.rootStore.currentAccountId;
  }

  set currentAccountId(accountId: string) {
    this.rootStore.currentAccountId = accountId;
  }

  @computed
  get hasCustomerProducts(): boolean {
    return this.depositCustomerProducts !== undefined && this.depositCustomerProducts.length > 0;
  }

  getDepositAccount = async (accountId: string) => {
    this.loadingAccount = true;
    const response = await this.depositApi.getDepositAccount(accountId);
    if (response?.ok && response.data) {
      let currentBaseAccount = this.rootStore.currentAccount;
      if (currentBaseAccount?.accountId !== accountId) {
        currentBaseAccount = this.rootStore.allCustomerProducts?.find(
          (customerProduct) => customerProduct.accountId === response.data?.depositAccount?.accountId,
        );
        this.currentAccountId = accountId;
      }
      this.currentAccount = response.data.depositAccount
        ? {
            nickname: currentBaseAccount?.nickname,
            ...response.data.depositAccount,
          }
        : undefined;
    }
    this.loadingAccount = false;
  };

  @action
  setNicknameOptimistically = async (nickname: string) => {
    if (this.currentAccount) {
      this.currentAccount.nickname = nickname;
    }
  };

  getTransactions = async (accountId: string, dateFrom: string | undefined, dateTo: string | undefined) => {
    this.loadingTransactions = true;
    this.loadingTransactionsError = false;
    const data: IGetTransactionsRequest = {
      accountId,
      nextToken: "",
      amountFrom: "",
      amountTo: "",
      dateFrom: dateFrom || "",
      dateTo: dateTo || "",
    };
    const response = await this.depositApi.getTransactions(data);
    if (response?.ok && response.data) {
      this.transactions = response.data.transactions;
    } else {
      this.loadingTransactionsError = true;
    }
    this.loadingTransactions = false;
  };

  getContracts = async () => {
    this.contracts = [];
    this.loadingContracts = true;
    const response = await this.depositApi.getContracts(this.currentAccountId);
    if (response?.ok && response.data) {
      this.contracts = response.data;
    }
    this.loadingContracts = false;
  };

  setCurrentAccount(accountId: string): void {
    this.currentAccountId = accountId;
  }

  @action
  resetContracts = () => {
    this.contracts = undefined;
    this.loadingContracts = false;
  };

  generateClosingDepositAccountParams = () => {
    return `accountNumber=${this.currentAccountId}`;
  };

  @action
  resetCurrentAccount = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
  };

  @action
  resetStore = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
    this.depositCustomerProducts = [];
    this.closedDepositCustomerProducts = [];
    this.loadingAccount = false;
    this.transactions = [];
    this.loadingTransactions = false;
    this.loadingTransactionsError = false;
    this.resetContracts();
  };
}
