import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { IConfig } from "../models/Config";
import { Environment } from "../models/Content/Enums";
import { ITransferAccount, ITransferAccountsResponse } from "../models/Transfer/Transfer";
import { Store } from "../../stores";
import { MockTransferAccountApi } from "./mock/MockTransferAccountApi";

export class TransferAccountApi {
  rootStore: Store;

  client?: ApisauceInstance;

  isMock?: Boolean;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "api-version": "1.0",
        "Ocp-Apim-Subscription-Key": config.TRANSFER_ACCOUNTS_OCP_APIM_SUBSCRIPTION_KEY,
      };
      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.TRANSFER_ACCOUNT_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);
      if (config.OCTOPUS_ENV !== Environment.Production) {
        MockTransferAccountApi(this.client.axiosInstance);
      }
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
      "x-country": this.rootStore.config?.COUNTRY_CODE || "",
    },
  });

  getTransferAccounts = async () =>
    this.client?.get<ITransferAccountsResponse>("", { Channel: "IB", v: "1.0" }, this.getHeaders());

  createTransferAccount = async (clearingNumber: string, accountNumber: string, displayName: string) =>
    this.client?.post<ITransferAccount>(
      "",
      {
        clearingNumber,
        accountNumber,
        displayName,
        creationChannel: "IB",
        v: "1.0",
      },
      this.getHeaders()
    );

  updateTransferAccount = async (
    accountId: string,
    clearingNumber: string,
    accountNumber: string,
    displayName: string
  ) =>
    this.client?.put<ITransferAccount>(
      `${accountId}`,
      {
        clearingNumber,
        accountNumber,
        displayName,
        creationChannel: "IB",
        v: "1.0",
      },
      this.getHeaders()
    );

  deleteTransferAccount = async (
    accountId: string,
    clearingNumber: string,
    accountNumber: string,
    displayName: string
  ) =>
    this.client?.delete<ITransferAccountsResponse>(
      `${accountId}`,
      { clearingNumber, accountNumber, displayName, v: "1.0" },
      this.getHeaders()
    );
}
