import { useStores } from "../../hooks";
import { AccountType, IPayment } from "../models/CustomerProducts";

interface IArchivedInvoicesLoadingParams {
  loadArchivedInvoices: () => void;
  invoices: IPayment[] | undefined;
  loading: boolean;
  error: boolean;
  invoiceAction: (invoice: IPayment) => void;
  invoiceIsDocument: boolean;
}

export const useDetermineArchivedInvoicesLoadingParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string | null,
): IArchivedInvoicesLoadingParams => {
  const { loanStore, leasingStore } = useStores();
  const emptyResponse: IArchivedInvoicesLoadingParams = {
    loadArchivedInvoices: () => {},
    invoices: [],
    loading: false,
    error: false,
    invoiceAction: () => {},
    invoiceIsDocument: false,
  };
  if (!accountId || !accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        loadArchivedInvoices: () => {
          loanStore.getArchivedInvoices(accountId);
        },
        invoices: loanStore.archivedInvoices,
        loading: loanStore.loadingArchivedInvoices,
        error: loanStore.loadingArchivedInvoicesError,
        invoiceAction: (invoice: IPayment) => {
          loanStore.setActivePayment(invoice);
        },
        invoiceIsDocument: false,
      };
    case AccountType.Leasing:
    case AccountType.PrivateLeasing:
      return {
        loadArchivedInvoices: () => {
          leasingStore.getArchivedInvoices(accountId);
        },
        invoices: leasingStore.archivedInvoices,
        loading: leasingStore.loadingArchivedInvoices,
        error: leasingStore.loadingArchivedInvoicesError,
        invoiceAction: (invoice: IPayment) => {
          leasingStore.setActivePayment(invoice);
        },
        invoiceIsDocument: false,
      };
    default:
      return emptyResponse;
  }
};
