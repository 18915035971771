import { action, makeObservable, observable } from "mobx";

export class MenuStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  isOpen: boolean = false;

  toggleOpen = () => {
    this.isOpen = !this.isOpen;
  };

  @action
  resetStore = () => {
    this.isOpen = false;
  };
}
