import {
  ICustomerResponse,
  IUpdateContactInfo,
  PreferenceOption,
  PreferenceType,
} from "../../../models/Customer/Customer";

export const mockFICustomerData: ICustomerResponse = {
  customerData: {
    customerId: "24325123",
    name: "Sami",
    surname: "Hyypiä",
    address: {
      street: {
        value: "Kasarmikatu 40",
        editable: true,
      },
      city: {
        value: "Helsinki",
        editable: true,
      },
      country: {
        value: "Finland",
        editable: true,
        validAnswers: [
          {
            label: "Suomi",
            value: "Finland",
            index: 0,
          },
          {
            label: "Ruotsi",
            value: "Sweden",
            index: 1,
          },
          {
            label: "Viro",
            value: "Estonia",
            index: 2,
          },
          {
            label: "Norja",
            value: "Norway",
            index: 3,
          },
          {
            label: "Venäjä",
            value: "Russia",
            index: 4,
          },
          {
            label: "Saksa",
            value: "Germany",
            index: 5,
          },
          {
            label: "Muu",
            value: "Other",
            index: 6,
          },
        ],
      },
      postCode: {
        value: "00130",
        editable: true,
      },
    },
    emailAddress: {
      value: "test@santanderconsumer.fi",
      editable: true,
    },
    mobilePhone: {
      value: "+358 50 1234567",
      editable: true,
    },
    capabilities: {
      canHandleTransferAccounts: false,
      hasNationalPayoutAccount: true,
    },
    personalPreferences: [
      {
        id: undefined,
        preferenceType: PreferenceType.FINotificationBy,
        required: true,
        editable: true,
        optionList: [
          {
            id: undefined,
            index: 0,
            option: PreferenceOption.FINotifyEmail,
            selected: false,
          },
          {
            id: undefined,
            index: 1,
            option: PreferenceOption.FINotifySMS,
            selected: true,
          },
        ],
      },
      {
        id: undefined,
        preferenceType: PreferenceType.FIMarketingBy,
        required: false,
        editable: true,
        optionList: [
          {
            id: undefined,
            index: 2,
            option: PreferenceOption.FINotifyEmail,
            selected: true,
          },
          {
            id: undefined,
            index: 1,
            option: PreferenceOption.FINotifySMS,
            selected: true,
          },
        ],
      },
    ],
    partnerConsents: [
      {
        companyNumber: "1900190-0",
        partnerCompany: "K Auto Oy",
        selected: false,
      },
      {
        companyNumber: "2629628-4",
        partnerCompany: "Italmotor Finland Oy",
        selected: false,
      },
      {
        companyNumber: "0115761-6",
        partnerCompany: "Veho Oy Ab",
        selected: false,
      },
      {
        companyNumber: "2636652-9",
        partnerCompany: "Oy Ford Ab",
        selected: true,
      },
      {
        companyNumber: "1968805-2",
        partnerCompany: "Nissan Nordic Europe Oy",
        selected: true,
      },
      {
        companyNumber: "2836801-8",
        partnerCompany: "Bergé Auto Nordics Oy",
        selected: false,
      },
      {
        companyNumber: "0288746-9",
        partnerCompany: "Länsiauto Oy",
        selected: false,
      },
      {
        companyNumber: "0202594-9",
        partnerCompany: "Volvo Car Finland Oy Ab",
        selected: true,
      },
      {
        companyNumber: "0988005-9",
        partnerCompany: "Oy BMW Suomi Ab",
        selected: true,
      },
    ],
    parties: [
      {
        displayName: "Sami Hyypiä",
        identityNumber: "010170-999R",
        isOrganization: false,
        displayIdentityNumber: "010170-****",
      },
      {
        displayName: "Nokia Oyj",
        identityNumber: "01120389",
        isOrganization: true,
        displayIdentityNumber: "0112038-9",
      },
    ],
  },
};

export const mockUpdateContactInfo: IUpdateContactInfo = {
  updatedCustomerInfoError: false,
};
