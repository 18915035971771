import { useStores, useTracking } from "netbank-shared/src/hooks";
import styles from "./Login.scss";
import { tx } from "netbank-shared/src/libs/i18n";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ProfilePicker } from "./ProfilePicker";

interface IProfilePickerPageProps {
  data: { header: string; subHeader: string; privateProfileLabel: string; organizationProfileLabel: string };
}

export const ProfilePickerPage = ({ data }: IProfilePickerPageProps) => {
  const navigate = useNavigate();
  const dataLayer = useTracking((window as any).dataLayer);
  const { contentStore, customerStore, customerPersistentStore } = useStores();
  const parties = customerStore.currentCustomer?.parties;

  const setTermsAndRedirectToHome = () => {
    if (!customerPersistentStore.viewedTerms && contentStore.currentPage?.termsPage) {
      navigate(contentStore.currentPage.termsPage);
      dataLayer.pushPageView(contentStore.currentPage.termsPage);
    } else {
      navigate(`/${tx("routing.lang")}`);
      dataLayer.pushPageView(`/${tx("routing.lang")}`);
    }
  };

  const setProfile = (isOrganization: boolean, organizationNumber: string) => {
    customerPersistentStore.setIsLoggedInToCorporateAccount(isOrganization);
    if (isOrganization) {
      customerPersistentStore.setCurrentOrganizationNumber(organizationNumber);
    } else {
      customerPersistentStore.setCurrentOrganizationNumber(undefined);
    }
    setTermsAndRedirectToHome();
  };

  if (!parties) {
    setTermsAndRedirectToHome();
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay}>
        <ProfilePicker
          parties={parties}
          textContent={{
            header: data.header,
            subheader: data.subHeader,
            privateProfileLabel: data.privateProfileLabel,
            organizationProfileLabel: data.organizationProfileLabel,
          }}
          setProfile={setProfile}
        />
      </div>
    </div>
  );
};
