import dayjs from "dayjs";
import { IConversationResponse, IConversations, IMessage } from "../../../models/Message/Conversations";
import { Direction, MessageSender } from "../../../models/Content/Enums";
import { mockAttachments } from "./attachments";

const mockMessagesOne: IMessage[] = [
  {
    messageId: "3",
    conversationId: "1",
    createdBy: "Mockinen",
    createdDate: dayjs().toString(),
    body: "Last sent message",
    direction: Direction.Sent,
    attachments: mockAttachments.slice(0, 1),
  },
  {
    messageId: "2",
    conversationId: "1",
    createdBy: MessageSender.Santander,
    createdDate: dayjs().toString(),
    body: "First received message",
    direction: Direction.Received,
    attachments: mockAttachments.slice(1, 3),
  },
  {
    messageId: "1",
    conversationId: "1",
    createdBy: "Mockinen",
    createdDate: dayjs().toString(),
    body: "First sent message",
    direction: Direction.Sent,
    attachments: mockAttachments.slice(3),
  },
];

const mockMessagesTwo: IMessage[] = [
  {
    messageId: "4",
    conversationId: "2",
    createdBy: "Mockinen",
    createdDate: dayjs().toString(),
    body: "First sent message",
    direction: Direction.Sent,
  },
];

export const mockNoConversations: IConversations = {
  conversations: [],
};

export const mockInboxConversations: IConversations = {
  conversations: [
    {
      conversationId: "2",
      title: "Subject (ProductName: accountNumber)",
      createdBy: "Mockala",
      createdDate: dayjs().toString(),
      isArchived: false,
      isClosed: false,
      hasUnreadMessages: false,
      messages: [...mockMessagesTwo],
      hasReply: !!mockMessagesTwo.find((m) => m.direction === Direction.Received),
      productType: "ConsumerLoan",
      subjectName: "Muu asia",
    },
    {
      conversationId: "1",
      title: "Subject (ProductName: accountNumber)",
      createdBy: "Mockinen",
      createdDate: dayjs().toString() as string,
      isArchived: false,
      isClosed: false,
      hasUnreadMessages: true,
      messages: [...mockMessagesOne],
      hasReply: !!mockMessagesOne.find((m) => m.direction === Direction.Received),
      productType: "ConsumerLoan",
      subjectName: "Muu asia",
    },
  ],
};

export const mockArchivedConversations: IConversations = {
  conversations: [
    {
      conversationId: "4",
      title: "Subject (ProductName: accountNumber)",
      createdBy: "Mockala",
      createdDate: dayjs().toString() as string,
      isArchived: true,
      isClosed: false,
      hasUnreadMessages: false,
      messages: [...mockMessagesTwo],
      hasReply: !!mockMessagesTwo.find((m) => m.direction === Direction.Received),
      productType: "ConsumerLoan",
      subjectName: "Muu asia",
    },
    {
      conversationId: "3",
      title: "Subject (ProductName: accountNumber)",
      createdBy: "Mockinen",
      createdDate: dayjs().toString() as string,
      isArchived: true,
      isClosed: false,
      hasUnreadMessages: true,
      messages: [...mockMessagesOne],
      hasReply: !!mockMessagesOne.find((m) => m.direction === Direction.Received),
      productType: "ConsumerLoan",
      subjectName: "Muu asia",
    },
  ],
};

export const mockConversations: IConversations = {
  conversations: [...mockInboxConversations.conversations, ...mockArchivedConversations.conversations],
};

export const mockConversation: IConversationResponse = {
  conversation: mockConversations.conversations.find((c) => c.conversationId === "1"),
};
