import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "../Checkbox/Checkbox";

interface ICheckboxFormFieldProps {
  name: string;
  label: string;
  checkboxId?: string;
  triggerValidation?: boolean; // Useful/needed when validation depends on other input values
  disabled?: boolean;
}

export const CheckboxFormField = ({
  label,
  name,
  checkboxId,
  triggerValidation,
  disabled,
}: ICheckboxFormFieldProps) => {
  const {
    control,
    trigger,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef, fieldState }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <Checkbox
            {...field}
            checkboxId={checkboxId}
            onChange={() => {
              field.onChange(!field.value);
              if (triggerValidation) trigger();
            }}
            label={label}
            checked={field.value}
            error={fieldState.error?.message?.toString()}
            disabled={disabled || isSubmitting}
          />
        );
      }}
    />
  );
};
