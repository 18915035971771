/* eslint-disable import/no-extraneous-dependencies */
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts";
import { IUpdateDueDayFormInfo } from "netbank-shared/src/libs/models/UpdateDueDay";
import { Routes } from "netbank-mobile/src/navigation/Routes";
import { IProductAppRouteParams } from "libs/models/Routing";

export interface IUpdateDueDayParams {
  getAccount: () => void;
  updateDueDay: (data: IUpdateDueDayFormInfo) => void;
  updatingDueDay: boolean;
  updatingError?: boolean | undefined;
  getCurrentDueDay: () => void;
  currentDueDay: number | undefined;
  loadingError: boolean;
  loadingAccount: boolean;
  loadingCurrentDueDay: boolean;
  productUrl: string;
  cleanUp: () => void;
  mobileProductParams: IProductAppRouteParams;
}

export const useDetermineUpdateDueDayParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string | null | undefined,
): IUpdateDueDayParams => {
  const { loanStore } = useStores();
  const emptyResponse: IUpdateDueDayParams = {
    getAccount: () => {},
    updateDueDay: () => {},
    updatingDueDay: false,
    updatingError: false,
    getCurrentDueDay: () => {},
    loadingCurrentDueDay: false,
    loadingError: false,
    loadingAccount: false,
    currentDueDay: 0,
    productUrl: "",
    mobileProductParams: { route: "" },
    cleanUp: () => {},
  };
  if (!accountId || !accountType) return emptyResponse;
  switch (accountType) {
    case AccountType.SecuredLoan:
      return {
        getAccount: () => loanStore.getLoanAccount(accountId),
        updateDueDay: (data: IUpdateDueDayFormInfo) => loanStore.updateDueDay(accountId, data),
        updatingDueDay: loanStore.updatingAccountDueDay,
        updatingError: loanStore.updatingAccountDueDayError,
        getCurrentDueDay: () => loanStore.getCurrentDueDay(accountId),
        currentDueDay: loanStore.currentDueDay,
        loadingCurrentDueDay: loanStore.fetchingCurrentDueDay,
        loadingError: loanStore.fetchingCurrentDueDayError,
        loadingAccount: loanStore.loadingAccount,
        cleanUp: () => loanStore.resetUpdateDueDay(),
        productUrl: tx("routing.securedLoan"),
        mobileProductParams: {
          route: Routes.securedLoan,
          params: { loanId: accountId },
        },
      };
    case AccountType.PrivateLoan:
      return {
        getAccount: () => loanStore.getLoanAccount(accountId),
        updateDueDay: (data: IUpdateDueDayFormInfo) => loanStore.updateDueDay(accountId, data),
        updatingDueDay: loanStore.updatingAccountDueDay,
        updatingError: loanStore.updatingAccountDueDayError,
        getCurrentDueDay: () => loanStore.getCurrentDueDay(accountId),
        currentDueDay: loanStore.currentDueDay,
        loadingCurrentDueDay: loanStore.fetchingCurrentDueDay,
        loadingError: loanStore.fetchingCurrentDueDayError,
        loadingAccount: loanStore.loadingAccount,
        cleanUp: () => loanStore.resetUpdateDueDay(),
        productUrl: tx("routing.privateLoan"),
        mobileProductParams: {
          route: Routes.privateLoan,
          params: { loanId: accountId },
        },
      };
    case AccountType.SalesFinance:
      return {
        getAccount: () => loanStore.getLoanAccount(accountId),
        updateDueDay: (data: IUpdateDueDayFormInfo) => loanStore.updateDueDay(accountId, data),
        updatingDueDay: loanStore.updatingAccountDueDay,
        updatingError: loanStore.updatingAccountDueDayError,
        getCurrentDueDay: () => loanStore.getCurrentDueDay(accountId),
        currentDueDay: loanStore.currentDueDay,
        loadingCurrentDueDay: loanStore.fetchingCurrentDueDay,
        loadingError: loanStore.fetchingCurrentDueDayError,
        loadingAccount: loanStore.loadingAccount,
        cleanUp: () => loanStore.resetUpdateDueDay(),
        productUrl: tx("routing.salesFinance"),
        mobileProductParams: {
          route: Routes.salesFinance,
          params: { loanId: accountId },
        },
      };
    default:
      return emptyResponse;
  }
};
