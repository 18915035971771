/* eslint-disable func-names */
export type TypeRequestAnimationFrame = (FrameRequestCallback: any) => number;
export type TypeCancelAnimationFrame = (number: Number) => void;

export const getRequestAnimationFrame: () => TypeRequestAnimationFrame = () => {
  return (
    window?.requestAnimationFrame ||
    (<any>window)?.webkitRequestAnimationFrame ||
    (<any>window)?.mozRequestAnimationFrame ||
    (<any>window)?.oRequestAnimationFrame ||
    (<any>window)?.msRequestAnimationFrame ||
    function (callback) {
      return window?.setTimeout(callback, 1000 / 60);
    }
  );
};

const runOnAnimationFrame = (
  func: FrameRequestCallback,
  currentTime: number,
  maxTimes: number,
  resolve: () => void
) => {
  getRequestAnimationFrame()((time) => {
    func(time);
    if (currentTime < maxTimes - 1) {
      runOnAnimationFrame(func, currentTime + 1, maxTimes, resolve);
    } else {
      resolve();
    }
  });
};

export const onAnimationFrame = (func: FrameRequestCallback, times?: number) => {
  if (times) {
    return new Promise((resolve) => {
      runOnAnimationFrame(func, 0, times, resolve);
    });
  }
  return getRequestAnimationFrame()(func);
};

export const getCancelAnimationFrame: () => TypeCancelAnimationFrame = () => {
  return window?.cancelAnimationFrame || (<any>window)?.mozCancelAnimationFrame;
};

export const cancelAnimationFrame = (number: number) => {
  return getCancelAnimationFrame()(number);
};
