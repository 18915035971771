import { IAttachment } from "libs/models/Attachment/Attachment";

export const mockAttachments: IAttachment[] = [
  {
    id: "1",
    fileName: "attachment1.txt",
    size: 12,
    file: new File([""], "attachment1", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "2",
    fileName: "attachment2.txt",
    size: 12,
    file: new File([""], "attachment2", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "10",
    fileName: "attachment10.txt",
    size: 12,
    file: new File([""], "attachment10", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "11",
    fileName: "attachment11.txt",
    size: 12,
    file: new File([""], "attachment11", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "12",
    fileName: "attachment12.txt",
    size: 12,
    file: new File([""], "attachment12", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "13",
    fileName: "attachment13.txt",
    size: 12,
    file: new File([""], "attachment13", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "20",
    fileName: "attachment20.txt",
    size: 12,
    file: new File([""], "attachment20", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "30",
    fileName: "attachment30.txt",
    size: 12,
    file: new File([""], "attachment30", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
  {
    id: "31",
    fileName: "attachment31.txt",
    size: 12,
    file: new File([""], "attachment31", { type: "text/plain" }),
    fileData: "123",
    mimeType: "text/plain",
  },
];
