import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "netbank-shared/src/hooks";
import { ClosingOfDepositAccountsForm } from "./ClosingOfDepositAccountsForm";
import { IClosingOfDepositAccountPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServicePageSkeleton } from "~views/pages/Skeletons/SelfServicePageSkeleton";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";

export const ClosingOfDepositAccountPage = observer(() => {
  const [loading, setLoading] = useState(true);
  const { transferStore, contentStore, depositStore } = useStores();
  const { currentAccount } = depositStore;

  const pageData = contentStore.currentPage as IClosingOfDepositAccountPage;

  useEffect(() => {
    const fetchData = async () => {
      await transferStore.validateAccountRedemption();

      if (!transferStore.savedAccounts) {
        await transferStore.getSavedAccounts();
      }
      setLoading(false);
    };
    fetchData();

    return () => {
      transferStore.resetTransaction();
    };
  }, []);

  if (loading) {
    return <SelfServicePageSkeleton />;
  }

  if (!currentAccount) {
    return <SelfServiceFailurePage data={pageData.emptyState} />;
  }

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText,
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: pageData.displayNumberHeaderLabel || "",
        value: currentAccount.displayNumber,
      },
    ],
  };

  return (
    <AccountActionContainer info={pageInfo}>
      <ClosingOfDepositAccountsForm accountId={currentAccount.accountId} />
    </AccountActionContainer>
  );
});
