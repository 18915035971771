import { Locale } from "../models/Content/Enums";

export const toLocaleString = (amount?: number, currency?: string, locale?: Locale, fractionDigits = 2) => {
  if (typeof amount !== "number" || !locale) return "";
  if (currency) {
    const localeString = amount.toLocaleString(locale, {
      currency,
      style: "currency",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
    if (locale === Locale.dk || locale === Locale.sv) {
      return localeString.replace(/.$/, ""); // Remove trailing point
    }
    return localeString;
  }

  return amount.toLocaleString(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};
