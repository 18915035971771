import React, { CSSProperties, ReactNode } from "react";
import { observer } from "mobx-react";
import Skeleton from "react-loading-skeleton";
import styles from "./SkeletonWrapper.scss";

export interface ISkeletonWrapperProps {
  width?: number;
  height?: number;
  circle?: boolean;
  count?: number;
  className?: string;
  style?: CSSProperties;
}

export interface ISkeletonDivWrapperProps {
  children?: ReactNode;
}

const DivWrapper = ({ children }: ISkeletonDivWrapperProps) => <div className={styles.skeleton}>{children}</div>;

export const SkeletonWrapper = observer(({ width, height, circle, count, className, style }: ISkeletonWrapperProps) => {
  const classes = [styles.wrapper];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(" ")}>
      <Skeleton width={width} height={height} circle={circle} count={count} wrapper={DivWrapper} style={style} />
    </div>
  );
});
