export const Routes = {
  // Stacks
  loginStack: "loginstack",
  appStack: "appstack",
  tabStack: "tabstack",

  // First level (App|LoginStack)
  root: "root",
  terms: "terms",
  signicat: "signicat",

  // Second level (Tab)
  home: "home",
  transfers: "transfers",
  messages: "messages",
  cms: "cms",
  card: "card",
  privateLoan: "privateLoan",
  securedLoan: "securedLoan",
  salesFinance: "salesFinance",
  deposit: "deposit",
  leasing: "leasing",
  // Dummy route that flips menuStore.isOpen boolean onPress
  menu: "menu",
};
