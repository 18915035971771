/* eslint-disable max-len */
import dayjs from "dayjs";
import {
  IComplianceStatus,
  IComplianceDefinition,
  ComplianceStatus,
  IComplianceSigningStatus,
  ComplianceSigningStatus,
  ComplianceFieldType,
  IUploadAttachmentsResponse,
} from "../../../models/CustomerCompliance/Compliance";

export const mockComplianceStatuses: IComplianceStatus = {
  status: ComplianceStatus.NotStarted,
  complianceDueDate: dayjs().add(3, "day").toDate(),
  completedQuestions: 0,
  outstaningQuestions: 2,
  bookmarkedAnswerId: undefined,
};

export const mockComplianceSignatures: IComplianceSigningStatus = {
  signatureLink: "https://google.com/",
  status: ComplianceSigningStatus.Signable,
};

export const mockUploadAttachments: IUploadAttachmentsResponse = {
  attachments: [{ fileId: "322f4ef7-a62e-46d7-aaa0-fd4f7c195063", fileName: "Test.png" }],
};

export const mockComplianceDefinitions: IComplianceDefinition[] = [
  {
    answerId: "MTAwMjY3N18w",
    title: "Billån og Leasing produktskjema",
    questions: [
      {
        id: "YjlmMmNhNTMtMjhiOC00YTExLTg5YjQtZTMxNmU4NWQ4OWI2",
        type: ComplianceFieldType.h3,
        editable: false,
        answers: undefined,
        questionText: "Billån og Leasing produktskjema",
        options: undefined,
        children: undefined,
      },
      {
        id: "OWUzOWY4OWEtZjVkNi00YmU0LWEwNTItYjQ5NTdlZDcxM2Qz",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText:
          "I forbindelse med oppdateringen av bedriftsinformasjonen din er vi også forpliktet til å innhente brukerdetaljer om produktene bedriften har med Santander Consumer Bank AS. ",
        options: undefined,
        children: undefined,
      },
      {
        id: "OWNhZDAxNGItOWMxMi00NjE1LTlkM2ItZTNkNWUyYTk3ODM2",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText:
          "Etter den norske hvitvaskingsloven er vi som bank forpliktet til å innhente kundeinformasjon på en kontinuerlig basis. Dette utgjør et viktig ledd i arbeidet mot økonomisk kriminalitet, hvitvasking og terrorfinansiering, og skal beskytte både din bedrift som kunde og banken.",
        options: undefined,
        children: undefined,
      },
      {
        id: "YzkzNGE4YjktODEzOC00NDVkLTlkMmItOTRjZDQ3YmY0Yjli",
        type: ComplianceFieldType.h5,
        editable: false,
        answers: undefined,
        questionText: "Hvordan fylle ut skjemaet:",
        options: undefined,
        children: undefined,
      },
      {
        id: "YTNkMGExYzYtN2NkYi00Njc0LWJkMTEtODkyODQ2NWMzZmZh",
        type: ComplianceFieldType.h4,
        editable: false,
        answers: undefined,
        questionText: "Fyll ut spørsmålene. Når du har besvart alle deler av skjemaet kan du trykke på «Send inn». ",
        options: undefined,
        children: undefined,
      },
    ],
  },
  {
    answerId: "MTAwMjY3N18x",
    title: "Billån og Leasing produktskjema",
    questions: [
      {
        id: "",
        type: ComplianceFieldType.Conditional,
        editable: false,
        answers: undefined,
        questionText: undefined,
        options: undefined,
        children: [
          {
            id: "MTAwMjY3N18xX0Z1bmRfU291cmNlX0Zvcl9SZXBheW1lbnRfQXV0b19Mb2FuX0xlYXNlX09SRw==",
            type: ComplianceFieldType.DropDownList,
            editable: false,
            answers: [
              {
                id: "Retained_Profit",
                text: "Selskapets alminnelige drift",
              },
            ],
            questionText:
              "Vennligst spesifiser hovedsakelig kilde til midler for tilbakebetaling av lånet/leasing avtalen",
            questionHelpText: "Lorem ipsum",
            options: [
              {
                id: "Retained_Profit",
                text: "Selskapets alminnelige drift",
              },
              {
                id: "Accumulated_Equity_Capital",
                text: "Selskapets opparbeidede egenkapital",
              },
              {
                id: "Debt_Or_Borrowed_Capital",
                text: "Gjeld / Fremmedkapital",
              },
              {
                id: "Other",
                text: "Annet",
              },
            ],
            children: undefined,
          },
          {
            id: "TWpjd056ZzFNMTh3X19VSFZ5Y0c5elpWOVBabDlCWTJOdmRXNTBYMFJsY0c5emFYVENwTUtrd3FRd3dxVENwRlJ5ZFdYQ3BNS2ssdsadsafdasf=",
            type: ComplianceFieldType.FileUploader,
            editable: true,
            answers: [],
            questionText: "Ladda upp filer",
            questionHelpText:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            options: [],
            conditions: [],
            children: undefined,
          },
          {
            id: "NDc1YTg1ZGUtYTc4MS00M2QxLWFjNjEtMWE1ZWE1NWJkNmNm",
            type: ComplianceFieldType.paragraph,
            editable: false,
            answers: undefined,
            questionText: "Dersom du valgte «annet» vennligst oppgi videre detaljer i delen under",
            options: undefined,
            children: undefined,
          },
          {
            id: "MTAwMjY3N18xX0Z1bmRfU291cmNlX0Zvcl9SZXBheW1lbnRfSWZfT3RoZXJfQXV0b19MX0xfT1JH",
            type: ComplianceFieldType.TextArea,
            editable: true,
            answers: [
              {
                id: "Fund_Source_For_Repayment_If_Other_Auto_L_L_ORG",
                text: "test",
              },
            ],
            questionText:
              "Vennligst spesifiser hovedsakelig kilde til midler for tilbakebetaling av auto lån/leasing avtalen",
            questionHelpText: "Lorem ipsum",
            options: undefined,
            children: undefined,
          },
        ],
      },
    ],
  },
  {
    answerId: "MTAwMjY3N18y",
    title: "Kundeskema",
    questions: [
      {
        id: "MmFjYjkxZjMtOTcwNi00MTQ0LWE0MGEtYTVlYWIwMDgyY2Qw",
        type: ComplianceFieldType.h5,
        editable: false,
        answers: undefined,
        questionText:
          "Ved å krysse av i boksen under bekrefter du at all informasjon oppgitt i dette skjemaet er korrekt og at du har svart på alle spørsmål i dette produktskjemaet. ",
        options: undefined,
        children: undefined,
      },
      {
        id: "MTAwMjY3N18yX0RlY2xhcmF0aW9u",
        type: ComplianceFieldType.RadioButtonsYesNo,
        editable: true,
        answers: [
          {
            id: "True",
            text: "Ja",
          },
        ],
        questionText: "Jeg bekrefter at informasjonen oppgitt er korrekt",
        questionHelpText: "Lorem ipsum",
        options: [
          {
            id: "True",
            text: "Ja",
          },
          {
            id: "False",
            text: "Nei",
          },
        ],
        children: undefined,
      },
      {
        id: "N2EwNjUzODUtZGM4Yy00N2MxLWFhNTItYzJiNjJjMDJkZmUz",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: undefined,
        questionText: "Hvis «nei» vennligst oppgi grunnen til dette i seksjonen under",
        options: undefined,
        children: undefined,
      },
      {
        id: "MTAwMjY3N18yX1BsZWFzZV9zcGVjaWZ5",
        type: ComplianceFieldType.TextArea,
        editable: true,
        answers: [],
        questionText: "Vennligst spesifiser",
        options: undefined,
        children: undefined,
      },
      {
        id: "MjllOWJiNjYtYWYwZi00NGMyLTgyN2MtYjExNjVlNDY2NDA3",
        type: ComplianceFieldType.paragraph,
        editable: false,
        answers: undefined,
        questionText:
          "Det kan hende at du blir kontaktet ved en senere anledning dersom vi skulle trenge mer informasjon om din bedrift/organisasjon.                             \nVi takker for ditt samarbeid i å hjelpe oss med å holde bedriftens/organisasjonens kundeprofil oppdatert!",
        options: undefined,
        children: undefined,
      },
    ],
  },
];
