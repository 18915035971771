import axios from "axios";
import { IConfig } from "netbank-shared/src/libs/models/Config";

export const getConfig = async () => {
  const env: string = process.env.NODE_ENV === "production" ? "prod" : "dev";
  const configPath = await import(`./${env}.conf`);
  const response = await axios.get<IConfig>(configPath.default);
  let config: IConfig | undefined;

  if (response.status === 200) {
    config = response.data;
  }

  return config;
};
