import React from "react";
import { observer } from "mobx-react";
import { DayModifiers, DayPicker, Matcher } from "react-day-picker";
import "./DayPicker.scss";
import { useStores } from "netbank-shared/src/hooks";
import { Navbar } from "./Navbar";
import { dateLocaleUtils } from "netbank-shared/src/libs/utils/dateLocal";

interface IDayPickerComponentProps {
  onDayClick?: (day: Date, modifiers: DayModifiers) => void;
  initialMonth?: Date;
  selectedDays?: Matcher | Matcher[];
  disabledDays?: Matcher | Matcher[];
  pickerRef?: React.RefObject<any>;
  tabIndex?: number;
}

export const DayPickerComponent = observer(
  ({ onDayClick, initialMonth, selectedDays, disabledDays, tabIndex }: IDayPickerComponentProps) => {
    const { uiStore } = useStores();

    return (
      <div tabIndex={tabIndex}>
        <DayPicker
          components={{
            Caption: (props) => <Navbar {...props} />,
          }}
          onDayClick={onDayClick}
          defaultMonth={initialMonth}
          selected={selectedDays}
          disabled={disabledDays}
          locale={dateLocaleUtils.getLocale(uiStore.lang!)}
        />
      </div>
    );
  },
);
