import React, { useState, FocusEvent } from "react";
import { observer } from "mobx-react";
import styles from "./Input.scss";
import rootStyles from "~views/pages/Root.scss";
import { capitalize } from "netbank-shared/src/libs/utils";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";
import errorIcon from "~assets/error.svg";

type IInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  icon?: string;
  suffix?: string;
  valueSuffix?: string;
  small?: boolean;
  large?: boolean;
  borderColor?: string;
  centered?: boolean;
  className?: string;
  wrapperStyle?: string;
  value?: string | number;
  infoPopover?: IInfoPopoverProps;
  error?: string;
  floatingPlaceholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const Input = observer(
  ({
    label,
    icon,
    suffix,
    valueSuffix,
    small,
    large,
    borderColor,
    centered,
    className,
    wrapperStyle,
    value,
    infoPopover,
    onBlur,
    onFocus,
    disabled,
    error,
    floatingPlaceholder,
    placeholder,
    ...props
  }: IInputProps) => {
    const [focus, setFocus] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
      setFocus(false);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
      setFocus(true);
    };

    const classes = [styles.wrapper];
    const placeholderClasses = [styles.placeholder];
    const wrapperClasses = [];

    if (focus) {
      classes.push(styles.focus);
    }

    if (small) {
      classes.push(styles.small);
    }

    if (large) {
      classes.push(styles.large);
    }

    if (borderColor) {
      classes.push(styles[`border${capitalize(borderColor)}`]);
    }

    if (centered) {
      classes.push(styles.centered);
    }

    if (className) {
      classes.push(className);
    }

    if (wrapperStyle) {
      wrapperClasses.push(wrapperStyle);
    }

    if (disabled) {
      classes.push(styles.disabled);
      wrapperClasses.push(styles.faded);
    }

    if (floatingPlaceholder && !placeholder) {
      classes.push(styles.hasPlaceholder);
    }

    if (value || focus) {
      placeholderClasses.push(styles.float);
    }

    return (
      <div className={wrapperClasses.join(" ")}>
        {label && (
          <div className={styles.labelRow}>
            <span className={styles.label}>{label}</span>
            {infoPopover?.content && !disabled && <InfoPopover className={styles.popover} {...infoPopover} />}
          </div>
        )}
        <div className={classes.join(" ")}>
          <input
            {...props}
            placeholder={placeholder}
            disabled={disabled}
            value={valueSuffix && !focus ? value + valueSuffix : value}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
          {!placeholder && floatingPlaceholder && (
            <span className={placeholderClasses.join(" ")}>{floatingPlaceholder}</span>
          )}
          {icon && <img className={styles.icon} src={icon} alt="icon" />}
          {suffix && <span className={styles.suffix}>{suffix}</span>}
        </div>
        {error && (
          <div className={rootStyles.error}>
            <img src={errorIcon} alt="error-icon" />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  },
);
