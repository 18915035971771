import { Locale } from "../models/Content/Enums";

export interface IRouterPathNames {
  lang: string;
  locale: Locale;
  privateLoan: string;
  securedLoan: string;
  salesFinance?: string;
  leasing: string;
  card: string;
  deposit: string;
}

export const SEPathNames: IRouterPathNames = {
  lang: "sv",
  locale: Locale.sv,
  privateLoan: "privatlån",
  securedLoan: "bil-och-fritidslån",
  leasing: "leasing",
  card: "kort",
  deposit: "sparkonto",
};

export const DKPathNames: IRouterPathNames = {
  lang: "dk",
  locale: Locale.dk,
  privateLoan: "privatlån",
  securedLoan: "bil-och-fritidslån",
  leasing: "leasing",
  card: "kort",
  deposit: "opsparingskonto",
};

export const FIPathNames: IRouterPathNames = {
  lang: "fi",
  locale: Locale.fi,
  privateLoan: "laina",
  securedLoan: "ajoneuvorahoitus",
  salesFinance: "sales-finance",
  leasing: "leasing",
  card: "kortti",
  deposit: "säästötili",
};

export const NOPathNames: IRouterPathNames = {
  lang: "no",
  locale: Locale.no,
  privateLoan: "forbrukslån",
  securedLoan: "bil-og-fritidslån",
  leasing: "leasing",
  card: "kredittkort",
  deposit: "sparing",
};

export const ENPathNames: IRouterPathNames = {
  lang: "en",
  locale: Locale.en,
  privateLoan: "private-loan",
  securedLoan: "secured-loan",
  leasing: "leasing",
  card: "card",
  deposit: "deposit",
};
