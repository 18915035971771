import { tx } from "netbank-shared/src/libs/i18n";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import React, { CSSProperties, useState } from "react";
import { Button, Checkbox } from "../index";
import styles from "./CheckboxConfirmation.scss";

export interface ICheckboxConfirmationProps {
  applyAction: () => void;
  cancelAction: () => void;
  canceling: boolean;
  loading: boolean;
  confirmationButtonLabel: string;
  cancelButtonLabel?: string;
  confirmationWarningText: string;
  trackingCategory?: TrackingCategory;
  trackingAction?: TrackingAction;
  className?: string;
  style?: CSSProperties;
}

export const CheckboxConfirmation = ({
  className,
  applyAction,
  cancelAction,
  canceling,
  loading,
  confirmationButtonLabel,
  confirmationWarningText,
  trackingAction,
  trackingCategory,
  cancelButtonLabel,
  ...rest
}: ICheckboxConfirmationProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className={[className, styles.wrapper].join(" ").trim()} {...rest}>
      <Checkbox label={confirmationWarningText} checked={checked} onChange={(e) => setChecked(e.target.checked)} />
      <div className={styles.buttonsWrapper}>
        <Button
          title={cancelButtonLabel || tx("misc.cancel")}
          onClick={cancelAction}
          loading={canceling}
          bordered
          centered
          large
          color="black"
          borderColor="black"
        />
        <Button
          title={confirmationButtonLabel}
          onClick={applyAction}
          color={checked ? "red" : "gray"}
          bordered
          borderColor={checked ? "red" : "gray"}
          large
          centered
          loading={loading}
          disabled={!checked}
          trackingCategory={trackingCategory}
          trackingAction={trackingAction}
        />
      </div>
    </div>
  );
};
