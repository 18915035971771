import React from "react";
import styles from "./LabelRow.scss";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";

interface ILabelRowProps {
  label: string;
  infoPopover?: IInfoPopoverProps;
}

export const LabelRow = ({ label, infoPopover }: ILabelRowProps) => {
  return (
    <div className={styles.labelRow}>
      <span className={styles.label}>{label}</span>
      {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
    </div>
  );
};
