import React from "react";
import styles from "./MessageAttachment.scss";
import bookmarkRedIcon from "~assets/bookmark-red.svg";
import { IAttachment } from "netbank-shared/src/libs/models/Attachment/Attachment";
import rootStyles from "../../Root.scss";

interface IMessageAttachmentProps {
  attachment: IAttachment;
  onDownloadClick: (id: string) => Promise<void>;
  loading: boolean;
}

export const MessageAttachment = ({ attachment, onDownloadClick, loading }: IMessageAttachmentProps) => {
  const { id, fileName } = attachment;
  const spinnerClasses = [rootStyles.loading, rootStyles.spinner, styles.spinner];
  return (
    <div className={styles.attachment}>
      <div className={styles.buttonWithIcon}>
        {loading ? (
          <span className={spinnerClasses.join(" ")} />
        ) : (
          <img src={bookmarkRedIcon} alt="red attachment icon" />
        )}
        <button type="button" onClick={() => onDownloadClick(id)} className={styles.attachmentButton}>
          <span>{fileName}</span>
        </button>
      </div>
    </div>
  );
};
