import React from "react";
import { observer } from "mobx-react";
import styles from "./InfoModal.scss";
import questionIcon from "~assets/question.svg";
import { useStores } from "netbank-shared/src/hooks";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";

interface IInfoModalProps {
  modalContent: IContentAreaItem[];
  label?: string;
  modalLabel?: string;
}

export const InfoModal = observer(({ modalContent, label, modalLabel }: IInfoModalProps) => {
  const { uiStore } = useStores();
  return (
    <div
      className={styles.wrapper}
      onClick={() =>
        uiStore.setModal({
          content: modalContent,
          title: modalLabel || label || "",
        })
      }
    >
      <img src={questionIcon} alt="info-icon" />
      {label && <span>{label}</span>}
    </div>
  );
});
