import React from "react";
import { observer } from "mobx-react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IAddInstallmentPage } from "netbank-shared/src/libs/models/Content/Page";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IAddInstallmentForm,
  generateAddInstallmentSchema,
  getAddInstallmentTypes,
} from "netbank-shared/src/libs/forms/PaymentPlanChange";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { DropdownFormField, FormWrapper, InputFormField } from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { DatePickerFormField } from "~views/shared/Forms/DatePickerFormField";

export const AddInstallmentPage = observer(() => {
  const navigate = useNavigate();
  const { contentStore, loanStore, uiStore, customerStore } = useStores();
  const { currentCustomer } = customerStore;
  const { currentPage } = contentStore;
  const page = currentPage as IAddInstallmentPage;

  const {
    amount,
    date,
    addInstallmentType,
    installmentTypes,
    phone,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
  } = page;
  const { currentAccount, selfServiceSusbmissionState } = loanStore;

  const allowBalloonPayment =
    currentAccount && !!currentAccount.balloonAmount && !Number.isNaN(currentAccount.balloonAmount);

  const methods = useForm<IAddInstallmentForm>({
    resolver: yupResolver(
      generateAddInstallmentSchema({
        locale: uiStore.locale!,
        accountType: currentAccount?.accountType,
        page,
        allowBalloonPayment,
      }),
    ),
    defaultValues: {
      phone: currentCustomer?.mobilePhone?.value,
    },
  });

  useFetchData({
    cleanup: () => loanStore.resetSelfServiceState(),
  });

  if (!currentAccount || !currentAccount.canAddInstallment) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const installmentTypeOptions = getAddInstallmentTypes(installmentTypes, allowBalloonPayment);

  const onSubmit: SubmitHandler<IAddInstallmentForm> = async (data) =>
    loanStore.addInstallment({
      ...data,
      installmentType: installmentTypeOptions.find((option) => option.value === data.installmentType)?.enumValue,
    });

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <InputFormField
              type="number"
              label={amount?.label || tx("addInstallment.amountLabel")}
              infoPopover={{ popoverTitle: amount?.infoTitle, content: amount?.infoText }}
              name="amount"
              suffix={uiStore.currencySymbol}
              placeholder={amount?.placeholder}
            />
            <DatePickerFormField
              label={date?.label || tx("addInstallment.dateLabel")}
              infoPopover={{ popoverTitle: date?.infoTitle, content: date?.infoText }}
              name="date"
              disabledDays={[{ before: new Date() }]}
            />
            <DropdownFormField
              label={addInstallmentType?.label || tx("addInstallment.typeLabel")}
              infoPopover={{ popoverTitle: addInstallmentType?.infoTitle, content: addInstallmentType?.infoText }}
              name="installmentType"
              list={installmentTypeOptions}
            />
            <InputFormField
              type="tel"
              label={phone?.label || tx("selfServiceLabels.phoneLabel")}
              infoPopover={{ popoverTitle: phone?.infoTitle, content: phone?.infoText }}
              name="phone"
              placeholder={phone?.placeholder}
              allowedCharacters={/[^0-9+]/g}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
