import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import rootStyles from "~views/pages/Root.scss";
import styles from "./RadioButtons.scss";
import errorIcon from "~assets/error.svg";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";
import { HtmlContent } from "../HtmlContent/HtmlContent";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";

interface IRadioButtonsProps {
  list: ListItem[];
  label?: string;
  infoPopover?: IInfoPopoverProps;
  topText?: string;
  value?: string;
  onChange: (item: ListItem) => void;
  disabled?: boolean;
  error?: string;
}

export const RadioButtons = observer(
  ({ list, label, infoPopover, topText, value, onChange, disabled = false, error }: IRadioButtonsProps) => {
    const formClasses = [styles.radioButtonsList];
    if (disabled) formClasses.push(styles.disabled);

    const onClick = (item: ListItem) => {
      onChange(item);
    };

    // Create radio button id's to prevent them from changing on each rerender
    const [radioButtonIds, setRadioButtonIds] = useState<string[]>([]);
    useEffect(() => {
      setRadioButtonIds(list.map(() => uuidv4()));
    }, []);

    if (radioButtonIds.length === 0) return null;

    return (
      <div className={styles.wrapper}>
        {label && (
          <div className={styles.labelRow}>
            <span className={styles.label}>{label}</span>
            {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
          </div>
        )}
        {topText && <HtmlContent html={topText} />}
        <form className={formClasses.join(" ")}>
          {list.map((item, i) => {
            const itemClasses = [styles.inputItem];
            const itemLabelClasses = [styles.inputLabel];
            if (item.disabled === true || disabled) itemLabelClasses.push(styles.disabled);

            const key = radioButtonIds[i];

            return (
              <div key={`radioButtons-${key}`}>
                <label htmlFor={`radioButtons-${key}`} className={itemLabelClasses.join(" ")}>
                  <input
                    type="radio"
                    value={item.value}
                    checked={item.value === value}
                    id={`radioButtons-${key}`}
                    onChange={() => {
                      if (!(item.disabled === true) && !disabled) onClick(item);
                    }}
                  />
                  <span className={itemClasses.join(" ")} />
                  {item.label}
                </label>
                {item.text && item.value === value && <HtmlContent html={item.text} />}
              </div>
            );
          })}
        </form>
        {error && (
          <div className={rootStyles.error}>
            <img src={errorIcon} alt="error-icon" />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  },
);
