import * as yup from "yup";
import { Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { AccountType } from "../../models/CustomerProducts";
import { IIncreaseLoanPage } from "libs/models/Content/Page";

export interface IIncreaseLoanForm {
  loanAmount: number;
  duration: number;
}

interface IIncreaseLoanProps {
  locale: Locale;
  minLoanAmount?: number;
  maxLoanAmount?: number;
  accountType?: AccountType;
  page: IIncreaseLoanPage;
  requiredError?: string;
}

export const generateIncreaseLoanForm = (props: IIncreaseLoanProps): yup.ObjectSchema<IIncreaseLoanForm> => {
  const { loanAmount, terms } = props.page;
  const invalidLoanAmountError = loanAmount?.error || tx("validators.required");
  const termsError = terms?.error || tx("validators.required");

  const schema = yup.object({
    loanAmount: yup
      .number()
      .required(invalidLoanAmountError)
      .integer(invalidLoanAmountError)
      .min(props.minLoanAmount || 20000, invalidLoanAmountError)
      .max(props.maxLoanAmount || 35000, invalidLoanAmountError)
      .test("isValidLoanAmountInterval", invalidLoanAmountError, (value) => value % 5000 === 0),
    duration: yup.number().required(termsError).integer(termsError).min(2, termsError).max(12, termsError),
  });

  return schema;
};
