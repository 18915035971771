import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { IAddInsurancePage } from "libs/models/Content/Page";

export interface IAddInsuranceForm {
  phone: string;
  message?: string;
}

interface IAddInsuranceProps {
  locale: Locale;
  page: IAddInsurancePage;
  requiredError?: string;
  invalidError?: string;
}

export const generateAddInsuranceSchema = (props: IAddInsuranceProps): yup.ObjectSchema<IAddInsuranceForm> => {
  const { phone } = props.page;
  const invalidPhoneError = phone?.error || tx("validators.phone");

  const schema = yup.object({
    message: yup.string().optional(),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
  });

  return schema;
};
