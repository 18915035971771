export const templateString = (input: string, object: any) => {
  if (!input) return "";

  return input.replace(/{[^{}]+}/g, (key) => {
    return object?.[key.replace(/[{}]+/g, "")] || "";
  });
};

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const keysToCamel = (o: any): object => {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== "function") {
    const n: { [key: string]: any } = {};
    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });
    return n;
  }

  if (Array.isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }
  return o;
};

export const objectToQueryString = (o: object): string => {
  if (!o) return "";

  let result = "";
  const details: string[] = [];
  Object.entries(o).forEach(
    ([key, value]) => key && value && details.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  );

  result = details.join("&");
  return result;
};
