import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "../Input/Input";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";

interface IInputFormFieldProps {
  type: "text" | "number" | "email" | "tel";
  name: string;
  label: string;
  placeholder?: string;
  suffix?: string;
  allowedCharacters?: RegExp;
  infoPopover?: IInfoPopoverProps;
  disabled?: boolean;
  triggerValidation?: boolean; // Useful/needed when validation depends on other input values
  formatString?: (input: string) => string;
}

export const InputFormField = ({
  type,
  label,
  name,
  placeholder,
  allowedCharacters,
  suffix,
  infoPopover,
  disabled,
  triggerValidation,
  formatString,
}: IInputFormFieldProps) => {
  const {
    control,
    trigger,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <Input
            {...field}
            type={type}
            label={label}
            placeholder={placeholder}
            error={error}
            onChange={(e) => {
              let value = type === "number" && +e.target.value > 0 ? +e.target.value : e.target.value;

              if (allowedCharacters && typeof value === "string") value = value.replace(allowedCharacters, "");
              if (formatString && typeof value === "string") {
                value = formatString(value);
              }
              field.onChange(value);
            }}
            onBlur={() => {
              if (triggerValidation) trigger();
            }}
            suffix={suffix}
            infoPopover={infoPopover}
            disabled={disabled || isSubmitting}
          />
        );
      }}
    />
  );
};
