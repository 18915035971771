import { create, ApisauceInstance } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { AuthConfiguration, BaseAuthConfiguration } from "react-native-app-auth";
import { Store } from "../../stores";
import {
  IAccessTokenDetails,
  IAuthCodeDetails,
  IGetCurityTokenResponse,
  IRefreshTokenDetails,
  IRevokeTokenDetails,
} from "../models/Auth";
import { IConfig } from "../models/Config";
import { Environment } from "../models/Content/Enums";
import { objectToQueryString } from "../utils";
import { MockAuthenticationApi } from "./mock/MockAuthenticationApi";

export class AuthenticationApi {
  rootStore: Store;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  authCodeDetails?: IAuthCodeDetails;

  baseAppAuthConfiguration?: BaseAuthConfiguration;

  mobileAppsAuthorizeRequest?: AuthConfiguration;

  accessTokenDetails?: IAccessTokenDetails;

  refreshTokenDetails?: IRefreshTokenDetails;

  revokeTokenDetails?: IRevokeTokenDetails;

  authUrl?: string;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  scopes = [
    "netbank.customer.read",
    "netbank.customer.write",
    "netbank.customer-product.read",
    "netbank.customer-product.write",
    "netbank.deposit.read",
    "netbank.deposit.write",
    "netbank.loan.read",
    "netbank.loan.write",
    "netbank.credit.read",
    "netbank.credit.write",
    "netbank.leasing.read",
    "netbank.leasing.write",
    "netbank.transfer-account.read",
    "netbank.transfer-account.write",
    "netbank.customer-compliance.read",
    "netbank.customer-compliance.write",
    "netbank.message.read",
    "netbank.message.write",
    "netbank.chat.read",
    "netbank.chat.write",
    "banking.mysantander",
    "openid",
    "profile",
  ];

  init = (config: IConfig | undefined) => {
    if (config) {
      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      };

      this.client = create({
        baseURL: config.CURITY_AUTH_URL,
        headers,
        timeout: 60000,
      });

      this.authCodeDetails = {
        signicatAcr: config.CURITY_ACR,
        response_type: config.CURITY_RESPONSE_TYPE,
        client_id: config.CURITY_CLIENT_ID,
        redirect_uri: config.CURITY_REDIRECT_URI,
        code_challenge_method: config.CURITY_CODE_CHALLENGE_METHOD,
        scope: this.scopes.join(" "),
      };

      this.accessTokenDetails = {
        client_id: config.CURITY_CLIENT_ID,
        redirect_uri: config.CURITY_REDIRECT_URI,
        grant_type: config.CURITY_GRANT_TYPE,
      };

      this.refreshTokenDetails = {
        client_id: config.CURITY_CLIENT_ID,
        grant_type: "refresh_token",
      };

      this.revokeTokenDetails = {
        client_id: config.CURITY_CLIENT_ID,
      };

      this.authUrl = `${config.CURITY_AUTH_URL}authorize?`;

      this.baseAppAuthConfiguration = {
        issuer: `${config.CURITY_AUTH_URL}~/`,
        clientId: config.CURITY_CLIENT_ID,
      };

      this.mobileAppsAuthorizeRequest = {
        ...this.baseAppAuthConfiguration,
        redirectUrl: config.CURITY_REDIRECT_URI,
        scopes: this.scopes,
        iosPrefersEphemeralSession: true,
      };

      if (config.CURITY_ACR) {
        this.mobileAppsAuthorizeRequest.additionalParameters = { signicatAcr: config.CURITY_ACR };
      }

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  getAuthCodeUrl = (codeChallenge: string, state: string) => {
    const details = {
      ...this.authCodeDetails,
      code_challenge: codeChallenge,
      state,
    };

    return `${this.authUrl}${objectToQueryString(details)}`;
  };

  getToken = async (code?: string, codeVerifier?: string) => {
    const details = {
      ...this.accessTokenDetails,
      code,
      code_verifier: codeVerifier,
    };

    const formBody = objectToQueryString(details);

    return this.client?.post<IGetCurityTokenResponse>(`token`, formBody);
  };

  refreshToken = async (refreshToken: string) => {
    const details = {
      ...this.refreshTokenDetails,
      refresh_token: refreshToken,
    };

    const formBody = objectToQueryString(details);

    return this.client?.post<IGetCurityTokenResponse>(`token`, formBody);
  };

  revokeToken = async (token?: string) => {
    if (!token) return null;
    const details = {
      ...this.revokeTokenDetails,
      token,
    };

    const formBody = objectToQueryString(details);

    return this.client?.post<IGetCurityTokenResponse>(`revoke`, formBody);
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockAuthenticationApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
