import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

export const nicknameValidationRules = {
  maxLength: 20,
  minLength: 1,
  charRegexp: /^[A-Za-zäöåÄÖÅæøåÆØÅ0-9 _]*[A-Za-zäöåÄÖÅæøåÆØÅ0-9][A-Za-zäöåÄÖÅæøåÆØÅ0-9 _]*$/,
  required: true,
};

export const AccountNicknameValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  const { maxLength, minLength, charRegexp, required } = nicknameValidationRules;

  if (required && !value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (value.length < minLength || value.length > maxLength) {
    valid = false;
    error = tx("validators.accountName", {
      name: errorName,
    });
  } else if (charRegexp && !charRegexp.test(value)) {
    valid = false;
    error = tx("validators.accountName", {
      name: errorName,
    });
  }

  return { valid, error };
};
