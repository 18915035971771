import { ICreateWithdrawalResponse, IWithdrawalCalculationResponse } from "libs/models/Transfer/Transfer";

export const mockCreateWithdrawalResponse: ICreateWithdrawalResponse = {
  accountId: "CV12345",
  accountNumber: "12345",
  accountName: "Mitt spar 25",
  amount: 100,
  success: true,
  details: "Udbetaling lykkedes",
};

export const mockGetWithdrawalDetailsResponse: IWithdrawalCalculationResponse = {
  accountId: "CV12345",
  accountNumber: "12345",
  accountName: "Mitt spar 25",
  availableBalance: 0,
  interestCarryingBalance: 0,
  amount: 0,
  valueDate: "",
  penaltyAmount: 0,
  allowed: true,
  details: "lykkedes",
};
