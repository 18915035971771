import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { PageType } from "netbank-shared/src/libs/models/Content/Enums";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import React, { createRef, RefObject, useEffect, useRef, useState } from "react";
import chevronRight from "~assets/chevron-right.svg";
import userIconFull from "~assets/user-full.svg";
import userIcon from "~assets/user-red.svg";
import { Button, DynamicLink, Image } from "..";
import { LangPicker } from "../LangPicker";
import styles from "./Menu.scss";

interface IMenuProps {
  page?: IPage;
  toggleOpen?: () => void;
  mainMenuRef: RefObject<HTMLDivElement>;
}

const renderMobileButtons = (
  page: IPage | undefined,
  logout: { (): any },
  profileRef: React.MutableRefObject<null>,
  logoutRef: React.MutableRefObject<null>,
  corporateContent: boolean,
) => {
  const customerProfileButtonTitle = page?.customerProfileButtonTitle;
  const onProfilePage = page?.personalSettingsPage === window?.location?.pathname;
  const logoutButtonText = page?.logoutLink?.[0]?.title || page?.logoutLink?.[0]?.text || tx("misc.logout");

  return (
    <>
      {page?.personalSettingsPage && (
        <DynamicLink
          ref={profileRef}
          to={page.personalSettingsPage}
          trackingAction={TrackingAction.MyProfile}
          trackingCategory={TrackingCategory.Header}
          className={styles.navItem}
          style={corporateContent ? {} : { marginTop: "20px" }}
        >
          <div className={styles.navItem}>
            <Image
              className={styles.icon}
              hoverReference={profileRef}
              wrapperStyle={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
              active={onProfilePage}
              image={
                {
                  name: tx("misc.customer"),
                  url: page.personalSettingsIcon?.url || userIcon,
                  altText: tx("misc.customer"),
                } as IImage
              }
              hoverImage={
                {
                  name: tx("misc.customer"),
                  url: page.personalSettingsIconActive?.url || userIconFull,
                  altText: tx("misc.customer"),
                } as IImage
              }
              activeImage={
                {
                  name: tx("misc.customer"),
                  url: page.personalSettingsIconActive?.url || userIconFull,
                  altText: tx("misc.customer"),
                } as IImage
              }
            />
            <span data-text={customerProfileButtonTitle}>{customerProfileButtonTitle}</span>
          </div>
        </DynamicLink>
      )}
      <Button onClick={() => logout()} ref={logoutRef} className={styles.navItem}>
        <div className={`${styles.navItem} ${styles.logoutButton}`}>
          {page?.logoutIcon && (
            <Image
              hoverReference={logoutRef}
              wrapperStyle={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
              className={styles.icon}
              image={page?.logoutIcon}
              hoverImage={page?.logoutIconActive}
            />
          )}
          <span data-text={logoutButtonText}>{logoutButtonText}</span>
        </div>
      </Button>
    </>
  );
};

export const Menu = observer(({ page, toggleOpen, mainMenuRef }: IMenuProps) => {
  const { uiStore, rootStore, messageStore, depositStore, contentStore } = useStores();
  const defaultClasses = [styles.mainMenu];
  const [menuState, setMenuState] = useState<{ [index: string]: boolean }>({});
  const [mainMenuClasses, setMainMenuClasses] = useState(defaultClasses);
  const corporateCustomerContent = contentStore.getIsCorporateCustomerContent();

  const filteredMenuItems = page?.menuItems?.filter((item) => {
    if (corporateCustomerContent) {
      switch (item.pageType) {
        case PageType.CardTransitPage:
        case PageType.DepositTransitPage:
        case PageType.LeasingTransitPage:
        case PageType.PrivateLoanTransitPage:
        case PageType.SecuredLoanTransitPage:
        case PageType.SalesFinanceTransitPage:
        case PageType.MessageInbox:
          return false;
        default:
          if (item.path === contentStore.currentPage?.transferPage) return false;
          return true;
      }
    }
    if (depositStore.hasCustomerProducts) return true;
    if (item.pageType === PageType.MessageInbox || item.path === contentStore.currentPage?.transferPage) {
      return false;
    }
    return true;
  });

  const menuItemRefs = useRef([]);
  if (filteredMenuItems?.length && menuItemRefs.current.length !== filteredMenuItems.length) {
    menuItemRefs.current = Array(filteredMenuItems.length)
      .fill(undefined)
      .map((_, i) => menuItemRefs.current[i] || createRef());
  }

  // Function used to add dropshadow to main menu if it is overflowing vertically for desktop
  const setMainMenuOverflowClass = () => {
    if (!mainMenuRef.current || uiStore.isMobile) return;
    if (mainMenuRef.current.offsetHeight < mainMenuRef.current.scrollHeight) {
      if (mainMenuClasses.indexOf(styles.separator) === -1)
        setMainMenuClasses(mainMenuClasses.concat(styles.separator));
    } else {
      setMainMenuClasses(defaultClasses);
    }
  };

  const openMenuItem = (id: string, force: boolean = false) => {
    const key = id.replaceAll("-", "");
    const updatedState = { ...menuState };
    updatedState[key] = force || !menuState[key];

    setMenuState(updatedState);
  };

  const getItemStatus = (id: string): boolean => {
    const key = id.replaceAll("-", "");

    return !!menuState[key];
  };

  useEffect(() => {
    setMainMenuOverflowClass();
  }, [page, uiStore.windowHeight]);

  useEffect(() => {
    const activeItems: string[] = [];
    setMenuState({});
    page?.menuItems?.forEach((parent) => {
      const childActive = parent.children?.findIndex((child) => child.active) >= 0;
      parent.children?.filter((child) => child.active)?.map((child) => activeItems.push(child.contentGuid));
      if (parent.active || childActive) activeItems.push(parent.contentGuid);
    });
    if (activeItems.length > 0) {
      activeItems.forEach((guid) => openMenuItem(guid, true));
    }
  }, [page]);

  const profileRef = useRef(null);
  const logoutRef = useRef(null);

  return (
    <div ref={mainMenuRef} className={mainMenuClasses.join(" ")}>
      {uiStore.isMobile && (
        <div className={styles.langPickerWrapper}>
          <LangPicker />
        </div>
      )}
      {filteredMenuItems?.map((item, i) => {
        const { hasChildren } = item;
        let activeClass = "";
        if (item.active) {
          activeClass = styles.active;
        }

        return (
          <React.Fragment key={item.contentGuid}>
            <div className={styles.navItemWrapper}>
              <DynamicLink
                to={item.path}
                trackingCategory={TrackingCategory.LeftMenu}
                trackingAction={item.trackingAction}
                onClick={() => {
                  openMenuItem(item.contentGuid, true);
                  if (toggleOpen) toggleOpen();
                }}
                className={styles.navItem}
                ref={menuItemRefs.current[i]}
              >
                <Image
                  hoverReference={menuItemRefs.current[i]}
                  wrapperStyle={{
                    width: "30px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                  active={item.active}
                  image={
                    {
                      name: item.name,
                      url: item.icon,
                      altText: item.name,
                    } as IImage
                  }
                  activeImage={
                    {
                      name: item.name,
                      url: item.iconActive,
                      altText: item.name,
                    } as IImage
                  }
                  hoverImage={
                    {
                      name: item.name,
                      url: item.iconActive,
                      altText: item.name,
                    } as IImage
                  }
                />
                <div className={activeClass}>
                  <span className={styles.itemName}>
                    {item.name}
                    {item.pageType === "MessageInboxPage" && messageStore.unreadAmount > 0 && (
                      <span className={styles.badgeWrapper}>
                        <span className={styles.badge}>
                          <span>{messageStore.unreadAmount}</span>
                        </span>
                      </span>
                    )}
                  </span>
                </div>
              </DynamicLink>
              {hasChildren && (
                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openMenuItem(item.contentGuid);
                  }}
                  className={styles.button}
                >
                  <img
                    className={[styles.expandButton, getItemStatus(item.contentGuid) ? styles.active : null].join(" ")}
                    src={chevronRight}
                    alt="expand"
                  />
                </Button>
              )}
            </div>
            {item?.children?.length > 0 && (
              <ul className={`${styles.submenu} ${getItemStatus(item.contentGuid) ? styles.open : ""}`}>
                {item.children.map((subItem) => (
                  <li key={subItem.name} className={subItem.active ? styles.active : ""}>
                    <DynamicLink
                      to={subItem.path}
                      key={subItem.contentGuid}
                      onClick={() => toggleOpen && toggleOpen()}
                      trackingCategory={TrackingCategory.LeftSubmenu}
                      trackingAction={subItem.trackingAction}
                    >
                      {subItem.name}
                    </DynamicLink>
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        );
      })}
      {uiStore.isMobile && renderMobileButtons(page, rootStore.logout, profileRef, logoutRef, corporateCustomerContent)}
    </div>
  );
});
