import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { mockTransferAccounts } from "./transfer";

export const MockTransferAccountApi = (axios: AxiosInstance) => {
  const mock = new MockAdapter(axios, {
    delayResponse: 2000,
  });

  const idRegexSegment = "[0-9]+";
  mock.onGet("").reply(200, mockTransferAccounts);
  mock.onPost("").reply((config) => {
    const data = JSON.parse(config.data);
    return [
      200,
      {
        clearingNumber: data.clearingNumber,
        accountNumber: data.accountNumber,
        displayName: data.displayName,
        bank: "Testbank",
        isAccountVerified: true,
      },
    ];
  });

  mock.onPut(new RegExp(idRegexSegment)).reply(204);

  mock.onDelete(`${new RegExp(`${idRegexSegment}`)}`).reply(204);
};
