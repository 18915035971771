import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { IMessageInboxPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { InboxTabs } from "netbank-shared/src/libs/models/Message/Inbox";
import { Button } from "~views/shared";
import { DynamicLink } from "~views/shared/DynamicLink/DynamicLink";
import styles from "./MessageInbox.scss";
import rootStyles from "../Root.scss";
import { ConversationRow } from "./ConversationRow";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import writeIcon from "~assets/write.svg";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { LoadingLottie } from "~views/shared/Lottie/LoadingLottie";

export const MessageInbox = observer(() => {
  const { contentStore, uiStore, messageStore } = useStores();
  const page = contentStore.currentPage as IMessageInboxPage;

  const [frontendIndex, setFrontendIndex] = useState<number>(messageStore.pageSize);
  const [fetchingMore, setFetchingMore] = useState(false);

  const hasNextPage = messageStore.conversations && messageStore.conversations.length > frontendIndex;

  const fetchMore = async () => {
    setFetchingMore(true);
    setFrontendIndex(frontendIndex + messageStore.pageSize);
    setFetchingMore(false);
  };

  const spinnerClasses = [rootStyles.spinner, styles.spinner];
  const messageClasses = [rootStyles.noBorder, rootStyles.accordion];

  if (uiStore.isMobile) {
    messageClasses.push(rootStyles.noPadding);
  }

  if (fetchingMore) {
    spinnerClasses.push(rootStyles.loading);
  }

  useEffect(() => {
    const fetchData = async () => {
      await messageStore.getConversations(!messageStore.conversations);
    };
    fetchData();
  }, []);

  const renderTabs = () => {
    return (
      <div className={styles.tabs}>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.Inbox ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.Inbox)}
        >
          <span className={styles.title}>{tx("message.inbox")}</span>
        </Button>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.Sent ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.Sent)}
        >
          <span className={styles.title}>{tx("message.sent")}</span>
        </Button>
        <Button
          className={`${styles.tab} ${messageStore.activeTab === InboxTabs.Archive ? styles.active : ""}`}
          onClick={() => messageStore.setActiveTab(InboxTabs.Archive)}
        >
          <span className={styles.title}>{tx("message.archive")}</span>
        </Button>
      </div>
    );
  };

  const getEmptyStateHeaderText = () => {
    switch (messageStore.activeTab) {
      case InboxTabs.Inbox:
        return page.inboxEmptyStateHeader || tx("message.inboxEmpty");
      case InboxTabs.Sent:
        return page.sentEmptyStateHeader || tx("message.sentEmpty");
      case InboxTabs.Archive:
        return page.archiveEmptyStateHeader || tx("message.archiveEmpty");
      default:
        return "";
    }
  };

  const getEmptyStateBodyContent = () => {
    switch (messageStore.activeTab) {
      case InboxTabs.Inbox:
        return page.inboxEmptyStateText;
      case InboxTabs.Sent:
        return page.sentEmptyStateText;
      case InboxTabs.Archive:
        return page.archiveEmptyStateText;
      default:
        return "";
    }
  };

  const showEmptyState =
    (!messageStore.conversations || messageStore.conversations.length === 0) && !messageStore.fetchingConversations;

  return (
    <>
      {page.pageDescription && (
        <section className={styles.pageDescription}>
          <HtmlContent html={page.pageDescription} />
        </section>
      )}
      <section className={messageClasses.join(" ")}>
        {uiStore.isSm && <div className={styles.outsideTabsContainer}>{renderTabs()}</div>}
        <div className={styles.inboxWrapper}>
          <div className={styles.inboxHeader}>
            {!uiStore.isSm && renderTabs()}
            {page?.newMessagePage && (
              <DynamicLink
                to={page?.newMessagePage}
                trackingCategory={TrackingCategory.Messages}
                trackingAction={TrackingAction.NewMessage}
                tabIndex={-1}
              >
                <Button
                  title={page.newMessageButtonText || tx("message.composeNewMessage")}
                  color="red"
                  fullWidth={uiStore.isSm}
                  iconPrefix={uiStore.isSm && writeIcon}
                  centered
                />
              </DynamicLink>
            )}
          </div>
          <div className={styles.conversationHeader}>
            <div className={styles.dateTitle}>{tx("misc.date")}</div>
            <div className={styles.conversationTitle}>{tx("misc.message")}</div>
            <div className={styles.offset} />
          </div>
          <div className={styles.conversations}>
            {showEmptyState && (
              <div className={styles.emptyInboxWrapper}>
                <span className={styles.emptyInboxHeader}>{getEmptyStateHeaderText()}</span>
                <HtmlContent className={styles.emptyInboxText} html={getEmptyStateBodyContent()} />
              </div>
            )}
            {messageStore.fetchingConversations && (
              <div className={styles.loadingRow}>
                <LoadingLottie />
              </div>
            )}
            {!messageStore.fetchingConversations &&
              messageStore.conversations
                ?.slice(0, frontendIndex)
                .map((conversation) => (
                  <ConversationRow
                    displayLastSent={messageStore.activeTab === InboxTabs.Sent}
                    data={conversation}
                    key={`${conversation.conversationId}`}
                  />
                ))}
            {hasNextPage && (
              <div onClick={fetchMore} className={[rootStyles.loadMoreRow, styles.loadMoreWrapper].join(" ")}>
                <span>{page.loadMoreButtonText || tx("message.loadMore")}</span>
                <span className={spinnerClasses.join(" ")} />
              </div>
            )}
          </div>
        </div>
      </section>
      <ContentArea />
    </>
  );
});
