import { IOptionItem, IPersonalPreference } from "libs/models/Customer/Customer";
import { v4 as uuidv4 } from "uuid";

export const addIDsToPreferences = (preferences?: IPersonalPreference[]) => {
  const preferencesWithIds: IPersonalPreference[] = [];
  preferences?.forEach((preference) => {
    const preferenceWithId = { ...preference, id: uuidv4() };
    const optionsWithIds: IOptionItem[] = [];
    preferenceWithId.optionList.forEach((option) => {
      const optionWithId = { ...option, id: uuidv4() };
      optionsWithIds.push(optionWithId);
    });
    preferenceWithId.optionList = optionsWithIds;
    preferencesWithIds.push(preferenceWithId);
  });
  return preferencesWithIds;
};
