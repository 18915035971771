import { observer } from "mobx-react";
import React, { ReactNode, useEffect } from "react";
import styles from "./AccountActionContainer.scss";
import { HtmlContent } from "~views/shared";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";

interface IAccountActionContainerProps {
  info: {
    title: string;
    text: string;
    accountInfo?: {
      label: string;
      value: string;
    }[];
  };
  children?: ReactNode;
  state?: SelfServiceSubmissionState;
  isPage?: boolean;
}

export const AccountActionContainer = observer(
  ({ children, info, state, isPage = true }: IAccountActionContainerProps) => {
    const classes = [styles.wrapper];

    if (isPage) {
      classes.push(styles.pageContent);
    }

    useEffect(() => {
      if (isPage && state !== SelfServiceSubmissionState.NotSubmitted) window.scrollTo(0, 0);
    }, [state]);

    return (
      <section>
        <div className={classes.join(" ")}>
          <div className={styles.headerContainer}>
            {info.accountInfo && info.accountInfo.length > 0 && (
              <div className={styles.accountInfoContainer}>
                {info.accountInfo.map((i, ind) => {
                  return (
                    <div key={`accountInfoRow-${ind}`}>
                      <span style={{ fontWeight: "bold" }}>{`${i.label}: `}</span> {i.value}
                    </div>
                  );
                })}
              </div>
            )}
            <div>
              <h1>{info.title}</h1>
              {info.text && <HtmlContent html={info.text} />}
            </div>
          </div>
          {children}
        </div>
      </section>
    );
  },
);
