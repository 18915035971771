import { ILink } from "../models/Content/LinkItem";
import { tx } from "../i18n";
import { UrlType } from "../models/Content/Enums";
import { Buffer } from "buffer";

export const boolToString = (value: boolean) => (value ? tx("misc.yes") : tx("misc.no"));

// Warning: This method will add bytes to the resulting file objects. I.e. the output has more bytes than input base64 string.
export const base64ToBlob = (base64: string, mimeType: string = "application/pdf") => {
  const byteBuffer = Buffer.from(base64, "base64");
  const blob = new Blob([byteBuffer], { type: mimeType });
  return blob;
};

// Warning: This method will add bytes to the resulting file objects. I.e. the output has more bytes than input base64 string.
export const base64ToFile = async (base64: string, fileName: string, mimeType: string = "application/pdf") => {
  const byteBuffer = Buffer.from(base64, "base64");
  const file = new File([byteBuffer], fileName, { type: `${mimeType}` });
  return file;
};

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      const readerData = reader.result;

      if (typeof readerData !== "string") {
        return reject(new Error("FileReader result had a wrong type. Return type should be a string."));
      }
      // Remove preceding Data-URL declaration, leaving only the base64 string
      const base64Data = readerData.substring(readerData.indexOf(",") + 1);
      return resolve(base64Data);
    };
    reader.readAsDataURL(blob);
  });
};

export const isBase64File = (value: string | undefined) => value?.match(/data:([a-zA-Z/]*);base64,([^"]*)/g);

const extractFileNameWithExtension = (url: string) => {
  return url
    .substr(1 + url.lastIndexOf("/"))
    .split("?")[0]
    .split("#")[0];
};

export const getUrlType = (url: string): UrlType => {
  if (url.indexOf("mailto:") === 0) {
    return UrlType.MailTo;
  }
  if (url.indexOf("tel:") === 0) {
    return UrlType.Tel;
  }

  const fileNameWithExtension = extractFileNameWithExtension(url);

  if (fileNameWithExtension.indexOf(".") > -1) {
    return UrlType.File;
  }

  if (url.indexOf("/") === 0) {
    return UrlType.Internal;
  }

  return UrlType.External;
};

export const parseNumber = (value: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  return parseFloat(value.replace(/ /g, "").replace(",", "."));
};

export const parseBool = (value: string) => {
  return value === "true";
};

export const bytesToSize = (bytes: number) => {
  const sizes = ["bytes", "kB", "MB"];

  if (bytes === 0) return "n/a";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
};

export const getFileSizeFromUrl = (url: string) => {
  let fileSize = "";
  const http = new XMLHttpRequest();
  http.open("HEAD", url, false);

  http.send(null);

  if (http.status === 200) {
    const contentLength = http.getResponseHeader("content-length");
    fileSize = contentLength ? bytesToSize(parseInt(contentLength)) : "";
  }

  return fileSize;
};

export const isUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  );
  return !!pattern.test(str);
};

export const createAutoDownloadTempLink = (
  fileName: string,
  objectUrl: string,
  autoDownload: boolean,
  inModal: boolean = false,
) => {
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = objectUrl;

  if (autoDownload) {
    tempLink.setAttribute("download", fileName);
  } else {
    tempLink.setAttribute("target", "_blank");
  }

  if (inModal) {
    document.getElementById("modal")?.appendChild(tempLink);
  } else {
    document.body.appendChild(tempLink);
  }

  tempLink.click();
  setTimeout(() => {
    if (inModal) {
      document.getElementById("modal")?.removeChild(tempLink);
    } else {
      document.body.removeChild(tempLink);
    }
    URL.revokeObjectURL(objectUrl);
  }, 200);
};

export const getQueryParam = (queryString: string, key: string): string => {
  const query = queryString.substring(1);
  const queryParam = query
    .split("&")
    ?.map((elem) => elem.split("="))
    .find((queryPair) => {
      return decodeURIComponent(queryPair[0]) === key;
    });
  return queryParam ? decodeURIComponent(queryParam[1]) : "";
};

/**
 * Replace "%7b" with "{" and "%7d" with "}". Useful for template strings in URL.
 * @param html String to have encoded brackets decoded
 */
export const decodeBrackets = (str: string) => {
  const strWithBrackets = str.replace(/%7b/g, "{").replace(/%7d/g, "}");
  return strWithBrackets;
};

/**
 * Convert string of whitespace-separated words to camelCase
 * @param str String to be converted to camelCase
 */
export function whitespaceToCamelCase(str: string) {
  const camelString = str
    .split(" ")
    .map(function (word: string, index: number) {
      // lowerCase first word
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
  return camelString;
}
export const createArrayFromInterval = (start: number, end: number, step: number = 1) =>
  Array.from({ length: (end - start) / step + 1 }, (_, index) => start + index * step);

/**
 * Make a deep clone of an object
 * @param obj Valid JSON to be deeply cloned
 * @return Deep clone of obj if JSON parses successfully, otherwise returns undefined
 */
export function deepClone<T>(obj: any): T | undefined {
  try {
    return JSON.parse(JSON.stringify(obj)) as T;
  } catch (e) {
    return undefined;
  }
}

export const cleanAndParseInt = (value: string) => {
  if (!value) return undefined;
  return parseInt(value.replace(/[\s,.]/g, "")) || undefined;
};

export const arrayCompare = (a: any[] | undefined, b: any[] | undefined) => {
  if (!a || !b) return false;
  return a.length === b.length && a.every((value) => b.includes(value));
};

/**
 * Add spaces between touching words in strings, e.g. MyString -> My String
 * @param str Any string, e.g. MyString
 * @return String with a space between each word
 */
export const AddSpaceBetweenWords = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const parseURL = (link: string) => {
  try {
    return new URL(link);
  } catch {
    return undefined;
  }
};

export const generateLinkWithParams = (
  link: ILink | undefined,
  params: { [key: string]: string },
): ILink | undefined => {
  if (!link) return undefined;
  const parsedLink = parseURL(link.href);
  if (!parsedLink) return undefined;
  Object.entries(params).forEach(([key, value]) => parsedLink.searchParams.append(key, value));

  return { ...link, href: parsedLink.href } as ILink;
};
