import { tx } from "../libs/i18n";
import { IDepositBaseAccount } from "libs/models/CustomerProducts";
import { action, computed, makeObservable, observable } from "mobx";
import { DepositApi } from "../libs/api";
import { TransferAccountApi } from "../libs/api/TransferAccountApi";
import {
  ICreateTransferResponse,
  ICreateWithdrawalResponse,
  IGetAccountRedemptionResponse,
  IToAccountFields,
  ITransferAccount,
  ITransferAccountFields,
  ITransferCalculationResponse,
  IWithdrawalCalculationResponse,
} from "../libs/models/Transfer/Transfer";
import { parseNumber } from "../libs/utils";
import { DepositStore } from "./DepositStore";
import { Store } from "./Store";
import { AccountRedemptionStatus } from "../libs/models/Content/Enums";

export class TransferStore {
  rootStore: Store;

  transferAccountApi: TransferAccountApi;

  depositApi: DepositApi;

  depositStore: DepositStore;

  constructor(
    rootStore: Store,
    transferAccountApi: TransferAccountApi,
    depositApi: DepositApi,
    depositStore: DepositStore,
  ) {
    this.rootStore = rootStore;
    this.transferAccountApi = transferAccountApi;
    this.depositApi = depositApi;
    this.depositStore = depositStore;
    makeObservable(this);
  }

  initialAccountState: ITransferAccountFields = {
    clearingNumber: "",
    displayName: "",
    accountNumber: "",
  };

  @observable
  loading: boolean = false;

  @observable
  deletingAccount: boolean = false;

  @observable
  creatingSavedAccount: boolean = false;

  @observable
  updatingSavedAccount: boolean = false;

  @observable
  fetchingSavedAccounts: boolean = false;

  @observable
  duplicateSavedAccountError: boolean = false;

  @observable
  transferDetailsApiError: boolean = false;

  @observable
  validatingAccountRedemption: boolean = false;

  @observable
  loadingAccountRedemption: boolean = false;

  @observable
  fromAccount?: IDepositBaseAccount | undefined;

  @observable
  toAccount: IToAccountFields | undefined;

  @observable
  amount: number | string = 0;

  @observable
  savedAccounts: ITransferAccount[] | undefined = undefined;

  @observable
  newAccount: ITransferAccountFields = this.initialAccountState;

  @observable
  updateAccount: ITransferAccountFields | undefined = undefined;

  @observable
  withdrawalCalculationStatus?: number = undefined;

  @observable
  transferCalculationStatus?: number = undefined;

  @observable
  transferStatus?: number = undefined;

  @observable
  requiresSigning: boolean = true;

  @observable
  autoStartToken: string = "";

  @observable
  statusChecker?: NodeJS.Timeout;

  @observable
  errors: { for: string; error: string }[] = [];

  @observable
  accountRedemptionData?: IGetAccountRedemptionResponse;

  @observable
  accountRedemptionStatusCode?: number;

  @observable
  accountRedemptionStatus: AccountRedemptionStatus = AccountRedemptionStatus.Unknown;

  @observable
  possibleOwnToAccounts: IDepositBaseAccount[] = [];

  @observable
  withdrawResponseData: ICreateWithdrawalResponse | null = null;

  @observable
  transferResponseData: ICreateTransferResponse | null = null;

  @observable
  withdrawalCalculationResponseData: IWithdrawalCalculationResponse | null = null;

  @observable
  transferCalculationResponseData: ITransferCalculationResponse | null = null;

  @computed
  get amountValue(): number {
    return typeof this.amount === "string" ? parseNumber(this.amount) : this.amount;
  }

  @action
  setFromAccount = (value: string) => {
    this.fromAccount = this.depositStore.depositCustomerProducts?.find(
      (account) => account.accountId === value || account.displayNumber === value,
    );

    if (this.fromAccount?.accountId === this.toAccount?.accountNumber) {
      this.toAccount = undefined;
    }
    this.possibleOwnToAccounts = [];
    const internalTransferAccounts = this.fromAccount?.internalTransferAccounts;
    internalTransferAccounts?.forEach((acc) => {
      const matchingCustomerProductsAccount = this.depositStore.depositCustomerProducts.find(
        (customerProductsAccount) => customerProductsAccount.accountId === acc.accountId,
      );
      if (
        matchingCustomerProductsAccount &&
        matchingCustomerProductsAccount.isActive &&
        !matchingCustomerProductsAccount.closesAt
      ) {
        this.possibleOwnToAccounts.push(matchingCustomerProductsAccount);
      }
    });

    this.transferCalculationResponseData = null;
    this.withdrawalCalculationResponseData = null;
    this.transferDetailsApiError = false;

    return this.fromAccount;
  };

  @action
  setToAccount = ({
    value,
    accountId,
    isNationalPayoutAccount,
  }: {
    value?: string | undefined;
    accountId?: string;
    isNationalPayoutAccount?: boolean;
  }) => {
    if (!value) {
      this.toAccount = undefined;
    }
    const toSavedAccount = this.savedAccounts?.find((account) => account.displayName === value);

    if (toSavedAccount) {
      this.toAccount = toSavedAccount;
      return;
    }

    if (!accountId) {
      this.toAccount = undefined;
    }

    const toOwnAccount = this.depositStore.depositCustomerProducts?.find((account) => account.accountId === accountId);

    if (toOwnAccount) {
      this.toAccount = {
        accountNumber: toOwnAccount.accountId,
        displayName: toOwnAccount.nickname || toOwnAccount.name,
        displayNumber: toOwnAccount.displayNumber,
        isFixedTerm: toOwnAccount.isFixedTerm,
        availableBalance: toOwnAccount.availableBalance,
      };
    }

    const nationalPayoutAccountName = tx("withdrawal.nationalPayoutAccount");

    if (isNationalPayoutAccount) {
      this.toAccount = {
        accountNumber: nationalPayoutAccountName,
        displayName: tx("withdrawal.withdrawal"),
        displayNumber: nationalPayoutAccountName,
        isNationalPayoutAccount: true,
      };
    }

    this.transferCalculationResponseData = null;
    this.withdrawalCalculationResponseData = null;
    this.transferDetailsApiError = false;
  };

  @action
  setAmount = (value: number | string) => {
    this.amount = value;
  };

  @action
  setNewAccount = (account?: ITransferAccountFields) => {
    this.newAccount = account || this.initialAccountState;
    this.duplicateSavedAccountError = false;
  };

  @action
  setUpdateAccount = (account?: ITransferAccountFields) => {
    this.updateAccount = account;
  };

  @action
  setWithdrawalCalculationStatus = (status?: number) => {
    this.withdrawalCalculationStatus = status;
  };

  @action
  setTransferCalculationStatus = (status?: number) => {
    this.transferCalculationStatus = status;
  };

  @action
  setTransferStatus = (status?: number) => {
    this.transferStatus = status;
  };

  @action
  setAccountRedemptionStatusCode = (status?: number) => {
    this.accountRedemptionStatusCode = status;
  };

  @action
  cancelTransaction = () => {
    this.setTransferStatus(undefined);
    this.setAccountRedemptionStatusCode(undefined);
    this.autoStartToken = "";
    this.loading = false;
    this.loadingAccountRedemption = false;
  };

  @action
  resetTransaction = (removeBankIdHash = false) => {
    this.setTransferStatus(undefined);
    this.setFromAccount("");
    this.setToAccount({});
    this.setAmount(0);
    if (removeBankIdHash) {
      this.rootStore.removeBankIdHash();
    }
  };

  @action
  resetAccountRedemption = () => {
    this.accountRedemptionData = undefined;
    this.accountRedemptionStatusCode = undefined;
    this.loadingAccountRedemption = false;
    this.validatingAccountRedemption = false;
    this.toAccount = undefined;
  };

  // API calls

  @action
  getSavedAccounts = async () => {
    if (!this.rootStore.customerStore.currentCustomer?.capabilities.canHandleTransferAccounts) return null;
    this.fetchingSavedAccounts = true;
    const response = await this.transferAccountApi.getTransferAccounts();
    if (response?.ok && response.data?.accounts) {
      this.savedAccounts = response.data.accounts;
      this.fetchingSavedAccounts = false;
      return response.data.accounts;
    }
    this.savedAccounts = undefined;
    this.fetchingSavedAccounts = false;
    return null;
  };

  @action
  createSavedAccount = async (setToAccount = false) => {
    if (!this.newAccount) return false;
    this.creatingSavedAccount = true;
    if (
      this.savedAccounts &&
      this.savedAccounts.findIndex(
        (a) => a.clearingNumber === this.newAccount.clearingNumber && a.accountNumber === this.newAccount.accountNumber,
      ) !== -1
    ) {
      this.duplicateSavedAccountError = true;
      this.creatingSavedAccount = false;
      return false;
    }
    const response = await this.transferAccountApi.createTransferAccount(
      this.newAccount.clearingNumber,
      this.newAccount.accountNumber,
      this.newAccount.displayName,
    );
    if (response?.ok) {
      await this.getSavedAccounts();
      if (setToAccount) {
        this.setToAccount({ value: this.newAccount.displayName });
      }
      this.setNewAccount(undefined);
    }
    this.creatingSavedAccount = false;
    return true;
  };

  @action
  updateSavedAccount = async (accountToUpdate: ITransferAccount) => {
    if (!this.updateAccount) return;
    this.updatingSavedAccount = true;
    const { clearingNumber, accountNumber, displayName } = this.updateAccount;
    const response = await this.transferAccountApi.updateTransferAccount(
      accountNumber,
      clearingNumber,
      accountNumber,
      displayName,
    );
    if (response?.ok) {
      this.savedAccounts = this.savedAccounts?.map((account) =>
        account.clearingNumber === accountToUpdate.clearingNumber &&
        account.accountNumber === accountToUpdate.accountNumber
          ? { ...account, clearingNumber, accountNumber, displayName }
          : account,
      );
      this.setUpdateAccount(undefined);
    }
    this.updatingSavedAccount = false;
  };

  @action
  deleteSavedAccount = async (account: ITransferAccount) => {
    this.deletingAccount = true;
    const response = await this.transferAccountApi.deleteTransferAccount(
      account.accountNumber,
      account.clearingNumber,
      account.accountNumber,
      account.displayName,
    );
    if (response?.ok) {
      if (
        this.toAccount?.clearingNumber === account.clearingNumber &&
        this.toAccount.accountNumber === account.accountNumber
      ) {
        this.setToAccount({});
      }
      this.savedAccounts = this.savedAccounts?.filter(
        (a) => !(a.clearingNumber === account.clearingNumber && a.accountNumber === account.accountNumber),
      );
    }
    this.deletingAccount = false;
  };

  @action
  updateCustomerProducts = async () => {
    await this.rootStore.getCustomerProducts();
  };

  // eslint-disable-next-line consistent-return
  getTransferCalculationDetails = async () => {
    this.loading = true;
    this.transferDetailsApiError = false;

    if (this.fromAccount && this.toAccount) {
      const fromAccountId = this.fromAccount.accountId;
      const toAccountId = this.toAccount?.accountNumber;

      if (this.toAccount.isNationalPayoutAccount) {
        const withdrawalDetails = await this.getWithdrawalCalculationDetails(
          this.fromAccount.accountId,
          this.amountValue,
        );
        return withdrawalDetails;
      }

      const response = await this.depositApi.getTransferDetails(fromAccountId, toAccountId, this.amountValue);

      if (response?.ok && response.data) {
        this.transferCalculationResponseData = response.data;
      } else {
        this.transferDetailsApiError = true;
      }

      this.setTransferCalculationStatus(response?.status);
      this.loading = false;
      return response?.data;
    }
  };

  getWithdrawalCalculationDetails = async (fromAccountId: string, amount: number) => {
    const response = await this.depositApi.getWithdrawalDetails(fromAccountId, amount);

    if (response?.ok && response.data) {
      this.withdrawalCalculationResponseData = response.data;
    } else {
      this.transferDetailsApiError = true;
    }

    this.withdrawalCalculationStatus = response?.status;
    this.loading = false;
    return response?.data;
  };

  @computed
  get parsedCalculationDetailsResponse() {
    if (!this.transferCalculationResponseData && !this.withdrawalCalculationResponseData) return;

    const fromAccountAvailableBalance =
      this.transferCalculationResponseData?.fromAccountAvailableBalance ??
      this.withdrawalCalculationResponseData?.availableBalance;
    const amount = this.transferCalculationResponseData?.amount ?? this.withdrawalCalculationResponseData?.amount;
    const toAccountAvailableBalance = this.transferCalculationResponseData?.toAccountAvailableBalance;
    const penaltyAmount =
      this.transferCalculationResponseData?.penaltyAmount ?? this.withdrawalCalculationResponseData?.penaltyAmount;
    const valueDate =
      this.transferCalculationResponseData?.valueDate ?? this.withdrawalCalculationResponseData?.valueDate;
    const requireManualHandling =
      toAccountAvailableBalance && penaltyAmount && toAccountAvailableBalance + (amount || 0) < penaltyAmount; // TODO: Move logic to API
    const allowed =
      !requireManualHandling &&
      (this.transferCalculationResponseData?.allowed ?? this.withdrawalCalculationResponseData?.allowed);

    // eslint-disable-next-line consistent-return
    return {
      allowed,
      fromAccountAvailableBalance,
      toAccountAvailableBalance,
      amount,
      penaltyAmount,
      valueDate,
      requireManualHandling,
    };
  }

  @computed
  get forbiddenTransferError() {
    return this.parsedCalculationDetailsResponse?.allowed === false;
  }

  clearTransferCalculation = () => {
    this.withdrawalCalculationResponseData = null;
    this.withdrawalCalculationStatus = undefined;
    this.transferCalculationResponseData = null;
    this.transferCalculationStatus = undefined;
  };

  createTransfer = async () => {
    this.loading = true;

    if (this.fromAccount && this.toAccount) {
      const fromAccountId = this.fromAccount.accountId;
      const toAccountId = this.toAccount?.accountNumber;

      if (this.toAccount.isNationalPayoutAccount) {
        this.createWithdrawal(this.fromAccount.accountId, this.amountValue);
        return;
      }

      const response = await this.depositApi.createTransfer(fromAccountId, toAccountId, this.amountValue);
      if (response?.ok && response.data) {
        this.transferResponseData = response.data;
        await this.rootStore.getCustomerProducts();
      }
      this.setTransferStatus(response?.status);
      this.loading = false;
    }
  };

  createWithdrawal = async (fromAccountId: string, amount: number) => {
    const response = await this.depositApi.createWithdrawal(fromAccountId, amount);

    if (response?.ok && response.data) {
      this.withdrawResponseData = response.data;
      await this.rootStore.getCustomerProducts();
    }

    this.transferStatus = response?.status;
    this.loading = false;
  };

  validateAccountRedemption = async () => {
    this.accountRedemptionData = undefined;
    this.setAccountRedemptionStatusCode(undefined);

    if (!this.rootStore.depositStore.currentAccountId) return;
    this.validatingAccountRedemption = true;

    const response = await this.depositApi.getAccountRedemption(this.rootStore.depositStore.currentAccountId);

    if (response?.ok && response.data) {
      this.accountRedemptionData = response.data;
      this.accountRedemptionStatus = AccountRedemptionStatus.Redeemable;
    } else {
      this.accountRedemptionStatus = AccountRedemptionStatus.NoRedemptionInfo;
    }
  };

  createAccountRedemption = async () => {
    if (!this.rootStore.depositStore.currentAccountId || this.toAccount === undefined) return;

    this.loadingAccountRedemption = true;
    const response = await this.depositApi.createAccountRedemption(this.rootStore.depositStore.currentAccountId);
    if (response?.ok) {
      this.setAccountRedemptionStatusCode(response?.status);
      this.accountRedemptionStatus = AccountRedemptionStatus.RedemptionSuccessful;
    } else {
      this.accountRedemptionStatus = AccountRedemptionStatus.RedemptionFailed;
    }
    this.accountRedemptionStatusCode = response?.status;
    this.loadingAccountRedemption = false;
  };

  @action
  resetSavedAccounts = () => {
    this.deletingAccount = false;
    this.creatingSavedAccount = false;
    this.updatingSavedAccount = false;
    this.fetchingSavedAccounts = false;
    this.duplicateSavedAccountError = false;
  };

  @action
  resetStore = () => {
    this.resetTransaction();
    this.resetAccountRedemption();
    this.resetSavedAccounts();
    this.clearTransferCalculation();
    this.loading = false;
    this.savedAccounts = undefined;
    this.newAccount = this.initialAccountState;
    this.updateAccount = undefined;
    this.requiresSigning = true;
    this.autoStartToken = "";
    this.statusChecker = undefined;
    this.errors = [];
    this.possibleOwnToAccounts = [];
    this.transferDetailsApiError = false;
  };
}
