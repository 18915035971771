import React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { templateString } from "netbank-shared/src/libs/utils";

interface IExpandableTextProps {
  html: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disableTemplating?: boolean;
  queryParamLink?: {};
}

export const HtmlContent = observer(
  ({ className, html, onClick, disableTemplating = false, queryParamLink }: IExpandableTextProps) => {
    const navigate = useNavigate();
    const { rootStore } = useStores();

    const finalHtml = disableTemplating ? html : templateString(html, { ...rootStore.templateData, ...queryParamLink });

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const targetElement: any = e.target;
      const link: string = targetElement.attributes?.href?.value;
      if (!link) return;
      e.preventDefault();

      if (onClick) onClick(e);

      if (link.startsWith("/")) {
        navigate(link);
      } else if (targetElement.attributes?.target?.value === "_blank") {
        window.open(link);
      } else if (link.startsWith("#")) {
        const element = document.getElementById(link.substring(1));
        if (element) {
          const headerOffset = 82;
          const y = element.getBoundingClientRect().top + window.pageYOffset - headerOffset;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      } else {
        window.location.href = link;
      }
    };

    return <div className={className} dangerouslySetInnerHTML={{ __html: finalHtml }} onClick={handleClick} />;
  },
);
