import { useEffect } from "react";

interface IUseFetchData {
  callback?: () => any;
  cleanup?: (callbackResult?: any) => void;
  condition?: boolean;
  deps?: any[];
}
export const useFetchData = ({ condition = true, callback, cleanup, deps = [] }: IUseFetchData) => {
  let callbackResult: any;

  useEffect(() => {
    const asyncFunction = async () => {
      if (condition && callback) {
        callbackResult = await callback();
      }
    };
    asyncFunction();
    return () => {
      cleanup?.(callbackResult);
    };
  }, deps);
};
