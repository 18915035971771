import { ICustomer } from "libs/models/Customer/Customer";
import React, { useEffect, useState } from "react";
import { useStores } from "../hooks";

export interface IProps {}

export function authorize<Props extends IProps>(Component: (props: Props) => JSX.Element) {
  const AuthorizedComponent = (props: Props) => {
    const { customerStore, uiStore, rootStore, securePersistentStore, complianceStore } = useStores();
    const [me, setMe] = useState<ICustomer | null>();
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
      const fetchCustomer = async () => {
        if (securePersistentStore.accessToken) {
          setLoginLoading(true);
          const customer = customerStore.currentCustomer || (await customerStore.getCustomer());
          if (customer) {
            const products = customerStore.getCustomerProducts();
            const complianceStatus = complianceStore.getStatus();
            await Promise.all([products, complianceStatus]);
            setLoginLoading(false);
            setMe(customer);
          } else {
            rootStore.logout(true);
          }
        } else {
          rootStore.logout(true);
        }
      };

      if (customerStore.currentCustomer && rootStore.allCustomerProducts !== undefined) {
        setMe(customerStore.currentCustomer);
        return;
      }
      uiStore.setLoading(true);
      fetchCustomer();
    }, []);

    if (loginLoading || typeof me === "undefined") {
      return null;
    }

    return <Component {...props} />;
  };

  return AuthorizedComponent;
}
