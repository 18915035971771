import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { INewMessagePage } from "netbank-shared/src/libs/models/Content/Page";
import {
  AllowedAttachmentAmountSetting,
  Colors,
  ConversationEmptyState,
  Product,
} from "netbank-shared/src/libs/models/Content/Enums";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "./NewConversation.scss";
import rootStyles from "../../Root.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { FaqBlock } from "~views/shared/ContentArea/Blocks/FaqBlock";
import { MessageEmptyState } from "./MessageEmptyState";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import {
  getFilteredFaqs,
  getAccountDropdownList,
  getSubjectItemsFromProduct,
  mapAreasToListItems,
  mapSubjectsToListItems,
  getAreaForLang,
  getSubjectForLang,
} from "netbank-shared/src/libs/utils";
import { getQueryParam } from "~utils/misc";
import { useForm } from "react-hook-form";
import {
  generateNewConversationFormSchema,
  INewConversationProductData,
  INewConversationForm,
} from "netbank-shared/src/libs/forms/NewConversationForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { DropdownFormField, FileAttachmentFormField, FormWrapper, TextAreaFormField } from "~views/shared/Forms";
import { ISubjectDropdownItem } from "netbank-shared/src/libs/models/Content/NewMessageDropdownItem";
import { ListItem, MessageProductListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";

export const NewConversation = observer(() => {
  const { messageStore, contentStore, uiStore, rootStore } = useStores();
  const page = contentStore.currentPage as INewMessagePage;
  const navigate = useNavigate();

  const [subjectList, setSubjectList] = React.useState<ISubjectDropdownItem[]>([]);
  const [subjectDropdownList, setSubjectDropdownList] = React.useState<ListItem[]>([]);
  const [areaDropdownList, setAreaDropdownList] = React.useState<ListItem[]>([]);
  const [showTextarea, setShowTextarea] = React.useState<boolean>();
  const [showFileAttachment, setShowFileAttachment] = React.useState<boolean>();
  const [isSendable, setIsSendable] = React.useState<boolean>();
  const [faqList, setFaqList] = React.useState<IGenericContentLink<IFAQItem>[]>([]);

  const { accounts } = rootStore;

  const products = page?.productDropdown;

  const noProduct = products.find((p) => p.product === Product.NoProduct);

  const accountList = getAccountDropdownList(accounts, products);

  if (noProduct) {
    const noProductItem: MessageProductListItem = {
      value: Product.NoProduct,
      displayValue: noProduct.text || tx("message.general"),
      label: noProduct.text || tx("message.general"),
      productName: Product.NoProduct,
    };

    accountList.unshift(noProductItem);
  }

  const getValidQueryParams = () => {
    const accountIdOrProductFromQueryParam =
      getQueryParam(window.location.search, "accountNumber") || getQueryParam(window.location.search, "product");
    const subjectFromQueryParam = getQueryParam(window.location.search, "subject", false);

    const accountItem = accountList.find((a) => a.value === accountIdOrProductFromQueryParam);
    const product = accountItem && products.find((p) => p.product === accountItem.productName);
    const subjects = (product && getSubjectItemsFromProduct(product, uiStore.lang)) || [];

    const subjectItem = subjects.find((s) => s.value.toLowerCase() === subjectFromQueryParam.toLowerCase());

    return {
      accountItem,
      subjectItem,
    };
  };

  const onCancel = () => {
    navigate(page?.messageInboxPage ?? `/${tx("routing.lang")}`);
  };

  const selectAccountText = page.selectAccountText || tx("message.selectAccountText");
  const selectSubjectText = page.selectSubjectText || tx("message.selectSubjectText");
  const selectAreaText = page.selectAreaText || tx("message.selectAreaText");

  const faqContent = page.faqBlocks || [];

  const onSubmit = async (formValues: INewConversationForm) => {
    await messageStore.createConversation(formValues);

    window.scrollTo(0, 0);
  };

  const defaultValues: INewConversationForm = {
    productData: undefined,
    body: "",
    subject: undefined,
    area: undefined,
    files: undefined,
  };

  const methods = useForm<INewConversationForm>({
    resolver: yupResolver(generateNewConversationFormSchema({ content: page, products, lang: uiStore.lang })),
    defaultValues,
  });

  const values = methods.watch();

  // Setting values from URL-query params here instead of in defaultValues. Reason being that
  // the reset function in the form fields should reset to empty values and not the query-param values.
  React.useEffect(() => {
    const validQueryParams = getValidQueryParams();
    methods.setValue(
      "productData",
      validQueryParams.accountItem && {
        accountId: validQueryParams.accountItem.value,
        productName: validQueryParams.accountItem.productName,
      },
    );
    methods.setValue("subject", validQueryParams.subjectItem?.value);
  }, []);

  React.useEffect(() => {
    const productData = products.find((p) => p.product === values.productData?.productName);

    setSubjectList(productData?.subjectDropdown || []);
    setSubjectDropdownList(mapSubjectsToListItems(productData?.subjectDropdown || [], uiStore.lang));
  }, [values.productData]);

  React.useEffect(() => {
    const subjectData = subjectList.find((data) => {
      return getSubjectForLang(data, uiStore.lang) === values.subject;
    });
    setAreaDropdownList(mapAreasToListItems(subjectData?.areaDropdown || [], uiStore.lang));
  }, [values.subject, subjectList]);

  React.useEffect(() => {
    const product = products.find((p) => p.product === values.productData?.productName);
    const subject = product?.subjectDropdown?.find((s) => getSubjectForLang(s, uiStore.lang) === values.subject);
    const area = subject?.areaDropdown?.find((a) => getAreaForLang(a, uiStore.lang) === values.area);

    const allowedAttachmentAmountSetting =
      area?.allowedAttachmentAmountSetting || subject?.allowedAttachmentAmountSetting;

    setFaqList(getFilteredFaqs(faqContent, values.productData?.productName, values.subject, values.area, uiStore.lang));
    setShowTextarea(product?.showTextarea || subject?.showTextarea || area?.showTextarea);
    setIsSendable(product?.sendable || subject?.sendable || area?.sendable);

    const shouldShowFileAttachment =
      allowedAttachmentAmountSetting && allowedAttachmentAmountSetting !== AllowedAttachmentAmountSetting.None;

    if (!shouldShowFileAttachment) {
      // If attachment-field is not reset, it might keep values in state even though
      // the component doesn't show any added attachments
      methods.resetField("files");
    }

    setShowFileAttachment(shouldShowFileAttachment);
  }, [values.productData, values.subject, values.area]);

  switch (messageStore.newConversationState) {
    case ConversationEmptyState.CreateConversationSuccess:
      return <MessageEmptyState data={page.happyCaseEmptyState} />;
    case ConversationEmptyState.CreateConversationFailed:
      return <MessageEmptyState data={page.errorCaseEmptyState} />;
    default:
      return (
        <>
          {page.pageDescription && (
            <section className={styles.pageDescription}>
              <HtmlContent html={page.pageDescription} />
            </section>
          )}
          <section className={uiStore.isMobile ? rootStyles.white : ""}>
            <div className={styles.newMessageWrapper}>
              <FormWrapper
                formMethods={methods}
                onSubmit={onSubmit}
                submitLabel={page.sendMessageText || tx("misc.send")}
                cancelAction={onCancel}
                cancelLabel={page.cancelText || tx("misc.cancel")}
                hideSubmit={!isSendable}
                onSubmitTrackingCategory={TrackingCategory.Messages}
                onSubmitTrackingAction={TrackingAction.MessageCTA}
              >
                <div className={styles.dropdownGrid}>
                  <div>
                    <DropdownFormField
                      name="productData"
                      label={selectAccountText}
                      list={accountList}
                      valueResetList={["subject", "area"]}
                      placeholder={selectAccountText}
                      transform={{
                        input: (data: INewConversationProductData) => data?.accountId || "",
                        output: (item) => {
                          return {
                            accountId: item.value,
                            productName: item.productName,
                          };
                        },
                      }}
                    />
                  </div>
                  <div>
                    <DropdownFormField
                      name="subject"
                      label={selectSubjectText}
                      list={subjectDropdownList}
                      placeholder={selectSubjectText}
                      disabled={!values.productData}
                      valueResetList={["area"]}
                    />
                  </div>
                  {areaDropdownList.length > 0 && (
                    <div>
                      <DropdownFormField
                        name="area"
                        label={selectAreaText}
                        list={areaDropdownList}
                        placeholder={selectAreaText}
                      />
                    </div>
                  )}
                </div>
                {faqList.length > 0 && (
                  <div className={styles.faqWrapper}>
                    <FaqBlock
                      label={page.faqSectionHeaderText}
                      faqBlocks={faqList}
                      color={Colors.BlackSqueeze}
                      noHorizontalPadding
                      noBorder
                      accountId={values.productData?.accountId}
                    />
                  </div>
                )}
                {showFileAttachment && (
                  <FileAttachmentFormField
                    name="files"
                    fileInputId="messageAttachments"
                    label={tx("message.attachmentInputLabel")}
                  />
                )}
                {showTextarea && (
                  <TextAreaFormField
                    name="body"
                    label={page.newMessageText || tx("message.newMessage")}
                    textareaProps={{ rows: 6 }}
                    placeholder={page.textareaPlaceholder || tx("message.textareaPlaceholder")}
                  />
                )}
              </FormWrapper>
            </div>
          </section>
          <ContentArea />
        </>
      );
  }
});
