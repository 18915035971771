import { IGetAutoStartBankIdResponse, IGetCurityTokenResponse, IGetTokenResponse } from "../../../models/Auth";

export const mockConnectBankId: IGetAutoStartBankIdResponse = {
  autoStartToken: "db3332f9-f9b6-4bb6-92a1-0ef7d0a9dff0",
  nounce: "2caadaf7b037866c30cccb8a0a9ef45fa6de1101854e4e8873ef8fc3bd92f166",
};

export const mockConnectToken: IGetTokenResponse = {
  accessToken: "ae6cc90cf6cc6389853ad014be41b287144dacd63d3fa48e8f080b569c98cfd8",
  expiresIn: 6000,
  tokenType: "Bearer",
  refreshToken: "10357b6faefb93bbd1dfd86a8c6d2fe2f8485b51f38aabdff862a1d46f8f1b28",
  uid: "1e878adf757b504a8b944c37ee062cf528c354319bda3b99ddf5cb8ed5436ee8",
  clientId: "QCjxog51r9CBAXEAPlWfPC8UL3QdWqoQ",
};

export const mockCurityConnectToken: IGetCurityTokenResponse = {
  accessToken: "ae6cc90cf6cc6389853ad014be41b287144dacd63d3fa48e8f080b569c98cfd8",
  expiresIn: 6000,
  tokenType: "Bearer",
  refreshToken: "10357b6faefb93bbd1dfd86a8c6d2fe2f8485b51f38aabdff862a1d46f8f1b28",
  idToken: "",
  curityRefresh: "",
};
