import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { ITermsPage } from "netbank-shared/src/libs/models/Content/Page";
import { Button } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import styles from "./Terms.scss";

export const TermsPage = observer(() => {
  const { uiStore, contentStore, customerPersistentStore } = useStores();
  const navigate = useNavigate();

  const currentPage = contentStore.currentPage as ITermsPage;

  const image = uiStore.isMobile ? currentPage?.termsImageMobile : currentPage?.termsImage;

  const setViewedTerms = () => {
    customerPersistentStore.setViewedTerms(true);
    navigate(`/${tx("routing.lang")}`);
  };

  useEffect(() => {
    const redirectToHomePage = async () => {
      navigate(`/${tx("routing.lang")}`);
    };
    if (customerPersistentStore.viewedTerms) {
      redirectToHomePage();
    }
  }, []);

  if (!currentPage) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.image} style={{ backgroundImage: `url("${image?.url}")` }} />
      <div className={styles.contentWrapper}>
        <h1>{currentPage.termsHeader}</h1>
        {currentPage.termsSubHeader && <span className={styles.subHeader}>{currentPage.termsSubHeader}</span>}
        {currentPage.termsText && <HtmlContent className={styles.termsText} html={currentPage.termsText} />}
        <div className={styles.flexContainer}>
          <div className={styles.emptyCell} />
          <Button
            title={currentPage.termsButtonText}
            color="red"
            fullWidth={uiStore.isMobile}
            centered
            large
            onClick={() => setViewedTerms()}
          />
        </div>
      </div>
    </div>
  );
});
