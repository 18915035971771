import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import loanTerminationsStyles from "../LoanTerminationsPage.scss";
import { Button, ErrorText, RadioButtons } from "~views/shared";
import { tx } from "netbank-shared/src/libs/i18n";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { LoanTerminationDebtor } from "netbank-shared/src/libs/models/CustomerProducts";

interface ILoanTerminationDebtorCalcStep {
  data: ILoanTerminationsPage;
  goToNextStep: () => void;
}

export const LoanTerminationDebtorCalcStep = observer(({ data, goToNextStep }: ILoanTerminationDebtorCalcStep) => {
  const { loanStore } = useStores();

  const [loadingNextStep, setLoadingNextStep] = React.useState(false);
  const [showGenericError, setShowGenericError] = React.useState(false);

  const optionList: ListItem[] = Object.keys(LoanTerminationDebtor).map((key, index) => {
    const optionValue =
      data.debtorOptions?.find((opt) => opt.type === key)?.label || tx(`loan.loanTerminations.debtor.${key}`);
    return {
      index,
      enumValue: (LoanTerminationDebtor as KeyObject<string>)[key],
      value: optionValue,
      label: optionValue,
    };
  });

  const selectedDebtor = optionList.find((option) => option.enumValue === loanStore.loanTerminationDebtor)?.value;

  const getLoanAccountTerminationsDetails = async () => {
    setShowGenericError(false);
    if (!selectedDebtor) {
      loanStore.setSelectedDebtorError(true);
      return;
    }

    setLoadingNextStep(true);
    const success = await loanStore.getLoanAccountTerminationDetails(loanStore.currentAccountId);
    setLoadingNextStep(false);
    if (success) {
      goToNextStep();
      window.scrollTo(0, 0);
    } else {
      setShowGenericError(true);
    }
  };

  return (
    <div>
      <HtmlContent className={loanTerminationsStyles.textContent} html={data.calculateStepInformationText} />
      <RadioButtons
        label={data.debtorLabel || tx("loan.loanTerminations.debtorLabel")}
        list={optionList}
        onChange={(e) => {
          loanStore.setSelectedDebtorError(false);
          loanStore.updateLoanTerminationDebtor(e.enumValue as LoanTerminationDebtor);
        }}
        value={selectedDebtor}
        disabled={loadingNextStep}
        error={loanStore.hasSelectedDebtorError ? data.debtorError || tx("validators.required") : undefined}
      />
      <div className={loanTerminationsStyles.actions}>
        <Button
          title={data.calculateButtonLabel || tx("loan.loanTerminations.calcButtonLabel")}
          color="red"
          large
          centered
          onClick={() => getLoanAccountTerminationsDetails()}
          loading={loadingNextStep}
          disabled={loadingNextStep}
        />
      </div>
      {showGenericError && (
        <div className={loanTerminationsStyles.errorTextWrapper}>
          <ErrorText error={data.getDetailsDebtorError || tx("misc.somethingWentWrong")} />
        </div>
      )}
    </div>
  );
});
