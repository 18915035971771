import { IGenericContentLink } from "libs/models/Content/ContentLink";
import { INotification } from "libs/models/Content/Notification";
import { action, computed, makeObservable, observable } from "mobx";
import { makePersistable, hydrateStore, isHydrated, stopPersisting } from "mobx-persist-store";
import { Store } from "./Store";
import { UIStore } from "./UIStore";

interface IStoredNotification {
  saved: string;
  contentLink: {
    guidValue: string;
  };
}

export class CustomerPersistentStore {
  rootStore: Store;

  uiStore: UIStore;

  constructor(
    private storage: any,
    rootStore: Store,
    uiStore: UIStore,
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.uiStore = uiStore;
  }

  hydrate = async (customerId: string) => {
    makePersistable(this, {
      name: `t:${customerId}`,
      properties: [
        "viewedNotifications",
        "viewedTerms",
        "loginOnSameDevice",
        "isLoggedInToCorporateAccount",
        "currentOrganizationNumber",
      ],
      storage: this.storage,
    });
    makePersistable(this, {
      name: `t:${customerId}:signingApplicationId`,
      properties: ["signingApplicationId"],
      expireIn: 300000, // 5 minutes
      removeOnExpiration: true,
      storage: this.storage,
    });
    hydrateStore(this);
  };

  stopPersisting() {
    stopPersisting(this);
  }

  @computed
  get isHydrated() {
    return isHydrated(this);
  }

  @observable
  viewedNotifications: IStoredNotification[] = [];

  @observable
  viewedTerms: boolean = false;

  @observable
  loginOnSameDevice: boolean = false;

  @observable
  signingApplicationId: string = "";

  @observable
  currentOrganizationNumber: string | undefined = undefined;

  @observable
  isLoggedInToCorporateAccount: boolean = false;

  @action
  setIsLoggedInToCorporateAccount = (isLoggedInToCorporateAccount: boolean) => {
    this.isLoggedInToCorporateAccount = isLoggedInToCorporateAccount;
  };

  @action
  setCurrentOrganizationNumber(organizationNumber?: string) {
    this.currentOrganizationNumber = organizationNumber;
  }

  @action // Side effect, removes updated notifications from viewed...
  isNotificationViewed = (notification: IGenericContentLink<INotification>): boolean => {
    const foundNotification = this.viewedNotifications.find(
      (e) => e?.contentLink?.guidValue === notification?.contentLink?.guidValue,
    );
    const isUpdated = foundNotification?.saved !== notification?.saved;

    if (foundNotification && !isUpdated) {
      return true;
    }
    if (foundNotification && isUpdated) this.removeFromViewedNotifications(notification);

    return false;
  };

  @action
  setViewedTerms = (value: boolean) => {
    this.viewedTerms = value;
  };

  @action
  setLoginOnSameDevice = (value: boolean) => {
    this.loginOnSameDevice = value;
  };

  @action
  addToViewedNotifications = (notification: IGenericContentLink<INotification>) => {
    if (!this.isNotificationViewed(notification)) {
      this.viewedNotifications = this.viewedNotifications.concat({
        contentLink: {
          guidValue: notification.contentLink.guidValue,
        },
        saved: notification.saved,
      });
    }
  };

  @action
  removeFromViewedNotifications = (notification: IGenericContentLink<INotification>) => {
    this.viewedNotifications = this.viewedNotifications.filter(
      (e) => e?.contentLink?.guidValue !== notification?.contentLink?.guidValue,
    );
  };

  @action
  setSigningApplicationId = (applicationId: string) => {
    this.signingApplicationId = applicationId;
  };

  @action
  resetPartyData = () => {
    this.currentOrganizationNumber = undefined;
    this.isLoggedInToCorporateAccount = false;
  };

  @action
  resetStore = () => {
    this.viewedNotifications = [];
    this.viewedTerms = false;
    this.loginOnSameDevice = false;
    this.signingApplicationId = "";
    this.resetPartyData();
  };
}
