import { useStores } from "../../hooks";
import { AccountType, IPayment } from "../models/CustomerProducts";

export const useDetermineOverdueInvoicesLoadingParamsByAccountType = (
  accountType: AccountType | undefined,
  accountId: string,
): {
  overdueInvoices: IPayment[] | undefined | null;
  loadingFunction: () => void;
  loading: boolean;
  error: boolean;
  invoiceAction: (invoice: IPayment) => void;
} => {
  const { loanStore, leasingStore } = useStores();
  switch (accountType) {
    case AccountType.PrivateLoan:
    case AccountType.SecuredLoan:
    case AccountType.SalesFinance:
      return {
        overdueInvoices: loanStore.overdueInvoices,
        loadingFunction: () => {
          loanStore.getOverdueInvoices(accountId);
        },
        loading: loanStore.loadingOverdueInvoices,
        error: loanStore.loadingOverdueInvoicesError,
        invoiceAction: (invoice: IPayment) => {
          loanStore.setActivePayment(invoice);
        },
      };
    case AccountType.PrivateLeasing:
    case AccountType.Leasing:
      return {
        overdueInvoices: leasingStore.overduePayments,
        loadingFunction: () => {
          leasingStore.getOverduePayments(accountId);
        },
        loading: leasingStore.loadingOverduePayments,
        error: leasingStore.loadingOverduePaymentsError,
        invoiceAction: (invoice: IPayment) => {
          leasingStore.setActivePayment(invoice);
        },
      };
    default:
      return {
        overdueInvoices: undefined,
        loadingFunction: () => {},
        loading: false,
        error: true,
        invoiceAction: () => {},
      };
  }
};
