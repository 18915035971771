import i18next from "i18next";
import { Lang } from "../models/Content/Enums";

/**
 * Translates text.
 *
 * @param key The i18n key.
 * @param vars Additional values sure to replace.
 */
export const tx = (key: string, vars?: string | object) => (key ? i18next.t(key, vars) : null) as string;

// We add a type guard to the includes method for supportedLangs,
// so if includes returns true, the arg is treated as a Lang enum
// value and not a string.
export type LangArray = Lang[] & {
  includes(arg: string): arg is Lang;
};

export const supportedLangs = [Lang.dk, Lang.fi, Lang.sv, Lang.no, Lang.en] as LangArray;

export const changeI18nLanguage = (lang: string) => {
  if (supportedLangs.includes(lang)) {
    i18next.changeLanguage(lang);
  }
};
