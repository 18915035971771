/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from "react";
import styles from "./MonthPicker.scss";
import { arrayCompare, getMonthNames, isSameDay, monthIsConsecutive, sortDates } from "netbank-shared/src/libs/utils";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import chevronRight from "~assets/chevron-right-red.svg";
import crossIcon from "~assets/cross.svg";
import { ErrorText } from "../ErrorText/ErrorText";
import { ChangeOverview } from "../ChangeOverview/ChangeOverview";

interface ISharedMonthPickerProps {
  showYearPicker?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  error?: string;
  canUpdateInitialValue?: boolean;
}

interface IMultipleMonthPickerProps {
  multiple: true;
  value: Date[] | undefined;
  onChange: (value: Date[]) => void;
  maxAllowedMonths?: number;
  maxAllowedMonthsPerYear?: number;
  allowConsecutive?: boolean;
  allowGap?: boolean;
  initialValue?: Date[] | undefined;
}

interface ISingleMonthPickerProps {
  multiple: false;
  value: Date | undefined;
  onChange: (value: Date) => void;
  initialValue?: Date | undefined;
  maxAllowedMonths: undefined;
  maxAllowedMonthsPerYear: undefined;
  allowConsecutive: undefined;
  allowGap: undefined;
}

export type IMonthPickerProps = (IMultipleMonthPickerProps | ISingleMonthPickerProps) & ISharedMonthPickerProps;

export const MonthPicker = observer(
  ({
    value,
    multiple,
    onChange,
    showYearPicker,
    disabled,
    maxAllowedMonths,
    maxAllowedMonthsPerYear,
    allowConsecutive,
    maxDate,
    minDate,
    initialValue,
    error,
    allowGap = true,
    canUpdateInitialValue = true,
  }: IMonthPickerProps) => {
    const { uiStore } = useStores();

    let initialPickerDate = multiple ? initialValue?.[0] : initialValue;
    if (!initialPickerDate && minDate) {
      initialPickerDate = minDate;
    }
    const [year, setYear] = React.useState(initialPickerDate?.getFullYear() || new Date().getFullYear());

    const prevYearIconClasses = [styles.prev];
    const nextYearIconClasses = [styles.next];
    const wrapperClasses = [styles.wrapper];

    const monthNames = getMonthNames(uiStore.locale);

    const displayMonth = (item: Date) => {
      return `${monthNames[item.getMonth()]}${showYearPicker ? ` ${item.getFullYear()}` : ""}`;
    };

    const parseChangeString = (changeValue: Date[] | Date | undefined) => {
      if (!changeValue) return "";

      return Array.isArray(changeValue)
        ? sortDates(changeValue)
            .map((v) => displayMonth(v))
            .join(", ")
        : displayMonth(changeValue);
    };

    const getChangeOverview = () => {
      let isUpdated = false;

      if (multiple && initialValue !== value) {
        if (initialValue) {
          isUpdated = showYearPicker
            ? !arrayCompare(initialValue, value)
            : !arrayCompare(
                initialValue.map((v) => v.getMonth()),
                value?.map((v) => v.getMonth()),
              );
        } else {
          isUpdated = !!(value && value.length > 0);
        }
      }

      return isUpdated
        ? { initialChangeValue: parseChangeString(initialValue), updatedChangeValue: parseChangeString(value) }
        : { initialChangeValue: "", updatedChangeValue: "" };
    };

    const dateIsSelected = (date: Date, month: number, y: number = year) => {
      const isSelected = date.getMonth() === month;
      return showYearPicker ? isSelected && date.getFullYear() === y : isSelected;
    };

    const dateIsLocked = (date: Date) =>
      !canUpdateInitialValue &&
      initialValue &&
      (multiple
        ? initialValue.findIndex((v) => dateIsSelected(v, date.getMonth(), date.getFullYear())) > -1
        : dateIsSelected(initialValue, date.getMonth(), date.getFullYear()));

    const onMonthChange = (month: number) => {
      if (disabled) return;
      const selectedDate = new Date(year, month, 1);

      if (!multiple) {
        onChange(selectedDate);
        return;
      }

      const isSelected = value && value.findIndex((v) => dateIsSelected(v, month)) > -1;

      if (isSelected) {
        if (!allowGap && minDate && isSameDay(selectedDate, minDate)) {
          // Reset value if non-gap and first value is selected causing a gap
          onChange([]);
        } else {
          onChange(value.filter((v) => !dateIsSelected(v, month)));
        }
      } else onChange([...(value || []), selectedDate]);
    };

    const removeItem = (item: Date) => {
      if (disabled || !multiple) return;
      const isSelected = value && value.findIndex((v) => v.getTime() === item.getTime()) > -1;

      if (isSelected) {
        if (!allowGap && minDate && isSameDay(item, minDate)) {
          // Reset value if non-gap and first value is selected causing a gap
          onChange([]);
        } else {
          onChange(value.filter((v) => v.getTime() !== item.getTime()));
        }
      }
    };

    const { initialChangeValue, updatedChangeValue } = getChangeOverview();
    const showChangeOverview =
      initialChangeValue !== updatedChangeValue &&
      (multiple ? !!initialValue && initialValue.length > 0 && !!value && value.length > 0 : !!initialValue && !!value);

    const hasExceededCurrentYearThreshold =
      maxAllowedMonthsPerYear && multiple
        ? value?.filter((v) => (showYearPicker ? v.getFullYear() === year : true))?.length === maxAllowedMonthsPerYear
        : false;

    const hasExceededTotalThreshold =
      maxAllowedMonths && multiple ? value?.filter((v) => !dateIsLocked(v))?.length === maxAllowedMonths : false;

    const prevYearDisabled = minDate && year === minDate.getFullYear();
    const nextYearDisabled = maxDate && year === maxDate.getFullYear();

    if (multiple && value && value.length > 0) wrapperClasses.push(styles.selected);
    if (disabled || prevYearDisabled) prevYearIconClasses.push(styles.disabled);
    if (disabled || nextYearDisabled) nextYearIconClasses.push(styles.disabled);

    return (
      <>
        <div className={wrapperClasses.join(" ")}>
          {showChangeOverview && <ChangeOverview initialValue={initialChangeValue} updatedValue={updatedChangeValue} />}
          {showYearPicker && (
            <div className={styles.yearWrapper}>
              <img
                className={prevYearIconClasses.join(" ")}
                src={chevronRight}
                alt="prev-icon"
                onClick={() => !disabled && !prevYearDisabled && setYear(year - 1)}
              />
              <span>{year}</span>
              <img
                className={nextYearIconClasses.join(" ")}
                src={chevronRight}
                alt="next-icon"
                onClick={() => !disabled && !nextYearDisabled && setYear(year + 1)}
              />
            </div>
          )}
          <div className={styles.monthsWrapper}>
            {monthNames.map((monthName, index) => {
              const currentMonth = new Date(year, index, 1);
              const classes = [styles.month];
              const isSelected =
                value &&
                (multiple ? value.findIndex((v) => dateIsSelected(v, index)) > -1 : dateIsSelected(value, index));

              const isOutOfRange =
                (maxDate && currentMonth > maxDate) || (minDate && new Date(year, index + 1, 0) < minDate);

              const isConsecutiveDisabled =
                multiple &&
                !allowConsecutive &&
                value &&
                value.findIndex((v) => monthIsConsecutive(v, index, showYearPicker ? year : undefined)) > -1;

              let isNonGapChronologicalDisabled = false;
              if (!allowGap)
                isNonGapChronologicalDisabled =
                  multiple && value && value.length > 0
                    ? value.findIndex((v) => !monthIsConsecutive(v, index, showYearPicker ? year : undefined)) > -1
                    : !!minDate && currentMonth > minDate;

              const isLocked = dateIsLocked(currentMonth);

              const isDisabled =
                disabled ||
                ((hasExceededTotalThreshold ||
                  hasExceededCurrentYearThreshold ||
                  isOutOfRange ||
                  isConsecutiveDisabled ||
                  isNonGapChronologicalDisabled) &&
                  !isSelected);

              if (isSelected) classes.push(styles.active);
              if (isDisabled && !isLocked) classes.push(styles.disabled);
              if (isLocked) classes.push(styles.locked);

              return (
                <div
                  key={`monthpicker-month-${index}`}
                  className={classes.join(" ")}
                  onClick={() => !isDisabled && !isLocked && onMonthChange(index)}
                >
                  {monthName.substring(0, 3)}
                </div>
              );
            })}
          </div>
        </div>
        {multiple && value && value.length > 0 && (
          <div className={styles.summary}>
            {value.map((item, index) => {
              const isLocked = dateIsLocked(item);
              return (
                <div key={`monthpicker-summary-item-${index}`}>
                  <p>{`${monthNames[item.getMonth()]} ${showYearPicker ? item.getFullYear() : ""}`}</p>
                  {!isLocked && !disabled && (
                    <div className={styles.close} onClick={() => !disabled && removeItem(item)}>
                      <img src={crossIcon} alt="close" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {error && <ErrorText error={error} />}
      </>
    );
  },
);
