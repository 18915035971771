import MockAdapter from "axios-mock-adapter";
import { mockCreditTransactions } from "./customerProducts";
import { mockCreditAccounts } from "./credit";

export const MockCreditApi = (mock: MockAdapter) => {
  const idRegexSegment = "[0-9]+";
  const singleAccountIdRegexSegment = "^[0-9]+$";

  mock.onGet("").reply(200, mockCreditAccounts);

  mock.onGet(new RegExp(`${singleAccountIdRegexSegment}`)).reply((config) => {
    if (config.url) {
      const urlArray = config.url.split("/");
      const accountNumber = urlArray[urlArray.length - 1];
      const creditAccount = mockCreditAccounts.creditAccounts?.find((a) => a.accountId === accountNumber);

      if (creditAccount) {
        return [200, { creditAccount, error: "" }];
      }
    }
    return [500];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/transactions`)).reply(200, mockCreditTransactions);
};
