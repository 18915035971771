import { useEffect } from "react";

export const useCloseWithEsc = (close: (() => void) | (() => Promise<void>)) => {
  useEffect(() => {
    const escKeyHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") close();
    };
    document.addEventListener("keydown", escKeyHandler);

    return () => {
      document.removeEventListener("keydown", escKeyHandler);
    };
  }, []);
};
