import React from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

interface ICookieConsentProps {
  cookieScript: string;
}

export const CookieConsent = observer(({ cookieScript }: ICookieConsentProps) => {
  return (
    <Helmet>
      <script src={cookieScript} type="text/javascript" />
    </Helmet>
  );
});
