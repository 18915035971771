import { action, makeObservable, observable } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";

export class GlobalPersistentStore {
  constructor(storage: any) {
    makeObservable(this);
    makePersistable(this, {
      name: "g",
      properties: ["customerId", "isMock"],
      storage,
    });
  }

  hydrate = async () => hydrateStore(this);

  @observable
  customerId?: string;

  @observable
  isMock?: boolean;

  @action
  setIsMock = (value: boolean) => {
    this.isMock = value;
  };

  @action
  resetStore = () => {
    this.customerId = undefined;
  };
}
