/* eslint-disable no-undef */
import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { cleanAndParseInt, createArrayFromInterval } from "../../utils";
import { ListItem } from "../../models/Content/ListItem";
import { AccountType } from "../../models/CustomerProducts";
import { IMileageChangePage } from "libs/models/Content/Page";

export enum LoanMileageChangeRequestedMileage {
  None = "",
  LessThanThirtyThousand = "LessThanThirtyThousand",
  BetweenThirtyAndFortyFiveThousand = "BetweenThirtyAndFortyFiveThousand",
  BetweenFortyFiveAndSixtyThousand = "BetweenFortyFiveAndSixtyThousand",
  BetweenSixtyAndSeventyFiveThousand = "BetweenSixtyAndSeventyFiveThousand",
  BetweenSeventyFiveAndNinetyThousand = "BetweenSeventyFiveAndNinetyThousand",
}

export interface IMileageChangeForm {
  currentMileage: number;
  requestedMileage: string;
  phone: string;
  message?: string;
  attachments?: File[];
  termsAccepted?: boolean;
}

export interface IMileageChangeResponse {
  conversationId: string;
}

interface IMileageChangeSchemaProps {
  locale: Locale;
  accountType: AccountType | undefined;
  page: IMileageChangePage;
  requiredError?: string;
  invalidError?: string;
}

const getRequestedMileageIntervalMaxThreshold = (requestedMileage: string) => {
  const requestedMileageList = getRequestedMileageList(AccountType.SecuredLoan);
  const requestedMileageItem = requestedMileageList.find((item) => item.value === requestedMileage);
  if (!requestedMileageItem?.enumValue) return undefined;

  switch (requestedMileageItem.enumValue) {
    case LoanMileageChangeRequestedMileage.LessThanThirtyThousand:
      return 30000;
    default:
      return cleanAndParseInt(requestedMileageItem.value.split(" - ")?.[1]);
  }
};

const validateRequestedMileage = (
  currentMileage: number,
  requestedMileageLabel: string,
  accountType: AccountType | undefined,
) => {
  if (!currentMileage || !requestedMileageLabel) return false;
  switch (accountType) {
    case AccountType.Leasing: {
      const requestedMileage = cleanAndParseInt(requestedMileageLabel);
      return requestedMileage ? requestedMileage > currentMileage : false;
    }
    case AccountType.SecuredLoan: {
      const requestedMileageMaxValue = getRequestedMileageIntervalMaxThreshold(requestedMileageLabel);
      return requestedMileageMaxValue ? requestedMileageMaxValue > currentMileage : false;
    }
    default:
      return false;
  }
};

export const getRequestedMileageList = (accountType?: AccountType, locale?: Locale): ListItem[] => {
  switch (accountType) {
    case AccountType.Leasing:
      return createArrayFromInterval(35000, 150000, 5000).map((value, index) => {
        return { index, value: value.toLocaleString(locale), label: value.toLocaleString(locale) };
      });

    case AccountType.SecuredLoan:
      return Object.keys(LoanMileageChangeRequestedMileage)
        .filter((key) => key !== "None")
        .map((key, index) => ({
          index,
          enumValue: (LoanMileageChangeRequestedMileage as KeyObject<string>)[key],
          value: tx(`mileageChange.requestedMileage.${key}`),
          label: tx(`mileageChange.requestedMileage.${key}`),
        }));
    default:
      return [];
  }
};

export const generateMileageChangeSchema = (props: IMileageChangeSchemaProps): yup.ObjectSchema<IMileageChangeForm> => {
  const { currentMileage, requestedMileage, phone, termsCheckbox } = props.page;
  const inalidCurrentMileageError = currentMileage?.error || tx("validators.required");
  const invalidRequestedMileageError = requestedMileage?.error || tx("validators.required");
  const invalidPhoneError = phone?.error || tx("validators.phone");
  const termsAcceptanceError = termsCheckbox?.error || tx("validators.required");
  const validRequestedMileages = getRequestedMileageList(props.accountType, props.locale).map((item) => item.value);
  const schema = yup.object({
    currentMileage: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(inalidCurrentMileageError)
      .positive(inalidCurrentMileageError),
    requestedMileage: yup
      .string()
      .oneOf(validRequestedMileages, invalidRequestedMileageError)
      .required(invalidRequestedMileageError)
      .test("isLeaserThanCurrentMileage", tx("mileageChange.invalidRequestedMileage"), (value, context) => {
        return validateRequestedMileage(context.parent.currentMileage, value, props.accountType);
      }),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
    message: yup.string().optional(),
    attachments: yup.array().optional(),
    termsAccepted: yup
      .boolean()
      .optional()
      .test("termsAccepted", termsAcceptanceError, (value) => {
        return props.accountType === AccountType.SecuredLoan ? !!value : true;
      }),
  });

  return schema;
};
