import { IAllAccounts, IBaseAccount } from "libs/models/CustomerProducts";
import { IGenericContentLink } from "../models/Content/ContentLink";
import { AllowedAttachmentAmountSetting, Direction, Lang, Product } from "../models/Content/Enums";
import { ListItem, MessageProductListItem } from "../models/Content/ListItem";
import {
  IAreaDropdownItem,
  IProductDropdownItem,
  ISubjectDropdownItem,
} from "../models/Content/NewMessageDropdownItem";
import { IFAQItem } from "../models/Content/accordions/FAQItem";
import { decode, encode } from "html-entities";

enum AccountType {
  Credit = "Credit",
  Deposit = "Deposit",
  Leasing = "PrivateLeasing",
  SecuredLoan = "SecuredLoan",
  PrivateLeasing = "PrivateLeasing",
  PrivateLoan = "PrivateLoan",
  SalesFinance = "SalesFinance",
}

export const getSubjectForLang = (sub: ISubjectDropdownItem | IFAQItem, lang?: Lang) => {
  switch (lang) {
    case Lang.sv:
      return sub.subjectSE || sub.subject;
    case Lang.fi:
      return sub.subjectFI || sub.subject;
    default:
      return undefined;
  }
};

export const getAreaForLang = (a: IAreaDropdownItem | IFAQItem, lang?: Lang) => {
  switch (lang) {
    case Lang.sv:
      return a.areaSE || a.area;
    case Lang.fi:
      return a.areaFI || a.area;
    default:
      return undefined;
  }
};

export const getSubjectItemsFromProduct = (product: IProductDropdownItem, lang?: Lang) => {
  const subjects: ListItem[] = [];
  if (product?.subjectDropdown) {
    subjects.push(
      ...product.subjectDropdown
        .filter((sub) => subjects.length === 0 || !subjects.some((s) => s.value === getSubjectForLang(sub, lang)))
        .map((sub, index) => {
          return {
            index,
            label: `${sub.text}`,
            value: `${getSubjectForLang(sub, lang)}`,
          };
        }),
    );
  }
  return subjects;
};

export const mapSubjectsToListItems = (subjects: ISubjectDropdownItem[], lang?: Lang) => {
  return subjects.map((sub, index) => {
    return {
      index,
      label: `${sub.text}`,
      value: `${getSubjectForLang(sub, lang)}`,
      displayValue: `${sub.text}`,
    };
  });
};

export const mapAreasToListItems = (areas: IAreaDropdownItem[], lang?: Lang) => {
  return areas.map((area, index) => {
    return {
      index,
      label: `${area.text}`,
      value: `${getAreaForLang(area, lang)}`,
      displayValue: `${area.text}`,
    };
  });
};

export const getFilteredFaqs = (
  allFaqs: IGenericContentLink<IFAQItem>[],
  product?: string,
  subject?: string,
  area?: string,
  lang?: Lang,
) => {
  const productFaqs = allFaqs.filter((faq) => {
    const faqProductTags = faq.product?.split(",");
    return faqProductTags?.some((tag) => tag === product);
  });
  const faqs = productFaqs.filter((faq) => {
    const faqSubjectTags = getSubjectForLang(faq, lang)?.split(",");
    const faqAreaTags = getAreaForLang(faq, lang)?.split(",");
    const subjectFilterMatch = !!subject && faqSubjectTags?.some((tag) => tag === subject);
    const areaFilterMatch = !!area && faqAreaTags?.some((tag) => tag === area);
    const filterMatch = subjectFilterMatch || areaFilterMatch;
    return filterMatch;
  });
  return faqs;
};

export const formatAccountsForList = (_accounts: IBaseAccount[]) => {
  return _accounts.map((acc) => ({
    label: `${acc.name} - ${acc.displayNumber}`,
    value: acc.accountId,
    displayValue: `${acc.name} - ${acc.displayNumber}`,
    productName: mapAccountTypeToProductType(acc.accountType),
  }));
};

export const getAccountDropdownList = (accounts: IAllAccounts, products: IProductDropdownItem[]) => {
  const accountList: MessageProductListItem[] = [];
  if (accounts.creditAccounts && products?.some((p) => p.product === Product.Credit)) {
    accountList.push(...formatAccountsForList([...accounts.creditAccounts]));
  }

  if (accounts.depositAccounts && products?.some((p) => p.product === Product.Deposit)) {
    accountList.push(...formatAccountsForList(accounts.depositAccounts));
  }

  if (accounts.privateLoanAccounts && products?.some((p) => p.product === Product.ConsumerLoan)) {
    accountList.push(
      ...formatAccountsForList([...accounts.privateLoanAccounts, ...accounts.blockedPrivateLoanAccounts]),
    );
  }

  if (accounts.securedLoanAccounts && products?.some((p) => p.product === Product.CarLoan)) {
    accountList.push(
      ...formatAccountsForList([...accounts.securedLoanAccounts, ...accounts.blockedSecuredLoanAccounts]),
    );
  }

  if (accounts.leasingAccounts && products?.some((p) => p.product === Product.PrivateLeasing)) {
    accountList.push(...formatAccountsForList(accounts.leasingAccounts));
  }

  if (accounts.salesFinanceAccounts && products?.some((p) => p.product === Product.SalesFinance)) {
    accountList.push(...formatAccountsForList(accounts.salesFinanceAccounts));
  }

  return accountList;
};

export const getProductForAccountType = (products: IProductDropdownItem[], accountType?: AccountType) => {
  return products?.find((p) => {
    if (accountType === AccountType.SecuredLoan) {
      return p.product === Product.CarLoan;
    }
    if (accountType === AccountType.PrivateLoan) {
      return p.product === Product.ConsumerLoan;
    }
    if (accountType === AccountType.SalesFinance) {
      return p.product === Product.SalesFinance;
    }
    if (!accountType) {
      return p.product === Product.NoProduct;
    }
    return p.product === accountType;
  });
};

export const mapAccountTypeToProductType = (accountType: AccountType) => {
  switch (accountType) {
    case AccountType.Credit:
      return Product.Credit;
    case AccountType.Deposit:
      return Product.Deposit;
    case AccountType.PrivateLeasing:
      return Product.PrivateLeasing;
    case AccountType.PrivateLoan:
      return Product.ConsumerLoan;
    case AccountType.SalesFinance:
      return Product.SalesFinance;
    case AccountType.SecuredLoan:
      return Product.CarLoan;
    default:
      return Product.NoProduct;
  }
};

export const getMaxAttachmentsAmount = (setting: AllowedAttachmentAmountSetting, max: number) => {
  switch (setting) {
    case AllowedAttachmentAmountSetting.None:
      return 0;
    case AllowedAttachmentAmountSetting.Single:
      return 1;
    case AllowedAttachmentAmountSetting.Many:
      return max;
    default:
      return 0;
  }
};

export const processMessageHtml = (body: string, direction: Direction) => {
  // Message body from API might be encoded as character entities and needs to be decoded (including non-html characters like text, spaces, etc.).
  const decodedBody = decode(body);

  // Wrap html with a "pre" tag to handle both <br/> and \n new-lines.
  // Received messages from CUS should be parsed as HTML, however HTML sent by customer should be parsed as string and therefore html-encoded.
  return `<pre>${direction === Direction.Received ? decodedBody : encode(decodedBody)}</pre>`;
};

export const processMessagePreviewHtml = (body: string, direction: Direction) => {
  return direction === Direction.Received ? decode(body).replace(/<.*?>/g, " ").replace(/\s+/g, " ") : decode(body);
};
