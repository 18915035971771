import { Lang } from "../models/Content/Enums";
import { sv, da, nb, fi } from "date-fns/locale";
import { Locale } from "date-fns";

interface LocaleUtils {
  getLocale: (lang?: Lang) => Locale | undefined;
}

const getLocale = (lang?: Lang) => {
  switch (lang) {
    case Lang.sv:
      return sv;
    case Lang.dk:
      return da;

    case Lang.no:
      return nb;

    case Lang.fi:
      return fi;
    default:
      return undefined;
  }
};

export const dateLocaleUtils: LocaleUtils = {
  getLocale,
};
