import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import {
  ILoanTerminitionAnswerOption,
  ILoanTerminitionConsentQuestion,
} from "netbank-shared/src/libs/models/CustomerProducts";
import loanTerminationsStyles from "../LoanTerminationsPage.scss";
import { Button, Input, RadioButtons } from "~views/shared";

import { tx } from "netbank-shared/src/libs/i18n";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";

interface ILoanTerminationConsentStep {
  data: ILoanTerminationsPage;
  goToNextStep: () => void;
}

export const LoanTerminationConsentStep = observer(({ data, goToNextStep }: ILoanTerminationConsentStep) => {
  const { loanStore } = useStores();

  const { currentAccountId, consentReplies, loanTerminationsPhoneError, loanTerminationsErrorList } = loanStore;

  const [loadingNextStep, setLoadingNextStep] = React.useState(false);

  const submitDetailsRequest = async () => {
    setLoadingNextStep(true);
    const success = await loanStore.submitLoanAccountTerminationDetails(currentAccountId);
    setLoadingNextStep(false);

    if (success) {
      goToNextStep();
      window.scrollTo(0, 0);
    }
  };

  const formatOptionsForList = (options: ILoanTerminitionAnswerOption[]) => {
    return options.map((option, index) => ({
      index,
      label: option.answerText,
      value: option.answerId,
      extra: option.collectContactPhoneNumber,
    }));
  };

  const renderQuestion = (question: ILoanTerminitionConsentQuestion, ind: number) => {
    const optionList = formatOptionsForList(question.answerOptions);
    const selectedOption = optionList.find((opt) => opt.value === consentReplies[ind].answerId);
    const hasError = loanTerminationsErrorList.find((error) => error === question.questionId);

    return (
      <div className={loanTerminationsStyles.question} key={`TerminationsQuestion-${ind}`}>
        <h3>{question.questionText}</h3>
        <RadioButtons
          list={optionList}
          onChange={(e) => {
            loanStore.updateConsentReplies(question.questionId, e.value, "");
          }}
          value={optionList.find((option) => option.value === consentReplies[ind].answerId)?.value}
          error={hasError ? data.questionError : undefined}
          disabled={loadingNextStep}
        />
        {selectedOption?.extra && (
          <>
            <Input
              className={loanTerminationsStyles.input}
              placeholder={tx("misc.phone")}
              onChange={(e) => {
                loanStore.updateConsentReplies(question.questionId, selectedOption.value, e.target.value);
              }}
              value={
                consentReplies.find((consentReply) => consentReply.questionId === question.questionId)
                  ?.contactPhoneNumber
              }
              error={loanTerminationsPhoneError ? data.phoneError : undefined}
              disabled={loadingNextStep}
            />
            <HtmlContent className={loanTerminationsStyles.phoneText} html={data.consentStepPhoneText} />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <HtmlContent className={loanTerminationsStyles.textContent} html={data.consentStepInformationText} />
      {loanStore.loanTerminationDetails?.consentQuestions.map(
        (question: ILoanTerminitionConsentQuestion, ind: number) => renderQuestion(question, ind),
      )}
      <div className={loanTerminationsStyles.actions}>
        <Button
          title={data.consentButtonLabel || tx("loan.loanTerminations.consentButtonLabel")}
          color="red"
          large
          centered
          onClick={() => submitDetailsRequest()}
          loading={loadingNextStep}
          disabled={loadingNextStep}
        />
      </div>
    </div>
  );
});
