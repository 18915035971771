import React from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import styles from "../Page.scss";
import rootStyles from "../Root.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { tx } from "netbank-shared/src/libs/i18n";

export const NotFoundPage = observer(() => {
  const { contentStore, uiStore } = useStores();
  const page = contentStore.getHomePage(tx("routing.lang")) as IPage;
  if (!page) return null;
  const image = uiStore.isMobile ? page.notFoundImageMobile || page.notFoundImage : page.notFoundImage;

  return (
    <div className={styles.wrapper}>
      <section className={[styles.notFound, rootStyles.fullWidth].join(" ")}>
        <HtmlContent className={styles.notFoundContent} html={page.notFoundContent} />
        {image?.url && <img className={styles.notFoundImage} src={image?.url} alt={image.altText || image.name} />}
      </section>
    </div>
  );
});
