import React, { RefObject, useRef } from "react";
import { observer } from "mobx-react";
import styles from "./SideNav.scss";
import { useStores } from "netbank-shared/src/hooks";
import { Menu } from "../Menu/Menu";

export const SideNav = observer(() => {
  const { contentStore } = useStores();
  const navClasses = [styles.nav];
  const mainMenuRef: RefObject<HTMLDivElement> = useRef(null);

  if (contentStore.currentPage?.backgroundColor) {
    navClasses.push(styles[contentStore.currentPage.backgroundColor]);
  }

  return (
    <nav className={navClasses.join(" ")}>
      <Menu page={contentStore.currentPage} mainMenuRef={mainMenuRef} />
    </nav>
  );
});
