import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "netbank-shared/src/hooks";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { LoanTerminationPaymentStep } from "./LoanTerminationFlow/LoanTerminationPaymentStep";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { LoanTerminationsStep } from "netbank-shared/src/libs/models/Content/Enums";
import { LoanTerminationDebtorCalcStep } from "./LoanTerminationFlow/LoanTerminationDebtorCalcStep";
import { AccountType, LoanTerminationDebtor } from "netbank-shared/src/libs/models/CustomerProducts";
import { LoadingLottie } from "~views/shared";

export const FILoanTerminationsPage = observer(() => {
  const { loanStore, contentStore } = useStores();
  const { currentPage } = contentStore;
  const { currentAccount } = loanStore;
  const paymentInfoRows: { data: string; label: string }[] = [];

  const pageData = currentPage as ILoanTerminationsPage;

  const initialStep =
    currentAccount?.accountType === AccountType.PrivateLoan
      ? LoanTerminationsStep.PaymentStep
      : LoanTerminationsStep.CalcStep;

  const [step, setStep] = React.useState<LoanTerminationsStep>(initialStep);
  const [loadingFlow, setLoadingFlow] = React.useState<boolean>(false);

  const goToNextStep = () => {
    switch (step) {
      case LoanTerminationsStep.CalcStep:
        setStep(LoanTerminationsStep.PaymentStep);
        break;
      default:
        setStep(LoanTerminationsStep.CalcStep);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      if (currentAccount?.accountType === AccountType.PrivateLoan) {
        loanStore.updateLoanTerminationDebtor(LoanTerminationDebtor.Customer);
      }
      if (initialStep === LoanTerminationsStep.PaymentStep) {
        setLoadingFlow(true);
        await loanStore.getLoanAccountTerminationDetails(loanStore.currentAccountId);
        setLoadingFlow(false);
      }
    };
    fetchDetails();
    return () => {
      loanStore.resetLoanTerminationsState();
    };
  }, []);

  if (!currentAccount?.accountId) {
    return <SelfServiceFailurePage data={pageData.emptyState} />;
  }

  const renderTerminationFlow = () => {
    const goBackToAccount = getProductUrl(currentAccount.accountType, currentAccount.accountId);

    if (loadingFlow) {
      return <LoadingLottie />;
    }

    switch (step) {
      case LoanTerminationsStep.CalcStep:
        return <LoanTerminationDebtorCalcStep data={pageData} goToNextStep={goToNextStep} />;
      case LoanTerminationsStep.PaymentStep:
        if (currentAccount.accountDetails?.bankAccount) {
          paymentInfoRows.push({
            data: currentAccount.accountDetails?.bankAccount,
            label: pageData.accountNumberLabel || tx("loan.loanTerminations.accountNumber"),
          });
        }

        if (currentAccount.accountDetails?.referenceNumber) {
          paymentInfoRows.push({
            data: currentAccount.accountDetails?.referenceNumber,
            label: pageData.referenceNumberLabel || tx("loan.loanTerminations.referenceNumber"),
          });
        }

        if (currentAccount.accountDetails?.businessIdentificationCode) {
          paymentInfoRows.push({
            data: currentAccount.accountDetails?.businessIdentificationCode,
            label: pageData.businessIdentifierCodeLabel || tx("loan.loanTerminations.bic"),
          });
        }

        return (
          <LoanTerminationPaymentStep
            data={pageData}
            paymentInfoRows={paymentInfoRows}
            goBackToAccountUrl={goBackToAccount}
            showExportButton={pageData.showExportButton}
          />
        );
      default:
        return <LoanTerminationDebtorCalcStep data={pageData} goToNextStep={goToNextStep} />;
    }
  };

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText,
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name || "",
      },
      {
        label: pageData.displayNumberHeaderLabel || tx("invoice.invoice"),
        value: currentAccount.displayNumber || "",
      },
    ],
  };

  return <AccountActionContainer info={pageInfo}>{renderTerminationFlow()}</AccountActionContainer>;
});
