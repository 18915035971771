import React from "react";
import styles from "./SelfServicePage.scss";
import { EmptyState } from "~views/shared";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";

interface ISelfServiceFailurePage {
  data: IEmptyState;
}

export const SelfServiceFailurePage = ({ data }: ISelfServiceFailurePage) => {
  return (
    <section>
      <div className={styles.failureContainer}>
        <EmptyState data={data} />
      </div>
    </section>
  );
};
