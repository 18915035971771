import { observer } from "mobx-react";
import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import loanTerminationsStyles from "../LoanTerminationsPage.scss";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import { ILoanAccountTerminationDetails } from "netbank-shared/src/libs/models/CustomerProducts";
import { Button } from "~views/shared";
import { useNavigate } from "react-router-dom";
import { ExpandableInfo } from "~views/shared/ContentArea/Blocks/ExpandableInfo";
import { IDataRow } from "netbank-shared/src/libs/models/IDataRow";
import { SelfServiceFormResult } from "../../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { SettlementDocument } from "../SettlementDocument";

interface ILoanTerminationPaymentStep {
  data: ILoanTerminationsPage;
  paymentInfoRows: {
    label: string;
    data: string;
  }[];
  goBackToAccountUrl: string;
  showExportButton: boolean;
}

export const LoanTerminationPaymentStep = observer(
  ({ data, paymentInfoRows, goBackToAccountUrl, showExportButton }: ILoanTerminationPaymentStep) => {
    const { uiStore, loanStore, commonService } = useStores();
    const { currency, locale } = uiStore;
    const { loanTerminationDetails } = loanStore;
    const details = loanTerminationDetails as ILoanAccountTerminationDetails;
    const navigate = useNavigate();

    const [downloadingPdf, setDownloadingPdf] = React.useState(false);

    const goBackToAccount = () => {
      loanStore.resetLoanTerminationsState();
      navigate(goBackToAccountUrl);
    };

    const handleExportPdfDocument = async () => {
      if (!loanStore.loanTerminationsCalculationDetails) {
        return;
      }
      setDownloadingPdf(true);
      const success = await loanStore.getLoanAccountTerminationDetails(loanStore.currentAccountId, true);
      setDownloadingPdf(false);

      if (success && loanStore.loanTerminationDetails) {
        const { detailsDocument } = loanStore.loanTerminationDetails;
        const { fileName, mimeType, fileBytes } = detailsDocument;
        commonService.downloadDocument(fileBytes, fileName, mimeType, false);
      }
    };

    if (!loanTerminationDetails)
      return (
        <SelfServiceFormResult
          content={{
            header: data.errorContent?.header || tx("loan.loanTerminations.errorContentHeader"),
            text: data.errorContent?.textContent || tx("loan.loanTerminations.errorContentText"),
            buttonLabel: data.errorContent?.buttonLabel || tx("loan.loanTerminations.goBackButtonLabel"),
          }}
          goBackToAccountUrl={goBackToAccountUrl}
          contentType={NotificationLevel.error}
        />
      );
    let terminationCalculationBreakdownRows: IDataRow[] = [];

    if (loanTerminationDetails.terminationCalculationBreakdown) {
      terminationCalculationBreakdownRows = [
        {
          type: "data",
          data: toLocaleString(loanTerminationDetails.terminationCalculationBreakdown.interest, currency, locale, 2),
          label: data.interestLabel || tx("loan.loanTerminations.interest"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.interest > 0,
        },
        {
          type: "data",
          data: toLocaleString(
            loanTerminationDetails.terminationCalculationBreakdown.openInvoices,
            currency,
            locale,
            2,
          ),
          label: data.openInvoicesLabel || tx("loan.loanTerminations.openInvoices"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.openInvoices > 0,
        },
        {
          type: "data",
          data: toLocaleString(loanTerminationDetails.terminationCalculationBreakdown.penaltyFees, currency, locale, 2),
          label: data.penaltyFeesLabel || tx("loan.loanTerminations.penaltyFees"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.penaltyFees > 0,
        },
        {
          type: "data",
          data: toLocaleString(
            loanTerminationDetails.terminationCalculationBreakdown.periodicFees,
            currency,
            locale,
            2,
          ),
          label: data.periodicFeesLabel || tx("loan.loanTerminations.periodicFees"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.periodicFees > 0,
        },
        {
          type: "data",
          data: toLocaleString(loanTerminationDetails.terminationCalculationBreakdown.restBalance, currency, locale, 2),
          label: data.restBalanceLabel || tx("loan.loanTerminations.restBalance"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.restBalance > 0,
        },
        {
          type: "data",
          data: toLocaleString(
            loanTerminationDetails.terminationCalculationBreakdown.terminationFee,
            currency,
            locale,
            2,
          ),
          label: data.terminationFeeLabel || tx("loan.loanTerminations.terminationFee"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.terminationFee > 0,
        },
        {
          type: "data",
          data: toLocaleString(loanTerminationDetails.terminationCalculationBreakdown.prePayment, currency, locale, 2),
          label: data.prePaymentLabel || tx("loan.loanTerminations.prePayment"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.prePayment > 0,
        },
        {
          type: "data",
          data: toLocaleString(
            loanTerminationDetails.terminationCalculationBreakdown.otherExpenses,
            currency,
            locale,
            2,
          ),
          label: data.otherExpensesLabel || tx("loan.loanTerminations.otherExpenses"),
          showCondition: loanTerminationDetails.terminationCalculationBreakdown.otherExpenses > 0,
        },
      ];
    }

    return (
      <div>
        <div className={loanTerminationsStyles.amountLabel}>
          {data.amountLabel || tx("loan.loanTerminations.amountLabel")}
        </div>
        <div className={loanTerminationsStyles.amount}>{toLocaleString(details.total || 0, currency, locale, 2)}</div>
        <HtmlContent className={loanTerminationsStyles.textContent} html={data.paymentStepDateInfo} />
        <HtmlContent className={loanTerminationsStyles.textContent} html={data.customerInformationText} />
        <HtmlContent className={loanTerminationsStyles.textContent} html={data.paymentInformationText} />
        {terminationCalculationBreakdownRows && terminationCalculationBreakdownRows.length > 0 && (
          <div className={loanTerminationsStyles.paymentBreakDown}>
            <ExpandableInfo
              leftHeader={data.paymentBreakdownLabel || tx("loan.loanTerminations.paymentBreakdown")}
              rows={terminationCalculationBreakdownRows}
            >
              {data.paymentBreakdownText && <HtmlContent html={data.paymentBreakdownText} />}
            </ExpandableInfo>
          </div>
        )}
        {paymentInfoRows && paymentInfoRows.length > 0 && (
          <div className={loanTerminationsStyles.paymentInfo}>
            {paymentInfoRows.map((p, i) => {
              return (
                <div className={loanTerminationsStyles.row} key={`paymentInfoRow-${i}`}>
                  <span className={loanTerminationsStyles.label}>{p.label}</span>
                  <span>{p.data}</span>
                </div>
              );
            })}
          </div>
        )}
        <HtmlContent className={loanTerminationsStyles.bottomInfo} html={data.referenceText} />
        <div className={loanTerminationsStyles.actions}>
          {showExportButton && (
            <SettlementDocument
              fileName={
                loanTerminationDetails?.detailsDocument?.fileName ||
                tx("loan.loanTerminations.settlementDocumentFileName")
              }
              loading={downloadingPdf}
              downloadAction={handleExportPdfDocument}
            />
          )}
          <Button
            title={data.goBackButtonLabel || tx("loan.loanTerminations.goBack")}
            large
            color="white"
            bordered
            borderColor="black"
            onClick={goBackToAccount}
          />
        </div>
      </div>
    );
  },
);
