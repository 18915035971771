import { action, computed, makeObservable, observable } from "mobx";

import { CardStatus, ICard, ICreditAccount, ICreditBaseAccount } from "../libs/models/CustomerProducts/CreditAccount";
import { Store } from "./Store";
import { CreditApi } from "libs/api";
import { IDocument, IGetTransactionsRequest, ITransaction } from "libs/models/CustomerProducts";

export class CreditStore {
  creditApi: CreditApi;

  rootStore: Store;

  constructor(creditApi: CreditApi, rootStore: Store) {
    this.creditApi = creditApi;
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  creditCustomerProducts: ICreditBaseAccount[] = [];

  @observable
  closedCreditCustomerProducts: ICreditBaseAccount[] = [];

  @observable
  currentAccount: ICreditAccount | undefined;

  @observable
  loadingAccount: boolean = false;

  @observable
  transactions: ITransaction[] = [];

  @observable
  loadingTransactions: boolean = false;

  @observable
  loadingTransactionsError: boolean = false;

  @observable
  contracts?: IDocument[];

  @observable
  loadingContracts: boolean = false;

  @computed
  get currentAccountId(): string {
    return this.rootStore.currentAccountId;
  }

  set currentAccountId(accountId: string) {
    this.rootStore.currentAccountId = accountId;
  }

  @computed
  get hasCustomerProducts(): boolean {
    return this.creditCustomerProducts !== undefined && this.creditCustomerProducts.length > 0;
  }

  @computed
  get currentCards(): ICard[] {
    const cardOrder = Object.values(CardStatus);
    return (
      this.currentAccount?.cards?.slice().sort((a, b) => cardOrder.indexOf(a.status) - cardOrder.indexOf(b.status)) ||
      []
    );
  }

  getCreditAccount = async (accountId: string) => {
    this.loadingAccount = true;
    const response = await this.creditApi.getCreditAccount(accountId);
    if (response?.ok && response.data) {
      let currentBaseAccount = this.rootStore.currentAccount;
      if (currentBaseAccount?.accountId !== accountId) {
        currentBaseAccount = this.rootStore.allCustomerProducts?.find(
          (customerProduct) => customerProduct.accountId === response.data?.creditAccount?.accountId,
        );
        this.currentAccountId = accountId;
      }
      this.currentAccount = response.data.creditAccount
        ? {
            nickname: currentBaseAccount?.nickname,
            ...response.data.creditAccount,
          }
        : undefined;
    }
    this.loadingAccount = false;
  };

  @action
  setNicknameOptimistically = async (nickname: string) => {
    if (this.currentAccount) {
      this.currentAccount.nickname = nickname;
    }
  };

  getContracts = async () => {
    this.contracts = [];
    this.loadingContracts = true;
    const response = await this.creditApi.getContracts(this.currentAccountId);
    if (response?.ok && response.data) {
      this.contracts = response.data;
    }
    this.loadingContracts = false;
  };

  setCurrentAccount(accountId: string): void {
    this.currentAccountId = accountId;
  }

  getTransactions = async (accountId: string, dateFrom: string | undefined, dateTo: string | undefined) => {
    this.loadingTransactions = true;
    this.loadingTransactionsError = false;
    const data: IGetTransactionsRequest = {
      accountId,
      nextToken: "",
      amountFrom: "",
      amountTo: "",
      dateFrom: dateFrom || "",
      dateTo: dateTo || "",
    };
    const response = await this.creditApi.getTransactions(data);

    if (response?.ok && response.data) {
      this.transactions = response.data.transactions;
    } else {
      this.loadingTransactionsError = true;
    }
    this.loadingTransactions = false;
  };

  @action
  resetContracts = () => {
    this.contracts = undefined;
    this.loadingContracts = false;
  };

  @action
  resetCurrentAccount = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
  };

  @action
  resetStore = () => {
    this.currentAccount = undefined;
    this.currentAccountId = "";
    this.creditCustomerProducts = [];
    this.loadingAccount = false;
    this.transactions = [];
    this.loadingTransactions = false;
    this.loadingTransactionsError = false;
  };
}
