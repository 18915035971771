import { ITransferAccountsResponse, ITransferCalculationResponse } from "../../../models/Transfer/Transfer";

export const mockTransferAccounts: ITransferAccountsResponse = {
  accounts: [
    {
      accountNumber: "8501110000",
      clearingNumber: "3300",
      displayName: "Martin",
      bank: "Nordea Personkonto",
      isAccountVerified: false,
    },
    {
      clearingNumber: "4107",
      accountNumber: "1644162",
      displayName: "Mors konto",
      bank: "Nordea",
      isAccountVerified: false,
    },
    {
      clearingNumber: "4107",
      accountNumber: "1241163",
      displayName: "Spender",
      bank: "Nordea",
      isAccountVerified: false,
    },
    {
      clearingNumber: "88888",
      accountNumber: "1234567",
      displayName: "Feil konto",
      bank: "Swedbank",
      isAccountVerified: false,
    },
  ],
};

export const mockTransferPostAccounts: ITransferAccountsResponse = {
  accounts: [
    {
      clearingNumber: "3300",
      accountNumber: "1234567890",
      displayName: "3300 1234567890",
      bank: "Nylig lagt til konto",
      isAccountVerified: false,
    },
  ],
};

export const mockGetTransferDetails: ITransferCalculationResponse = {
  allowed: false,
  valueDate: "",
  amount: 0,
  fromAccountAvailableBalance: 0,
  penaltyAmount: 1000,
  toAccountAvailableBalance: 0,
  details: "",
  fromAccountId: "",
  fromAccountNumber: "",
  toAccountId: "",
  toAccountNumber: "",
};
