import * as React from "react";
import loadingAnimationData from "~assets/lottie/loading.json";
import { Lottie } from "..";

interface ILoadingLottieProps {
  height?: number;
  width?: number;
}

export const LoadingLottie = ({ height, width }: ILoadingLottieProps) => {
  return (
    <Lottie
      options={{
        animationData: loadingAnimationData,
        autoplay: true,
        loop: true,
      }}
      height={height || 100}
      width={width || 100}
    />
  );
};
