import {
  ComplianceConditionOperator,
  ComplianceFieldType,
  ComplianceSigningStatus,
  ComplianceStatus,
  IComplianceCondition,
  IComplianceDefinition,
  IComplianceDefinitionQuestion,
  IComplianceErrors,
  IComplianceFieldOption,
  IComplianceSigningStatus,
  IComplianceStatus,
  IUpdateComplianceAnswer,
} from "../libs/models/CustomerCompliance/Compliance";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeObservable, observable } from "mobx";
import { CustomerComplianceApi } from "../libs/api";
import { tx } from "../libs/i18n";

export class ComplianceStore {
  customerComplianceApi: CustomerComplianceApi;

  constructor(customerComplianceApi: CustomerComplianceApi) {
    this.customerComplianceApi = customerComplianceApi;
    makeObservable(this);
  }

  @observable
  complianceStatus?: IComplianceStatus = undefined;

  @observable
  complianceDefinition: IComplianceDefinition[] = [];

  @observable
  complianceSigningStatus?: IComplianceSigningStatus = undefined;

  @observable
  currentStep: number = 0;

  @observable
  currentQuestions?: IComplianceDefinitionQuestion[];

  @observable
  currentErrors: IComplianceErrors = {};

  @observable
  showValidationErrors: boolean = false;

  @observable
  showUnexpectedError: boolean = false;

  @observable
  fetchingDefinition: boolean = false;

  @observable
  updating: boolean = false;

  @observable
  fetchingSignature: boolean = false;

  @observable
  isProcessing: boolean = false;

  @observable
  signingStateChecker?: NodeJS.Timeout;

  @observable
  contractGenerationChecker?: NodeJS.Timeout;

  @observable
  signingBackAction?: () => void = undefined;

  acceptedDocumentMimeTypes = ["application/pdf", "image/jpeg", "image/tiff", "image/gif", "image/png"];

  acceptedDocumentExtensions = [".pdf", ".jpg", ".jpeg", ".tiff", ".gif", ".png"];

  @computed
  get hasNextStep(): boolean {
    return this.currentStep <= this.complianceDefinition.length - 1;
  }

  @computed
  get hasPreviousStep(): boolean {
    if (!this.complianceDefinition || this.complianceDefinition.length === 0 || this.currentStep === 0) return false;

    const currentPage = this.complianceDefinition[this.currentStep];
    const previousPage = this.complianceDefinition[this.currentStep - 1];
    if (!currentPage || !previousPage) return false;

    if (currentPage.title !== previousPage.title || currentPage.accountId !== previousPage.accountId) return false;

    return true;
  }

  @computed
  get isInProgress(): boolean {
    return this.complianceStatus?.status === ComplianceStatus.InProgress;
  }

  @computed
  get isNotStarted(): boolean {
    return this.complianceStatus?.status === ComplianceStatus.NotStarted;
  }

  @computed
  get isActiveQuestionnaire(): boolean {
    // Is not on final definition step AND status is NotStarted or InProgress
    return (
      (this.hasNextStep && this.complianceStatus?.status !== ComplianceStatus.Completed) ||
      this.complianceStatus?.status === ComplianceStatus.NotStarted ||
      this.complianceStatus?.status === ComplianceStatus.InProgress
    );
  }

  @computed
  get isSignable(): boolean {
    // Is on final step AND status is Signable
    return (
      this.currentStep === this.complianceDefinition.length &&
      this.complianceStatus?.status === ComplianceStatus.Signable
    );
  }

  @computed
  get isCompleted(): boolean {
    return this.complianceStatus?.status === ComplianceStatus.Completed;
  }

  getStatus = async () => {
    const response = await this.customerComplianceApi.getStatuses();
    if (response?.ok && response.data) {
      this.complianceStatus = response.data;
    }
  };

  getDefinition = async () => {
    this.fetchingDefinition = true;
    const response = await this.customerComplianceApi.getDefinitions();
    if (response?.ok && response.data) {
      this.complianceDefinition = response.data;
      if (this.complianceStatus?.bookmarkedAnswerId) {
        const currentStepIndex = response.data.findIndex(
          (step) => step.answerId === this.complianceStatus!.bookmarkedAnswerId,
        );

        if (currentStepIndex > -1) this.currentStep = currentStepIndex;
      }
      this.setCurrentStepByIndex(this.currentStep);
    }
    this.fetchingDefinition = false;
  };

  getSigningLink = async (): Promise<string | undefined> => {
    this.fetchingSignature = true;
    const response = await this.customerComplianceApi.getSignatures();
    this.fetchingSignature = false;
    if (response?.ok && response.data) {
      this.complianceSigningStatus = response.data;
      switch (response.data.status) {
        case ComplianceSigningStatus.Signed:
          if (this.complianceStatus) this.complianceStatus.status = ComplianceStatus.Completed;
          return undefined;
        case ComplianceSigningStatus.GeneratingSingningLink:
          this.isProcessing = true;
          this.startContractGenerationPolling();
          return undefined;

        case ComplianceSigningStatus.Signable:
          if (!response.data.signatureLink) {
            this.showUnexpectedError = true;
            return undefined;
          }
          return response.data.signatureLink;
        default:
          this.showUnexpectedError = true;
          return undefined;
      }
    }
    this.showUnexpectedError = true;
    return undefined;
  };

  @action
  startSigningPolling = async () => {
    if (this.signingStateChecker === undefined) {
      this.signingStateChecker = setInterval(async () => {
        const check = await this.pollForSigningState();
        if (check) {
          if (this.signingStateChecker) {
            clearInterval(this.signingStateChecker);
            this.signingStateChecker = undefined;
            this.isProcessing = false;
            this.signingBackAction = undefined;
            this.complianceStatus!.status = ComplianceStatus.Completed;
          }
        }
      }, 5000);
    }
  };

  @action
  pollForSigningState = async () => {
    if (this.fetchingSignature) {
      // Still fetching, skip this polling iteration
      return false;
    }

    this.fetchingSignature = true;
    const response = await this.customerComplianceApi.getSignatures();
    this.fetchingSignature = false;
    if (response?.ok && response.data) {
      this.complianceSigningStatus = response.data;
      return response.data.status === ComplianceSigningStatus.Signed;
    }
    return false;
  };

  @action
  startContractGenerationPolling = async () => {
    if (this.contractGenerationChecker === undefined) {
      this.contractGenerationChecker = setInterval(async () => {
        const check = await this.pollForContractGeneration();
        if (check) {
          if (this.contractGenerationChecker) {
            clearInterval(this.contractGenerationChecker);
            this.contractGenerationChecker = undefined;
            if (this.complianceSigningStatus) {
              // TODO: Implement redirection for mobile apps
              window?.open(this.complianceSigningStatus.signatureLink, "_blank")?.focus();
              this.startSigningPolling();
            }
          }
        }
      }, 5000);
    }
  };

  @action
  pollForContractGeneration = async () => {
    if (this.fetchingSignature) {
      // Still fetching, skip this polling iteration
      return false;
    }

    this.fetchingSignature = true;
    const response = await this.customerComplianceApi.getSignatures();
    this.fetchingSignature = false;
    if (response?.ok && response.data) {
      this.complianceSigningStatus = response.data;
      return response.data.status === ComplianceSigningStatus.Signable && response.data.signatureLink;
    }
    return false;
  };

  @action
  updateField = (id: string, answers: IComplianceFieldOption[]) => {
    if (!this.currentQuestions) return;
    this.showUnexpectedError = false;
    const item = this.getQuestionById(id);
    if (item) item.answers = answers;
    this.resetFieldErrors(id);
  };

  @action
  setNextStep = async () => {
    if (!this.complianceDefinition || !this.currentQuestions) return;

    const step = this.complianceDefinition[this.currentStep];

    if (!step) return;

    this.updating = true;
    let isSuccessful = true;

    const hasEditableQuestions = step.questions.some((q) => q.editable || q.children?.some((c) => c.editable));

    if (hasEditableQuestions) {
      this.showValidationErrors = false;
      this.showUnexpectedError = false;
      this.currentErrors = {};

      isSuccessful = false;

      if (step.questions.length > 0) {
        if (!this.validateStep()) {
          // Show validation error list if frontend validation is needed
          this.updating = false;
          return;
        }

        const updateRequest = this.generateUpdateRequest();

        if (updateRequest) {
          const response = await this.customerComplianceApi.updateAnswers(step.answerId, updateRequest);

          if (response?.ok) {
            // Update definition & status
            this.complianceDefinition[this.currentStep].questions = this.currentQuestions!;
            await this.getStatus();
            isSuccessful = true;
          } else if (response?.status === 400 && response?.data?.errors) {
            this.currentErrors = response.data.errors;
            this.showValidationErrors = true;
          } else {
            this.showUnexpectedError = true;
          }
        }
      }
    }

    if (isSuccessful && this.complianceStatus?.status !== ComplianceStatus.Completed) {
      this.setCurrentStepByIndex(this.currentStep + 1);
    }

    this.updating = false;
  };

  @action
  setPrevStep = () => {
    if (!this.complianceDefinition) return;

    if (this.hasPreviousStep) {
      this.setCurrentStepByIndex(this.currentStep - 1);
    }
  };

  @action
  setCurrentStepByIndex = (i: number) => {
    this.currentStep = i;
    if (i >= 0 && i < this.complianceDefinition.length) {
      this.currentQuestions = JSON.parse(JSON.stringify(this.complianceDefinition[i].questions));
    }
  };

  validateStep = () => {
    if (!this.complianceDefinition || this.currentStep === undefined) return false;

    // Implement frontend validation logic if applicable
    return true;
  };

  getQuestionById = (
    id: string,
    items?: IComplianceDefinitionQuestion[],
  ): IComplianceDefinitionQuestion | undefined => {
    const questions = items || this.currentQuestions;
    if (!questions) return undefined;

    for (const item of questions) {
      if (item.id === id) return item;
      if (item.children) {
        const child = this.getQuestionById(id, item.children);
        if (child) return child;
      }
    }

    return undefined;
  };

  generateAnswersPayloadByQuestionType = (question: IComplianceDefinitionQuestion): IComplianceFieldOption[] => {
    if (!question?.answers) return [];

    switch (question.type) {
      case ComplianceFieldType.TextField:
      case ComplianceFieldType.PositiveIntegerInput:
      case ComplianceFieldType.DecimalInput:
      case ComplianceFieldType.TextArea:
      case ComplianceFieldType.CheckBox:
      case ComplianceFieldType.DatePicker:
        return question.answers.map((answer) => ({ id: answer.text }));

      case ComplianceFieldType.RadioButtons:
      case ComplianceFieldType.RadioButtonsYesNo:
      case ComplianceFieldType.DropDownList:
      case ComplianceFieldType.DropDownListYesNo:
      case ComplianceFieldType.DropDownListMulti:
        return question.answers.map((answer) => ({ id: answer.id }));

      default:
        return [];
    }
  };

  generateUpdateRequest = (): IUpdateComplianceAnswer[] => {
    const updatedAnswers: IUpdateComplianceAnswer[] = [];

    const generateUpdateRequestByItems = (items: IComplianceDefinitionQuestion[]) => {
      if (!items) return;
      items.forEach((question) => {
        const shouldSkipConditional =
          question.type === ComplianceFieldType.Conditional &&
          !!question.conditions &&
          !this.validateConditionalsByQuestion(question);
        if (shouldSkipConditional) return;

        if (question.editable && question.id && question.answers) {
          const answers = this.generateAnswersPayloadByQuestionType(question);
          const updatedQuestion: IUpdateComplianceAnswer = {
            questionId: question.id,
            answers,
          };
          updatedAnswers.push(updatedQuestion);
        }

        if (question.children && question.children.length > 0) generateUpdateRequestByItems(question.children);
      });
    };

    if (this.currentQuestions) {
      generateUpdateRequestByItems(this.currentQuestions);
    }

    return updatedAnswers;
  };

  questionHasAnswers = (question: IComplianceDefinitionQuestion) =>
    question.answers && question.answers.some((a) => a.id);

  validateConditionalsByQuestion = (question: IComplianceDefinitionQuestion) => {
    let isValid = false;
    const { conditions } = question;

    isValid = this.validateConditions(conditions);

    return isValid;
  };

  validateConditions = (conditions?: IComplianceCondition[]): boolean => {
    if (!conditions) return false;
    let isValid = false;

    for (const condition of conditions) {
      if (!condition) continue;

      if (condition.operator === ComplianceConditionOperator.True) {
        isValid = true;
      } else {
        const conditionQuestion = condition.questionId ? this.getQuestionById(condition.questionId) : undefined;

        const answers = conditionQuestion ? this.generateAnswersPayloadByQuestionType(conditionQuestion) : [];
        const answer = answers?.[0]?.id;
        const valueList = condition.value?.split(",");

        switch (condition.operator) {
          case ComplianceConditionOperator.Equals:
            isValid = !!answer && answer === condition.value;
            break;
          case ComplianceConditionOperator.NotEquals:
            isValid = answer !== condition.value;
            break;
          case ComplianceConditionOperator.GreaterThan:
            isValid = !!answer && +answer > +condition.value;
            break;
          case ComplianceConditionOperator.LessThan:
            isValid = !!answer && +answer < +condition.value;
            break;
          case ComplianceConditionOperator.GreaterThanOrEqualTo:
            isValid = !!answer && +answer >= +condition.value;
            break;
          case ComplianceConditionOperator.LessThanOrEqualTo:
            isValid = !!answer && +answer <= +condition.value;
            break;
          case ComplianceConditionOperator.IsEmpty:
            isValid = !answer;
            break;
          case ComplianceConditionOperator.IsNotEmpty:
            isValid = !!answer;
            break;
          case ComplianceConditionOperator.Contains:
            isValid = answers && !!answers.find((a) => a.id === condition.value);
            break;
          case ComplianceConditionOperator.ContainsAny:
            isValid = answers && !!answers.find((a) => a.id && valueList.includes(a.id));
            break;
          case ComplianceConditionOperator.ContainsAll:
            isValid = answers && valueList.every((value) => !!answers.find((a) => a.id === value));
            break;
          case null:
            // null operator requires no validation and will include child conditions that will be validated below
            isValid = true;
            break;
          default:
            isValid = false;
            break;
        }
      }

      if (!!condition.conditions && condition.conditions.length > 0) {
        isValid = isValid && this.validateConditions(condition.conditions);
      }

      if (isValid) break;
    }

    return isValid;
  };

  uploadAttachments = async (answerId: string, questionId: string, attachments: File[]) => {
    const question = this.getQuestionById(questionId);
    if (!attachments || attachments.length === 0 || !question) return;
    this.resetFieldErrors(questionId);

    const formData = new FormData();

    attachments.forEach((attachment) => formData.append("attachments", attachment));

    const response = await this.customerComplianceApi.uploadAttachments(answerId, questionId, formData);
    if (response?.ok && response.data?.attachments) {
      const successfulUploads = response.data?.attachments.filter((attachemnt) => !!attachemnt.fileId);
      const updatedAnswers: IComplianceFieldOption[] = successfulUploads.map((attachment) => ({
        id: attachment.fileName,
        text: attachment.fileId,
      }));
      question.answers = [...(question.answers || []), ...updatedAnswers];

      const errors = response.data.attachments
        .filter((attachment) => !attachment.fileId)
        .map((error) => tx("file.singleFileAttachmentError", { fileName: error.fileName }));

      if (errors && errors.length > 0) {
        this.currentErrors[questionId] = errors;
        this.showValidationErrors = true;
      }
    } else {
      this.currentErrors[questionId] = [tx("compliance.unexpectedErrorText")];
      this.showValidationErrors = true;
    }
  };

  deleteAttachment = async (answerId: string, questionId: string, attachmentId: string) => {
    const question = this.getQuestionById(questionId);
    if (!attachmentId || !question) return;
    this.resetFieldErrors(questionId);

    const response = await this.customerComplianceApi.deleteAttachment(answerId, questionId, attachmentId);

    if (response?.ok) {
      question.answers = question.answers?.filter((a) => a.text !== attachmentId);
    } else {
      this.currentErrors[questionId] = [tx("compliance.unexpectedErrorText")];
      this.showValidationErrors = true;
    }
  };

  resetFieldErrors = (questionId: string) => {
    delete this.currentErrors[questionId];
    if (Object.keys(this.currentErrors).length === 0) this.showValidationErrors = false;
  };

  @action
  resetDefinition = () => {
    this.complianceDefinition = [];
    this.currentStep = 0;
    this.currentQuestions = undefined;
    this.currentErrors = {};
    this.showValidationErrors = false;
    this.showUnexpectedError = false;
    this.fetchingDefinition = false;
    this.updating = false;
    this.isProcessing = false;
    this.fetchingSignature = false;
    this.signingBackAction = undefined;
    if (this.signingStateChecker) {
      clearInterval(this.signingStateChecker);
      this.signingStateChecker = undefined;
    }
    if (this.contractGenerationChecker) {
      clearInterval(this.contractGenerationChecker);
      this.contractGenerationChecker = undefined;
    }
  };

  @action
  resetStore = () => {
    this.complianceStatus = undefined;
    this.complianceSigningStatus = undefined;
    this.resetDefinition();
  };
}
