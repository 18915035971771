import { IAccountInsurance } from "../Content/Insurance";
import { IBaseAccount } from "./BaseAccount";
import { ICarInformation } from "./Car";
import { IPromiseToPayInvoiceInfo, InvoiceDistributionFormatType } from "./Invoice";

export interface ILeaseAccountApiResponse {
  leasingAccount?: ILeaseAccount;
  error?: string;
}

export interface ILeaseAccountsApiResponse {
  leaseAccounts?: ILeaseAccount[];
  errors?: string;
}

export interface ILeaseAccount extends ILeaseBaseAccount {
  paymentAccount?: string;
  hasInsurance?: boolean;
  contractPeriod: number;
  elapsedPeriod: number;
  openDate: string;
  invoiceFee?: number;
  canPromiseToPay?: boolean;
  canPromiseToPayInvoiceInformation?: IPromiseToPayInvoiceInfo;
  invoiceDistributionFormat?: InvoiceDistributionFormatType;
  invoiceMethod?: string;
  canRequestMileageChange?: boolean;
  canRequestInsurance?: boolean;
  eInvoice?: {
    eInvoiceDate: string;
  };
  insurances?: IAccountInsurance[];
}

export interface ILeaseBaseAccount extends IBaseAccount {
  carInformation: ICarInformation;
}

export interface IVendorAddress {
  street1: string;
  street2: string;
  postalCode: string;
  country: string;
  type: string;
}

export interface IVendor {
  addresses?: IVendorAddress[];
  id: string;
  customerNumber?: number;
  name?: string;
  contactName?: string;
  phones?: string[];
  mobiles?: string[];
  eMails?: string[];
}

export interface IEngagementsObject {
  id: number;
  objectId?: string;
  make?: string;
  model?: string;
  serial?: string;
  year?: number;
}

export interface ILeaseAccountInfoLabels {
  leaseInformationLabel: string;
  rentalPeriodLabel: string;
  accountNumberLabel: string;
  expirationDateLabel: string;
  dealerLabel: string;
  modelLicenseLabel: string;
}

export interface ILeaseInterestsFeesLabels {
  interestFeesLabel: string;
  aviFeeLabel: string;
}

export interface IGetPaymentsRequest {
  accountId: string;
  paymentType: PaymentType;
  dateFrom?: string;
  dateTo?: string;
}

export enum PaymentType {
  All = "all",
  Due = "due",
  Overdue = "overdue",
  Paid = "paid",
}
