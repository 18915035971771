import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReactPlugin, AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { initStore, Store } from "netbank-shared/src/stores/Store";
import { StoreContext } from "netbank-shared/src/contexts/storesContext";
import { PageLoader } from "~views/shared";
import { OutdatedBrowser } from "~views/pages/OutdatedBrowser/OutdatedBrowser";
import { getConfig } from "../config/config";
import { WebCommonService } from "./WebCommonService";
import WebPlatformStore from "./WebPlatformStore";
import { ConfirmationPopup } from "~views/shared/ConfirmationPopup/ConfirmationPopup";
import { RetryPage } from "~views/pages/RetryPage/RetryPage";
import { useNavigate } from "react-router-dom";

const onError = () => <RetryPage />;

export const withStore = (Comp: any) => {
  const ComponentWithStore = () => {
    const confirmationPopupRef: RefObject<HTMLDivElement> = useRef(null);
    const [store, setStore] = useState<Store>();
    const navigate = useNavigate();

    const initializeTelemetry = (connectionString?: string) => {
      if (!connectionString) return undefined;

      const reactPlugin = new ReactPlugin();

      const appInsights = new ApplicationInsights({
        config: {
          connectionString,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
          isStorageUseDisabled: true,
        },
      });
      appInsights.loadAppInsights();

      return { reactPlugin, appInsights };
    };

    useEffect(() => {
      const mountStore = async () => {
        const config = await getConfig();
        const s = initStore(localStorage, localStorage, new WebCommonService(navigate), new WebPlatformStore(config));
        if (!s.telemetryPlugin) {
          const telemetryConfig = initializeTelemetry(config?.TELEMETRY_CONNECTION_STRING);
          s.telemetryPlugin = telemetryConfig?.reactPlugin;
          s.appInsights = telemetryConfig?.appInsights;
        }

        const mountedStore = await s.onMount(config, window, navigate);
        setStore(mountedStore);
      };

      mountStore();
    }, []);

    if (!store?.config) {
      return null;
    }

    if (store.telemetryPlugin) {
      return (
        <StoreContext.Provider value={store}>
          <AppInsightsContext.Provider value={store.telemetryPlugin}>
            <AppInsightsErrorBoundary appInsights={store.telemetryPlugin} onError={onError}>
              {store.uiStore.hasOutdatedBrowser ? (
                <OutdatedBrowser />
              ) : (
                <>
                  <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />
                  <PageLoader />
                  <Comp store={store} />
                </>
              )}
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        </StoreContext.Provider>
      );
    }

    return (
      <StoreContext.Provider value={store}>
        {store.uiStore.hasOutdatedBrowser ? (
          <OutdatedBrowser />
        ) : (
          <>
            <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />
            <PageLoader />
            <Comp store={store} />
          </>
        )}
      </StoreContext.Provider>
    );
  };

  return ComponentWithStore;
};
