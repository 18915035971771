import React from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { ExpandableText } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { tx } from "netbank-shared/src/libs/i18n";
import { decodeBrackets } from "netbank-shared/src/libs/utils";

interface IAccordionFaqBlockProps {
  data: IGenericContentLink<IFAQItem>;
  accountId?: string;
}

export const AccordionFaqBlock = observer(({ data, accountId }: IAccordionFaqBlockProps) => {
  const publishDate = data.showPublishDate && data.saved && dayjs(data.saved).format("DD MMM, YYYY");

  return (
    <ExpandableText header={data.question} expanded={data.isOpen}>
      <HtmlContent html={decodeBrackets(data.answer)} queryParamLink={{ accountId }} />
      {publishDate && (
        <p style={{ fontStyle: "italic" }}>
          <br />
          {tx("misc.published")}
          {` ${publishDate}`}
        </p>
      )}
    </ExpandableText>
  );
});
