/* eslint-disable import/no-extraneous-dependencies */
import { tx } from "netbank-shared/src/libs/i18n";
import {
  AdditionalAmountType,
  ILoanAccount,
  IPayment,
  IPaymentReference,
} from "netbank-shared/src/libs/models/CustomerProducts";
import { formatDate } from "./date";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { InvoiceReferenceLineType, Locale, PaymentDataType } from "../models/Content/Enums";

export interface IMapDataProperty {
  dataType: PaymentDataType;
  payment: IPayment;
  account: ILoanAccount;
  locale?: Locale;
  currency: string;
}

export const mapDataProperty = ({ dataType, payment, account, locale, currency }: IMapDataProperty) => {
  switch (dataType) {
    case PaymentDataType.AccountNumber:
      return account.displayNumber;
    case PaymentDataType.AccountName:
      return account.name;
    case PaymentDataType.AdministrationFee:
      return toLocaleString(payment.adminFee, currency, locale);
    case PaymentDataType.Balance:
      return toLocaleString(payment.balance, currency, locale);
    case PaymentDataType.CustomerNumber:
      return payment.customerNumber;
    case PaymentDataType.DueDate:
      return formatDate(payment.dueDate);
    case PaymentDataType.FeesAndInsurance:
      return toLocaleString(payment.feesAndInsurance, currency, locale);
    case PaymentDataType.Installment:
      return toLocaleString(payment.installmentAmount, currency, locale);
    case PaymentDataType.InsuranceFee:
      return toLocaleString(payment.insuranceFee, currency, locale);
    case PaymentDataType.InterestRate:
      return payment.interestRate;
    case PaymentDataType.InterestAmount:
      return toLocaleString(payment.interestAmount, currency, locale);
    case PaymentDataType.InvoiceDate:
      return formatDate(payment.invoiceDate);
    case PaymentDataType.InvoiceNumber:
      return payment.invoiceNumber;
    case PaymentDataType.InvoicePeriod:
      return !!payment.periodStartDate && !!payment.periodEndDate
        ? `${formatDate(payment.periodStartDate)} - ${formatDate(payment.periodEndDate)}`
        : undefined;
    case PaymentDataType.InvoicePeriodStartDate:
      return formatDate(payment.periodStartDate);
    case PaymentDataType.RemainingDebt:
      return toLocaleString(payment.remainderAmount, currency, locale);
    case PaymentDataType.TotalAmount:
      return toLocaleString(payment.totalAmount, currency, locale);
    default:
      return undefined;
  }
};

export interface IFeeLabels {
  insuranceFeeLabel?: string;
  monthlyFeeLabel?: string;
  principalPenaltyFeeLabel?: string;
  reminderFeeLabel?: string;
  terminationFeeLabel?: string;
}

export const mapFeeLabels = (labels: IFeeLabels, amountType: AdditionalAmountType) => {
  switch (amountType) {
    case AdditionalAmountType.Insurance:
      return labels.insuranceFeeLabel || tx("invoice.insuranceFee");
    case AdditionalAmountType.MonthlyFee:
      return labels.monthlyFeeLabel || tx("invoice.monthlyFeeLoan"); // TODO: When leasing accounts are added, look at account type to determine if it should be monthlyFeeLoan or monthlyFeeLeasing
    case AdditionalAmountType.PrincipalPenalty:
      return labels.principalPenaltyFeeLabel || tx("invoice.principalPenaltyFee");
    case AdditionalAmountType.ReminderFee:
      return labels.reminderFeeLabel || tx("invoice.reminderFee");
    case AdditionalAmountType.TerminationFee:
      return labels.terminationFeeLabel || tx("invoice.terminationFee");
    default:
      return "";
  }
};

export const mapPaymentReferenceLines = (reference: IPaymentReference, referenceLineType: InvoiceReferenceLineType) => {
  switch (referenceLineType) {
    case InvoiceReferenceLineType.BankAccount:
      return reference.bankAccount;
    case InvoiceReferenceLineType.BIC:
      return reference.businessIdentificationCode;
    case InvoiceReferenceLineType.InvoiceReference:
      return reference.invoiceReference;
    case InvoiceReferenceLineType.ReceiverName:
      return reference.receiverName;
    case InvoiceReferenceLineType.VirtualBarcode:
      return reference.virtualBarcode;
    default:
      return "";
  }
};
