import { observable, action, computed, makeObservable } from "mobx";
import dayjs from "dayjs";
import { onAnimationFrame } from "../libs/utils";
import { screenBreakpoints, TScreenKeys } from "../libs/constants";
import { Lang, Locale, CountryCode, Currency } from "../libs/models/Content/Enums";
import { IPopup } from "../libs/models/Content/Popup";
import { IModal } from "../libs/models/Content/Modal";
import { IPopover } from "../libs/models/Content/Popover";
import { IConfirmationPopup } from "../libs/models/Content/ConfirmationPopup";
import { Store } from "./Store";
import { IAccordionView } from "../libs/models/Content/AccordionView";
import { IPlatformStore } from "../libs/models/PlatformStore";
import { supportedLangs, tx, initTranslations } from "../libs/i18n";
import { getLangInUrl } from "../libs/utils/url";

export class UIStore {
  platformStore: IPlatformStore;

  rootStore: Store;

  window: Window | undefined;

  customerActivityEvents = [
    "mousemove",
    "mousedown",
    "mousewheel",
    "keypress",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  logoutWarningTime: number = 1000 * 60 * 14;

  logoutTime: number = 1000 * 60 * 15;

  @observable
  breakpoint: TScreenKeys = "xl";

  @observable
  windowWidth: number = 0;

  @observable
  windowHeight: number = 0;

  @observable
  windowPageYOffset: number = 0;

  @observable
  windowPageXOffset: number = 0;

  @observable
  locale?: Locale;

  @observable
  lang?: Lang;

  @observable
  currency: string = "";

  @observable
  currencySymbol: string = "";

  @observable
  popups: IPopup[] = [];

  @observable
  popover?: IPopover = undefined;

  @observable
  modal?: IModal = undefined;

  @observable
  hideAccordionBlockTitle?: boolean = false;

  @observable
  accordionView?: IAccordionView = undefined;

  @observable
  modalAccordionView?: IAccordionView = undefined;

  @observable
  menuAccordionView?: IAccordionView = undefined;

  @observable
  confirmationPopup?: IConfirmationPopup = undefined;

  @observable
  loading: boolean = false;

  @observable
  logoAnimationLoaded: boolean = false;

  @observable
  isMounted: boolean = false;

  @observable
  hasOutdatedBrowser: boolean = false;

  @observable
  logoutWarningTimeout?: NodeJS.Timeout;

  @observable
  logoutTimeout?: NodeJS.Timeout;

  @observable
  tokenCheckTimeout?: NodeJS.Timeout;

  @observable
  isFirstPageview: boolean = true;

  @observable
  headerMessageDataText: string = "";

  @observable
  headerLogoutDataText: string = "";

  @observable
  headerCustomerProfileDataText: string = "";

  @observable
  showAppSplashScreen: boolean = true;

  @observable
  popoverMobileOpenId?: string;

  // Required for Android. AppBlurScreen shown due to datepicker opens in modal, triggering app blur.
  @observable
  datepickerMobileOpen?: boolean;

  @action
  setHeaderMessageDataText = (value: string) => {
    this.headerMessageDataText = value;
  };

  @action
  setHeaderLogoutDataText = (value: string) => {
    this.headerLogoutDataText = value;
  };

  @action
  setHeaderCustomerProfileDataText = (value: string) => {
    this.headerCustomerProfileDataText = value;
  };

  @computed
  get isMobile() {
    return this.windowWidth < 992;
  }

  @computed
  get isTablet() {
    return this.windowWidth < 1300;
  }

  @computed
  get isSm() {
    return this.windowWidth < 540;
  }

  @computed
  get langInUrl() {
    return this.window && getLangInUrl(this.window.location.pathname);
  }

  @computed
  get showMessageViews() {
    return this.lang === Lang.sv || this.lang === Lang.fi;
  }

  @computed
  get showTransferViews() {
    return this.lang === Lang.sv || this.lang === Lang.dk;
  }

  constructor(rootStore: Store, platformStore: IPlatformStore) {
    this.platformStore = platformStore;
    this.hasOutdatedBrowser = !!this.platformStore.isOutdated();
    this.rootStore = rootStore;
    makeObservable(this);
    this.beforeUnload = this.beforeUnload.bind(this);
  }

  onMount = async (window: any) => {
    if (!this.isMounted) {
      if (window) {
        this.window = window;
        this.window?.addEventListener("resize", this.onResize);
        this.onResize();

        this.window?.addEventListener("scroll", this.onScroll);
        this.onScroll();
      }

      // Rather short interval time to make sure the token doesn't expire
      // if the customer refreshes the page.
      this.tokenCheckTimeout = setInterval(() => {
        this.handleRefreshToken();
      }, 10 * 1000);

      // Check refresh token on load as well.
      this.handleRefreshToken();
    }

    // Sync language setting in URL with i18n
    if (supportedLangs.includes(tx("routing.lang")) && this.langInUrl && this.langInUrl !== tx("routing.lang")) {
      if (this.rootStore.contentStore.availableLanguages?.includes(this.langInUrl as Lang)) {
        this.rootStore.contentStore.changeLanguage(this.langInUrl);
      } else {
        this.rootStore.commonService.redirect(`/${tx("routing.lang")}`);
      }
    }

    this.isMounted = true;
  };

  initLocale = (country?: CountryCode) => {
    switch (country) {
      case CountryCode.SE:
        this.locale = Locale.sv;
        this.currency = Currency.SEK;
        this.lang = Lang.sv;
        break;
      case CountryCode.FI:
        this.locale = Locale.fi;
        this.currency = Currency.EUR;
        this.lang = Lang.fi;
        break;
      case CountryCode.NO:
        this.locale = Locale.no;
        this.currency = Currency.NOK;
        this.lang = Lang.no;
        break;
      case CountryCode.DK:
        this.locale = Locale.dk;
        this.currency = Currency.DKK;
        this.lang = Lang.dk;
        break;
      default:
        break;
    }

    initTranslations(this.lang);
    this.rootStore.contentStore.setI18nLang(this.lang);

    if (this.locale && this.currency) {
      this.currencySymbol = (0)
        .toLocaleString(this.locale, {
          currency: this.currency,
          style: "currency",
        })
        .replace(/\d/g, "")
        .replace(/,/g, "")
        .trim();
    }
  };

  beforeUnload() {
    this.onUnmount();
  }

  registerBrowserLeaveWarning() {
    this.window?.addEventListener("beforeunload", this.beforeUnload);
  }

  deRegisterBrowserLeaveWarning() {
    this.window?.removeEventListener("beforeunload", this.beforeUnload);
  }

  onUnmount = () => {
    if (this.isMounted) {
      if (this.window) {
        this.window.removeEventListener("resize", this.onResize);
        this.window.removeEventListener("scroll", this.onScroll);
        this.window.removeEventListener("beforeunload", this.beforeUnload);
        this.customerActivityEvents.forEach((e) =>
          this.window?.removeEventListener(e, this.resetCustomerActivityTimers),
        );
        if (this.tokenCheckTimeout) {
          clearInterval(this.tokenCheckTimeout);
        }
      }
      this.isMounted = false;
    }
  };

  // Inactivity helpers

  initCustomerActivityEvents = () => {
    if (window && this.platformStore.isWeb()) {
      this.resetCustomerActivityTimers();
      this.customerActivityEvents.forEach((e) => window.addEventListener(e, this.resetCustomerActivityTimers));
    }
    if (this.platformStore.isApplication()) {
      this.resetCustomerActivityTimers();
    }
  };

  initCustomerActivityTimeouts = () => {
    if (this.platformStore.isWeb()) {
      this.logoutWarningTimeout = setTimeout(this.handleWarning, this.logoutWarningTime);
    }
    this.logoutTimeout = setTimeout(this.handleLogout, this.logoutTime);
  };

  resetCustomerActivityTimers = () => {
    this.clearCustomerActivityTimeoutFunc();
    this.initCustomerActivityTimeouts();
  };

  clearCustomerActivityTimeoutFunc = () => {
    if (this.logoutWarningTimeout) clearTimeout(this.logoutWarningTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  handleWarning = () => {
    this.rootStore.setIdleWarning();
  };

  handleLogout = async () => {
    if (this.platformStore.isApplication()) {
      await this.rootStore.logout(true);
    } else {
      await this.rootStore.logout();
    }
  };

  // actions
  handleRefreshToken = async () => {
    const { expiresIn, tokenTimestamp } = this.rootStore.securePersistentStore;
    if (tokenTimestamp && expiresIn) {
      const tokenTimestampDate = dayjs(tokenTimestamp).add(expiresIn, "milliseconds");

      if (tokenTimestampDate.isBefore(dayjs())) {
        this.rootStore.refreshToken();
      }
    }
  };

  @action
  onResize = () => {
    onAnimationFrame(() => {
      this.windowWidth = this.window?.innerWidth || 0;
      this.windowHeight = this.window?.innerHeight || 0;
      this.breakpoint = Object.keys(screenBreakpoints).find((key: any) => {
        return this.isBreakpoint(key);
      }) as TScreenKeys;
    });
  };

  @action
  onScroll = () => {
    onAnimationFrame(() => {
      this.windowPageYOffset = this.window?.scrollY || 0;
      this.windowPageXOffset = this.window?.scrollX || 0;
    });
  };

  isBreakpoint = (breakpoint: TScreenKeys) => {
    return screenBreakpoints[breakpoint] >= this.windowWidth;
  };

  @action
  setPopup = (popup: IPopup): number => {
    return this.popups.push(popup) - 1;
  };

  removePopup = (index: number) => {
    this.popups.splice(index, 1);
  };

  @action
  setPopover = (popover: IPopover) => {
    this.popover = popover;
  };

  removePopover = () => {
    this.popover = undefined;
  };

  setModal = (modal: IModal) => {
    this.modal = modal;
  };

  setHideAccordionBlockTitle = (hide: boolean) => {
    this.hideAccordionBlockTitle = hide;
  };

  removeModal = () => {
    this.modal = undefined;
    this.modalAccordionView = undefined;
  };

  setAccordionView = (accordionView: IAccordionView) => {
    this.accordionView = accordionView;
  };

  setModalAccordionView = (modalAccordionView: IAccordionView) => {
    this.modalAccordionView = modalAccordionView;
  };

  setMenuAccordionView = (menuAccordionView: IAccordionView) => {
    this.menuAccordionView = menuAccordionView;
  };

  removeAccordionView = () => {
    this.accordionView = undefined;
  };

  removeModalAccordionView = () => {
    this.modalAccordionView = undefined;
  };

  removeMenuAccordionView = () => {
    this.menuAccordionView = undefined;
  };

  setConfirmationPopup = (confirmationPopup: IConfirmationPopup) => {
    this.confirmationPopup = confirmationPopup;
  };

  removeConfirmationPopup = () => {
    this.confirmationPopup = undefined;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setLogoAnimationLoaded = (logoAnimationLoaded: boolean) => {
    this.logoAnimationLoaded = logoAnimationLoaded;
  };

  setIsFirstPageview = (value: boolean) => {
    this.isFirstPageview = value;
  };

  setShowAppSplashScreen = (value: boolean) => {
    this.showAppSplashScreen = value;
  };

  setPopoverMobileOpenId = (value?: string) => {
    this.popoverMobileOpenId = value;
  };

  setDatepickerMobileOpen = (value: boolean) => {
    this.datepickerMobileOpen = value;
  };
}
