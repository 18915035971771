import * as React from "react";
import { observer } from "mobx-react";
import { withRoot } from "../../Root";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IUpdateDueDayPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { useDetermineUpdateDueDayParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { UpdateDueDayForm } from "./UpdateDueDayForm";
import { SelfServicePageSkeleton } from "~views/pages/Skeletons/SelfServicePageSkeleton";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";

export const UpdateDueDayPage = observer(() => {
  const { contentStore, loanStore } = useStores();
  const { currentAccount } = loanStore;
  const pageData = contentStore.currentPage as IUpdateDueDayPage;

  const updateParams = useDetermineUpdateDueDayParamsByAccountType(
    currentAccount?.accountType,
    currentAccount?.accountId,
  );

  useFetchData({
    condition: !updateParams.loadingCurrentDueDay,
    callback: () => {
      updateParams.getCurrentDueDay();
    },
    cleanup: () => updateParams.cleanUp(),
  });

  const { successContent, errorContent } = pageData;

  const { loadingError, loadingCurrentDueDay, productUrl } = updateParams;

  if (loadingCurrentDueDay) return <SelfServicePageSkeleton />;
  if (loadingError || !currentAccount?.canUpdateDueDay) return <SelfServiceFailurePage data={pageData.emptyState} />;

  const formComponent = (() => {
    const goBackToAccountUrl = `/${tx("routing.lang")}/${productUrl}/${currentAccount.accountId}`;
    switch (updateParams.updatingError) {
      case false:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={goBackToAccountUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case true:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={goBackToAccountUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      default:
        return (
          <UpdateDueDayForm
            pageData={pageData}
            updateParams={updateParams}
            accountInfo={{ name: currentAccount.name, displayNumber: currentAccount.displayNumber }}
            goToAccountUrl={goBackToAccountUrl}
          />
        );
    }
  })();

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText,
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: pageData.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  return <AccountActionContainer info={pageInfo}>{formComponent}</AccountActionContainer>;
});

export default withRoot(UpdateDueDayPage);
