export const Colors = {
  primary: "#f9f9f9",
  backgroundPrimary: "#f2f8fb",
  red: "#ec0000",
  mercury: "#e5e5e5",
  boulder: "#767676",
  black: "#191919",
  white: "#ffffff",
  borderGrey: "#cccccc",
  botticelli: "#c5dde6",
  tundora: "#444444",
  sinbad: "#99c4d4",
  blackSqueeze: "#deedf2",
  jellybean: "#257fa4",
  mediumSky: "#c3dee7",
  darkSky: "#9bc3d3",
  lightError: "#fde5e5",
  lightWarning: "#fffaeb",
  milkPunch: "#fff5d6",
  alto: "#e0e0e0",
  silver: "#bdbdbd",
  turquoise: "#1bb3bc",
  surfieGreen: "#137e84",
  doveGrey: "#666666",
  accesibleSky: "#257fa4",
  limeGreen: "#63ba68",
  shark: "#191c1c",
  green: "#008437",
  cornflowerBlue: "#f5f9fb",
  sunglow: "#ffcc33",
  lightSuccess: "#f0f8f0",
  catskillWhite: "#f2f8fa",
  codGray: "#191919",
};
