import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { DayModifiers } from "react-day-picker";
import { DayPickerComponent } from "../DayPicker/DayPicker";
import styles from "./DateRange.scss";
import { Button, Dropdown } from "..";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import calendarIcon from "~assets/calendar.svg";
import { useCloseWithEsc } from "~hooks";
import { formatDate } from "netbank-shared/src/libs/utils";
import { IDateInterval, IDateRangePreset } from "netbank-shared/src/libs/models/Date";

interface IDateProps {
  dateInterval?: IDateInterval;
  updateFilter: (dateLimits: IDateInterval | undefined) => void;
  title: string;
  setTitle: (newLabel: string) => void;
}

interface IDateRangeFormProps {
  closeAction: () => void;
  applyAction: (dateInterval: IDateInterval | undefined) => void;
  setTitle: (newLabel: string) => void;
}

const DateRangeForm = observer(({ closeAction, applyAction, setTitle }: IDateRangeFormProps) => {
  const [filterStart, setFilterStart] = useState<Date | undefined>(undefined);
  const [filterEnd, setFilterEnd] = useState<Date | undefined>(undefined);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [newLabel, setNewLabel] = useState({ value: "", fromPreset: false });

  const dateRangePresets: IDateRangePreset[] = [
    { index: 0, label: tx("date.last30"), value: "last-30-days" },
    { index: 1, label: tx("date.last3Months"), value: "last-3-months" },
    { index: 2, label: tx("date.thisYear"), value: "this-year" },
  ];

  const setFilterDateFromPreset = (selected: IDateRangePreset) => {
    setNewLabel({ value: selected.label, fromPreset: true });
    const today = new Date();

    switch (selected.index) {
      case 0: {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        setFilterStart(lastMonth);
        setFilterEnd(today);
        break;
      }
      case 1: {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        setFilterStart(threeMonthsAgo);
        setFilterEnd(today);
        break;
      }
      case 2: {
        const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
        setFilterStart(firstDayOfYear);
        setFilterEnd(today);
        break;
      }
      default:
        break;
    }
  };

  const apply = () => {
    const dateInterval =
      !!filterStart && !!filterEnd ? { startDate: dayjs(filterStart), endDate: dayjs(filterEnd) } : undefined;
    if (newLabel.fromPreset) {
      setTitle(newLabel.value);
    } else if (dateInterval) {
      setTitle(`${dateInterval.startDate?.format("YYMMDD")} - ${dateInterval.endDate?.format("YYMMDD")}`);
    }
    applyAction(dateInterval);
    closeAction();
  };

  const handleStartDayClick = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    if (date) setFilterStart(date);
    setShowStart(false);
    setShowEnd(true);
    setNewLabel({ value: "", fromPreset: false });
  };

  const handleEndDayClick = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    if (date) setFilterEnd(date);
    setShowEnd(false);
    setNewLabel({ value: "", fromPreset: false });
  };

  const dropdownOpenAction = () => {
    setShowStart(false);
    setShowEnd(false);
  };

  const startDayPickerClasses = [styles.dayPicker];

  if (showStart) {
    startDayPickerClasses.push(styles.open);
  }

  const endDayPickerClasses = [styles.dayPicker];

  if (showEnd) {
    endDayPickerClasses.push(styles.open);
  }
  return (
    <>
      <div className={styles.inputs}>
        <div className={styles.dateWrapper}>
          <span>{tx("misc.dateFrom")}</span>
          <Button
            title={(filterStart && formatDate(filterStart)) || tx("misc.selectDate")}
            onClick={() => {
              setShowStart(true);
              setShowEnd(false);
            }}
            active={showStart}
            iconSuffix={calendarIcon}
            borderColor="blue"
            bordered
            fullWidth
            justifySpaceBetween
          />
        </div>

        <div className={styles.dateWrapper}>
          <span>{tx("misc.dateTo")}</span>
          <Button
            title={(filterEnd && formatDate(filterEnd)) || tx("misc.selectDate")}
            onClick={() => {
              setShowEnd(true);
              setShowStart(false);
            }}
            active={showEnd}
            iconSuffix={calendarIcon}
            borderColor="blue"
            bordered
            fullWidth
            justifySpaceBetween
          />
        </div>
      </div>
      <div className={startDayPickerClasses.join(" ")}>
        <DayPickerComponent
          onDayClick={handleStartDayClick}
          initialMonth={filterStart}
          selectedDays={filterStart}
          disabledDays={
            filterEnd && {
              before: dayjs(filterEnd).subtract(1, "year").toDate(),
              after: filterEnd,
            }
          }
          tabIndex={showStart ? 0 : -1}
        />
      </div>
      <div className={endDayPickerClasses.join(" ")}>
        <DayPickerComponent
          onDayClick={handleEndDayClick}
          initialMonth={filterStart}
          selectedDays={filterEnd}
          disabledDays={
            filterStart && {
              before: filterStart,
              after: dayjs(filterStart).add(1, "year").toDate(),
            }
          }
          tabIndex={showEnd ? 0 : -1}
        />
      </div>

      <div className={styles.datePresets}>
        <span>{tx("date.periodOfTime")}</span>
        <Dropdown
          value={newLabel.value || tx("misc.select")}
          list={dateRangePresets}
          onChange={setFilterDateFromPreset}
          onOpen={dropdownOpenAction}
          innerLabel={tx("misc.select")}
        />
      </div>
      <div className={styles.actions}>
        <Button title={tx("misc.cancel")} onClick={closeAction} bordered color="black" borderColor="black" />
        <Button title={tx("misc.apply")} onClick={apply} color="red" />
      </div>
    </>
  );
});

export const DateRange = observer(({ updateFilter, dateInterval, title, setTitle }: IDateProps) => {
  const { uiStore } = useStores();

  const [open, setOpen] = useState(false);
  const [popupId, setPopupId] = useState(-1);
  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      if (popupId === -1) {
        setPopupId(
          uiStore.setPopup({
            children: (
              <DateRangeForm closeAction={() => setOpen(!open)} applyAction={updateFilter} setTitle={setTitle} />
            ),
            open,
            cancelAction: () => setOpen(!open),
            element: ref,
          }),
        );
      }
    } else if (popupId !== -1) {
      uiStore.removePopup(popupId);
      setPopupId(-1);
    }
  }, [open]);

  useCloseWithEsc(() => setOpen(false));

  const selected = dateInterval?.startDate && dateInterval.endDate;

  return (
    <div className={styles.wrapper}>
      <Button title={title} onClick={() => setOpen(!open)} bordered color={open || selected ? "blue" : ""} ref={ref} />
    </div>
  );
});
