import * as yup from "yup";
import { tx } from "../i18n";
import { INewMessagePage } from "libs/models/Content/Page";
import { Lang, Product } from "../models/Content/Enums";
import { IProductDropdownItem } from "libs/models/Content/NewMessageDropdownItem";
import { getSubjectForLang } from "../utils";

export interface INewConversationProductData {
  accountId?: string;
  productName?: string;
}

export interface INewConversationForm {
  body: string;
  productData?: INewConversationProductData;
  subject?: string;
  area?: string;
  files?: File[];
}

export interface INewConversationFormProps {
  content: INewMessagePage;
  products: IProductDropdownItem[];
  lang?: Lang;
}

export const generateNewConversationFormSchema = ({ products, lang }: INewConversationFormProps) => {
  const requiredError = tx("validators.required");

  const yupSchema = new yup.ObjectSchema<INewConversationForm>({
    productData: yup.object({
      accountId: yup.string(),
      productName: yup.mixed<Product>().oneOf(Object.values(Product)),
    }),
    body: yup.string().required(requiredError),
    subject: yup.string().when(["accountData"], {
      is: (value: INewConversationProductData) => {
        const subjects = products.find((p) => p.product === value?.productName)?.subjectDropdown;
        return !!value?.productName && subjects && subjects.length > 0;
      },
      then: (schema) => schema.required(requiredError),
    }),
    area: yup.string().when(["accountData", "subject"], {
      is: (accountValue: INewConversationProductData, subjectValue: string) => {
        const subjects = products.find((p) => p.product === accountValue?.productName)?.subjectDropdown;
        const areas = subjects?.find((s) => getSubjectForLang(s, lang) === subjectValue)?.areaDropdown;
        return !!accountValue && !!subjectValue && areas && areas.length > 0;
      },
      then: (schema) => schema.required(requiredError),
    }),
    files: yup.array(),
  });

  return yupSchema;
};
