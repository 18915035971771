import React, { useState } from "react";
import { observer } from "mobx-react";
import { Lottie } from "~views/shared";
import { useStores } from "netbank-shared/src/hooks";
import logoAnimationData from "~assets/lottie/logo_reveal.json";
import styles from "./PageLoader.scss";
import { delay } from "netbank-shared/src/libs/utils";
import { LoadingLottie } from "../Lottie/LoadingLottie";

export const PageLoader = observer(() => {
  const { uiStore } = useStores();
  const [showLoader, setShowLoader] = useState(false);

  const loadingClasses = [styles.loading];
  const loaderClasses = [styles.loader];

  if (uiStore.loading) {
    loadingClasses.push(styles.active);
  }

  if (showLoader) {
    loaderClasses.push(styles.active);
  }

  const logoAnimationCallback = async () => {
    uiStore.setLogoAnimationLoaded(true);
    await delay(200);
    setShowLoader(true);
  };

  return (
    <div className={loadingClasses.join(" ")}>
      {uiStore.loading && (
        <>
          <div className={uiStore.logoAnimationLoaded ? styles.logoLoaded : ""}>
            <Lottie
              options={{
                loop: false,
                animationData: logoAnimationData,
              }}
              height={315}
              width={400}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: logoAnimationCallback,
                },
              ]}
            />
          </div>
          <div className={loaderClasses.join(" ")}>
            <LoadingLottie />
          </div>
        </>
      )}
    </div>
  );
});
