import { observer } from "mobx-react";
import React from "react";
import { Location, useLocation, useParams } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { capitalize } from "netbank-shared/src/libs/utils";
import { DynamicLink } from "../DynamicLink/DynamicLink";
import styles from "./Breadcrumbs.scss";

interface IBreadcrumb {
  path: string;
  title: string;
  index?: number;
}

const getParamCrumbs = (
  getProductName: (nameId: [string, unknown] | undefined) => string,
  params: {},
  location: Location,
): IBreadcrumb[] | undefined => {
  if (Object.keys(params).length < 1) return undefined;
  const paramCrumbs: IBreadcrumb[] = [];
  const paramEntries = Object.entries(params);
  const pathSegments = location.pathname.split("/")?.filter((el) => el !== "");
  let path = "";

  pathSegments.forEach((segment, index) => {
    path += `/${segment}`;
    const param = paramEntries.find((el) => el[1] === segment);
    if (param?.length === 2)
      paramCrumbs.push({
        index,
        path,
        title: capitalize(getProductName(param)),
      });
  });
  return paramCrumbs;
};

export const Breadcrumbs = observer(() => {
  const { contentStore, creditStore, leasingStore, loanStore, depositStore } = useStores();
  const params = useParams();
  const location = useLocation();

  const generateBreadcrumbs = (
    getProductName: (nameId: [string, unknown] | undefined) => string,
    currentPage?: IPage,
  ) => {
    const active = currentPage?.menuItems?.find((item) => item.active);
    const menuItemsHasCurrentPath = currentPage?.menuItems?.find(
      (item) =>
        item.path === window.location.pathname || item.children?.find((c) => c.path === window.location.pathname),
    );

    const isHome = (path?: string) => {
      if (typeof path === "undefined") return false;
      return path?.toLowerCase()?.match(`^(/${tx("routing.lang")}|/${tx("routing.lang")}/|/|)$`) !== null;
    };

    const home = currentPage?.menuItems?.find((item) => isHome(item.path));
    const breadcrumbs: IBreadcrumb[] = [];
    let currentItem = active;

    const paramCrumbs = getParamCrumbs(getProductName, params, location);

    if (home?.path) {
      breadcrumbs.push({
        path: home?.path ?? "",
        title: home?.name ?? "",
      });
    }

    if (currentItem !== undefined) {
      do {
        if (!isHome(currentItem.path)) {
          breadcrumbs.push({
            path: currentItem.path ?? "",
            title: currentItem.name ?? "",
          });
        }
        currentItem = currentItem?.children?.find((el) => el?.active);
      } while (currentItem?.active);

      if (
        !contentStore.isTemplatePage &&
        !menuItemsHasCurrentPath &&
        currentItem?.path !== window.location.pathname &&
        !isHome(window.location.pathname)
      ) {
        // Adds currentPage as last element in breadcrumbs
        // if current path is not in menuItems && not template page
        breadcrumbs.push({
          path: window.location.pathname ?? "",
          title: currentPage?.name ?? "",
        });
      }

      if (paramCrumbs !== undefined && paramCrumbs?.length > 0) {
        /* eslint-disable array-callback-return */
        paramCrumbs?.map((crumb) => {
          if (crumb.index !== undefined) breadcrumbs.splice(crumb.index, 0, crumb);
        });
      }
    } else if (currentPage) {
      if (currentPage.parentLink?.url && currentPage.parentName) {
        breadcrumbs.push({
          path: currentPage.parentLink?.url ?? "",
          title: currentPage.parentName ?? "",
        });
      }
      breadcrumbs.push({
        path: window.location.pathname ?? "",
        title: currentPage.name ?? "",
      });
    }

    return breadcrumbs?.filter((el, i) => breadcrumbs.findIndex((e) => el.title === e.title) === i);
  };

  const getProductName = (nameId: [string, unknown] | undefined): string => {
    const { creditCustomerProducts } = creditStore;
    const { leasingAccounts } = leasingStore;
    const { loanCustomerProducts } = loanStore;
    const { depositCustomerProducts } = depositStore;
    let name = nameId?.[0].replace(/id$/i, "") || "";
    switch (name) {
      case "card": {
        const creditAccount = creditCustomerProducts?.find((account) => account.accountId === nameId?.[1]);
        name = creditAccount?.name || name;
        break;
      }
      case "leasing":
        name = leasingAccounts?.find((account) => account.accountId === nameId?.[1])?.name || name;
        break;
      case "loan":
        name = loanCustomerProducts?.find((account) => account.accountId === nameId?.[1])?.name || name;
        break;
      case "deposit":
        name = depositCustomerProducts?.find((account) => account.accountId === nameId?.[1])?.name || name;
        break;
      default:
        break;
    }
    return name;
  };

  const breadcrumbs = generateBreadcrumbs(getProductName, contentStore?.currentPage);

  return (
    <div className={styles.wrapper}>
      {breadcrumbs?.map((crumb: IBreadcrumb, i: number) => {
        const isLastCrumb = i === breadcrumbs.length - 1;
        return (
          <DynamicLink
            to={crumb.path}
            className={styles.link}
            key={`breadcrumb-${i}-${crumb.path}`}
            disabled={isLastCrumb}
          >
            {crumb.title}
          </DynamicLink>
        );
      })}
    </div>
  );
});
