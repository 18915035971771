import { tx } from "../i18n";
import { ExportCountry, InsuranceCompany } from "../models/Content/Enums";
import { IPowerOfAttorneyFormInfo, IPowerOfAttorneyTerms } from "libs/models/PowerOfAttorney";

export const isValidInput = (input: IPowerOfAttorneyFormInfo, invalidCountries?: ExportCountry[]) => {
  if (input.travelDate === undefined) return false;
  if (input.insuranceCompany === undefined) return false;
  if (input.acceptTermsAndExpense === false) return false;
  if (input.travellingToInvalidCountry && (input.countries.length === 0 || input.attachments?.length === 0))
    return false;
  if (input.travellingToInvalidCountry === undefined && invalidCountries && invalidCountries.length > 0) return false;
  return true;
};

export function getInvalidCountries(terms: IPowerOfAttorneyTerms, company?: string): ExportCountry[] | undefined {
  const invalidCountries = terms.insuranceCompanyTerms?.find(
    (term) => term.insuranceCompany === company,
  )?.invalidCountries;
  return invalidCountries;
}

export function hasInvalidCountries(terms: IPowerOfAttorneyTerms, company?: InsuranceCompany) {
  const invalidCountries = getInvalidCountries(terms, company);
  return !!invalidCountries && invalidCountries.length > 0;
}

export const getInsuranceCompanyDisplayValue = (company: string) => {
  switch (company) {
    case InsuranceCompany.Lahitapiola:
      return tx("insuranceCompany.lahitapiola");
    default:
      return company;
  }
};
