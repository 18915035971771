import { ListItem } from "../Content/ListItem";

export interface ICustomerResponse {
  customerData: ICustomer;
}

export interface ICustomer {
  customerId: string;
  name: string;
  surname: string;
  homePhone?: InputField;
  mobilePhone: InputField;
  emailAddress: InputField;
  address: Address;
  customerProducts?: CustomerProduct[];
  capabilities: ICustomerCapabilities;
  personalPreferences: IPersonalPreference[];
  partnerConsents?: IPartnerConsent[];
  parties?: IParty[];
}

export interface IUpdateContactInfo {
  updatedCustomerInfoError: boolean;
}

export interface Address {
  street: InputField;
  postCode: InputField;
  city: InputField;
  country: InputField;
}

export interface IParty {
  displayName: string;
  identityNumber: string;
  isOrganization: boolean;
  displayIdentityNumber: string;
}

enum CustomerProduct {
  Deposit = "Deposit",
  Credit = "Credit",
  Loan = "Loan",
  Leasing = "Leasing",
}

type ICustomerCapabilities = {
  canHandleTransferAccounts: boolean;
  hasNationalPayoutAccount: boolean;
};

export interface InputField {
  value?: string;
  editable?: boolean;
  validAnswers?: ListItem[];
}

export interface IPersonalPreference {
  id?: string;
  preferenceType: PreferenceType;
  required: boolean;
  editable: boolean;
  optionList: IOptionItem[];
}

export interface IOptionItem {
  id?: string;
  option: PreferenceOption;
  selected: boolean;
  index?: number;
}

export enum CustomerContactInfoField {
  Email = "Email",
  MobilePhone = "MobilePhone",
  HomePhone = "HomePhone",
  StreetAddress = "StreetAddress",
  PostCode = "PostCode",
  City = "City",
  Country = "Country",
}

export interface UpdateCustomerInfoData {
  homePhone?: string;
  mobilePhone?: string;
  emailAddress?: string;
  street?: string;
  postCode?: string;
  city?: string;
  country?: string;
  personalPreferences?: {
    preferenceType: string;
    optionList: IOptionItem[];
  }[];
}

export interface IPartnerConsent {
  companyNumber: string;
  partnerCompany: string;
  selected: boolean;
}

export enum PreferenceType {
  DKTermsAndConditions = "DKTermsAndConditions",
  DKConsentedToDigitalMarketing = "DKConsentedToDigitalMarketing",
  FINotificationBy = "FINotificationBy",
  FIMarketingBy = "FIMarketingBy",
}

export enum PreferenceOption {
  DKHasConsentedToDigitalMarketing = "DKHasConsentedToDigitalMarketing",
  DKHasAcceptedTermsAndConditions = "DKHasAcceptedTermsAndConditions",
  FIMarketingSMS = "FIMarketingSMS",
  FIMarketingEmail = "FIMarketingEmail",
  FINotifySMS = "FINotifySMS",
  FINotifyEmail = "FINotifyEmail",
}

export enum UpdateCustomerInfoStatus {
  UpdateSuccessfull = "UpdateSuccessfull",
  UpdateFailed = "UpdateFailed",
  ValidationError = "ValidationError",
}
