import { observer } from "mobx-react";
import React from "react";
import styles from "./CorporateHeader.scss";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";

export interface ICorporateHeaderProps {
  labels?: {
    organizationName?: string;
    organizationNumber?: string;
  };
}

export const CorporateHeader = observer(({ labels }: ICorporateHeaderProps) => {
  const { customerStore } = useStores();

  const currentParty = customerStore.getCurrentParty();
  if (!currentParty) return null;

  return (
    <section className={styles.wrapper}>
      <p>
        <b>{`${labels?.organizationName || tx("misc.organizationName")}: `}</b>
        {currentParty.displayName}
      </p>
      <p>
        <b>{`${labels?.organizationNumber || tx("misc.organizationNumber")}: `}</b>
        {currentParty.displayIdentityNumber}
      </p>
    </section>
  );
});
