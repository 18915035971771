import dayjs from "dayjs";
import { IGetAccountRedemptionResponse } from "libs/models/Transfer/Transfer";

export const mockGetAccountRedemptionResponse: IGetAccountRedemptionResponse = {
  accountId: "CV12345",
  accountName: "Opsparing Fri 12345",
  availableBalance: 10000,
  totalPayment: 20000,
  interest: 0.1,
  closeDate: dayjs().add(1, "month").toString(),
};
