import * as React from "react";
import styles from "./IncreaseLoanPage.scss";
import rootStyles from "../../Root.scss";
import { HtmlContent } from "~views/shared";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { IIncreaseLoanSummaryLabelsBlock } from "netbank-shared/src/libs/models/Content/IncreaseLoan/IncreaseLoanSummaryLabelsBlock";
import { tx } from "netbank-shared/src/libs/i18n";
import { toLocaleString } from "netbank-shared/src/libs/utils";

interface IIncreaseLoanSummaryBlock {
  labels: IIncreaseLoanSummaryLabelsBlock;
  footer: string;
}

export const IncreaseLoanSummaryBlock = observer(({ labels, footer }: IIncreaseLoanSummaryBlock) => {
  const { loanStore, uiStore } = useStores();
  const { increaseLoanCalculation, fetchingIncreaseLoanCalculation } = loanStore;
  const { currency, locale } = uiStore;

  const spinnerClasses = [rootStyles.loading, rootStyles.spinner, styles.spinner];

  const renderSummaryItem = (label: string, value: string, showCondition?: boolean) => {
    return (
      <div>
        {fetchingIncreaseLoanCalculation || !showCondition ? (
          <span>
            <div className={spinnerClasses.join(" ")} />
          </span>
        ) : (
          <span>{value}</span>
        )}
        <span>{label}</span>
      </div>
    );
  };
  return (
    <div className={styles.summaryWrapper}>
      <div className={styles.summaryItems}>
        {renderSummaryItem(
          labels?.loanAmount || tx("increaseLoan.calculationLabels.loanAmount"),
          toLocaleString(increaseLoanCalculation?.loanAmount, currency, locale, 0),
          !!increaseLoanCalculation?.loanAmount,
        )}
        {renderSummaryItem(
          labels?.monthlyPayment || tx("increaseLoan.calculationLabels.monthlyPayment"),
          toLocaleString(increaseLoanCalculation?.monthlyPayment, currency, locale, 0),
          !!increaseLoanCalculation?.monthlyPayment,
        )}
        {renderSummaryItem(
          labels?.totalRepaymentAmount || tx("increaseLoan.calculationLabels.totalRepaymentAmount"),
          toLocaleString(increaseLoanCalculation?.totalRepayment, currency, locale, 0),
          !!increaseLoanCalculation?.totalRepayment,
        )}
        {renderSummaryItem(
          labels?.interestRate || tx("increaseLoan.calculationLabels.interestRate"),
          `${toLocaleString(increaseLoanCalculation?.interestRate, undefined, locale, 2)} %*`,
          !!increaseLoanCalculation?.interestRate,
        )}
        {renderSummaryItem(
          labels?.annualPercentageRate || tx("increaseLoan.calculationLabels.annualPercentageRate"),
          `${toLocaleString(increaseLoanCalculation?.annualPercentageRate, undefined, locale, 2)} %`,
          !!increaseLoanCalculation?.annualPercentageRate,
        )}
        {renderSummaryItem(
          labels?.repaymentPeriod || tx("increaseLoan.calculationLabels.repaymentPeriod"),
          `~${increaseLoanCalculation?.repaymentPeriod} ${tx("misc.years")}`,
          !!increaseLoanCalculation?.repaymentPeriod,
        )}
        {renderSummaryItem(
          labels?.numberOfInstallments || tx("increaseLoan.calculationLabels.numberOfInstallments"),
          `${increaseLoanCalculation?.installmentsAmount} ${tx("increaseLoan.installments")}`,
          !!increaseLoanCalculation?.installmentsAmount,
        )}
        {renderSummaryItem(
          labels?.monthlyFee || tx("increaseLoan.calculationLabels.monthlyFee"),
          `${toLocaleString(increaseLoanCalculation?.monthlyFee, currency, locale, 0)}**`,
          !!increaseLoanCalculation?.monthlyFee,
        )}
        {renderSummaryItem(
          labels?.setUpFee || tx("increaseLoan.calculationLabels.setUpFee"),
          toLocaleString(increaseLoanCalculation?.setUpFee, currency, locale, 0),
          !!increaseLoanCalculation?.setUpFee,
        )}
      </div>
      {footer && <HtmlContent className={styles.summaryFooter} html={footer} />}
    </div>
  );
});
