/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import { computed, makeObservable, observable } from "mobx";
import { hotjar } from "react-hotjar";
import { NavigateFunction } from "react-router-dom";
import {
  EpiserverApi,
  AuthenticationApi,
  CustomerProductsApi,
  DepositApi,
  LoanApi,
  CreditApi,
  CustomerApi,
  TransferAccountApi,
  CustomerComplianceApi,
  MessageApi,
  LeasingApi,
  ChatApi,
  LoanCalculationApi,
} from "../libs/api";
import { tx } from "../libs/i18n";
import { IConfig } from "../libs/models/Config";
import {
  CardActionBlockTypes,
  LoanActionBlockTypes,
  PageType,
  CountryCode,
  ComplianceLanguage,
} from "../libs/models/Content/Enums";
import { ContentStore } from "./ContentStore";
import { CreditStore } from "./CreditStore";
import { CustomerStore } from "./CustomerStore";
import { DepositStore } from "./DepositStore";
import { LeasingStore } from "./LeasingStore";
import { ComplianceStore } from "./ComplianceStore";
import { LoanStore } from "./LoanStore";
import { MenuStore } from "./MenuStore";
import { MessageStore } from "./MessageStore";
import { TransferStore } from "./TransferStore";
import { UIStore } from "./UIStore";
import { GlobalPersistentStore } from "./GlobalPersistentStore";
import { CustomerPersistentStore } from "./CustomerPersistentStore";
import { CommonService } from "../libs/models/CommonService";
import {
  AccountType,
  IBaseAccount,
  ICreditAccount,
  IDepositAccount,
  IDocument,
  ILeaseAccount,
  ILoanAccount,
  IPayment,
} from "../libs/models/CustomerProducts";
import { IPlatformStore } from "../libs/models/PlatformStore";
import { SecurePersistentStore } from "./SecurePersistentStore";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ICustomProperties, IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { ApiResponse } from "apisauce";

require("dayjs/locale/sv");
require("dayjs/locale/da");
require("dayjs/locale/fi");
require("dayjs/locale/nb");

const localizedFormat = require("dayjs/plugin/localizedFormat");

let store: Store | undefined;
export class Store {
  commonService: CommonService;

  episerverApi: EpiserverApi;

  authApi: AuthenticationApi;

  customerApi: CustomerApi;

  customerProductsApi: CustomerProductsApi;

  customerComplianceApi: CustomerComplianceApi;

  depositApi: DepositApi;

  loanApi: LoanApi;

  creditApi: CreditApi;

  leasingApi: LeasingApi;

  transferAccountApi: TransferAccountApi;

  messageApi: MessageApi;

  chatApi: ChatApi;

  loanCalculationApi: LoanCalculationApi;

  globalPersistentStore: GlobalPersistentStore;

  customerPersistentStore: CustomerPersistentStore;

  securePersistentStore: SecurePersistentStore;

  uiStore: UIStore;

  menuStore: MenuStore;

  depositStore: DepositStore;

  leasingStore: LeasingStore;

  loanStore: LoanStore;

  creditStore: CreditStore;

  messageStore: MessageStore;

  customerStore: CustomerStore;

  contentStore: ContentStore;

  rootStore: Store;

  transferStore: TransferStore;

  complianceStore: ComplianceStore;

  config?: IConfig;

  platformStore: IPlatformStore;

  navigate?: NavigateFunction;

  storage: any;

  secureStorage: any;

  @observable
  allCustomerProducts?: IBaseAccount[] = undefined;

  @observable
  isMock = false;

  @observable
  loggingOut = false;

  @observable
  currentAccountId = "";

  @observable
  telemetryPlugin: ReactPlugin | undefined = undefined;

  @observable
  appInsights: ApplicationInsights | undefined = undefined;

  @computed
  get currentAccount(): IBaseAccount | undefined {
    return this.allCustomerProducts?.find((acc) => acc.accountId === this.currentAccountId);
  }

  set currentAccount(account: IBaseAccount | undefined) {
    this.currentAccountId = account?.accountId ?? "";
  }

  @computed
  get currentAccountDetails(): ILoanAccount | ILeaseAccount | IDepositAccount | ICreditAccount | undefined {
    const currentAccount = this.allCustomerProducts?.find((acc) => acc.accountId === this.currentAccountId);
    if (!currentAccount) return undefined;

    switch (currentAccount.accountType) {
      case AccountType.PrivateLoan:
      case AccountType.SecuredLoan:
      case AccountType.SalesFinance:
        return this.loanStore.currentAccount;
      case AccountType.Deposit:
        return this.depositStore.currentAccount;
      case AccountType.Credit:
        return this.creditStore.currentAccount;
      case AccountType.Leasing:
        return this.leasingStore.currentAccount;
      default:
        return undefined;
    }
  }

  @computed
  get fetchingCurrentAccountDetails() {
    return (
      this.loanStore.loadingAccount ||
      this.depositStore.loadingAccount ||
      this.creditStore.loadingAccount ||
      this.leasingStore.loadingAccount
    );
  }

  @computed
  get activePayment(): IPayment | undefined {
    const currentAccount = this.allCustomerProducts?.find((acc) => acc.accountId === this.currentAccountId);
    if (!currentAccount) return undefined;

    switch (currentAccount.accountType) {
      case AccountType.PrivateLoan:
      case AccountType.SecuredLoan:
      case AccountType.SalesFinance:
        return this.loanStore.activePayment;
      case AccountType.Leasing:
        return this.leasingStore.activePayment;
      default:
        return undefined;
    }
  }

  @computed
  get accounts() {
    return {
      creditAccounts: this.creditStore.creditCustomerProducts,
      depositAccounts: this.depositStore.depositCustomerProducts,
      leasingAccounts: this.leasingStore.leasingCustomerProducts,
      privateLoanAccounts: this.loanStore.privateLoanCustomerProducts,
      securedLoanAccounts: this.loanStore.securedLoanCustomerProducts,
      salesFinanceAccounts: this.loanStore.salesFinanceCustomerProducts,
      blockedPrivateLoanAccounts: this.loanStore.blockedPrivateLoanAccounts,
      blockedSecuredLoanAccounts: this.loanStore.blockedSecuredLoanAccounts,
    };
  }

  @computed
  get templateData() {
    return {
      ...this.currentAccount,
      ...this.customerStore.currentCustomer,
      ...this.loanStore.templateData,
    };
  }

  constructor(storage: any, secureStorage: any, commonService: CommonService, platformStore: IPlatformStore) {
    this.storage = storage;
    this.secureStorage = secureStorage;
    this.commonService = commonService;
    this.platformStore = platformStore;
    this.uiStore = new UIStore(this, this.platformStore);
    this.globalPersistentStore = new GlobalPersistentStore(storage);
    this.customerPersistentStore = new CustomerPersistentStore(storage, this, this.uiStore);
    this.securePersistentStore = new SecurePersistentStore(secureStorage);
    this.authApi = new AuthenticationApi(this);
    this.customerApi = new CustomerApi(this);
    this.customerProductsApi = new CustomerProductsApi(this, this.customerPersistentStore);
    this.customerComplianceApi = new CustomerComplianceApi(this, this.customerPersistentStore);
    this.depositApi = new DepositApi(this);
    this.loanApi = new LoanApi(this);
    this.creditApi = new CreditApi(this);
    this.leasingApi = new LeasingApi(this);
    this.transferAccountApi = new TransferAccountApi(this);
    this.messageApi = new MessageApi(this);
    this.chatApi = new ChatApi(this, this.customerPersistentStore);
    this.loanCalculationApi = new LoanCalculationApi();

    this.menuStore = new MenuStore();
    this.depositStore = new DepositStore(this.depositApi, this);
    this.loanStore = new LoanStore(this.loanApi, this.loanCalculationApi, this);
    this.leasingStore = new LeasingStore(this.leasingApi, this);

    this.creditStore = new CreditStore(this.creditApi, this);
    this.customerStore = new CustomerStore(
      this.authApi,
      this.customerApi,
      this.customerProductsApi,
      this.chatApi,
      this,
      this.customerPersistentStore,
    );
    this.messageStore = new MessageStore(this.messageApi, this.commonService);
    this.transferStore = new TransferStore(this, this.transferAccountApi, this.depositApi, this.depositStore);
    this.complianceStore = new ComplianceStore(this.customerComplianceApi);
    this.episerverApi = new EpiserverApi(this, this.customerStore, this.customerPersistentStore);
    this.contentStore = new ContentStore(this, this.customerPersistentStore, this.episerverApi);
    this.rootStore = this;
    makeObservable(this);
  }

  onMount = async (config?: IConfig, window?: Window, navigate?: NavigateFunction) => {
    this.uiStore.onMount(window);
    // On first mount read the config and create api client instances.
    if (!this.config) {
      this.config = config;

      await this.securePersistentStore.hydrate();
      await this.globalPersistentStore.hydrate();
      if (this.globalPersistentStore.customerId && !this.customerPersistentStore.isHydrated) {
        await this.customerPersistentStore.hydrate(this.globalPersistentStore.customerId);
      }

      this.uiStore.initLocale(this.config?.COUNTRY_CODE as CountryCode);
      this.episerverApi.init(this.config);
      this.authApi.init(this.config);
      this.customerApi.init(this.config);
      this.customerProductsApi.init(this.config);
      this.customerComplianceApi.init(this.config);
      this.transferAccountApi.init(this.config);
      this.depositApi.init(this.config);
      this.loanApi.init(this.config);
      this.leasingApi.init(this.config);
      this.creditApi.init(this.config);
      this.messageApi.init(this.config);
      this.chatApi.init(this.config);
      this.loanCalculationApi.init(this.config);
      this.navigate = navigate;
      this.contentStore.siteUrl = this.config?.SITE_URL;
      this.setIsMock(this.globalPersistentStore.isMock || false);

      if (this.config?.HOTJAR_ID && this.config.HOTJAR_SV) {
        const hotjarId = parseInt(this.config?.HOTJAR_ID, 10);
        const hotjarSv = parseInt(this.config?.HOTJAR_SV, 10);

        if (!Number.isNaN(hotjarId) && !Number.isNaN(hotjarSv)) hotjar.initialize(hotjarId, hotjarSv);
      }
    }

    // set global dayjs locale
    dayjs.locale(tx("locale"));
    dayjs.extend(localizedFormat);
    return this;
  };

  onUnmount = () => {
    this.uiStore.onUnmount();
  };

  setIsMock = (isMock: boolean) => {
    this.isMock = isMock;
    this.authApi.setMock(isMock);
    this.customerApi.setMock(isMock);
    this.customerProductsApi.setMock(isMock);
    this.customerComplianceApi.setMock(isMock);
    this.depositApi.setMock(isMock);
    this.loanApi.setMock(isMock);
    this.creditApi.setMock(isMock);
    this.leasingApi.setMock(isMock);
    this.messageApi.setMock(isMock);
    this.chatApi.setMock(isMock);
    this.globalPersistentStore.setIsMock(isMock);
  };

  getAccountByNumber = (accountId: string) => {
    return this.allCustomerProducts?.find((acc) => acc.accountId === accountId);
  };

  getAccountDetailsByNumber = async (accountId: string) => {
    const currentAccount = this.allCustomerProducts?.find((acc) => acc.accountId === accountId);

    if (!currentAccount) return;

    switch (currentAccount.accountType) {
      case AccountType.PrivateLoan:
      case AccountType.SecuredLoan:
      case AccountType.SalesFinance:
        await this.loanStore.getLoanAccount(accountId);
        break;
      case AccountType.Deposit:
        await this.depositStore.getDepositAccount(accountId);
        break;
      case AccountType.Credit:
        await this.creditStore.getCreditAccount(accountId);
        break;
      case AccountType.Leasing:
        await this.leasingStore.getLeaseAccount(accountId);
        break;
      default:
        break;
    }
  };

  resetAccountDetails = () => {
    if (!this.currentAccountDetails) return;

    switch (this.currentAccountDetails.accountType) {
      case AccountType.PrivateLoan:
      case AccountType.SecuredLoan:
      case AccountType.SalesFinance:
        this.loanStore.resetCurrentAccount();
        break;
      case AccountType.Deposit:
        this.depositStore.resetCurrentAccount();
        break;
      case AccountType.Credit:
        this.creditStore.resetCurrentAccount();
        break;
      case AccountType.Leasing:
        this.leasingStore.resetCurrentAccount();
        break;
      default:
        break;
    }
  };

  generateAccountParams = () => {
    return `accountNumber=${this.currentAccountId}`;
  };

  generateNemPaymentParams = () => {
    if (this.currentAccountId) {
      return `accountNumber=${this.currentAccountId}&isNemPayment`;
    }
    return `isNemPayment`;
  };

  async getCustomerProducts() {
    const response = await this.customerProductsApi.getCustomerProducts();
    if (response?.ok && response.data) {
      const depositCustomerProducts = response.data?.depositCustomerProducts || [];
      const closedDepositCustomerProducts = response.data?.closedDepositCustomerProducts || [];
      const privateLoanCustomerProducts = response.data?.privateLoanCustomerProducts || [];
      const securedLoanCustomerProducts = response.data?.securedLoanCustomerProducts || [];
      const salesFinanceCustomerProducts = response.data?.salesFinanceCustomerProducts || [];
      const creditCustomerProducts = response.data?.creditCustomerProducts || [];
      const closedCreditCustomerProducts = response.data?.closedCreditCustomerProducts || [];
      const leasingCustomerProducts = response.data?.leasingCustomerProducts || [];

      this.depositStore.depositCustomerProducts = depositCustomerProducts;
      this.depositStore.closedDepositCustomerProducts = closedDepositCustomerProducts;
      this.creditStore.creditCustomerProducts = creditCustomerProducts;
      this.creditStore.closedCreditCustomerProducts = closedCreditCustomerProducts;
      this.loanStore.privateLoanCustomerProducts = privateLoanCustomerProducts;
      this.loanStore.securedLoanCustomerProducts = securedLoanCustomerProducts;
      this.loanStore.salesFinanceCustomerProducts = salesFinanceCustomerProducts;
      this.leasingStore.leasingCustomerProducts = leasingCustomerProducts;

      this.allCustomerProducts = [
        ...depositCustomerProducts,
        ...closedDepositCustomerProducts,
        ...privateLoanCustomerProducts,
        ...securedLoanCustomerProducts,
        ...salesFinanceCustomerProducts,
        ...creditCustomerProducts,
        ...closedCreditCustomerProducts,
        ...leasingCustomerProducts,
      ];
    }
  }

  getAccountByDisplayNumber = (displayNumber: string) => {
    return this.allCustomerProducts?.find((acc) => acc.displayNumber === displayNumber);
  };

  getAccountByComplianceAccountId = (accountId: string): IBaseAccount | undefined => {
    if (!accountId) return undefined;

    let displayNumber = "";

    if (/^\d+$/.test(accountId)) {
      // In STG accountId is always matches the displayNumber of the account
      displayNumber = accountId;
    } else {
      // In production a core system prefix is added:
      // Match using regex Capturing group. accountId format examples:
      // - V21CVDK-210042595
      // - CMSWAY4DK-9352000478038
      const match = accountId.match(/[0-9a-zA-Z]+-(\d+)/);

      if (!match || match.length < 2) {
        return undefined;
      }

      displayNumber = match[1]; // eslint-disable-line prefer-destructuring
    }

    return this.allCustomerProducts?.find((acc) => acc.displayNumber === displayNumber);
  };

  getDocumentById = async (documentId: string): Promise<IDocument | undefined> => {
    if (!this.currentAccount) return undefined;

    let response: ApiResponse<IDocument, IDocument> | undefined;
    switch (this.currentAccount.accountType) {
      case AccountType.Deposit:
        response = await this.depositApi.getContractDocument(this.currentAccount.accountId, documentId);
        break;
      case AccountType.PrivateLoan:
      case AccountType.SecuredLoan:
      case AccountType.SalesFinance:
        response = await this.loanApi.getContractDocument(this.currentAccount.accountId, documentId);
        break;
      case AccountType.Credit:
        response = await this.creditApi.getContractDocument(this.currentAccount.accountId, documentId);
        break;
      case AccountType.Leasing:
      case AccountType.PrivateLeasing:
        response = await this.leasingApi.getContractDocument(this.currentAccount.accountId, documentId);
        break;
      default:
        break;
    }

    if (response?.ok && response?.data) {
      return response.data;
    }

    return undefined;
  };

  customerCanActOn = (blockType?: string): boolean => {
    if (!blockType) return false;

    switch (blockType) {
      case PageType.CardTransitPage:
        return this.creditStore.hasCustomerProducts;
      case PageType.PrivateLoanTransitPage:
        return this.loanStore.hasPrivateLoanCustomerProducts;
      case PageType.SecuredLoanTransitPage:
        return this.loanStore.hasSecuredLoanCustomerProducts;
      case PageType.DepositTransitPage:
        return this.depositStore.hasCustomerProducts;
      case PageType.LeasingTransitPage:
        return this.leasingStore.hasCustomerProducts;
      case LoanActionBlockTypes.AccordionTerminationAmountBlock:
        return !!this.loanStore.currentAccount?.canCalculateTerminationAmount;
      case PageType.CardSettingsPage:
      case CardActionBlockTypes.AccordionBlockCardBlock:
      case CardActionBlockTypes.AccordionActivateCardBlock:
      case CardActionBlockTypes.AccordionOrderCardBlock:
        return false;
      default:
        return true;
    }
  };

  logout = async (internal = false) => {
    this.loggingOut = true;
    const epiLogoutUrl = this.contentStore?.currentPage?.logoutLink?.[0]?.href;
    if (this.customerStore.isGiosgInitialized) {
      const { giosgVisitorId, giosgRooms } = this.customerStore;
      await this.chatApi.endChat(giosgVisitorId, giosgRooms);
      if (window?._giosg) {
        window._giosg("visitor", "removeAll");
        window._giosg("chat", "close", { all: true });
      }
    }

    await this.revokeToken();
    this.globalPersistentStore.resetStore();
    this.securePersistentStore.resetStore();
    this.customerPersistentStore.resetPartyData();
    this.allCustomerProducts = undefined;
    this.currentAccountId = "";
    this.navigate = undefined;

    this.depositStore.resetStore();
    this.leasingStore.resetStore();
    this.loanStore.resetStore();
    this.creditStore.resetStore();
    this.customerStore.resetStore();
    this.complianceStore.resetStore();
    this.menuStore.resetStore();
    this.contentStore.resetStore();
    this.transferStore.resetStore();
    this.messageStore.resetStore(true);
    this.commonService.handleLogout(epiLogoutUrl, internal);
    if (internal || this.platformStore.isApplication()) {
      this.loggingOut = false;
    }
  };

  refreshToken = () => {
    this.customerStore.refreshToken();
  };

  setCurityGeneralError = (applyAction?: () => void) => {
    const { currentPage } = this.contentStore;

    // TODO : Add content to CMS
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: tx("login.generalErrorHeader"),
        text: tx("login.generalErrorText"),
        applyText: tx("login.generalErrorTryAgainText"),
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
          applyAction?.();
        },
      });
    }
  };

  setNoCustomerError = (applyAction?: () => void) => {
    const { currentPage } = this.contentStore;
    this.revokeToken();
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.noCustomerErrorHeader,
        text: currentPage.noCustomerErrorText,
        applyText: currentPage.loginErrorTryAgainButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
          applyAction?.();
        },
      });
    }
  };

  setIdleWarning = () => {
    const { currentPage } = this.contentStore;
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.logoutWarningHeader,
        text: currentPage.logoutWarningText,
        applyText: currentPage.logoutWarningStayButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
        },
        closeText: currentPage.logoutWarningLogoutButton,
        closeAction: async () => {
          this.uiStore.removeConfirmationPopup();
          await this.logout();
        },
      });
    }
  };

  bankIdRedirectUrl = () => {
    if (this.platformStore?.isIOSChrome?.()) {
      return encodeURIComponent("googlechrome://");
    }

    if (this.platformStore?.isIOSFirefox?.()) {
      return encodeURIComponent("firefox://");
    }

    if (this.platformStore?.isIOSEdge?.()) {
      return encodeURIComponent("microsoft-edge-https://");
    }

    if (this.platformStore?.isiOSDevice?.()) {
      const { origin, pathname, search } = window.location;
      // If search is set there is no way to get automatic back redirection from bankid without refreshing the page
      if (search) return "null";

      return encodeURIComponent(`${origin}${pathname}#bankidIdentified`);
    }

    return "null";
  };

  openBankId = (token: string | undefined) => {
    if (!token) return;
    window.location.href = `bankid:///?autostarttoken=${token}&redirect=${this.bankIdRedirectUrl()}`;
  };

  removeBankIdHash = (path?: string) => {
    // Only for web. Removes hash required by safari in order to come back to safari app without refreshing the page.
    if (window?.location.hash.includes("#bankidIdentified")) {
      const { pathname, hash, search } = window.location;
      const updatedPath = `${pathname}${hash}${search}`.replace("#bankidIdentified", "");
      this.commonService.redirect(path || updatedPath);
    }
  };

  revokeToken = async () => {
    // Refresh token should always exist. Revokes both refresh and access token. Fall back to accessToken revocation only if refresh token is missing
    await this.authApi.revokeToken(this.securePersistentStore.refreshToken || this.securePersistentStore.accessToken);
  };

  getComplianceLanguage = () => {
    if (!this.config) return "";

    switch (this.config.COUNTRY_CODE) {
      case "SE":
        return ComplianceLanguage.SWE;
      case "DK":
        return ComplianceLanguage.DAN;
      case "FI":
        return ComplianceLanguage.FIN;
      case "NO":
        return ComplianceLanguage.NOR;
      default:
        return "";
    }
  };

  resetCurrentAccountTemplateCache = () => this.contentStore.resetCurrentAccountTemplateCache();

  trackException = (exception: IExceptionTelemetry) => {
    if (!this.appInsights) return;

    const customProps: ICustomProperties = {
      customerId: this.globalPersistentStore.customerId,
      currentAccountId: this.currentAccountId,
      currentPage: this.contentStore.currentPage?.url,
      locale: this.uiStore.locale,
      os: this.platformStore.getOS(),
    };

    if (this.platformStore.isApplication()) {
      customProps.appVersion = this.platformStore.getVersion();
      customProps.buildVersion = this.platformStore.getBuildNumber?.();
    }

    if (this.customerStore.isGiosgInitialized) {
      customProps.giosgRooms = this.customerStore.giosgRooms;
      customProps.giosgVisitorId = this.customerStore.giosgVisitorId;
    }

    this.appInsights.trackException(exception, customProps);
  };
}

export const initStore = (
  storage: any,
  secureStorage: any,
  commonService: CommonService,
  platformStore: IPlatformStore,
) => {
  store = store != null ? store : new Store(storage, secureStorage, commonService, platformStore);
  return store;
};
