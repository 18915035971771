import React from "react";
import { observer } from "mobx-react";
import styles from "../Page.scss";
import { SkeletonWrapper } from "~views/shared";

export const HomeSkeleton = observer(() => {
  return (
    <div className={styles.wrapper}>
      <section>
        <SkeletonWrapper width={30} />
        <SkeletonWrapper width={300} height={40} />
      </section>
      <section>
        <SkeletonWrapper height={150} />
      </section>
    </div>
  );
});
