/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from "i18next";
import sv from "./translations/sv";
import en from "./translations/en";
import dk from "./translations/dk";
import fi from "./translations/fi";
import { Lang } from "libs/models/Content/Enums";

export const initTranslations = (lang?: Lang) => {
  if (!lang) return;
  i18next.init({
    lng: lang,
    debug: false,
    resources: {
      sv,
      en,
      dk,
      fi,
    },
    interpolation: {
      format: (value: string, format, _) => {
        switch (format) {
          /**
           * All letters to uppercase
           */
          case "upper":
            return value?.toUpperCase() || "";

          /**
           * All letters to lowercase
           */
          case "lower":
            return value?.toLowerCase() || "";

          /**
           * The first letter to uppercase and the rest lowercase
           */
          case "capital":
            return `${value?.slice(0, 1).toUpperCase() || ""}${value?.slice(1).toLowerCase() || ""}`;

          case "to_sentence": {
            if (!Array.isArray(value)) {
              return value;
            }
            const array = value as string[];
            return array?.length > 1
              ? [array.slice(0, -1).join(", "), array.slice(-1)].join(` ${i18next.t(`misc.and`)} `)
              : array[0] || "";
          }

          default:
            return value || "";
        }
      },
    },
  });
};
