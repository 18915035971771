import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";
import { Locale } from "../models/Content/Enums";

export const PhoneValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  let minLength = 9;
  let maxLength = 15;

  let norm = value ? value.replace(/[^0-9+]/g, "") : "";
  if (norm.startsWith("00")) {
    norm = norm.replace("00", "+");
  }

  if (norm.startsWith("+")) {
    minLength += 3;
    maxLength += 3;
  }

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (norm.length < minLength || norm.length > maxLength) {
    valid = false;
    error = tx("validators.phone", {
      name: errorName,
    });
  }

  return { valid, error };
};

export const PhoneValidatorMultiCountry = (country: Locale, value?: string) => {
  const SEMobilePhoneRegex = /^(\+46|0)[1-9]\d{8}$/;
  const FIMobilePhoneRegex =
    /^(([0-9_@./\\#&+-]{7,14})|((0[^0\D]{1}0)|(0[^0\D]{1}0[^0\D]{1}))([\s-]?[0-9]{4,11})|([0-9]{3}[\s-]?([^0\D]{1}\d{3,11}))|((0[^0\D]{1}0)|([1-9]{3,4}))(([\s-]?[1-9]{3,4}[\s-]?\d{3,7})|([\s-]?[1-9]{3,4})))$/;
  const DKMobilePhoneRegex = /^[\d]{8}$/;

  if (!value) return null;

  switch (country) {
    case Locale.sv:
      return SEMobilePhoneRegex.test(value);
    case Locale.dk:
      return DKMobilePhoneRegex.test(value);
    case Locale.fi:
      return FIMobilePhoneRegex.test(value);
    default:
      return false;
  }
};
