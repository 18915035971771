/* eslint-disable no-undef */
import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { AddInstallmentType, Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { AccountType } from "../../models/CustomerProducts";
import { IAddInstallmentPage } from "libs/models/Content/Page";
import { ListItem } from "libs/models/Content/ListItem";
import { IAddInstallmentType } from "libs/models/Content/AddInstallmentType";

export interface IAddInstallmentForm {
  amount: number;
  date: Date;
  installmentType: string;
  phone: string;
}

interface IAddInstallmentProps {
  locale: Locale;
  page: IAddInstallmentPage;
  accountType?: AccountType;
  allowBalloonPayment?: boolean;
  requiredError?: string;
  invalidError?: string;
}

export const getAddInstallmentTypes = (
  addInstallmentTypes: IAddInstallmentType[],
  allowBalloonPayment?: boolean,
): ListItem[] =>
  Object.keys(AddInstallmentType)
    .filter((key) => (!allowBalloonPayment ? key !== "BalloonPayment" : true))
    .map((key, index) => ({
      index,
      enumValue: (AddInstallmentType as KeyObject<string>)[key],
      value:
        addInstallmentTypes?.find((addInstallmentType) => addInstallmentType.type === (key as AddInstallmentType))
          ?.label || tx(`addInstallment.addInstallmentType.${key}`),
      label:
        addInstallmentTypes?.find((addInstallmentType) => addInstallmentType.type === (key as AddInstallmentType))
          ?.label || tx(`addInstallment.addInstallmentType.${key}`),
    }));

export const generateAddInstallmentSchema = (props: IAddInstallmentProps): yup.ObjectSchema<IAddInstallmentForm> => {
  const { amount, date, phone, addInstallmentType, installmentTypes } = props.page;
  const invalidAmountError = amount?.error || tx("validators.required");
  const invalidDateError = date?.error || tx("validators.required");
  const invalidAddnstallmentTypeError = addInstallmentType?.error || tx("validators.required");
  const invalidPhoneError = phone?.error || tx("validators.phone");

  const addInstallmentTypeOptions = getAddInstallmentTypes(installmentTypes, props.allowBalloonPayment);
  const validInstallmentTypes = addInstallmentTypeOptions.map((item) => item.value);

  const schema = yup.object({
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(invalidAmountError)
      .positive(invalidAmountError),
    date: yup.date().required(invalidDateError),
    installmentType: yup
      .string()
      .oneOf(validInstallmentTypes, invalidAddnstallmentTypeError)
      .required(invalidAddnstallmentTypeError),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
  });

  return schema;
};
