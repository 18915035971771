import { observer } from "mobx-react";
import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IMileageChangePage } from "netbank-shared/src/libs/models/Content/Page";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts";
import { LeasingMileageChangePage } from "./LeasingMileageChangePage";
import { SecuredLoanMileageChangePage } from "./SecuredLoanMileageChangePage";

export const MileageChangePage = observer(() => {
  const { contentStore, rootStore } = useStores();
  const { currentPage } = contentStore;
  const { currentAccount } = rootStore;

  const pageData = currentPage as IMileageChangePage;

  switch (currentAccount?.accountType) {
    case AccountType.Leasing:
      return <LeasingMileageChangePage page={pageData} />;
    case AccountType.SecuredLoan:
      return <SecuredLoanMileageChangePage page={pageData} />;
    default:
      return <SelfServiceFailurePage data={pageData.emptyState} />;
  }
});
