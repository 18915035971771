import { create, ApisauceInstance, ApiResponse } from "apisauce";
import { Environment, GdprInformationType } from "../models/Content/Enums";
import { Store } from "stores";
import { IConfig } from "../models/Config";
import { ICustomerResponse, IUpdateContactInfo, UpdateCustomerInfoData } from "../models/Customer/Customer";
import { MockCustomerApi } from "./mock/MockCustomerApi";
import MockAdapter from "axios-mock-adapter";
import { AxiosAdapter, AxiosInstance } from "axios";
import { IGdprData } from "libs/models/Gdpr/Gdpr";

export class CustomerApi {
  rootStore: Store;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  client?: ApisauceInstance;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": config.COUNTRY_CODE,
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.CUSTOMER_API_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) {
        this.setupMockAdapter();
      }
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    if (!response.ok) {
      this.rootStore.trackException({ error: response.originalError || new Error("Error in CustomerApi") });
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getCustomer = async () => this.client?.get<ICustomerResponse>("", {}, this.getHeaders());

  updateContactInfo = async (updatedCustomerInfo: UpdateCustomerInfoData) => {
    return this.client?.put<IUpdateContactInfo>("", updatedCustomerInfo, this.getHeaders());
  };

  addDisbursementAccount = (disbursementAccount: string) =>
    this.client?.put<IUpdateContactInfo>("", { disbursementAccount }, this.getHeaders());

  getGdprData = async (gdprInformationType: GdprInformationType, withFile: boolean = false) =>
    this.client?.get<IGdprData>("gdpr-data", { gdprInformationType, withFile }, this.getHeaders());

  /* eslint-disable no-undef */
  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockCustomerApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
