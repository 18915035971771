import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { getQueryParam } from "netbank-shared/src/libs/utils";
import { NewConversation } from "./NewConversation";
import { Conversation } from "./Conversation";

export const NewMessage = observer(() => {
  const { messageStore } = useStores();

  const conversationIdFromUrl = getQueryParam(window.location.search, "conversationId");

  useEffect(() => {
    const fetchData = async () => {
      if (!messageStore.conversations) {
        await messageStore.getConversations();
      }
      if (conversationIdFromUrl) {
        messageStore.getConversation(conversationIdFromUrl);
      }
    };
    fetchData();

    return () => messageStore.resetStore();
  }, []);

  return (
    <>
      {!conversationIdFromUrl && <NewConversation />}
      {conversationIdFromUrl && <Conversation />}
    </>
  );
});
