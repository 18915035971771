import MockAdapter from "axios-mock-adapter";
import { jsPDF as JsPDF } from "jspdf";
import { mockContracts, mockTransactionsIndex } from "./customerProducts";
import { mockDepositAccounts } from "./deposit";
import { mockGetTransferDetails, mockTransfer } from "./transfer";
import { mockCreateWithdrawalResponse, mockGetWithdrawalDetailsResponse } from "./withdrawal";
import { mockGetAccountRedemptionResponse } from "./accountRedemption";
import { IDocument } from "libs/models/CustomerProducts";

export const MockDepositApi = (mock: MockAdapter) => {
  const transactionsParams = (accountId: string, nextToken = "") => ({
    params: {
      accountId,
      nextToken,
      amountFrom: "",
      amountTo: "",
      dateFrom: "",
      dateTo: "",
    },
  });
  const idRegexSegment = "[0-9]+";
  const singleAccountIdRegexSegment = "^[0-9]+$";

  mock.onGet("").reply(200, mockDepositAccounts);

  mock.onGet(new RegExp(`${singleAccountIdRegexSegment}`)).reply((config) => {
    if (config.url) {
      const urlArray = config.url.split("/");
      const accountNumber = urlArray[urlArray.length - 1];
      const depositAccount = mockDepositAccounts.depositAccounts?.find((a) => a.accountId === accountNumber);

      if (depositAccount) {
        return [200, { depositAccount, error: "" }];
      }
    }
    return [500];
  });

  mock.onGet("transactions", transactionsParams("1")).reply(200, mockTransactionsIndex);

  mock.onGet(new RegExp(`${idRegexSegment}/contracts$`)).reply(200, mockContracts);

  mock.onGet(new RegExp(`${idRegexSegment}/contracts/${idRegexSegment}`)).reply((config) => {
    if (config.url) {
      const urlArray = config.url.split("/");
      const documentId = urlArray[urlArray.length - 1];
      const document = mockContracts.find((t) => t.id === documentId);

      if (!document) return [404];

      const doc = new JsPDF();
      doc.text("Mock contract document", 10, 10);

      const response: IDocument = {
        ...document,
        fileBytes: doc.output("datauristring").split("base64,")[1],
      };
      return [200, response];
    }
    return [500];
  });

  mock.onPost(new RegExp(`${idRegexSegment}/transfers`)).reply(201, mockTransfer); // For an unsuccessful transfer, import and use mockIncorrectAccountTransfer from Transfer.ts

  mock.onGet(new RegExp(`${idRegexSegment}/withdrawal-details`)).reply((config) => {
    let responseCode = 200;
    const withdrawalDetails = mockGetWithdrawalDetailsResponse;
    const { params, url } = config;
    const fromAccount = mockDepositAccounts.depositAccounts?.find(
      (account) => account.accountId === url?.match(/\d+/)?.[0],
    );

    if (!fromAccount || typeof params?.amount !== "number" || params?.amount <= 0) {
      responseCode = 400;
      withdrawalDetails.allowed = false;
      withdrawalDetails.details = "Transfer not allowed";
    } else {
      withdrawalDetails.accountId = fromAccount.accountId;
      withdrawalDetails.amount = params?.amount;
      withdrawalDetails.valueDate = `${new Date(Date.now() + 86400000)}`;
      withdrawalDetails.allowed = true;
      withdrawalDetails.availableBalance = fromAccount.availableBalance || 0;
      withdrawalDetails.interestCarryingBalance = fromAccount.availableBalance || 0;
    }

    return [responseCode, withdrawalDetails];
  });

  mock.onGet(new RegExp(`${idRegexSegment}/transfer-details`)).reply((config) => {
    let responseCode = 200;
    const transferDetails = mockGetTransferDetails;
    const { params, url } = config;
    const fromAccount = mockDepositAccounts.depositAccounts?.find(
      (account) => account.accountId === url?.match(/\d+/)?.[0],
    );
    const toAccount = mockDepositAccounts.depositAccounts?.find((account) => account.accountId === params?.toAccountId);

    if (
      !fromAccount ||
      !toAccount ||
      !params?.toAccountId ||
      typeof params?.amount !== "number" ||
      params?.amount <= 0
    ) {
      responseCode = 400;
      transferDetails.allowed = false;
    } else {
      transferDetails.fromAccountId = fromAccount.accountId;
      transferDetails.toAccountId = params?.toAccountId;
      transferDetails.amount = params.amount;
      transferDetails.valueDate = `${new Date(Date.now() + 86400000)}`;
      transferDetails.fromAccountAvailableBalance = fromAccount.availableBalance || 0;
      transferDetails.toAccountAvailableBalance = toAccount.availableBalance || 0;
      transferDetails.allowed = true;
    }

    return [responseCode, transferDetails];
  });

  mock.onPost(new RegExp(`${idRegexSegment}/withdrawals`)).reply(200, mockCreateWithdrawalResponse);

  mock.onPost(new RegExp(`${idRegexSegment}/account-redemptions`)).reply(201, {});

  mock.onGet(new RegExp(`${idRegexSegment}/account-redemptions`)).reply(200, mockGetAccountRedemptionResponse);
};
