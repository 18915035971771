import React, { useState } from "react";
import { observer } from "mobx-react";
import styles from "./TextArea.scss";
import rootStyles from "~views/pages/Root.scss";
import { capitalize } from "netbank-shared/src/libs/utils";
import errorIcon from "~assets/error.svg";
import { IInfoPopoverProps, InfoPopover } from "../InfoPopover/InfoPopover";

type ITextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  icon?: string;
  suffix?: string;
  large?: boolean;
  borderColor?: string;
  centered?: boolean;
  infoPopover?: IInfoPopoverProps;
  error?: string;
};

export const TextArea = observer(
  ({ label, icon, suffix, borderColor, infoPopover, error, ...props }: ITextAreaProps) => {
    const [focus, setFocus] = useState(false);

    const classes = [styles.wrapper];
    if (focus) {
      classes.push(styles.focus);
    }

    if (borderColor) {
      classes.push(styles[`border${capitalize(borderColor)}`]);
    }

    return (
      <div>
        {label && (
          <div className={styles.labelRow}>
            <span className={styles.label}>{label}</span>
            {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
          </div>
        )}
        <div className={classes.join(" ")}>
          <textarea {...props} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
          {icon && <img className={styles.icon} src={icon} alt="icon" />}
          {suffix && <span className={styles.suffix}>{suffix}</span>}
        </div>
        {error && (
          <div className={rootStyles.error}>
            <img src={errorIcon} alt="error-icon" />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }
);
