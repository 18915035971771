import React, { useRef, useState, RefObject } from "react";
import { observer } from "mobx-react";
import chevronRight from "~assets/chevron-right.svg";
import styles from "./TransferAccountDropdown.scss";
import rootStyles from "~views/pages/Root.scss";
import { useStores } from "netbank-shared/src/hooks";
import { IInfoPopoverProps, InfoPopover } from "~shared/InfoPopover/InfoPopover";
import { tx } from "netbank-shared/src/libs/i18n";
import errorIcon from "~assets/error.svg";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts/BaseAccount";
import santanderIcon from "~assets/logo-icon-plain.svg";
import { Button } from "~views/shared/Button/Button";
import { ICustomer } from "netbank-shared/src/libs/models/Customer/Customer";
import { AccountListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { IDepositAccount } from "netbank-shared/src/libs/models/CustomerProducts";
import { toLocaleString } from "netbank-shared/src/libs/utils";

interface ITransferAccountDropdownProps {
  value: string;
  label: string;
  setCreateToAccount: (value: boolean) => void;
  createToAccount: boolean;
  onChange: (item: AccountListItem) => void;
  subtext?: string;
  icon?: string;
  suffix?: string;
  suffixLabel?: string;
  infoPopover?: IInfoPopoverProps;
  ownAccounts?: IDepositAccount[];
  onOpen?: () => void;
  error?: string;
  disabledText?: string;
  disabled?: boolean;
}

export const TransferAccountDropdown = observer(
  ({
    value,
    label,
    subtext,
    icon,
    suffix,
    suffixLabel,
    infoPopover,
    onChange,
    onOpen,
    createToAccount,
    setCreateToAccount,
    ownAccounts,
    error,
    disabledText,
    disabled = false,
  }: ITransferAccountDropdownProps): JSX.Element => {
    const contentRef: RefObject<HTMLDivElement> = useRef(null);
    const { transferStore, uiStore, customerStore } = useStores();
    const currentCustomer = customerStore.currentCustomer as ICustomer | null;
    const canHandleTransferAccount = currentCustomer?.capabilities.canHandleTransferAccounts ?? false;
    const hasNationalPayoutAccount = currentCustomer?.capabilities.hasNationalPayoutAccount;
    const [open, setOpen] = useState(false);
    const { locale, currency } = uiStore;

    const ownAccountList = ownAccounts?.map((account, index): AccountListItem => {
      const isAccountDeposit = account.accountType === AccountType.Deposit;
      return {
        index,
        value: account.accountId,
        label: account.nickname || account.name || "",
        valueSuffix: ` | ${account.displayNumber}`,
        suffix: toLocaleString(account.availableBalance, currency, locale),
        icon: santanderIcon,
        disabledText,
        disabled: isAccountDeposit && account.isActivationPeriodExpired,
      };
    });

    const savedAccounts: AccountListItem[] =
      transferStore.savedAccounts?.map((account, index) => {
        return {
          index,
          value: account.accountNumber,
          label: account.displayName || `${account.clearingNumber}-${account.accountNumber}`,
          valueSuffix: account.displayName && ` | ${account.clearingNumber}-${account.accountNumber}`,
        };
      }) || [];

    const handleClickOutside = (e: MouseEvent) => {
      if (contentRef && e.target instanceof Node && !contentRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    const onCreateNewAccount = (val: boolean) => {
      setCreateToAccount(val);
      setOpen(false);
    };

    const onClick = (item: AccountListItem) => {
      onChange(item);
      onCreateNewAccount(false);
    };

    React.useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    const disabledOrDataMissing =
      disabled ||
      (savedAccounts.length === 0 &&
        (ownAccounts?.length === 0 || ownAccounts === undefined) &&
        !hasNationalPayoutAccount);

    const headerClasses = [styles.header];
    if (disabledOrDataMissing) headerClasses.push(styles.disabled);
    const dropdownListClasses = [styles.dropdownListWrapper, styles.borderTop];

    if (open) {
      headerClasses.push(styles.open);
      dropdownListClasses.push(styles.open);
    }

    const onClickHeader = () => {
      if (!disabledOrDataMissing) {
        if (!open && typeof onOpen !== "undefined") onOpen();
        setOpen(!open);
      }
    };

    const renderDropdownList = (title: string, list: AccountListItem[]) => {
      const listHeaderClasses = [styles.listHeader];
      if (title !== "") listHeaderClasses.push(styles.title);
      return (
        <>
          <span className={listHeaderClasses.join(" ")}>{title}</span>
          <ul>
            {list.map((item) => {
              const itemClasses = [styles.listItem];
              const suffixClasses = [];
              const iconClasses = [];
              const isActive =
                `${item.label}${item.valueSuffix}` === value || item.label === value || item.value === value;

              if (isActive) {
                itemClasses.push(styles.active);
              }
              if (item.suffix) {
                itemClasses.push(styles.suffixWrapper);
              }
              if (item.disabled) {
                itemClasses.push(styles.disabled);
                suffixClasses.push(styles.disabledSuffix);
                iconClasses.push(styles.disabledIcon);
              }
              return (
                <li className={itemClasses.join(" ")} key={`dropdown-${item.value}`}>
                  <div
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !item.disabled) onClick(item);
                    }}
                    role="button"
                    tabIndex={open ? 0 : -1}
                    onClick={() => {
                      if (!item.disabled) onClick(item);
                    }}
                    className={styles.itemValueWrapper}
                  >
                    {item.icon && <img src={item.icon} alt="icon" className={iconClasses.join(" ")} />}
                    <div>
                      <div className={styles.itemValue}>
                        {item.label}
                        {item.valueSuffix}
                      </div>
                      {item.disabled && <div className={styles.disabledText}>{item.disabledText}</div>}
                    </div>
                  </div>
                  {item.suffix && !uiStore.isMobile && <div>{item.suffix}</div>}
                </li>
              );
            })}
          </ul>
        </>
      );
    };

    const renderToAccountLists = (
      title: string,
      list: AccountListItem[],
      secondaryTitle?: string,
      secondaryList?: AccountListItem[],
    ) => {
      const nationalPayoutAccount: AccountListItem = {
        index: 0,
        label: tx("withdrawal.withdrawal"),
        value: tx("withdrawal.nationalPayoutAccount"),
        valueSuffix: ` | ${tx("withdrawal.nationalPayoutAccount")}`,
        isNationalPayoutAccount: true,
      };
      return (
        <div className={dropdownListClasses.join(" ")}>
          <div className={styles.dropdownList}>
            {secondaryTitle &&
              secondaryList &&
              secondaryList.length > 0 &&
              renderDropdownList(secondaryTitle, secondaryList)}
            {list.length > 0 && renderDropdownList(title, list)}
            {hasNationalPayoutAccount && renderDropdownList("", [nationalPayoutAccount])}
            {canHandleTransferAccount && (
              <div>
                <span className={styles.listHeader}>{tx("transfer.newReceiverLabel")}</span>
                <Button
                  className={[styles.listItem, styles.lastItem].join(" ")}
                  onClick={() => onCreateNewAccount(true)}
                  tabIndex={open ? 0 : -1}
                >
                  {tx("transfer.addNewReceiver")}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div className={styles.wrapper} ref={contentRef}>
        {label && (
          <div className={styles.labelRow}>
            <span className={rootStyles.label}>{label}</span>
            {infoPopover?.content && <InfoPopover className={styles.popover} {...infoPopover} />}
          </div>
        )}
        {subtext && <HtmlContent className={styles.subtext} html={subtext} />}
        <div className={styles.dropdownWrapper}>
          <div
            className={headerClasses.join(" ")}
            onClick={onClickHeader}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter") onClickHeader();
            }}
          >
            <div className={styles.title}>
              {createToAccount ? (
                <div className={styles.itemValue}>{tx("transfer.newReceiverLabel")}</div>
              ) : (
                <>
                  <div className={styles.itemValue}>
                    {icon && value && <img src={icon} alt="icon" />}
                    <span>{value}</span>
                  </div>
                  {suffix && !uiStore.isMobile && <div className={styles.suffix}>{suffix}</div>}
                </>
              )}
            </div>
            <img className={styles.expandIcon} src={chevronRight} alt="" />
          </div>
          {renderToAccountLists(
            tx("transfer.savedAccountsLabel"),
            savedAccounts,
            tx("transfer.ownedAccountsLabel"),
            ownAccountList,
          )}
        </div>
        {uiStore.isMobile && suffix && suffixLabel && (
          <div className={styles.mobileSuffix}>
            <span>{suffixLabel}</span>
            <span>{suffix}</span>
          </div>
        )}
        {error && (
          <div className={rootStyles.error}>
            <img src={errorIcon} alt="error-icon" />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  },
);
