import React from "react";
import { observer } from "mobx-react";
import styles from "../SelfServicePage.scss";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IPaymentHolidayPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import {
  IPaymentHolidayForm,
  generatePaymentHolidayForm,
} from "netbank-shared/src/libs/forms/PaymentHoliday/PaymentHolidayForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckboxFormField, FormWrapper } from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { PaymentHolidayPicker } from "./PaymentHolidayPicker";
import { HtmlContent } from "~views/shared";
import { getMonthName, sortDates, templateString, toLocaleString } from "netbank-shared/src/libs/utils";

export const PaymentHolidayPage = observer(() => {
  const navigate = useNavigate();

  const { contentStore, loanStore, uiStore } = useStores();
  const { currentPage } = contentStore;
  const page = currentPage as IPaymentHolidayPage;

  const { monthsPicker, termsText, termsCheckbox, submitLabel, cancelLabel, successContent, errorContent } = page;
  const { currentAccount, selfServiceSusbmissionState } = loanStore;

  const methods = useForm<IPaymentHolidayForm>({
    resolver: yupResolver(
      generatePaymentHolidayForm({ locale: uiStore.locale!, accountType: currentAccount?.accountType, page }),
    ),
  });

  const { months } = methods.watch();

  useFetchData({
    cleanup: () => loanStore.resetSelfServiceState(),
  });

  if (!currentAccount || !currentAccount.canRequestPaymentHoliday || !currentAccount.paymentHolidayDetails) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }
  const onSubmit: SubmitHandler<IPaymentHolidayForm> = async (data) => loanStore.addPaymentHoliday(data);

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text:
                successContent?.textContent &&
                templateString(successContent.textContent, {
                  selfServiceValue: sortDates(months)
                    .map((m) => getMonthName(m.getMonth(), uiStore.locale))
                    .join(` ${tx("misc.and")} `),
                }),
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <PaymentHolidayPicker
              label={monthsPicker?.label || tx("paymentHoliday.monthsPickerLabel")}
              infoPopover={{ popoverTitle: monthsPicker?.infoTitle, content: monthsPicker?.infoText }}
              name="months"
              details={currentAccount.paymentHolidayDetails!}
            />
            {currentAccount.fees?.paymentHolidayFee && termsText && (
              <HtmlContent
                className={styles.termsWrapper}
                html={templateString(termsText, {
                  selfServiceFee: toLocaleString(loanStore.getPaymentHolidayFee(months), undefined, uiStore.locale),
                })}
              />
            )}
            <CheckboxFormField
              name="termsAccepted"
              label={termsCheckbox?.label || tx("selfServiceLabels.termsCheckboxLabel")}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
