import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { getAccountDisplayName } from "netbank-shared/src/libs/utils";
import { Button, InfoText } from "~views/shared";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import editIcon from "~assets/pencil-outline.svg";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";

export interface LeasingPageProps {
  location: Location;
}

const LeasingPage = observer(({ location }: LeasingPageProps) => {
  const { leasingId } = useParams<IProductRouteParams>();
  const { contentStore, leasingStore, uiStore } = useStores();
  const { currentAccount, duePayment } = leasingStore;
  const leasingTemplate = contentStore.currentLeasingTemplatePage;

  const showHasOverdueInvoiceBanner =
    currentAccount?.hasOverdueInvoice && leasingTemplate?.accountHasOverdueInvoiceText;
  const showAMLFrozenBanner = currentAccount?.isAmlFrozen && leasingTemplate?.accountIsFrozenAMLText;

  const showAccountStatusBanners = showHasOverdueInvoiceBanner || showAMLFrozenBanner;

  useFetchData({
    condition: !!leasingId && !leasingStore.loadingAccount,
    callback: () => {
      leasingStore.getLeaseAccount(leasingId);
    },
    cleanup: () => {
      leasingStore.resetCurrentAccount();
      leasingStore.resetLeasingPayments();
    },
  });

  useFetchData({
    condition: !!leasingId && !leasingStore.loadingOverduePayments && !leasingStore.overduePayments,
    callback: () => {
      leasingStore.getOverduePayments(leasingId);
    },
  });

  useFetchData({
    condition: !!leasingId && !leasingStore.loadingDuePayment && duePayment === undefined,
    callback: () => {
      leasingStore.getDuePayment(leasingId);
    },
  });

  useFetchData({
    callback: () => {
      if (leasingStore.currentAccountId !== leasingId && !!leasingId) leasingStore.currentAccountId = leasingId;
      if (currentAccount?.accountId === leasingId) contentStore.getLeasingTemplate();
    },
    deps: [location, currentAccount],
  });

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage || leasingStore.loadingAccount) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(leasingStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(leasingTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{leasingTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount?.displayNumber}`}
          </p>
          {leasingTemplate?.accountTypeSubHeadingLabel && (
            <p>
              <span className={styles.bold}>{leasingTemplate.accountTypeSubHeadingLabel}</span>
              {` ${currentAccount?.name}`}
            </p>
          )}
        </div>
      </section>
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isMobile && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${leasingTemplate?.image?.url})`,
              }}
            />
          )}
          <div className={styles.templateContent}>
            <div className={styles.balance}>
              {!!currentAccount.carInformation && (
                <div>
                  <span className={styles.templateTitle}>{currentAccount.carInformation?.description}</span>
                  <h3>{currentAccount.carInformation?.license}</h3>
                </div>
              )}
              {uiStore.isMobile && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${leasingTemplate?.image?.url})`,
                  }}
                />
              )}
            </div>

            <div className={styles.flexBetween}>
              <div>
                <span className={styles.templateTitle}>{leasingTemplate?.remainingPeriod}</span>
                <h4>
                  {`${currentAccount.contractPeriod - currentAccount.elapsedPeriod} 
                  ${tx("date.months")}`}
                </h4>
              </div>
              <div>
                <span className={styles.templateTitle}>{leasingTemplate?.totalPeriod}</span>
                <h4>
                  {`${currentAccount.contractPeriod} 
                  ${tx("date.months")}`}
                </h4>
              </div>
            </div>
            <div className={styles.progressBar}>
              <div
                style={{
                  width: `${(currentAccount.elapsedPeriod / currentAccount.contractPeriod) * 100}%`,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {showAccountStatusBanners && (
        <section className={styles.accountStatusContainer}>
          {showHasOverdueInvoiceBanner && (
            <InfoText
              textWithQueryLink={leasingTemplate.accountHasOverdueInvoiceText}
              level={NotificationLevel.information}
              accountNumber={currentAccount.accountId}
            />
          )}
          {showAMLFrozenBanner && (
            <InfoText text={leasingTemplate?.accountIsFrozenAMLText} level={NotificationLevel.error} />
          )}
        </section>
      )}
      <ContentArea page={leasingTemplate} />
    </div>
  );
});

export default withRoot(LeasingPage);
