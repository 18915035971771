import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { toLocaleString, getAccountDisplayName } from "netbank-shared/src/libs/utils";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { EmptyState, InfoText, Button } from "~views/shared";
import { BasicInfoBlock } from "~views/shared/ContentArea/Blocks/BasicInfoBlock";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import styles from "../Page.scss";
import cardStyles from "./Card.scss";
import { withRoot } from "../Root";
import accordionStyle from "~views/shared/ContentArea/Blocks/AccordionBlock.scss";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { AccountStatus } from "netbank-shared/src/libs/models/CustomerProducts";
import { CarouselComponent, CarouselSlide } from "~views/shared/CarouselComponent/CarouselComponent";
import { CardItem } from "./CardItem";
import { isProperty } from "netbank-shared/src/libs/models/Content/Property";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import editIcon from "~assets/pencil-outline.svg";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";

const CardPage = observer(() => {
  const location = useLocation();
  const { cardId } = useParams<IProductRouteParams>();
  const { contentStore, uiStore, creditStore } = useStores();
  const { currency, locale } = uiStore;
  const { currentAccount, currentCards } = creditStore;

  const cardTemplate = contentStore.currentCardTemplatePage;

  useEffect(() => {
    if (cardId) {
      const fetchAccount = async () => {
        if (!creditStore.loadingAccount) {
          creditStore.getCreditAccount(cardId);
        }
      };
      fetchAccount();
    }
    return () => {
      creditStore.resetCurrentAccount();
    };
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      creditStore.currentAccountId = cardId;
      await contentStore.getCardTemplate();
    };
    fetchTemplate();
  }, [location]);

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  useEffect(() => {
    if (cardId) {
      creditStore.setCurrentAccount(cardId);
    }
  }, [currentAccount, cardId, contentStore.cardTemplatePages]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage || creditStore.loadingAccount) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  const isClosing = currentAccount.accountStatus === AccountStatus.AccountToClose;
  const isBlocked = currentAccount.accountStatus === AccountStatus.AccountDecline;
  const accountBlockedState = cardTemplate?.accountBlockedEmptyState?.[0];
  const closingContent =
    cardTemplate?.closingOfAccountContent && cardTemplate.closingOfAccountContent.length > 0
      ? cardTemplate.closingOfAccountContent
      : null;

  const usedCredit = Math.abs(currentAccount.bookedBalance || 0);

  const usedCreditProgress = (usedCredit / (currentAccount.creditLimit || 0)) * 100;

  const hasCard = currentCards.length > 0;

  const renderContent = () => {
    return (
      <section>
        <div className={styles.templateHeader}>
          {!uiStore.isMobile && !hasCard && (
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${cardTemplate?.image?.url})`,
              }}
            />
          )}
          {hasCard && (
            <>
              {currentCards.length === 1 && (
                <div className={cardStyles.cardWrapper}>
                  <CardItem card={currentCards[0]} />
                </div>
              )}
              {currentCards.length > 1 && (
                <div className={[cardStyles.cardWrapper, cardStyles.wrapperLarge].join(" ")}>
                  <div>
                    <CarouselComponent
                      slidesPerView={1}
                      grabCursor
                      pagination={{ clickable: true }}
                      customPaginationWrapper="card-slider"
                      effect="coverflow"
                      coverflowEffect={{
                        rotate: 0,
                        depth: 0,
                        slideShadows: false,
                      }}
                    >
                      {currentCards.map((card) => {
                        return (
                          <CarouselSlide key={`slider-${card.cardId}`}>
                            <div className={cardStyles.cardSliderItem}>
                              <CardItem card={card} />
                            </div>
                          </CarouselSlide>
                        );
                      })}
                    </CarouselComponent>
                  </div>
                </div>
              )}
            </>
          )}
          <div className={styles.templateContent}>
            <div className={styles.balance}>
              <div>
                <span className={styles.templateTitle}>{cardTemplate?.availableCredit}</span>
                <h3>{toLocaleString(isClosing ? 0 : currentAccount.availableBalance, currency, locale)}</h3>
              </div>
              {uiStore.isMobile && !hasCard && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${cardTemplate?.image?.url})`,
                  }}
                />
              )}
            </div>

            <div className={styles.flexBetween}>
              <div>
                <span className={styles.templateTitle}>{cardTemplate?.usedCredit}</span>
                <h4>{toLocaleString(usedCredit, currency, locale)}</h4>
              </div>
              <div>
                <span className={styles.templateTitle}>{cardTemplate?.creditAmount}</span>
                <h4>{toLocaleString(currentAccount.creditLimit, currency, locale)}</h4>
              </div>
            </div>
            <div className={styles.progressBar}>
              <div
                style={{
                  width: `${usedCreditProgress}%`,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderBlocked = (blockedEmptyState: IEmptyState) => {
    return (
      <section>
        <div className={[accordionStyle.wrapper, accordionStyle.open].join(" ")}>
          <div className={accordionStyle.header}>
            {isProperty(blockedEmptyState.header) ? blockedEmptyState.header?.value : blockedEmptyState.header}
          </div>
          <div className={accordionStyle.content}>
            <div className={accordionStyle.verticalPadding}>
              <EmptyState data={blockedEmptyState} />
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${currentAccount.name} - Santander`}</title>
      </Helmet>
      <div className={styles.wrapper}>
        <section className={styles.headerWrapper}>
          {currentAccount.isClosed && (
            <div className={styles.bannerWrapper}>
              <InfoText
                text={cardTemplate?.closedAccountBannerText || tx("card.closedAccountBannerText")}
                level={NotificationLevel.information}
              />
            </div>
          )}
          <div>
            <h1
              style={{
                display: "inline-flex",
              }}
            >
              {getAccountDisplayName(creditStore.currentAccount)}
            </h1>
            {contentStore.isSetAccountNameEnabled && (
              <Button
                className={styles.boxWrapper}
                onClick={() => {
                  renderModal(cardTemplate?.setAccountNameModalContent);
                }}
              >
                <div className={styles.box}>
                  <img src={editIcon} alt="edit-icon" />
                </div>
              </Button>
            )}
            <p>
              <span className={styles.bold}>{tx("misc.accountNumber")}:</span>
              {` ${currentAccount.displayNumber || currentAccount.accountId}`}
            </p>
            {cardTemplate?.accountTypeSubHeadingLabel && (
              <p>
                <span className={styles.bold}>{cardTemplate?.accountTypeSubHeadingLabel}</span>
                {` ${currentAccount.name}`}
              </p>
            )}
          </div>
        </section>
        {!isBlocked && renderContent()}
        {isBlocked && accountBlockedState && renderBlocked(accountBlockedState)}
        {isClosing &&
          closingContent?.map((infoBlock, i) => (
            <BasicInfoBlock
              data={infoBlock}
              key={infoBlock.contentLink.guidValue}
              nextBlockType={infoBlock.contentType[i + 1]}
              noBorder={i < closingContent.length - 1}
              noBottomPadding={i < closingContent.length - 1}
            />
          ))}
        {!isBlocked && <ContentArea page={cardTemplate} />}
      </div>
    </>
  );
});

export default withRoot(CardPage);
