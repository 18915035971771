/* eslint-disable no-undef */
import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { IncreaseInstallmentsType, Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { AccountType } from "../../models/CustomerProducts";
import { IIncreaseInstallmentsPage } from "libs/models/Content/Page";
import { ListItem } from "libs/models/Content/ListItem";
import { IIncreaseInstallmentType } from "libs/models/Content/IncreaseInstallmentType";

export interface IIncreaseInstallmentsForm {
  amount: number;
  increaseInstallmentType: string;
  phone: string;
}

interface IIncreaseInstallmentsProps {
  locale: Locale;
  page: IIncreaseInstallmentsPage;
  accountType?: AccountType;
  allowBalloonPayment?: boolean;
}

export const getIncreaseInstallmentTypes = (
  increaseInstallmentTypes: IIncreaseInstallmentType[],
  allowBalloonPayment?: boolean,
): ListItem[] =>
  Object.keys(IncreaseInstallmentsType)
    .filter((key) => (!allowBalloonPayment ? key !== "BalloonPayment" : true))
    .map((key, index) => ({
      index,
      enumValue: (IncreaseInstallmentsType as KeyObject<string>)[key],
      value:
        increaseInstallmentTypes?.find(
          (increaseInstallmentType) => increaseInstallmentType.type === (key as IncreaseInstallmentsType),
        )?.label || tx(`increaseInstallments.increaseInstallmentsType.${key}`),
      label:
        increaseInstallmentTypes?.find(
          (increaseInstallmentType) => increaseInstallmentType.type === (key as IncreaseInstallmentsType),
        )?.label || tx(`increaseInstallments.increaseInstallmentsType.${key}`),
    }));

export const generateIncreaseInstallmentsSchema = (
  props: IIncreaseInstallmentsProps,
): yup.ObjectSchema<IIncreaseInstallmentsForm> => {
  const { amount, phone, increaseInstallmentType, increaseInstallmentTypes } = props.page;
  const invalidAmountError = amount?.error || tx("validators.required");
  const invalidIncreaseInstallmentTypeError = increaseInstallmentType?.error || tx("validators.required");
  const invalidPhoneError = phone?.error || tx("validators.phone");

  const increaseInstallmentTypeOptions = getIncreaseInstallmentTypes(
    increaseInstallmentTypes,
    props.allowBalloonPayment,
  );
  const validIncreaseInstallmentTypes = increaseInstallmentTypeOptions.map((item) => item.value);

  const schema = yup.object({
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(invalidAmountError)
      .positive(invalidAmountError),
    increaseInstallmentType: yup
      .string()
      .oneOf(validIncreaseInstallmentTypes, invalidIncreaseInstallmentTypeError)
      .required(invalidIncreaseInstallmentTypeError),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
  });

  return schema;
};
