import { ICustomerResponse, IUpdateContactInfo } from "libs/models/Customer/Customer";

export const mockDKCustomerData: ICustomerResponse = {
  customerData: {
    customerId: "24325123",
    name: "Nicklas",
    surname: "Bendter",
    address: {
      street: {
        value: "Per Henrik Lings Allé 2",
        editable: false,
      },
      city: {
        value: "Copenhagen",
        editable: false,
      },
      country: {
        value: "Denmark",
        editable: false,
        validAnswers: [],
      },
      postCode: {
        value: "2100",
        editable: false,
      },
    },
    emailAddress: {
      value: "test@santanderconsumer.dk",
      editable: true,
    },
    mobilePhone: {
      value: "35431234",
      editable: true,
    },
    capabilities: {
      canHandleTransferAccounts: false,
      hasNationalPayoutAccount: true,
    },
    personalPreferences: [],
  },
};

export const mockUpdateContactInfo: IUpdateContactInfo = {
  updatedCustomerInfoError: false,
};
