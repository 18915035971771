import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FileAttachment } from "../FileAttachment/FileAttachment";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { acceptedDocumentExtensions, acceptedDocumentMimeTypes } from "netbank-shared/src/libs/utils";
import { IAttachment } from "netbank-shared/src/libs/models/Attachment/Attachment";

interface IFileAttachmentFormFieldProps {
  fileInputId: string;
  name: string;
  label: string;
  infoPopover?: IInfoPopoverProps;
  desktopLabel?: string;
  desktopLabelSuffix?: string;
  mobileLabel?: string;
  maxFileSizeLabel?: string;
  acceptedExtensionsLabel?: string;
  triggerValidation?: boolean;
  disabled?: boolean;
  maxAttachments?: number;
}

export const FileAttachmentFormField = ({
  fileInputId,
  name,
  label,
  infoPopover,
  desktopLabel,
  desktopLabelSuffix,
  mobileLabel,
  maxFileSizeLabel,
  acceptedExtensionsLabel,
  triggerValidation,
  disabled,
  maxAttachments,
}: IFileAttachmentFormFieldProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
    trigger,
  } = useFormContext();
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const error = errors[name]?.message?.toString();

  const addAttachments = (files: File[]) => {
    const newAttachments = files.map(
      (file) =>
        ({
          id: uuidv4(),
          fileName: file.name,
          file,
        }) as IAttachment,
    );
    setAttachments([...attachments, ...newAttachments]);
  };

  const removeAttachments = (id: string) => attachments.filter((a) => a.id !== id);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FileAttachment
          id={fileInputId}
          infoPopover={infoPopover}
          label={label}
          acceptedMimeTypes={acceptedDocumentMimeTypes}
          acceptedExtensions={acceptedDocumentExtensions}
          attachments={attachments}
          addAttachments={(files) => {
            addAttachments(files);
            field.onChange(files);
            if (triggerValidation) trigger();
          }}
          removeAttachment={(id) => {
            const updatedAttachments = removeAttachments(id);
            setAttachments(updatedAttachments);
            field.onChange(updatedAttachments);
            if (triggerValidation) trigger();
          }}
          desktopLabel={desktopLabel}
          desktopLabelSuffix={desktopLabelSuffix}
          mobileLabel={mobileLabel}
          maxFileSizeLabel={maxFileSizeLabel}
          acceptedExtensionsLabel={acceptedExtensionsLabel}
          errors={error ? [error] : undefined}
          disabled={disabled || isSubmitting}
          maxAttachments={maxAttachments}
        />
      )}
    />
  );
};
