export const spaceEveryFourthLetter = (str: string | undefined): string => {
  if (!str) {
    return "";
  }
  let newStr = "";
  str.split("").forEach((letter, i) => {
    newStr += letter;
    if ((i + 1) % 4 === 0) {
      newStr += " ";
    }
  });
  return newStr;
};
