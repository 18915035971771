import React, { RefObject, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import loanTerminationsStyles from "../LoanTerminationsPage.scss";
import styles from "../../../Page.scss";
import rootStyles from "~views/pages/Root.scss";
import { Button } from "~views/shared";
import dayjs from "dayjs";
import { tx } from "netbank-shared/src/libs/i18n";
import calendarIcon from "~assets/calendar.svg";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import errorIcon from "~assets/error.svg";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { DayPickerComponent } from "~views/shared/DayPicker/DayPicker";
import { formatDate } from "netbank-shared/src/libs/utils";

interface ILoanTerminationCalcStep {
  data: ILoanTerminationsPage;
  goToNextStep: () => void;
}

export const LoanTerminationCalcStep = observer(({ data, goToNextStep }: ILoanTerminationCalcStep) => {
  const { loanStore } = useStores();

  const { loanTerminationsCalcDate, hasConsentTerminationDateError } = loanStore;

  const dayPickerClasses = [loanTerminationsStyles.dayPicker];

  const firstSelectableDate = dayjs().add(1, "d").toDate();

  const contentRef: RefObject<HTMLDivElement> = React.useRef(null);
  const calendarButtonRef: RefObject<HTMLButtonElement> = React.useRef(null);

  const [open, setOpen] = useState(false);
  const [loadingNextStep, setLoadingNextStep] = React.useState(false);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  if (open) {
    dayPickerClasses.push(loanTerminationsStyles.open);
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (contentRef && e.target instanceof Node && !contentRef.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  const getLoanAccountTerminationsDetails = async () => {
    if (!loanTerminationsCalcDate) {
      loanStore.hasConsentTerminationDateError = true;
      return;
    }

    setLoadingNextStep(true);
    const success = await loanStore.getLoanAccountTerminationDetails(loanStore.currentAccountId);
    setLoadingNextStep(false);
    if (success) {
      goToNextStep();
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      <HtmlContent className={loanTerminationsStyles.textContent} html={data.calculateStepInformationText} />
      <div className={loanTerminationsStyles.dropdownWrapper}>
        <div ref={contentRef} className={loanTerminationsStyles.dropdown}>
          <Button
            className={styles.dateButton}
            title={(loanTerminationsCalcDate && formatDate(loanTerminationsCalcDate)) || tx("misc.selectDate")}
            onClick={() => setOpen(!open)}
            borderColor="blue"
            active={open}
            iconSuffix={calendarIcon}
            bordered
            fullWidth
            ref={calendarButtonRef}
            disabled={loadingNextStep}
          />

          <div className={dayPickerClasses.join(" ")}>
            <DayPickerComponent
              onDayClick={(date: Date) => {
                loanStore.setConsentTerminationDateError(false);
                loanStore.updateLoanTerminationsCalcDate(date);
                setOpen(false);
              }}
              initialMonth={firstSelectableDate}
              selectedDays={loanTerminationsCalcDate?.toDate()}
              disabledDays={[{ before: firstSelectableDate }]}
            />
          </div>
          {hasConsentTerminationDateError && (
            <div className={rootStyles.error}>
              <img src={errorIcon} alt="error-icon" />
              <span>{data.dateError}</span>
            </div>
          )}
        </div>
      </div>
      <div className={loanTerminationsStyles.actions}>
        <Button
          title={data.calculateButtonLabel || tx("loan.loanTerminations.calcButtonLabel")}
          color="red"
          large
          centered
          onClick={() => getLoanAccountTerminationsDetails()}
          loading={loadingNextStep}
          disabled={loadingNextStep}
        />
      </div>
    </div>
  );
});
