import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IInfoPopoverProps } from "../InfoPopover/InfoPopover";
import { Dropdown } from "../Dropdown/Dropdown";
import { tx } from "netbank-shared/src/libs/i18n";
import { ListItem, MessageProductListItem } from "netbank-shared/src/libs/models/Content/ListItem";

interface IDropdownFormFieldProps {
  name: string;
  label: string;
  list: ListItem[];
  infoPopover?: IInfoPopoverProps;
  triggerValidation?: boolean;
  displayValue?: string;
  valueResetList?: string[]; // List of field names that should reset onChange
  disabled?: boolean;
  placeholder?: string;
  transform?: { input: (value: any) => string; output: (item: ListItem & Partial<MessageProductListItem>) => any };
}

export const DropdownFormField = ({
  label,
  name,
  list,
  infoPopover,
  triggerValidation,
  valueResetList = [],
  disabled,
  placeholder,
  transform = { input: (value) => value, output: (item: ListItem) => item.value },
}: IDropdownFormFieldProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
    trigger,
    resetField,
  } = useFormContext();
  const error = errors[name]?.message?.toString();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldWithRef }) => {
        const { ref, ...field } = fieldWithRef; // eslint-disable-line @typescript-eslint/no-unused-vars
        return (
          <Dropdown
            {...field}
            value={transform.input(field.value)}
            placeholder={placeholder}
            infoPopover={infoPopover}
            label={label}
            innerLabel={tx("misc.select")}
            onChange={(item) => {
              field.onChange(transform.output(item));
              valueResetList.forEach((valueName) => {
                resetField(valueName);
              });
              if (triggerValidation) trigger();
            }}
            list={list!}
            error={error}
            disabled={disabled || isSubmitting}
          />
        );
      }}
    />
  );
};
