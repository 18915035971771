import React from "react";
import { observer } from "mobx-react";
import { SkeletonWrapper } from "~views/shared";
import { useStores } from "netbank-shared/src/hooks";
import styles from "./SkeletonStyles.scss";

export const SelfServicePageSkeleton = observer(() => {
  const { uiStore } = useStores();

  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.accountInfoContainer}>
          <div style={{ paddingBottom: 5 }}>
            <SkeletonWrapper height={20} width={200} />
          </div>
          <div>
            <SkeletonWrapper height={20} width={200} />
          </div>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <SkeletonWrapper height={40} width={300} />
        </div>
        <div style={{ paddingBottom: 20 }}>
          <SkeletonWrapper height={10} />
          <SkeletonWrapper height={10} />
          <SkeletonWrapper height={10} />
          <SkeletonWrapper height={10} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SkeletonWrapper height={250} />
          <div className={styles.buttonContainer}>
            <div className={styles.button}>
              <SkeletonWrapper height={40} width={uiStore.isMobile ? 300 : 150} />
            </div>
            <SkeletonWrapper height={40} width={uiStore.isMobile ? 300 : 150} />
          </div>
        </div>
      </div>
    </section>
  );
});
