import { observer } from "mobx-react";
import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { EmptyState } from "~views/shared";
import accordionStyles from "../../../shared/ContentArea/Blocks/AccordionBlock.scss";
import styles from "./MessageEmptyState.scss";
import newConversationStyles from "./NewConversation.scss";

interface IMessageEmptyStateProps {
  data: IEmptyState;
}

export const MessageEmptyState = observer(({ data }: IMessageEmptyStateProps) => {
  const { contentStore, uiStore } = useStores();
  const page = contentStore.currentPage;
  const returnUrl = page?.messageInboxPage;
  const wrapperStyles = [];
  if (uiStore.isMobile) {
    wrapperStyles.push(newConversationStyles.newMessageWrapper, styles.mobileWrapper);
  }

  const contentStyles = [accordionStyles.content, accordionStyles.verticalPadding];

  return (
    <section className={wrapperStyles.join(" ")}>
      <div className={newConversationStyles.newMessageWrapper}>
        <div className={contentStyles.join(" ")}>
          <EmptyState data={data} wrapperClassName={styles.emptyState} returnLink={returnUrl} />
        </div>
      </div>
    </section>
  );
});
