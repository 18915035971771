import { observer } from "mobx-react";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  ConfigEditor,
  HtmlContent,
  InputToggle,
  CookieConsent,
  LangPicker,
  ConfirmationPopup,
  LoadingLottie,
  LoginNotifications,
} from "~views/shared/index";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { Environment, Lang } from "netbank-shared/src/libs/models/Content/Enums";
import { isUrl } from "netbank-shared/src/libs/utils";
import { withStore } from "../../../mobx-web";
import styles from "./Login.scss";
import chevronRight from "~assets/chevron-right-red.svg";
import santanderLogotype from "~assets/santander-logo.svg";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { RetryPage } from "../RetryPage/RetryPage";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { useLocation } from "react-router-dom";

export interface ILoginPageFaqItemProps {
  item: IGenericContentLink<IFAQItem>;
}

const LoginPageFaqItem = observer(({ item }: ILoginPageFaqItemProps) => {
  const guid = item.contentLink.guidValue;
  const [open, setOpen] = useState(false);
  const questionClasses = [styles.question];
  const answerClasses = [styles.answer];
  if (open) {
    questionClasses.push(styles.open);
    answerClasses.push(styles.open);
  }
  return (
    <div className={styles.qaWrapper}>
      <div key={`faq-${guid}`} className={questionClasses.join(" ")} onClick={() => setOpen(!open)}>
        <span>{item.question}</span>
      </div>
      <HtmlContent className={answerClasses.join(" ")} html={item.answer} />
    </div>
  );
});

const LoginPage = observer(() => {
  const location = useLocation();
  const { customerStore, uiStore, contentStore, rootStore, globalPersistentStore, securePersistentStore } = useStores();

  const [uncachedHomePage, setUncachedHomePage] = useState<IPage>();

  const confirmationPopupRef: RefObject<HTMLDivElement> = useRef(null);

  const { currentPage } = contentStore;

  const logotype = uiStore.isMobile
    ? currentPage?.mobileLogotype?.url
    : currentPage?.logotypeLoginPage?.url || santanderLogotype;

  const santanderHomepageURL = currentPage?.santanderHomepageURL || tx("login.santanderHomepageURL");

  const loginHeader = currentPage?.loginHeader;
  const loginText = currentPage?.loginText;

  const loginFAQ = currentPage?.loginFAQ;

  const loginImage = uiStore.isMobile ? currentPage?.loginImageMobile : currentPage?.loginImage;

  const confirmationPopupActive = uiStore.confirmationPopup !== undefined;

  const offset = 170;
  const numOfLoginNotificaitions = currentPage?.loginNotifications?.length || 0;
  const notificationPaddingOffset = numOfLoginNotificaitions ? numOfLoginNotificaitions * offset : 0;
  const queryParams = new URLSearchParams(window.location.search);
  const redirected = queryParams.get("redirected");

  useEffect(() => {
    const fetchPage = async () => {
      // Do not cache homepage used to show login content.
      // User specific headers are missing for episerver visitor groups.
      const homePage = await contentStore.getPage(`/${tx("routing.lang")}`, false);

      if (!homePage) {
        return;
      }

      setUncachedHomePage(homePage);

      const availableLanguages = homePage?.languagePicker?.supportedLanguages;

      if (!availableLanguages) {
        return;
      }

      if (!homePage?.languagePicker?.supportedLanguages?.includes(tx("routing.lang") as Lang)) {
        if (redirected) {
          return;
        }

        contentStore.setI18nLang(uiStore.lang);
        rootStore.commonService.redirectToLogin();
      }
    };

    fetchPage();
    if (securePersistentStore.accessToken) {
      rootStore.logout(true);
    }
    uiStore.setLoading(!!contentStore.currentPage);
  }, [location]);

  useEffect(() => {
    if (!currentPage) {
      uiStore.setLoading(true);
    } else {
      uiStore.setLoading(false);
    }
  }, [currentPage]);

  const onSubmit = async () => {
    if (!rootStore.isMock) {
      const authUrl = await customerStore.getAuthenticationUrl();
      window.open(authUrl, "_self");
    } else {
      window.open(`${tx("routing.lang")}/auth/callback`, "_self");
    }
  };

  if (contentStore.isRetryPage) return <RetryPage />;

  if (!currentPage) return null;

  return (
    <>
      <Helmet>
        <title>
          {currentPage?.loginPageTitle ? `${currentPage?.loginPageTitle} - Santander` : "Netbank - Santander"}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {rootStore.config?.COOKIE_CONSENT_SCRIPT && isUrl(rootStore.config.COOKIE_CONSENT_SCRIPT) && (
        <CookieConsent cookieScript={rootStore.config.COOKIE_CONSENT_SCRIPT} />
      )}
      {confirmationPopupActive && <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />}
      <header className={`${styles.loginHeader} ${styles.flexBetween}`}>
        {logotype && (
          <a href={santanderHomepageURL}>
            <img className={styles.loginLogotype} src={logotype} alt="Santander" />
          </a>
        )}
        <div>
          <LangPicker uncachedHomePage={uncachedHomePage} />
        </div>
      </header>
      <div>
        <LoginNotifications />
        <div className={styles.wrapper}>
          {!uiStore.isMobile && (
            <div className={styles.image} style={{ backgroundImage: `url("${loginImage?.url}")` }} />
          )}
          {!customerStore.isLoginSubmitting && (
            <div
              className={`${styles.contentWrapper} ${styles.form}`}
              style={{ paddingTop: notificationPaddingOffset }}
            >
              <h1>{loginHeader}</h1>
              <p className={styles.large}>{loginText}</p>

              <Button
                title={currentPage.loginButtonTextDesktop || tx("login.buttonText")}
                color="white"
                iconSuffix={chevronRight}
                bordered
                borderColor="darkSky"
                className={styles.loginButton}
                fullWidth
                centered
                loading={customerStore.isLoginSubmitting}
                onClick={() => {
                  onSubmit();
                }}
                trackingCategory={TrackingCategory.Login}
                trackingAction={uiStore.isMobile ? TrackingAction.AnotherDevice : TrackingAction.SameDevice}
              />
              {rootStore.config?.OCTOPUS_ENV !== Environment.Production && (
                <>
                  <div className={styles.mockToggle}>
                    <span>{tx("login.mockData")}</span>
                    <InputToggle
                      id="mock-toggle"
                      toggle={() => rootStore.setIsMock(!rootStore.isMock)}
                      checked={globalPersistentStore.isMock}
                    />
                  </div>
                  <ConfigEditor />
                </>
              )}
              <hr />
              {loginFAQ &&
                loginFAQ.length > 0 &&
                loginFAQ.map((item) => <LoginPageFaqItem item={item} key={item.contentLink.guidValue} />)}
            </div>
          )}

          {customerStore.isLoginSubmitting && !customerStore.tokenRetrieved && <LoadingLottie />}
        </div>
      </div>
    </>
  );
});

export default withStore(LoginPage);
