import { GdprInformationType } from "../models/Content/Enums";

export const mapGdprInformationType = (informationType: string) => {
  switch (informationType) {
    case GdprInformationType.Application:
      return GdprInformationType.Application;
    case GdprInformationType.Contract:
      return GdprInformationType.Contract;
    case GdprInformationType.Customer:
      return GdprInformationType.Customer;
    default:
      return "";
  }
};
