import React from "react";
import { observer } from "mobx-react";
import styles from "./InputToggle.scss";

interface IInputToggleProps {
  id: string;
  checked?: boolean;
  toggle?: () => void;
}

export const InputToggle = observer(({ id, checked, toggle }: IInputToggleProps) => {
  const handleChange = () => {
    if (toggle) {
      toggle();
    }
  };

  return (
    <label className={styles.toggle} htmlFor={`checkbox-${id}`}>
      <input id={id} type="checkbox" checked={checked} onChange={handleChange} />
      <div className={styles.slider} onClick={handleChange}>
        <div className={styles.icon} />
      </div>
    </label>
  );
});
