import { IToAccountFields } from "libs/models/Transfer/Transfer";

export const buildAccountDisplayString = (number: string, name?: string, separator: string = " | ") => {
  if (name) {
    return `${name}${separator}${number}`;
  }
  return number;
};

export const getToAccountDisplayNumber = (
  { displayNumber, clearingNumber, accountNumber }: IToAccountFields,
  separator = "-",
) => {
  if (displayNumber) {
    return displayNumber;
  }
  if (clearingNumber) {
    return `${clearingNumber}${separator}${accountNumber}`;
  }
  return accountNumber;
};
