import { CommonService } from "netbank-shared/src/libs/models/CommonService";
import { NavigateFunction } from "react-router-dom";
import { getQueryParam } from "../utils/misc";
import { jsPDF } from "jspdf";
import { tx } from "netbank-shared/src/libs/i18n";
import { base64ToBlob, createAutoDownloadTempLink } from "netbank-shared/src/libs/utils";

export class WebCommonService extends CommonService {
  constructor(private navigate: NavigateFunction) {
    super();
  }

  handleLogout = (url?: string, isInternal?: boolean) => {
    if (isInternal || !url) {
      this.redirectToLogin();
      return;
    }

    window.location.href = url;
  };

  getQueryParam = (key: string): string => {
    return getQueryParam(window.location.search, key);
  };

  redirect = (url: string) => {
    if (!url) return;

    this.navigate?.(url);
  };

  redirectToLogin = () => {
    this.redirect(`/${tx("routing.lang")}/login?redirected=true`);
  };

  createPdf = () => {
    /* eslint-disable new-cap */
    const doc = new jsPDF();
    doc.text("Mock file", 10, 10);
    const blobPart = doc.output("arraybuffer");
    return blobPart;
  };

  downloadDocument = async (
    fileData: string | Blob,
    name: string,
    mimeType?: string,
    autoDownload: boolean = true,
    inModal = false,
  ) => {
    const blob = fileData instanceof Blob ? fileData : base64ToBlob(fileData);
    const objectUrl = URL.createObjectURL(blob);

    createAutoDownloadTempLink(name, objectUrl, autoDownload, inModal);
  };
}
