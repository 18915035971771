import React from "react";
import { observer } from "mobx-react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { ITerminateInsurancePage } from "netbank-shared/src/libs/models/Content/Page";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ITerminateInsuranceForm,
  generateTerminateInsuranceSchema,
  getTerminateInsuranceReasons,
} from "netbank-shared/src/libs/forms/Insurances/TerminateInsuranceForm";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import {
  CheckboxFormField,
  DropdownFormField,
  FormWrapper,
  InputFormField,
  TextAreaFormField,
} from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { useDetermineTerminateInsuranceParamsByAccountType } from "netbank-shared/src/libs/determineLoadingParams";

export const TerminateInsurancePage = observer(() => {
  const navigate = useNavigate();
  const { contentStore, uiStore, rootStore, customerStore } = useStores();
  const { currentCustomer } = customerStore;
  const { currentPage } = contentStore;

  const page = currentPage as ITerminateInsurancePage;
  const { currentAccount: rootCurrentAccount } = rootStore;

  const currentAccountType = rootCurrentAccount?.accountType;

  const { terminateInsurance, resetSelfServiceState, currentAccount, selfServiceSusbmissionState } =
    useDetermineTerminateInsuranceParamsByAccountType(currentAccountType);

  const {
    terminateInsuranceReason,
    terminateInsuranceReasons,
    phone,
    message,
    termsCheckbox,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
  } = page;

  const methods = useForm<ITerminateInsuranceForm>({
    resolver: yupResolver(
      generateTerminateInsuranceSchema({
        locale: uiStore.locale!,
        page,
      }),
    ),
    defaultValues: {
      phone: currentCustomer?.mobilePhone?.value,
    },
  });

  useFetchData({
    cleanup: () => resetSelfServiceState(),
  });

  if (
    !currentAccount?.insurances ||
    currentAccount.insurances.findIndex((insurance) => !!insurance.canTerminate) === -1
  ) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const terminateInsuranceReasonOptions = getTerminateInsuranceReasons(terminateInsuranceReasons);

  const onSubmit: SubmitHandler<ITerminateInsuranceForm> = async (data) =>
    terminateInsurance({
      ...data,
      terminationReason: terminateInsuranceReasonOptions.find((option) => option.value === data.terminationReason)
        ?.enumValue,
    });

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <DropdownFormField
              label={terminateInsuranceReason?.label || tx("terminateInsurance.terminateInsuranceReasonLabel")}
              infoPopover={{
                popoverTitle: terminateInsuranceReason?.infoTitle,
                content: terminateInsuranceReason?.infoText,
              }}
              name="terminationReason"
              list={terminateInsuranceReasonOptions}
            />
            <TextAreaFormField
              label={message?.label || tx("selfServiceLabels.messageLabel")}
              infoPopover={{ popoverTitle: message?.infoTitle, content: message?.infoText }}
              name="message"
              placeholder={message?.placeholder}
            />
            <InputFormField
              type="tel"
              label={phone?.label || tx("selfServiceLabels.phoneLabel")}
              infoPopover={{ popoverTitle: phone?.infoTitle, content: phone?.infoText }}
              name="phone"
              placeholder={phone?.placeholder}
              allowedCharacters={/[^0-9+]/g}
            />
            <CheckboxFormField
              name="termsAccepted"
              label={termsCheckbox?.label || tx("selfServiceLabels.termsCheckboxLabel")}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
