/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, action, makeObservable, computed } from "mobx";
import { CancelTokenSource } from "axios";
import dayjs from "dayjs";
import { ApiErrorResponse, ApiOkResponse } from "apisauce";
import { AuthorizeResult } from "react-native-app-auth";
import { CustomerApi, CustomerProductsApi, AuthenticationApi, ChatApi } from "../libs/api";
import { addIDsToPreferences, generateRandomString, keysToCamel, pkceChallenge } from "../libs/utils";
import { IGetCurityTokenResponse } from "../libs/models/Auth";
import { Store } from "./Store";
import { IContentAreaItem } from "../libs/models/Content/ContentAreaItem";
import {
  CustomerContactInfoField,
  ICustomer,
  InputField,
  UpdateCustomerInfoStatus,
} from "../libs/models/Customer/Customer";
import { AccountNicknameValidator } from "../libs/validators";
import { CustomerPersistentStore } from "./CustomerPersistentStore";
import { IContactInfoForm } from "libs/forms/ContactInfoForm";
import { GdprInformationType } from "libs/models/Content/Enums";

export class CustomerStore {
  authApi: AuthenticationApi;

  customerApi: CustomerApi;

  customerProductsApi: CustomerProductsApi;

  chatApi: ChatApi;

  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  constructor(
    authApi: AuthenticationApi,
    customerApi: CustomerApi,
    customerProductsApi: CustomerProductsApi,
    chatApi: ChatApi,
    rootStore: Store,
    customerPersistentStore: CustomerPersistentStore,
  ) {
    this.authApi = authApi;
    this.customerApi = customerApi;
    this.customerProductsApi = customerProductsApi;
    this.chatApi = chatApi;
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
    makeObservable(this);
  }

  refreshTokenOffsetSeconds: number = 30;

  @observable
  currentCustomer?: ICustomer | null = undefined;

  @observable
  unreadMessages: number = 0;

  @observable
  unpaidInvoices: number = 0;

  @observable
  cancelLoginToken?: CancelTokenSource;

  @observable
  autoStartToken?: string = "";

  @observable
  loginNounce?: string;

  @observable
  loginOnSameDevice: boolean = false;

  @observable
  isSigningOnSameDevice: boolean = false;

  @observable
  tokenRequested: boolean = false;

  @observable
  tokenRetrieved: boolean = false;

  @observable
  isLoginSubmitting: boolean = false;

  @observable
  previousLoginEvent: string = "";

  @observable
  loginResubmitForm?: () => void;

  @observable
  fetchingDataPortabilityDocument?: boolean;

  @observable
  loadingGiosg = false;

  @observable
  isGiosgInitialized = false;

  @observable
  giosgVisitorId = "";

  @observable
  giosgRooms: string[] = [];

  @observable
  isUpdatingContactInfo = false;

  @observable
  hasUpdateAccountNicknameError: boolean = false;

  @observable
  hasValidationAccountNicknameError: boolean = false;

  setCurrentCustomer = (customer: ICustomer) => {
    this.currentCustomer = customer;
  };

  @computed
  get hasParties(): boolean {
    return (this.currentCustomer?.parties && this.currentCustomer.parties.length > 0) || false;
  }

  @action
  isOnlyCorporateCustomer = () => {
    return this.rootStore.allCustomerProducts?.length === 0 && this.hasParties;
  };

  @action
  getCustomer = async (updatingContactInfo: boolean = false) => {
    if (!updatingContactInfo && this.currentCustomer) return this.currentCustomer;

    const response = await this.customerApi.getCustomer();

    if (response?.ok && response.data?.customerData) {
      const { customerData } = response.data;
      // Add id's to be used as keys
      customerData.personalPreferences = addIDsToPreferences(customerData.personalPreferences) || [];
      this.currentCustomer = customerData;

      const { customerId } = customerData;

      if (!this.rootStore.customerPersistentStore.isHydrated) {
        await this.rootStore.customerPersistentStore.hydrate(customerId);
      }
      this.rootStore.globalPersistentStore.customerId = customerId;
      this.rootStore.uiStore.initCustomerActivityEvents();
      if (this.rootStore.platformStore?.isWeb()) {
        this.rootStore.uiStore.registerBrowserLeaveWarning();
      }

      return customerData;
    }
    this.currentCustomer = null;
    return null;
  };

  getCurrentParty = () => {
    const { currentOrganizationNumber } = this.customerPersistentStore;

    if (!currentOrganizationNumber) return undefined;

    return this.currentCustomer?.parties?.find((party) => party.identityNumber === currentOrganizationNumber);
  };

  @action
  updateCustomerContactInfo = (field: CustomerContactInfoField, value: string) => {
    if (!this.currentCustomer) return;

    let fieldToUpdate: InputField = {};

    switch (field) {
      case CustomerContactInfoField.MobilePhone:
        fieldToUpdate = this.currentCustomer.mobilePhone;
        break;
      case CustomerContactInfoField.Email:
        fieldToUpdate = this.currentCustomer.emailAddress;
        break;
      case CustomerContactInfoField.StreetAddress:
        fieldToUpdate = this.currentCustomer.address.street;
        break;
      case CustomerContactInfoField.PostCode:
        fieldToUpdate = this.currentCustomer.address.postCode;
        break;
      case CustomerContactInfoField.City:
        fieldToUpdate = this.currentCustomer.address.city;
        break;
      case CustomerContactInfoField.Country:
        fieldToUpdate = this.currentCustomer.address.country;
        break;
      default:
        return;
    }

    fieldToUpdate.value = value;

    this.currentCustomer = {
      ...this.currentCustomer,
      ...fieldToUpdate,
    };
  };

  @action
  updateContactInfo = async (data: IContactInfoForm) => {
    this.isUpdatingContactInfo = true;

    const updatedData = {
      mobilePhone: data.mobilePhone,
      email: data.emailAddress,
      street: data.addressStreet,
      postalCode: data.addressPostCode,
      city: data.addressCity,
      country: data.addressCountry,
      personalPreferences: data.personalPreferences,
      partnerConsents: data.partnerConsents,
    };

    const response = await this.customerApi.updateContactInfo(updatedData);
    this.isUpdatingContactInfo = false;

    this.getCustomer(true);

    if (response?.ok || response?.data?.updatedCustomerInfoError) {
      return UpdateCustomerInfoStatus.UpdateSuccessfull;
    }

    return UpdateCustomerInfoStatus.UpdateFailed;
  };

  getCustomerProducts = async () => this.rootStore.getCustomerProducts();

  getGdprData = async (informationType: GdprInformationType, withFile?: boolean) => {
    const response = await this.customerApi.getGdprData(informationType, withFile);

    if (response?.ok && response.data) {
      return response.data;
    }
    return false;
  };

  getAuthenticationUrl = async () => {
    const PKCEChallenge = await pkceChallenge();

    const state = generateRandomString();
    this.rootStore.securePersistentStore.setState(state);

    // create code verifier
    this.rootStore.securePersistentStore.setCodeVerifier(PKCEChallenge.codeVerifier);

    return this.authApi.getAuthCodeUrl(PKCEChallenge.codeChallenge, state);
  };

  getCurityToken = async (code?: string) => {
    const { codeVerifier } = this.rootStore.securePersistentStore;
    const tokenResponse = await this.authApi.getToken(code, codeVerifier);
    return this.handleTokenResponse(tokenResponse);
  };

  cancelLogin = async () => {
    if (this.cancelLoginToken) {
      this.cancelLoginToken.cancel();
    }
  };

  refreshToken = async () => {
    const { refreshToken } = this.rootStore.securePersistentStore;
    if (!refreshToken) return;

    const tokenResponse = await this.authApi.refreshToken(refreshToken);
    this.handleTokenResponse(tokenResponse);
  };

  handleTokenResponse = (
    tokenResponse: ApiErrorResponse<IGetCurityTokenResponse> | ApiOkResponse<IGetCurityTokenResponse> | undefined,
  ): boolean => {
    if (tokenResponse?.ok && tokenResponse.data) {
      const data = keysToCamel(tokenResponse.data) as IGetCurityTokenResponse;
      if (data?.accessToken) {
        this.rootStore.securePersistentStore.setAccessToken(data.accessToken);
      }

      if (data?.refreshToken) {
        this.rootStore.securePersistentStore.setRefreshToken(data.refreshToken);
      }

      if (data?.expiresIn && data?.refreshToken) {
        const expiresInMs = (data.expiresIn - this.refreshTokenOffsetSeconds) * 1000;
        this.rootStore.securePersistentStore.setExpiresIn(expiresInMs);
        this.rootStore.securePersistentStore.setTokenTimestamp(dayjs().toString());
      }
      return true;
    }

    this.rootStore.logout();
    return false;
  };

  getMobileAppsAuthorizeRequest = () => this.authApi.mobileAppsAuthorizeRequest;

  handleMobileAppsAuthorizeResponse = (result: AuthorizeResult) => {
    if (result.accessToken) {
      this.rootStore.securePersistentStore.setAccessToken(result.accessToken);
    }

    if (result.refreshToken) {
      this.rootStore.securePersistentStore.setRefreshToken(result.refreshToken);
    }

    if (result.accessTokenExpirationDate && result.refreshToken) {
      const expiresInMs = dayjs(result.accessTokenExpirationDate).diff(dayjs(), "ms");
      this.rootStore.securePersistentStore.setExpiresIn(expiresInMs - this.refreshTokenOffsetSeconds * 1000);
      this.rootStore.securePersistentStore.setTokenTimestamp(dayjs().toString());
    }
  };

  customerCanActOn = (blockType?: string) => this.rootStore.customerCanActOn(blockType);

  canActOnSelfServiceContent = (content: IContentAreaItem): boolean => {
    let res = true;
    if (this.customerCanActOn(content?.contentType?.[1])) {
      if (content?.content) {
        content.content.forEach((el: IContentAreaItem) => {
          res = res && this.canActOnSelfServiceContent(el);
        });
      } else {
        res = true;
      }
    } else {
      res = false;
    }
    return res;
  };

  updateCustomerAccountNickname = async (accountId: string, nickname: string) => {
    this.hasUpdateAccountNicknameError = false;
    if (!AccountNicknameValidator(nickname || "", {}).valid) {
      this.hasValidationAccountNicknameError = true;
      return;
    }

    const response = await this.customerProductsApi.createNickname({
      accountNumber: accountId,
      accountNickname: nickname,
    });

    if (response?.ok) {
      await this.getCustomerProducts();
      const customerProduct = this.rootStore.allCustomerProducts?.find((account) => account.accountId === accountId);
      const savedNickname = customerProduct?.nickname;
      if (savedNickname === nickname) {
        return nickname; // eslint-disable-line consistent-return
      }
    } else {
      this.hasUpdateAccountNicknameError = true;
    }
  };

  addDisbursementAccount = async (disbursementAccount: string) => {
    const response = await this.customerApi.addDisbursementAccount(disbursementAccount.replace(/\s/g, ""));
    if (response?.ok) {
      return UpdateCustomerInfoStatus.UpdateSuccessfull;
    }

    return UpdateCustomerInfoStatus.UpdateFailed;
  };

  @action
  initializeGiosg = async (visitorId: string, rooms: string[]) => {
    if (this.loadingGiosg) return;

    this.loadingGiosg = true;
    await this.chatApi.initiateChat(visitorId, rooms);
    this.loadingGiosg = false;
    this.giosgVisitorId = visitorId;
    this.giosgRooms = rooms;
    this.setIsGiosgInitialized(true);
  };

  @action
  setIsGiosgInitialized = (value: boolean) => {
    this.isGiosgInitialized = value;
  };

  @action
  resetAccountNicknameErrors = () => {
    this.hasUpdateAccountNicknameError = false;
    this.hasValidationAccountNicknameError = false;
  };

  @action
  resetStore = () => {
    if (this.rootStore.platformStore?.isWeb()) {
      this.rootStore.uiStore.deRegisterBrowserLeaveWarning();
    }
    this.resetAccountNicknameErrors();
    this.rootStore.uiStore.clearCustomerActivityTimeoutFunc();
    this.currentCustomer = undefined;
    this.cancelLoginToken = undefined;
    this.autoStartToken = "";
    this.loginNounce = undefined;
    this.loginOnSameDevice = false;
    this.isSigningOnSameDevice = false;
    this.tokenRequested = false;
    this.tokenRetrieved = false;
    this.isLoginSubmitting = false;
    this.previousLoginEvent = "";
    this.loginResubmitForm = undefined;
    this.fetchingDataPortabilityDocument = false;
    this.loadingGiosg = false;
    this.isGiosgInitialized = false;
    this.giosgVisitorId = "";
    this.giosgRooms = [];
  };
}
