import { TrackingAction, TrackingCategory } from "libs/models/Tracking";

export const useTracking = (dataLayer: any) => {
  const push = (item: any) => {
    dataLayer?.push(item);
  };

  const pushPageView = (pagePath: string, pageSearch?: string, customObject?: object) => {
    const pageviewObject: any = {
      ...customObject,
      event: "pageview",
      page_title: pagePath,
      page_location: pageSearch,
      page_path: pagePath,
    };

    push(pageviewObject);
  };

  const pushInteraction = (
    eventCategory: TrackingCategory,
    eventAction: TrackingAction,
    eventPrefix?: string,
    eventValue?: number
  ) => {
    if (!eventCategory || !eventAction) return;

    const event = eventPrefix ? `${eventPrefix} | Netbank` : "Netbank";
    push({
      event,
      eventCategory,
      eventAction,
      eventValue,
      eventLabel: window.location.pathname,
    });
  };
  return {
    push,
    pushPageView,
    pushInteraction,
  };
};
