import { IDocument } from "libs/models/CustomerProducts/Document";

export const mockContracts: IDocument[] = [
  {
    id: "56861069",
    created: "2021-05-05T15:09:29",
    fileName: "Test me-Contract 1.pdf",
    mimeType: "application/pdf",
  },
];
