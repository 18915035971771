import { tx } from "../../i18n";
import * as yup from "yup";
import { IPromiseToPayForm } from "./PromiseToPay";

interface IPromiseToPayProps {
  promiseToPayDateError?: string;
}

export const generatePromiseToPayForm = (props: IPromiseToPayProps): yup.ObjectSchema<IPromiseToPayForm> => {
  const requiredError = props.promiseToPayDateError || tx("validators.required");

  const yupSchema = yup.object({
    promiseToPayDate: yup.date().when(["cantPayOnSelectableDates"], {
      is: false,
      then: (schema) => schema.required(requiredError),
    }),
    cantPayOnSelectableDates: yup.bool(),
    message: yup.string().when(["cantPayOnSelectableDates"], {
      is: true,
      then: (schema) => schema.required(requiredError),
    }),
    phoneNumber: yup.string(),
    attachments: yup.array(),
    invoiceAmount: yup.number(),
    dueDate: yup.date(),
  });

  return yupSchema;
};
