import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Home from "../pages/Home/Home";
import Cms from "../pages/Cms/Cms";
import Leasing from "../pages/Leasing/Leasing";
import Card from "../pages/Card/Card";
import Deposit from "../pages/Deposit/Deposit";
import Curity from "../pages/Curity/Curity";
import Login from "../pages/Login/Login";
import PrivateLoan from "../pages/Loan/PrivateLoan";
import SecuredLoan from "../pages/Loan/SecuredLoan";
import SalesFinance from "../pages/Loan/SalesFinance";
import { RoutingAnimationLayout } from "./RoutingAnimationLayout";
import { RouteComponentProps } from "react-router-dom";
import { CountryCode } from "netbank-shared/src/libs/models/Content/Enums";
import { IRouterPathNames, SEPathNames, DKPathNames, FIPathNames, NOPathNames } from "netbank-shared/src/libs/i18n";

interface IRouterWrapperProps extends RouteComponentProps {
  country?: CountryCode;
}

export const StripTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  }

  return null;
};

export const RouterWrapper = ({ country }: IRouterWrapperProps) => {
  const getPathNames = (): IRouterPathNames | undefined => {
    switch (country) {
      case CountryCode.SE:
        return SEPathNames;
      case CountryCode.DK:
        return DKPathNames;
      case CountryCode.FI:
        return FIPathNames;
      case CountryCode.NO:
        return NOPathNames;
      default:
        return undefined;
    }
  };

  const pathNames = getPathNames();

  if (!pathNames) return null;
  return (
    <div className="route-section">
      <StripTrailingSlash />
      <Routes>
        <Route element={<RoutingAnimationLayout />}>
          <Route path="/" element={<Navigate to={`/${pathNames.lang}`} />} />
          <Route path={`/${pathNames.lang}/auth/callback`} element={<Curity />} />
          <Route path={`/${pathNames.lang}/login`} element={<Login />} />
          <Route path={`/${pathNames.lang}`} element={<Home />} />
          <Route path={`/${pathNames.lang}/${pathNames.privateLoan}/:loanId`} element={<PrivateLoan />} />
          <Route path={`/${pathNames.lang}/${pathNames.securedLoan}/:loanId`} element={<SecuredLoan />} />
          <Route path={`/${pathNames.lang}/${pathNames.salesFinance}/:loanId`} element={<SalesFinance />} />
          <Route path={`/${pathNames.lang}/${pathNames.leasing}/:leasingId`} element={<Leasing />} />
          <Route path={`/${pathNames.lang}/${pathNames.card}/:cardId`} element={<Card />} />
          <Route path={`/${pathNames.lang}/${pathNames.deposit}/:depositId`} element={<Deposit />} />
          <Route path="*" element={<Cms />} />
        </Route>
      </Routes>
    </div>
  );
};

export default RouterWrapper;
