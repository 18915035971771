import { ICustomerProducts } from "../../../../models/CustomerProducts";
import { mockBaseCreditAccountOne, mockBaseCreditAccountThree, mockBaseCreditAccountTwo } from "../../credit";
import {
  mockBaseDepositAccountOne,
  mockBaseDepositAccountTwo,
  mockBaseDepositAccountThree,
  mockBaseDepositAccountFour,
  mockBaseDepositAccountFive,
  mockBaseDepositAccountSix,
} from "../../deposit";
import { mockBaseLeaseAccountOne } from "../../leasing";
import {
  mockBasePrivateLoanAccountOne,
  mockBasePrivateLoanAccountTwo,
  mockBasePrivateLoanAccountThree,
  mockBasePrivateLoanAccountFour,
  mockBaseSalesFinanceLoanAccount,
} from "../../loan/privateLoan";
import { mockBaseSecuredLoanAccountOne, mockBaseSecuredLoanAccountTwo } from "../../loan/securedLoan";

export const mockCustomerProducts: ICustomerProducts = {
  creditCustomerProducts: [mockBaseCreditAccountOne, mockBaseCreditAccountTwo],
  closedCreditCustomerProducts: [mockBaseCreditAccountThree],
  depositCustomerProducts: [
    mockBaseDepositAccountOne,
    mockBaseDepositAccountTwo,
    mockBaseDepositAccountThree,
    mockBaseDepositAccountFour,
    mockBaseDepositAccountFive,
  ],
  closedDepositCustomerProducts: [mockBaseDepositAccountSix],
  privateLoanCustomerProducts: [
    mockBasePrivateLoanAccountOne,
    mockBasePrivateLoanAccountTwo,
    mockBasePrivateLoanAccountThree,
    mockBasePrivateLoanAccountFour,
  ],
  securedLoanCustomerProducts: [mockBaseSecuredLoanAccountOne, mockBaseSecuredLoanAccountTwo],
  salesFinanceCustomerProducts: [mockBaseSalesFinanceLoanAccount],
  leasingCustomerProducts: [mockBaseLeaseAccountOne],
  errors: [],
};
