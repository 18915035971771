export const formatStringToFinnishIBAN = (input: string) => {
  const maxLength = 18;
  return (
    input
      .replace(/ /g, "")
      .slice(0, maxLength)
      .match(/.{1,4}/g)
      ?.join(" ")
      .toUpperCase() || ""
  );
};
