export const getQueryParam = (queryString: string, key: string, caseSensitive?: boolean): string => {
  const query = queryString?.substring(1);
  const queryParam = query
    ?.split("&")
    ?.map((elem) => elem.split("="))
    .find((queryPair) => {
      const queryValue = caseSensitive ? queryPair[0] : queryPair[0].toLowerCase();
      const keyValue = caseSensitive ? key : key.toLowerCase();
      return decodeURIComponent(queryValue) === keyValue;
    });
  return queryParam ? decodeURIComponent(queryParam[1]) : "";
};
