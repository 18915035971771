import * as React from "react";
import styles from "./ChangeOverview.scss";
import arrowRight from "~assets/arrow-right.svg";

type IChangeOverviewProps = {
  initialValue: string;
  updatedValue: string;
};

export const ChangeOverview = ({ initialValue, updatedValue }: IChangeOverviewProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.currentValue}>{initialValue}</div>
      <img src={arrowRight} alt="arrow-icon" />
      <div className={styles.updatedValue}>{updatedValue}</div>
    </div>
  );
};
