import { IAccountInsurance } from "../Content/Insurance";
import { IBaseAccount } from "./BaseAccount";

export interface ICreditAccountApiResponse {
  creditAccount?: ICreditAccount;
  error?: string;
}

export interface ICreditAccountsApiResponse {
  creditAccounts?: ICreditAccount[];
  errors?: string;
}

export interface ICreditAccount extends ICreditBaseAccount {
  debtorName: string;
  currency: string;
  creditLimit?: number;
  accountInterest?: number;
  hasInsurance?: boolean;
  insurances?: IAccountInsurance[];
  canRequestInsurance?: boolean;
  cards?: ICard[];
  paymentReference?: string;
}

export interface ICreditBaseAccount extends IBaseAccount {
  balance?: number;
}
export interface ICreditAccountInfoLabels {
  accountInformationLabel: string;
  accountNumberLabel: string;
  accountTypeLabel: string;
  cardLabel: string;
  cardNumberLabel: string;
  creditLimitLabel: string;
  expiresAtLabel: string;
  ppicLabel: string;
  statusLabel: string;
  debtorLabel: string;
  paymentReferenceLabel: string;
}

export interface ICreditInterestsFeesLabels {
  atmFeeLabel: string;
  bankTransferFeeLabel: string;
  currencyExchangeFeeLabel: string;
  interestFeesLabel: string;
  interestLabel: string;
  invoiceFeeLabel: string;
}

export interface ICard {
  cardId: string;
  cardHolderName: string;
  expiryDate: string;
  maskedCardNumber: string;
  status: CardStatus;
}

export enum CardStatus {
  Active = "Active",
  Inactive = "Inactive",
  Blocked = "Blocked",
  Expired = "Expired",
}

export enum CreditAccountNames {
  SantanderRed = "SantanderRed",
  AcceptCard = "AcceptCard",
}
