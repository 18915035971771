import * as React from "react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IPowerOfAttorneyPage } from "netbank-shared/src/libs/models/Content/Page";
import { observer } from "mobx-react";
import { tx } from "netbank-shared/src/libs/i18n";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { SelfServicePageSkeleton } from "~views/pages/Skeletons/SelfServicePageSkeleton";
import { PowerOfAttorneyForm } from "./PowerOfAttorneyForm";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts";
import { IPowerOfAttorneyForm } from "netbank-shared/src/libs/forms/PowerOfAttorneyForm";

export const PowerOfAttorneyPage = observer(() => {
  const { loanStore, leasingStore, contentStore, rootStore } = useStores();
  const { currentAccount } = rootStore;
  const pageData = contentStore.currentPage as IPowerOfAttorneyPage;
  const accountType = currentAccount?.accountType;

  const getStore = () => {
    switch (accountType) {
      case AccountType.SecuredLoan:
        return loanStore;
      case AccountType.Leasing:
        return leasingStore;
      default:
        return null;
    }
  };

  const store = getStore();

  if (!currentAccount || !store) {
    return <SelfServiceFailurePage data={pageData.emptyState} />;
  }

  const {
    getPowerOfAttorneyTerms,
    resetPowerOfAttorneyState,
    createPowerOfAttorney,
    powerOfAttorneyTerms,
    fetchingPowerOfAttorneyTerms,
    fetchingPowerOfAttorneyTermsError,
    creatingPowerOfAttorneyError,
  } = store;

  const { successContent, errorContent } = pageData;

  useFetchData({
    callback: () => getPowerOfAttorneyTerms(currentAccount.accountId),
    condition: !fetchingPowerOfAttorneyTerms,
    cleanup: () => resetPowerOfAttorneyState(),
  });

  const handleSubmit = async (formData: IPowerOfAttorneyForm) => {
    await createPowerOfAttorney(currentAccount.accountId, formData);
  };

  if (fetchingPowerOfAttorneyTerms) return <SelfServicePageSkeleton />;

  if (!powerOfAttorneyTerms || fetchingPowerOfAttorneyTermsError)
    return <SelfServiceFailurePage data={pageData.emptyState} />;

  const formComponent = (() => {
    const goToAccountUrl = getProductUrl(currentAccount.accountType, currentAccount.accountId);
    switch (creatingPowerOfAttorneyError) {
      case false:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={goToAccountUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case true:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={goToAccountUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      default:
        return (
          <PowerOfAttorneyForm
            pageData={pageData}
            terms={powerOfAttorneyTerms}
            goToAccountUrl={goToAccountUrl}
            onSubmit={handleSubmit}
          />
        );
    }
  })();

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText,
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: pageData.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  return <AccountActionContainer info={pageInfo}>{formComponent}</AccountActionContainer>;
});
