import { AccordionName } from "libs/models/Content/Enums";
import React from "react";

interface IAccordionContext {
  expandedAccordions?: AccordionName[];
}

export const AccordionContext = React.createContext<IAccordionContext>({
  expandedAccordions: undefined,
});
