import * as yup from "yup";
import { MobilePhoneLocale, isMobilePhone } from "validator";
import { Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { AccountType } from "../../models/CustomerProducts";
import { IReduceInstallmentsPage } from "libs/models/Content/Page";
import { getEmploymentTypeList, getHousingTypeList, requiresEmploymentDetails } from "../SharedFormDropdowns";

export interface IReduceInstallmentsForm {
  amount: number;
  phone: string;
  employmentType: string;
  housingType: string;
  income: number;
  termsAccepted: boolean;
  employer?: string;
  occupation?: string;
  mileage?: number;
  message?: string;
  attachments?: File[];
}

interface IReduceInstallmentsProps {
  locale: Locale;
  accountType?: AccountType;
  page: IReduceInstallmentsPage;
  requiredError?: string;
  invalidError?: string;
}

export const generateReduceInstallmentsSchema = (
  props: IReduceInstallmentsProps,
): yup.ObjectSchema<IReduceInstallmentsForm> => {
  const { amount, phone, employmentType, housingType, income, termsCheckbox, employer, occupation, mileage } =
    props.page;
  const invalidAmountError = amount?.error || tx("validators.required");
  const invalidPhoneError = phone?.error || tx("validators.phone");
  const termsAcceptanceError = termsCheckbox?.error || tx("validators.required");
  const invalidEmploymentTypeError = employmentType?.error || tx("validators.required");
  const invalidHousingTypeError = housingType?.error || tx("validators.required");
  const invalidIncomeError = income?.error || tx("validators.required");
  const invalidEmployerError = employer?.error || tx("validators.required");
  const invalidOccupationError = occupation?.error || tx("validators.required");
  const invalidMileageError = mileage?.error || tx("validators.required");

  const employmentTypeOptions = getEmploymentTypeList();
  const validEmploymentTypes = employmentTypeOptions.map((item) => item.value);
  const housingTypeOptions = getHousingTypeList();
  const validHousingTypes = housingTypeOptions.map((item) => item.value);

  const schema = yup.object({
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(invalidAmountError)
      .positive(invalidAmountError),
    phone: yup
      .string()
      .required(invalidPhoneError)
      .test("isMobilePhone", invalidPhoneError, (value) => {
        return !!value && isMobilePhone(value, props.locale as MobilePhoneLocale);
      }),
    employmentType: yup
      .string()
      .oneOf(validEmploymentTypes, invalidEmploymentTypeError)
      .required(invalidEmploymentTypeError),
    employer: yup.string().when(["employmentType"], {
      is: requiresEmploymentDetails,
      then: (employerSchema) => employerSchema.required(invalidEmployerError),
    }),
    occupation: yup.string().when(["employmentType"], {
      is: requiresEmploymentDetails,
      then: (occupationSchema) => occupationSchema.required(invalidOccupationError),
    }),
    housingType: yup.string().oneOf(validHousingTypes, invalidHousingTypeError).required(invalidHousingTypeError),
    income: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(invalidIncomeError)
      .positive(invalidIncomeError)
      .integer(invalidIncomeError),
    message: yup.string().optional(),
    attachments: yup.array().optional(),
    termsAccepted: yup.boolean().required(termsAcceptanceError).oneOf([true], termsAcceptanceError),
    mileage: yup
      .number()
      .optional()
      .test("mileage", invalidMileageError, (value) => {
        return props.accountType === AccountType.SecuredLoan ? !!value && value > 0 : true;
      }),
  });

  return schema;
};
