import { action, computed, makeObservable, observable } from "mobx";
import { InboxTabs } from "../libs/models/Message/Inbox";
import { IConversation } from "../libs/models/Message/Conversations";
import { ConversationEmptyState, Direction, Product } from "../libs/models/Content/Enums";
import { CommonService } from "../libs/models/CommonService";
import { MessageApi } from "../libs/api";
import { CancelToken } from "apisauce";
import { CancelTokenSource } from "axios";
import { INewConversationForm } from "libs/forms/NewConversationForm";
import { IReplyMessageForm } from "libs/forms/ReplyMessageForm";

export class MessageStore {
  messageApi: MessageApi;

  commonService: CommonService;

  constructor(messageApi: MessageApi, commonService: CommonService) {
    this.messageApi = messageApi;
    this.commonService = commonService;
    makeObservable(this);
  }

  @observable allConversations?: IConversation[] = undefined;

  @observable pageSize: number = 20;

  @observable hasNextPage: boolean = false;

  @observable activeTab: InboxTabs = InboxTabs.Inbox;

  @observable conversationData?: IConversation = undefined;

  @observable fetchingConversations: boolean = false;

  @observable fetchingConversation: boolean = false;

  @observable downloadingAttachments: Set<string> = new Set();

  // TODO: Should get this from API or CMS
  @observable maxFilesPerMessage: number = 5;

  // TODO: Should get this value from API or CMS
  @observable maxFileSize: number = 5000000; // 5 MB

  @observable conversationEmptyState?: ConversationEmptyState = undefined;

  @observable newConversationState?: ConversationEmptyState = undefined;

  @observable pollingConversations: boolean = false;

  @observable getConversationCancelTokens: CancelTokenSource[] = [];

  // This is based on FI, possibly different in other countries
  acceptedDocumentMimeTypes = ["application/pdf", "image/jpeg", "image/gif", "image/png", "application/octet-stream"];

  acceptedDocumentExtensions = [".jpg", ".jpeg", ".gif", ".png", ".pdf"];

  @computed
  get inboxConversations(): IConversation[] | undefined {
    return this.allConversations && this.filterConversationList(this.allConversations).filter((c) => !c.isArchived);
  }

  @computed
  get archivedConversations(): IConversation[] | undefined {
    return this.allConversations && this.filterConversationList(this.allConversations).filter((c) => c.isArchived);
  }

  @computed
  get sentConversations(): IConversation[] | undefined {
    return this.allConversations?.filter(
      (c) => c.messages.find((m) => m.direction === Direction.Sent) && !c.isArchived,
    );
  }

  @computed
  get unreadAmount(): number {
    const filteredConversations =
      this.allConversations &&
      this.filterConversationList(this.allConversations)?.filter((conversation) => conversation.hasUnreadMessages);
    return filteredConversations?.length || 0;
  }

  @computed
  get conversations(): IConversation[] | undefined {
    switch (this.activeTab) {
      case InboxTabs.Inbox:
        return this.inboxConversations;
      case InboxTabs.Archive:
        return this.archivedConversations;
      case InboxTabs.Sent:
        return this.sentConversations;
      default:
        return undefined;
    }
  }

  @action
  setActiveTab = (tab: InboxTabs) => {
    this.activeTab = tab;
  };

  hasResponseFromCustomerService = (conversation: IConversation) => {
    return conversation.messages.some((message) => message.direction === Direction.Received);
  };

  filterConversationList = (list: IConversation[]) => {
    return list.filter((conversation) => this.hasResponseFromCustomerService(conversation));
  };

  @action
  getConversations = async (loading: boolean = true) => {
    const cancelSource = CancelToken.source();
    this.getConversationCancelTokens.push(cancelSource);

    this.fetchingConversations = loading;
    this.pollingConversations = !loading;
    const response = await this.messageApi.getConversations(cancelSource.token);

    if (response?.ok && response.data) {
      const conversationList = [...response.data.conversations].sort((a, b) => {
        const messagesA = [...a.messages].sort((messageA, messageB) => {
          return new Date(messageB.createdDate).getTime() - new Date(messageA.createdDate).getTime();
        });
        const messagesB = [...b.messages].sort((messageA, messageB) => {
          return new Date(messageB.createdDate).getTime() - new Date(messageA.createdDate).getTime();
        });

        const createdDateA = messagesA[0].createdDate;
        const createdDateB = messagesB[0].createdDate;
        const dateA = new Date(createdDateA).getTime();
        const dateB = new Date(createdDateB).getTime();
        return dateB - dateA;
      });

      this.allConversations = conversationList;
    }
    this.fetchingConversations = false;
    this.pollingConversations = false;
    this.getConversationCancelTokens = this.getConversationCancelTokens.filter(
      (source) => source.token !== cancelSource.token,
    );
  };

  @action
  getConversation = async (id: string) => {
    this.fetchingConversation = true;
    const response = await this.messageApi.getConversation(id);
    if (response?.ok && response.data) {
      const { conversation } = response.data;
      this.conversationData = conversation;
    } else {
      this.conversationEmptyState = ConversationEmptyState.ConversationNotFound;
    }
    this.fetchingConversation = false;
  };

  @action
  createConversation = async (formValues: INewConversationForm) => {
    const requestValues = formValues;

    if (requestValues.productData?.accountId === Product.NoProduct) {
      requestValues.productData.accountId = "";
    }

    const response = await this.messageApi.createConversation(requestValues);

    if (response?.ok) {
      this.newConversationState = ConversationEmptyState.CreateConversationSuccess;
    } else {
      this.newConversationState = ConversationEmptyState.CreateConversationFailed;
    }
  };

  @action
  reply = async (conversationId: string, replyForm: IReplyMessageForm) => {
    const newReplyMessageRequest = {
      conversationId,
      body: replyForm.body,
      files: replyForm.files,
    };

    const response = await this.messageApi.createReplyMessage(newReplyMessageRequest);

    if (response?.ok) {
      this.conversationEmptyState = ConversationEmptyState.ReplyMessageSuccess;
    } else {
      this.conversationEmptyState = ConversationEmptyState.ReplyMessageFailed;
    }
  };

  @action
  markAsRead = async (conversationId: string) => {
    // Cancel all ongoing get-conversation requests as they can overwrite the updated read state
    this.getConversationCancelTokens.forEach((source) => {
      source.cancel();
    });
    const response = await this.messageApi.markConversationAsRead(conversationId);

    // If marAsRead request succeeds we can assume the conversation will have the hasUnreadMessage
    // state set to false. To avoid flashing read/unread state while waiting for get conversations,
    // we set it directly in state.
    if (response?.ok) {
      if (this.allConversations) {
        const index = this.allConversations?.findIndex((c) => c.conversationId === conversationId);
        const updatedConversations = this.allConversations;
        updatedConversations[index].hasUnreadMessages = false;
        this.allConversations = updatedConversations;
      }
    }
  };

  @action
  archive = async (conversationId: string) => this.messageApi.archiveConversation(conversationId);

  @action
  downloadAttachment = async (attachmentId: string) => {
    if (this.downloadingAttachments.has(attachmentId)) return;

    this.downloadingAttachments.add(attachmentId);
    const response = await this.messageApi.downloadAttachment(attachmentId);

    if (response?.ok && response.data?.attachment) {
      const { fileData, fileName, mimeType } = response.data.attachment;
      if (fileData) {
        await this.commonService.downloadDocument(fileData, fileName, mimeType);
      }
    }
    this.downloadingAttachments.delete(attachmentId);
  };

  @action
  resetConversationData = () => {
    this.conversationData = undefined;
  };

  @action
  resetStore = (resetConversations = false) => {
    this.activeTab = InboxTabs.Inbox;
    this.conversationData = undefined;
    this.fetchingConversations = false;
    this.fetchingConversation = false;
    this.conversationEmptyState = undefined;
    this.newConversationState = undefined;
    if (resetConversations) {
      this.allConversations = undefined;
      this.hasNextPage = false;
    }
  };
}
