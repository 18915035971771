import React from "react";
import { useStores } from "netbank-shared/src/hooks";
import { IPromiseToPayPage } from "netbank-shared/src/libs/models/Content/Page";

import { observer } from "mobx-react";
import { IBaseAccount, IPromiseToPayInvoiceInfo } from "netbank-shared/src/libs/models/CustomerProducts";
import { Locale } from "netbank-shared/src/libs/models/Content/Enums";
import { PromiseToPayDKForm } from "./PromiseToPayDKForm";
import { NotFoundPage } from "~views/pages/NotFound/NotFound";
import { PromiseToPayFIForm } from "./PromiseToPayFIForm";

export interface IPromiseToPayProps {
  data: IPromiseToPayPage;
  invoiceInfo: IPromiseToPayInvoiceInfo;
  goToAccountUrl: string;
  baseAccount: IBaseAccount;
}

export const PromiseToPayForm = observer((props: IPromiseToPayProps) => {
  const { uiStore } = useStores();

  switch (uiStore.locale) {
    case Locale.dk:
      return <PromiseToPayDKForm {...props} />;
    case Locale.fi:
      return <PromiseToPayFIForm {...props} />;
    default:
      return <NotFoundPage />;
  }
});
