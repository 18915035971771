import React from "react";
import { observer } from "mobx-react";
import styles from "./LinkItemCollection.scss";
import { ILink, ILinkItemBlock, ILinkItemCollection } from "netbank-shared/src/libs/models/Content/LinkItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { DynamicLink } from "../DynamicLink/DynamicLink";

interface ILinkItemCollectionProps {
  linkItemCollection: IGenericContentLink<ILinkItemCollection>;
  wrapperStyles?: string;
  contentWrapperStyles?: string;
  titleWrapperStyles?: string;
  linksWrapperStyles?: string;
  noTitles?: boolean;
}

export const LinkItemCollection = observer(
  ({
    linkItemCollection,
    wrapperStyles,
    contentWrapperStyles,
    titleWrapperStyles,
    linksWrapperStyles,
    noTitles = false,
  }: ILinkItemCollectionProps) => {
    const links: ILinkItemBlock[] | ILink[] = linkItemCollection?.linkItems || linkItemCollection?.links;
    const title = linkItemCollection?.header;

    if (links === undefined || links?.length <= 0) return null;

    const classes = [styles.wrapper, wrapperStyles];
    const contentClasses = [styles.content, contentWrapperStyles];
    const titleWrapperClasses = [styles.titleWrapper, titleWrapperStyles];
    const linksWrapperClasses = [styles.linksWrapper, linksWrapperStyles];

    return (
      <div className={classes.join(" ")}>
        <div className={contentClasses.join(" ")}>
          {!noTitles && <div className={titleWrapperClasses.join(" ")}>{title}</div>}
          {links?.length > 0 && (
            <div className={linksWrapperClasses.join(" ")}>
              {links?.map((linkItem: ILinkItemBlock | ILink, i: number) => {
                const link: ILink = (linkItem as ILinkItemBlock)?.link?.[0] || linkItem;
                if (link?.href === undefined || link?.href === "") return null;
                return (
                  <DynamicLink
                    key={`${link?.contentLink?.guidValue}-${i}`}
                    to={link?.href}
                    target={link?.target}
                    trackingAction={(linkItem as ILinkItemBlock)?.trackingAction}
                    trackingCategory={(linkItem as ILinkItemBlock)?.trackingCategory}
                  >
                    {link?.title || link?.text}
                  </DynamicLink>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
);
