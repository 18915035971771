import { observer } from "mobx-react";
import React from "react";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { IMileageChangePage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import {
  IMileageChangeForm,
  generateMileageChangeSchema,
  getRequestedMileageList,
} from "netbank-shared/src/libs/forms/PaymentPlanChange";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormWrapper,
  InputFormField,
  DropdownFormField,
  FileAttachmentFormField,
  TextAreaFormField,
} from "~views/shared/Forms";
import { useNavigate } from "react-router-dom";
import { AccountType } from "netbank-shared/src/libs/models/CustomerProducts";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { SelfServiceFormResult } from "../SelfServiceFormPage/SelfServiceFormResult";
import { SelfServiceSubmissionState } from "netbank-shared/src/libs/models/Content/Enums";

export interface ILeasingMileageChangePageProps {
  page: IMileageChangePage;
}

export const LeasingMileageChangePage = observer(({ page }: ILeasingMileageChangePageProps) => {
  const navigate = useNavigate();
  const { leasingStore, uiStore, customerStore } = useStores();
  const { currentCustomer } = customerStore;
  const {
    currentMileage,
    requestedMileage,
    phone,
    message,
    fileUploader,
    submitLabel,
    cancelLabel,
    successContent,
    errorContent,
  } = page;
  const { currentAccount, selfServiceSusbmissionState } = leasingStore;

  const methods = useForm<IMileageChangeForm>({
    resolver: yupResolver(
      generateMileageChangeSchema({ locale: uiStore.locale!, accountType: AccountType.Leasing, page }),
    ),
    defaultValues: {
      phone: currentCustomer?.mobilePhone?.value,
    },
  });
  const { isSubmitted } = methods.formState;

  useFetchData({
    cleanup: () => leasingStore.resetSelfServiceState(),
  });

  if (!currentAccount?.canRequestMileageChange) {
    return <SelfServiceFailurePage data={page.emptyState} />;
  }

  const requestedMileageOptions = getRequestedMileageList(AccountType.Leasing, uiStore.locale!);

  const onSubmit: SubmitHandler<IMileageChangeForm> = async (data) =>
    leasingStore.requestMileageChange({ ...data, requestedMileage: data.requestedMileage.replace(/[\s,.]/g, "") });

  const pageInfo = {
    title: page.pageTitle,
    text: page.pageText || "",
    accountInfo: [
      {
        label: page.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: page.displayNumberHeaderLabel || tx("misc.accountNumber"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  const productUrl = getProductUrl(AccountType.Leasing, currentAccount.accountId);

  const renderFlow = () => {
    switch (selfServiceSusbmissionState) {
      case SelfServiceSubmissionState.Success:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (successContent && successContent.header) || tx("selfServiceForm.resultHeader"),
              text: successContent && successContent.textContent,
              buttonLabel: (successContent && successContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.success}
          />
        );
      case SelfServiceSubmissionState.Error:
        return (
          <SelfServiceFormResult
            goBackToAccountUrl={productUrl}
            content={{
              header: (errorContent && errorContent.header) || tx("selfServiceForm.unexpectedErrorText"),
              text: errorContent && errorContent.textContent,
              buttonLabel: (errorContent && errorContent.buttonLabel) || tx("selfServiceForm.goBack"),
            }}
            contentType={NotificationLevel.error}
          />
        );
      case SelfServiceSubmissionState.NotSubmitted:
        return (
          <FormWrapper
            formMethods={methods}
            onSubmit={onSubmit}
            submitLabel={submitLabel || tx("misc.send")}
            cancelAction={() => navigate(productUrl)}
            cancelLabel={cancelLabel || tx("selfServiceForm.goBack")}
          >
            <InputFormField
              type="number"
              label={currentMileage?.label || tx("mileageChange.currentMileageLabel")}
              infoPopover={{ popoverTitle: currentMileage?.infoTitle, content: currentMileage?.infoText }}
              name="currentMileage"
              placeholder={currentMileage?.placeholder}
              suffix="km"
              triggerValidation={isSubmitted}
            />
            <DropdownFormField
              label={requestedMileage?.label || tx("mileageChange.requestedMileageLabel")}
              infoPopover={{ popoverTitle: requestedMileage?.infoTitle, content: requestedMileage?.infoText }}
              name="requestedMileage"
              list={requestedMileageOptions}
            />
            <InputFormField
              type="tel"
              label={phone?.label || tx("selfServiceLabels.phoneLabel")}
              infoPopover={{ popoverTitle: phone?.infoTitle, content: phone?.infoText }}
              name="phone"
              placeholder={phone?.placeholder}
              allowedCharacters={/[^0-9+]/g}
            />
            <TextAreaFormField
              label={message?.label || tx("selfServiceLabels.messageLabel")}
              infoPopover={{ popoverTitle: message?.infoTitle, content: message?.infoText }}
              name="message"
              placeholder={message?.placeholder}
            />
            <FileAttachmentFormField
              fileInputId="leasingMileageChange"
              name="attachments"
              label={fileUploader?.label || tx("selfServiceLabels.fileUploaderLabel")}
              infoPopover={{ popoverTitle: fileUploader?.infoTitle, content: fileUploader?.infoText }}
              desktopLabel={fileUploader?.desktopLabel}
              desktopLabelSuffix={fileUploader?.desktopLabelSuffix}
              mobileLabel={fileUploader?.mobileLabel}
              maxFileSizeLabel={fileUploader?.maxFileSizeLabel}
              acceptedExtensionsLabel={fileUploader?.acceptedExtensionsLabel}
            />
          </FormWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <AccountActionContainer info={pageInfo} state={selfServiceSusbmissionState}>
      {renderFlow()}
    </AccountActionContainer>
  );
});
