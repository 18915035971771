/* eslint-disable no-undef */
import { tx } from "../i18n";
import { ListItem } from "../models/Content/ListItem";

export enum EmploymentType {
  None = "",
  Permanent = "Permanent",
  Temporary = "Temporary",
  Retired = "Retired",
  PartTime = "PartTime",
  Student = "Student",
  Unemployed = "Unemployed",
}

export enum HousingType {
  None = "",
  Owned = "Owned",
  Rental = "Rental",
  SharedHousehold = "SharedHousehold",
}

export const getEmploymentTypeList = (): ListItem[] =>
  Object.keys(EmploymentType)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (EmploymentType as KeyObject<string>)[key],
      value: tx(`selfServiceLabels.employmentType.${key}`),
      label: tx(`selfServiceLabels.employmentType.${key}`),
    }));

export const getHousingTypeList = (): ListItem[] =>
  Object.keys(HousingType)
    .filter((key) => key !== "None")
    .map((key, index) => ({
      index,
      enumValue: (HousingType as KeyObject<string>)[key],
      value: tx(`selfServiceLabels.housingType.${key}`),
      label: tx(`selfServiceLabels.housingType.${key}`),
    }));

export const checkHasEmployer = (employmentType: EmploymentType) =>
  [EmploymentType.Permanent, EmploymentType.PartTime, EmploymentType.Temporary].includes(employmentType);

export const requiresEmploymentDetails = (employmentType: EmploymentType) => {
  const employmentTypeOptions = getEmploymentTypeList();
  const selectedEmploymentType = employmentTypeOptions.find((option) => option.value === employmentType);
  return selectedEmploymentType && checkHasEmployer(selectedEmploymentType.enumValue as EmploymentType);
};
