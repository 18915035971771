import { observer } from "mobx-react";

import React from "react";
import { tx } from "netbank-shared/src/libs/i18n";
import NotFound from "~assets/404.svg";
import NotFoundMobile from "~assets/404-mobile.svg";

import styles from "./RetryPage.scss";
import santanderLogotype from "~assets/santander-logo.svg";

import { useStores } from "netbank-shared/src/hooks";

export const RetryPage = observer(() => {
  const { uiStore } = useStores();
  const { isMobile } = uiStore;

  return (
    <div className={styles.pageWrapper}>
      <header className={`${styles.loginHeader} ${styles.flexBetween}`}>
        <a href="/">
          <img className={styles.loginLogotype} src={santanderLogotype} alt="Santander" />
        </a>
      </header>
      <div className={styles.wrapper}>
        <section className={styles.content}>
          <div className={styles.textContent}>
            <h1>{tx("retryPage.title")}</h1>
            <p>{tx("retryPage.text")} </p>
            <a href={window.location.href}>{tx("retryPage.reloadLink")}</a>
          </div>
          <img className={styles.notFoundImage} src={isMobile ? NotFoundMobile : NotFound} alt="Not Found" />
        </section>
      </div>
    </div>
  );
});
