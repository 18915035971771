import { ILoanAccountSubmitTermination, ILoanAccountTerminationDetails } from "libs/models/CustomerProducts";
import { mockSalesFinanceLoanAccount, mockPrivateLoanAccountOne, mockPrivateLoanAccountTwo } from "./privateLoan";
import { mockSecuredLoanAccountOne, mockSecuredLoanAccountTwo } from "./securedLoan";
import { IDueDayResponse } from "libs/models/UpdateDueDay";
import { IPowerOfAttorneyFormInfo, IPowerOfAttorneyTerms } from "../../../models/PowerOfAttorney";
import { ExportCountry, InsuranceCompany } from "../../../models/Content/Enums";

export const mockLoanAccounts = {
  loanAccounts: [
    mockPrivateLoanAccountOne,
    mockPrivateLoanAccountTwo,
    mockSecuredLoanAccountOne,
    mockSecuredLoanAccountTwo,
    mockSalesFinanceLoanAccount,
  ],
  errors: "",
};

export * from "./payments";

export const mockLoanAccountTerminationsDetails: ILoanAccountTerminationDetails = {
  accountId: "CV210651817",
  accountNumber: "210651817",
  accountName: "Privatlån",
  validToDate: "2023-09-13T22:00:00+00:00",
  dueDate: "2023-09-13T22:00:00+00:00",
  matureDate: "2030-01-31T23:00:00+00:00",
  interestRate: 10.44,
  balance: -164800.0,
  calculatedBalance: 195316.7,
  interest: 16846.0,
  invoiceTotal: 195316.7,
  total: 0,
  expenses: 0.0,
  fees: 0.0,
  paymentInformation: {
    registrationNumber: "5295",
    accountNumber: "0010017009",
    referenceNumber: "210651817",
    ibanNumber: "DK1352950010017009",
  },
  consentQuestions: [
    {
      questionId: "SV0542b0de-6b7f-4985-b8db-6de564e6cc74",
      questionText: "Hvorfor ønsker du at indfri dit lån?",
      sortOrder: 0,
      answerOptions: [
        {
          answerId: "SV18d168e0-3a0d-4bb3-816e-3814916f5b8d",
          answerText: "Jeg vil flytte lånet til en anden bank",
          isDefaultAnswer: false,
          sortOrder: 0,
          collectContactPhoneNumber: false,
        },
        {
          answerId: "SVd742e123-54a2-409c-bc61-7098ca67d97e",
          answerText: "Jeg er kommet til penge og har ikke behov for lånet mere",
          isDefaultAnswer: false,
          sortOrder: 2,
          collectContactPhoneNumber: false,
        },
        {
          answerId: "SVad7e01bb-1205-48e7-a965-abae4d11f69c",
          answerText: "Jeg vil ikke indfri mit lån, men vil blot se beløbet",
          isDefaultAnswer: false,
          sortOrder: 3,
          collectContactPhoneNumber: false,
        },
        {
          answerId: "SVd358aa1b-e5e4-4d7f-916c-b0408c4ceb9f",
          answerText: "Jeg vil samle flere lån i en anden bank",
          isDefaultAnswer: false,
          sortOrder: 1,
          collectContactPhoneNumber: false,
        },
        {
          answerId: "SV153f0ee8-d392-4426-83bb-eebef1d06497",
          answerText: "Andet",
          isDefaultAnswer: false,
          sortOrder: 4,
          collectContactPhoneNumber: false,
        },
      ],
    },
    {
      questionId: "SV16eebd45-1e56-4d65-9eda-b3eb2d8a7de8",
      questionText: "Må vi kontakte dig?",
      sortOrder: 1,
      answerOptions: [
        {
          answerId: "SVba5408ff-33cf-4b66-a78b-129540fc3855",
          answerText: "Nej tak, kontakt mig venligst ikke",
          isDefaultAnswer: false,
          sortOrder: 1,
          collectContactPhoneNumber: false,
        },
        {
          answerId: "SV353778ee-39b6-4920-9cdd-dfb7752509a6",
          answerText: "Ja, kontakt mig venligst",
          isDefaultAnswer: false,
          sortOrder: 0,
          collectContactPhoneNumber: true,
        },
      ],
    },
  ],
};

export const mockSubmitLoanAccountTerminations: ILoanAccountSubmitTermination = {
  accountId: "CV210651817",
  accountNumber: "210651817",
  accountName: "Privatlån",
  success: true,
};

export const mockCurrentDueDay: IDueDayResponse = { currentDueDay: 10, canUpdateDueDay: true };

export const mockGetPowerOfAttorneyTerms: IPowerOfAttorneyTerms = {
  insuranceCompanyTerms: [
    {
      insuranceCompany: InsuranceCompany.Fennia,
      invalidCountries: [ExportCountry.Albania, ExportCountry.Iran],
    },
    {
      insuranceCompany: InsuranceCompany.Folksam,
      invalidCountries: [ExportCountry.CzechRepublic, ExportCountry.Cyprus],
    },
    {
      insuranceCompany: InsuranceCompany.Lahitapiola,
      invalidCountries: [],
    },
  ],
};

export const mockPowerOfAttorneyResponse: IPowerOfAttorneyFormInfo = {
  travelDate: new Date(),
  insuranceCompany: InsuranceCompany.Pop,
  travellingToInvalidCountry: true,
  acceptTermsAndExpense: true,
  countries: [ExportCountry.Albania, ExportCountry.Albania],
};
