import * as yup from "yup";
import { Locale } from "../../models/Content/Enums";
import { tx } from "../../i18n";
import { AccountType } from "../../models/CustomerProducts";
import { IPaymentHolidayPage } from "libs/models/Content/Page";

export interface IPaymentHolidayForm {
  months: Date[];
  termsAccepted: boolean;
}

interface IPaymentHolidayProps {
  locale: Locale;
  accountType?: AccountType;
  page: IPaymentHolidayPage;
  requiredError?: string;
}

export const generatePaymentHolidayForm = (props: IPaymentHolidayProps): yup.ObjectSchema<IPaymentHolidayForm> => {
  const { monthsPicker, termsCheckbox } = props.page;
  const requiredError = props.requiredError || tx("validators.required");
  const invalidMonthError = monthsPicker?.error || tx("validators.required");
  const termsAcceptanceError = termsCheckbox?.error || tx("validators.required");

  const schema = yup.object({
    months: yup.array().min(1, invalidMonthError).required(requiredError).of(yup.date().required()),
    termsAccepted: yup.boolean().required(termsAcceptanceError).oneOf([true], termsAcceptanceError),
  });

  return schema;
};
